import React from 'react'
import qs from 'qs'
import { useHistory } from 'react-router'
import UnsubscribedFromAppInvitationReminders from './UnsubscribedFromAppInvitationReminders'
import Page from '../../components/System/Layout/Page'
import styled from 'styled-components'
import Middesk from '../../components/System/Icons/Middesk'
import { theme } from '@middesk/components'
import SomethingWentWrong from './SomethingWentWrong'
import ChangedNotificationSetting from './ChangedNotificationSetting'
import UnknownKeySuccess from './UnknownKeySuccess'

const { spacing, colors, typography } = theme

const LogoWrapper = styled.div`
  margin-bottom: ${spacing.xxlarge};
`

const BodyWrapper = styled.div`
  width: 60%;
  margin: auto;
  gap: 20px;
  background-color: ${colors.white};
  border: ${colors.dawn} 2px solid;
  border-radius: 8px;
  padding: 40px 100px;
  text-align: center;

  h1 {
    color: ${colors.graphite};
    font-family: ${typography.faces.display};
    font-weight: ${typography.weights.normal};
    font-size: ${typography.sizes.xlarge};
    margin-bottom: ${spacing.xlarge};
  }

  a {
    colors: ${colors.blue};
    font-weight: ${typography.weights.bold};
  }
`

const NoWidth = styled.div`
  width: 0px;
`

const keyComponents: {
  [key: string]: () => JSX.Element
} = {
  unsubscribe_from_application_invitation_reminders: UnsubscribedFromAppInvitationReminders,
  change_notification_setting: ChangedNotificationSetting
}

const EmailLinkCallback = (): JSX.Element => {
  const history = useHistory()
  const params = qs.parse(history.location.search, {
    ignoreQueryPrefix: true
  })

  const Component = ((): (() => JSX.Element) => {
    if (params.success !== 'true') {
      return SomethingWentWrong
    }

    return keyComponents[params.key as string] || UnknownKeySuccess
  })()

  return (
    <Page displaySubtitle={false}>
      <NoWidth />
      <>
        <LogoWrapper>
          <Middesk />
        </LogoWrapper>

        <BodyWrapper>
          <Component />
        </BodyWrapper>
      </>
    </Page>
  )
}

export default EmailLinkCallback
