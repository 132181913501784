export type SpacingSize =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
export type SpacingUnit = 5 | 10 | 20 | 30 | 40 | 60
export type SpacingTerm = `${SpacingUnit}px`

const SPACING: Record<SpacingSize, SpacingTerm> = {
  xsmall: '5px',
  small: '10px',
  medium: '20px',
  large: '30px',
  xlarge: '40px',
  xxlarge: '60px'
}

export default SPACING
