import { Form, PasswordStrength, TextField } from '@middesk/components'
import { parse } from 'qs'
import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import ActionFooter from '../components/ActionFooter'
import Brand from '../components/Brand'
import Button from '../components/Button'
import LandingCard from '../components/LandingCard'
import LinkFooter from '../components/LinkFooter'
import { COLORS } from '../components/System/Colors'
import Link from '../components/System/Link'
import LandingGlobalStyle from '../LandingGlobalStyle'
import API from '../lib/api'

const initialValues = {
  password: '',
  confirm_password: ''
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
})

const ResetPassword = () => {
  const [values, setValues] = useState(initialValues)
  const history = useHistory()
  const { search } = useLocation()

  const { email, token } = parse(search, {
    ignoreQueryPrefix: true
  })
  if (!email || !token) {
    return <h2>Page not found</h2>
  }
  const reset = () => {
    API.post('/password', {
      email,
      confirmation_token: token,
      password: values.password
    }).then(() => {
      history.push('/login')
    })
  }
  return (
    <>
      <LandingGlobalStyle />
      <LandingCard>
        <Row center='xs'>
          <Col>
            <Brand color={COLORS.indigo} height='45px' />
            <p>Update password</p>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Form
              {...{
                initialValues,
                validationSchema,
                onSubmit: reset,
                onChange: (e: any) => setValues(e)
              }}
            >
              <Row>
                <Col xs>
                  <TextField
                    placeholder='Enter new password'
                    name='password'
                    label='Password'
                    type='password'
                  />
                </Col>
              </Row>
              <Row>
                <Col xs>
                  <PasswordStrength password={values.password} />
                </Col>
              </Row>
              <Row>
                <Col xs>
                  <TextField
                    placeholder='Confirm password'
                    name='confirm_password'
                    label='Confirm Password'
                    type='password'
                  />
                </Col>
              </Row>
              <ActionFooter>
                <Button
                  type='submit'
                  disabled={!validationSchema.isValidSync(values)}
                >
                  Change password
                </Button>
              </ActionFooter>
            </Form>
          </Col>
        </Row>
      </LandingCard>
      <LinkFooter>
        <Link href='/login'>Return to sign in</Link>
      </LinkFooter>
    </>
  )
}

export default ResetPassword
