import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { parse } from 'qs'
import Body from '../components/Body'
import CreateAccountFormNew from '../components/Forms/CreateAccountFormNew'
import LinkFooter from '../components/LinkFooter'
import SubText from '../components/SubText'
import api from '../lib/api'
import { openFAQ } from '../lib/helpers'
import { Banner } from '@middesk/components'
import Link from '../components/System/Link'
import {
  XXLarge,
  Large,
  TYPOGRAPHY_SIZES,
  TYPOGRAPHY_WEIGHTS
} from '../components/System/Typography'
import SPACING from '../components/System/Spacing'
import { COLORS } from '../components/System/Colors'
import Middesk from '../components/System/Icons/Middesk'
import { Col, Row } from 'react-styled-flexboxgrid'
import { ReferringAccount } from '../types'
import { getPartnerReferralCookieCode } from '../lib/partnerCookie'

const PRIVACY_LINK = 'https://middesk.com/privacy'

export const SignupHeader = styled(({ className }) => (
  <div className={className}>
    <Middesk />
  </div>
))`
  align-items: center;
  display: flex;
  flex-direction: row;
  max-height: 100px;
  margin: ${SPACING.xlarge};
  gap: ${SPACING.small};

  ${Large} {
    color: ${COLORS.black};
    font-size: ${TYPOGRAPHY_SIZES.xlarge}px;
  }
`

const Sidebar = styled(
  ({ className }): JSX.Element => {
    return (
      <div className={className}>
        <div>
          <XXLarge bold>
            Simplified payroll registration and compliance for employers
          </XXLarge>
          <div>
            <img src='/icons/signup_1.svg' />
            <div>
              <h4>Register in states in minutes</h4>
              <p>
                Set up state unemployment and withholding accounts in minutes,
                instead of days.
              </p>
            </div>
          </div>
          <div>
            <img src='/icons/signup_2.svg' />
            <div>
              <h4>Stay compliant as you grow</h4>
              <p>
                Offload dealing with the government directly and use Agent to
                cut through administrative blockers.
              </p>
            </div>
          </div>
          <div>
            <img src='/icons/signup_3.svg' />
            <div>
              <h4>Use one source for government accounts</h4>
              <p>
                Agent brings together all your state registrations. See the
                status of your applications in an easy-to-use dashboard and be
                notified when you need to take action.
              </p>
            </div>
          </div>
          <div>
            <span>
              <Link onClick={openFAQ}>Learn more in our Help Center</Link>
            </span>
          </div>
          <div>
            <span>
              Have questions?&nbsp;
              <Link href='mailto:agent@middesk.com'>Contact us</Link>
            </span>
          </div>
        </div>
      </div>
    )
  }
)`
  background: ${COLORS.dawn};
  height: 100%;
  width: 100%;

  padding-top: 80px;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-left: 5vw;

    > div {
      align-items: center;
      display: flex;
      margin: ${SPACING.small};

      > img {
        margin-right: ${SPACING.medium};
      }

      > span {
        font-size: ${TYPOGRAPHY_SIZES.large}px;
        font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
      }

      ${Link} {
        font-size: ${TYPOGRAPHY_SIZES.large}px;
      }
    }

    > div:first-child,
    div:nth-child(4) {
      margin-bottom: ${SPACING.large};
    }
  }
`

const SignupBox = styled(
  ({ className }): JSX.Element => {
    const history = useHistory()
    const [
      referringAccount,
      setReferringAccount
    ] = useState<ReferringAccount | null>(null)

    const { search } = useLocation()
    const { referral_code } = parse(search, {
      ignoreQueryPrefix: true
    })

    useEffect(() => {
      if (referral_code) {
        api
          .get(`/referrals/${referral_code}`)
          .then(json => {
            setReferringAccount(json)
          })
          /* eslint-disable-next-line no-console */
          .catch((e: any) => console.log(e))
      }
    }, [])

    useEffect(() => {
      const referralCode = getPartnerReferralCookieCode()

      if (referralCode) {
        history.push(`/referrals/${referralCode}`)
      }
    }, [])

    const showReferralBanner = () => {
      return referringAccount && referringAccount.name !== 'Zeal'
    }

    const getPartnerMessage = () => {
      if (!referringAccount) {
        return
      }

      if (
        referringAccount.partner_discount &&
        referringAccount.partner_discount.discount_type === 'percent'
      ) {
        return `${referringAccount.name} has invited you to get off ${referringAccount.partner_discount.amount}% your first year of services.`
      }

      return `${referringAccount.name} has invited you to receive a discount`
    }

    if (referringAccount?.agent_partner_referral_link) {
      window.location.href = referringAccount.agent_partner_referral_link
    }

    return (
      <div className={className}>
        {showReferralBanner() && (
          <Banner intent='info' style={{ marginBottom: SPACING.large }}>
            {getPartnerMessage()}
          </Banner>
        )}
        <Body title='Create your account'>
          <CreateAccountFormNew />
          <LinkFooter>
            <SubText>
              Already have an account? <Link href='/login'>Sign In</Link>・By
              creating an account you agree to our{' '}
              <Link target='_blank' href='/terms'>
                Terms
              </Link>{' '}
              and{' '}
              <Link target='_blank' href={PRIVACY_LINK}>
                Privacy Policy
              </Link>
            </SubText>
          </LinkFooter>
        </Body>
      </div>
    )
  }
)`
  max-height: 450px;
  max-width: 600px;
  margin: auto;

  ${SubText} {
    margin-left: ${SPACING.xsmall};
    text-align: left;
  }
`

const SignupNew = styled(
  ({ className }): JSX.Element => {
    return (
      <div className={className}>
        <Row>
          <Col xs={7}>
            <SignupHeader />
            <SignupBox />
          </Col>
          <Col xs={5}>
            <Sidebar />
          </Col>
        </Row>
      </div>
    )
  }
)`
  height: 100vh;
  overflow: hidden;

  > *:first-child {
    height: 100%;
  }
`

export default SignupNew
