import React, { useContext } from 'react'
import styled from 'styled-components'
import { TransfersIntakeComponentProps } from '../../../types'
import TransfersIntakeCard from '../TransfersIntakeCard'
import SPACING from '../../System/Spacing'
import { LargeDisplay } from '../../System/Typography'
import Button from '../../System/Button'
import { Link } from 'react-router-dom'
import OrderSubmittedIcon from '../../System/Icons/OrderSubmitted'
import IconBubble from '../../System/IconBubble'
import { AuthContext } from '../../../contexts/AuthProvider'

const OrderSubmittedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${SPACING.medium};
  justify-content: center;
  text-align: center;
`

const CenteredUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`
const OrderSubmitted = ({
  onNext,
  onBack
}: TransfersIntakeComponentProps): JSX.Element => {
  const { inGuestMode } = useContext(AuthContext)

  return (
    <TransfersIntakeCard isLast onBack={onBack} onNext={onNext}>
      <OrderSubmittedContainer>
        <IconBubble variant='green'>
          <OrderSubmittedIcon />
        </IconBubble>
        <LargeDisplay>You&apos;re all set!</LargeDisplay>
        {inGuestMode ? (
          <>
            <div>
              Thank you for submitting the order! We&apos;ll let you know when
              your account information has been received from the state.
            </div>
          </>
        ) : (
          <>
            <div>
              We&apos;ll make these changes to your state accounts. <br />
              <br />
              Once we&apos;ve transferred your accounts: <br />
              <CenteredUl>
                <li>Find incoming mail on the Mail page</li>
                <li>View state filings on the Documents page</li>
              </CenteredUl>
            </div>
            <Link to='/home'>
              <Button>View dashboard</Button>
            </Link>
          </>
        )}
      </OrderSubmittedContainer>
    </TransfersIntakeCard>
  )
}

export default OrderSubmitted
