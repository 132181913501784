import {
  GetForeignQualificationParameters,
  ForeignQualification
} from '../../types'
import api from '../api'

export type GetForeignQualificationResponse = {
  data: Array<ForeignQualification>
  has_more: boolean
  total_count: number
}

export const APIForeignQualification = {
  index: (
    companyId: string,
    params: GetForeignQualificationParameters
  ): Promise<GetForeignQualificationResponse> =>
    api.get(`/v1/agent/companies/${companyId}/foreign_qualifications`, params)
}
