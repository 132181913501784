import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import get from 'lodash/get'
import { Attribute, theme, Form, TextField, Button } from '@middesk/components'
import {
  TYPOGRAPHY_SIZES,
  TYPOGRAPHY_WEIGHTS
} from '../../components/System/Typography'
import { TaxRegistrationItem, TaxAgencyRegistration, Item } from '../../types'
import { StateDataContext } from '../../contexts/StateDataProvider'
import LoaderButton from '../../components/LoaderButton'
import Pencil from '../../components/System/Icons/Pencil'
import {
  formatItemValidations,
  validItemFormat
} from '../../components/UnmanagedItemsIntake'
import { APITaxAgencyRegistration } from '../../lib/api/taxAgencyRegistration'
import { transformQuestionFormatToMask } from '../../components/UnmanagedItemsIntake/UnmanagedItemsForm'

const { spacing, typography, colors } = theme

const ELEMENT_SPACING = '80px'

const BoldAttribute = styled(Attribute)`
  margin: 0 ${ELEMENT_SPACING} ${spacing.normal} 0;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};

  > div:nth-child(2) {
    padding-top: 5px;
    font-weight: ${TYPOGRAPHY_WEIGHTS.regular};
  }
`

const EmptyAttribute = styled(Attribute)`
  margin: 0 ${ELEMENT_SPACING} ${spacing.normal} 0;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;

  > div:nth-child(2) {
    padding-top: 5px;
    font-weight: ${TYPOGRAPHY_WEIGHTS.regular};
  }
`

const ItemsListWrapper = styled.div`
  display: flex;
  height: 100%:
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spacing.normal};
`

const ItemRow = styled.div`
  display: flex;
`

const EditUnmanagedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colors.karl};
  font-size: ${typography.sizes.medium};
  max-height: 20px;

  svg {
    margin-right: ${spacing.xsmall};
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-right: ${spacing.xsmall};
  }
`

const InputWrapper = styled.div`
  margin-top: ${spacing.large};
  width: 50%;
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${spacing.small};
`

const ErrorMessage = styled.div`
  display: flex;
  margin-top: ${spacing.medium};
  color: ${colors.magenta};
  justify-content: flex-end;
`

const TaxRegistrationItems = ({
  tar,
  updateTar
}: {
  tar: TaxAgencyRegistration
  updateTar: (tar: TaxAgencyRegistration) => void
}) => {
  const {
    items = [],
    tax_registration,
    foreign_qualification,
    state,
    agency,
    id,
    company_id
  } = tar
  const unmanaged = !tax_registration && !foreign_qualification
  const initialValues = items.reduce((acc, item: TaxRegistrationItem) => {
    return { ...acc, [item.key]: item.value }
  }, {})
  const { stateAgencyData } = useContext(StateDataContext)
  const [editItems, setEditItems] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(initialValues)
  const [errorMessage, setErrorMessage] = useState('')

  if (!editItems) {
    return (
      <ItemsListWrapper>
        <ItemRow>
          {items.map((item: TaxRegistrationItem, i: number) => {
            const { label, value } = item
            const ValueAttribute = value ? BoldAttribute : EmptyAttribute

            return (
              <ValueAttribute key={`item-${i}`} label={label}>
                {value || '-'}
              </ValueAttribute>
            )
          })}
        </ItemRow>
        {unmanaged && (
          <EditUnmanagedWrapper onClick={() => setEditItems(true)}>
            <Pencil />
            <span> Edit</span>
          </EditUnmanagedWrapper>
        )}
      </ItemsListWrapper>
    )
  }

  const agencyData = stateAgencyData.find(({ abbr }) => abbr === state)
  const {
    tax_registration: { agencies }
  } = agencyData
  const tarAgencyItems =
    agencies.find(({ name }: { name: string }) => name === agency)?.items || []

  const validation = tarAgencyItems.reduce(
    (obj: any, { key, validation, is_tax_id }: Item) => {
      const formats = formatItemValidations(validation?.format)

      return {
        ...obj,
        [key]: yup
          .string()
          .test('required', 'Required', value =>
            is_tax_id ? (value || '').length > 0 : true
          )
          .test(
            key,
            `Value must be in correct format: ${formats.join(', ')}`,
            value => validItemFormat(value, formats)
          )
          .nullable()
      }
    },
    {}
  )

  const validationSchema = yup.object().shape(validation)
  const isDisabled = !validationSchema.isValidSync(values)

  const onSubmit = () => {
    setSubmitting(true)
    setErrorMessage('')

    const updatedItems = items.map(item => {
      return { id: item.id, value: get(values, item.key) }
    })

    APITaxAgencyRegistration.update(company_id, {
      id,
      items: updatedItems
    })
      .then(data => {
        updateTar(data)
        setEditItems(false)
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please refresh and try again')
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onCancel = () => {
    setValues(initialValues)
    setEditItems(false)
    setErrorMessage('')
  }

  return (
    <div>
      <Form
        {...{ initialValues, validationSchema, onSubmit, onChange: setValues }}
      >
        <InputWrapper>
          {items.map((item: TaxRegistrationItem, i: number) => {
            const { key, label } = item
            const agencyItem = tarAgencyItems.find(
              ({ key: itemKey }: { key: string }) => itemKey === key
            )
            const formats = formatItemValidations(agencyItem.validation?.format)

            return (
              <StyledTextField
                key={`item-update-${key}-${i}`}
                name={key}
                label={label}
                placeholder={formats.join(', ')}
                type={formats.length === 1 ? 'masked' : undefined}
                mask={
                  formats.length === 1
                    ? transformQuestionFormatToMask(formats[0])
                    : undefined
                }
                showErrorMessage
              />
            )
          })}
        </InputWrapper>
        <ButtonRow>
          {!submitting && (
            <Button type='secondary' onClick={onCancel}>
              Cancel
            </Button>
          )}
          <LoaderButton
            {...{
              submitting,
              isDisabled,
              submitText: 'Save'
            }}
          />
        </ButtonRow>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Form>
    </div>
  )
}

export default TaxRegistrationItems
