import React, { useState } from 'react'
import api from '../lib/api'
import styled from 'styled-components'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { CheckboxField, Form, Modal } from '@middesk/components'
import RoleSelector from './RoleSelector'
import { User } from '../pages/Team'
import { COLORS } from './System/Colors'
import SPACING from '../components/System/Spacing'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'

const Label = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  line-height: 9px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: ${COLORS.karl};
`

const CheckboxContainer = styled.div`
  margin-top: ${SPACING.small};
`

const NameContainer = styled.div`
  margin-bottom: ${SPACING.small};
`

const Name = styled.span`
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

type UpdateUserFormValues = {
  role: string
  receive_agent_emails: boolean
}

interface UpdateUserModalProps {
  onClose: () => void
  user: User
  onSubmit: () => void
  isOpen: boolean
}

export const UpdateUserModal = ({
  onClose,
  user,
  onSubmit,
  isOpen
}: UpdateUserModalProps) => {
  const { addToast } = useToasts()

  const [values, setValues] = useState({
    role: user.roles.includes('admin') ? 'admin' : 'member',
    receive_agent_emails: user.settings.receives_agent_emails
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchema = yup.object().shape({
    role: yup.string().required()
  })

  const CUSTOM_MODAL_STYLES = {
    content: {
      width: '600px'
    }
  }

  const handleSubmit = (values: UpdateUserFormValues) => {
    setIsSubmitting(true)

    api
      .patch(`/users/${user.id}`, {
        roles: [values.role],
        receive_agent_emails: values.receive_agent_emails
      })
      .then(() => {
        addToast(`Successfully updated settings for ${user.name}`, {
          appearance: 'success'
        })
        onSubmit()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      close={onClose}
      closeLabel='Cancel'
      confirm={() => {
        handleSubmit(values)
      }}
      confirmLabel='Submit'
      isOpen={isOpen}
      title='Update user role'
      style={CUSTOM_MODAL_STYLES}
      isConfirmDisabled={!validationSchema.isValidSync(values) || isSubmitting}
    >
      <Form initialValues={values} onChange={setValues}>
        <NameContainer>
          <Name>{user.name}</Name>
        </NameContainer>
        <RoleSelector values={values} setValues={setValues} />
        <Label>Notifications</Label>
        <CheckboxContainer>
          <CheckboxField
            label='Receive email notifications'
            name='receive_agent_emails'
          />
        </CheckboxContainer>
      </Form>
    </Modal>
  )
}

export default UpdateUserModal
