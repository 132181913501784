import { useFormikContext } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Dropzone, DropzoneFile } from '@middesk/components'
import { BASE_64_IDENTIFIER } from '../../lib/helpers'
import {
  InfoRequestValues,
  DescriptionNote,
  OutboundAttachment,
  ButtonContainer
} from '../../pages/InfoRequestForm'
import { InfoRequestItem } from '../../types'
import { encode } from 'base64-arraybuffer'
import { Body as TypographyBody } from '../System/Typography'
import LoaderButton from '../LoaderButton'
import Files from '../Files'
import { isString } from 'lodash'

export const DocumentInfoRequest = ({
  exceptionItem,
  submitting
}: {
  exceptionItem: InfoRequestItem
  submitting: boolean
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<InfoRequestValues>()
  const [attachmentFile, setAttachmentFile] = useState<DropzoneFile[]>([])
  const [attachment, setAttachment] = useState<string>(values.attachment || '')
  const attachmentFileDidMountRef = useRef(false)

  const PROCESSING_DURATION = 500

  useEffect(() => {
    setFieldValue('attachment', attachment)
  }, [attachment])

  useEffect(() => {
    if (
      attachmentFileDidMountRef.current &&
      attachmentFile.length === 0 &&
      attachment
    ) {
      setAttachment('')
    } else {
      attachmentFileDidMountRef.current = true
    }
  }, [attachmentFile])

  const handleDrop = (data: DropzoneFile[]) => {
    setAttachmentFile([data[0]])
    isString(data[0].data)
      ? setAttachment(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setAttachment(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const handleSetFiles = (files: DropzoneFile[]) => {
    setAttachmentFile(files.length > 0 ? [files[0]] : [])
  }

  return (
    <>
      <TypographyBody>{exceptionItem.title}</TypographyBody>
      <br />
      <>
        <Dropzone
          {...{
            label: 'Upload document',
            name: 'attachment',
            onDrop: (content, setProcessing) => {
              handleDrop(content)

              setTimeout(() => {
                setProcessing(false)
              }, PROCESSING_DURATION)
            },
            activeText: 'Drop document',
            inactiveText: 'Drop document file here',
            loadingText: 'Processing document'
          }}
        />
        <Files
          {...{
            files: attachmentFile,
            setFiles: handleSetFiles
          }}
        />
      </>
      <br />
      <DescriptionNote description={exceptionItem.description} />
      <OutboundAttachment attachment={exceptionItem.outbound_attachment} />

      <ButtonContainer>
        <LoaderButton
          {...{
            isDisabled: !values.attachment,
            submitting: submitting,
            submitText: 'Submit'
          }}
        />
      </ButtonContainer>
    </>
  )
}
