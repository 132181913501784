import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import {
  theme,
  Loader as MiddeskLoader,
  ListPagination
} from '@middesk/components'
import Card from '../../components/System/Card'
import { getTaxAgencyRegistrationStatusLabel } from '../../lib/helpers'
import {
  AgencyRegistration,
  ContainerProps,
  TaxAgencyRegistration
} from '../../types'
import Accounts from './Accounts'
import {
  SECRETARY_OF_STATE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PER_PAGE_COUNT,
  STATES,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  TAX_AGENCY_REGISTRATION_STATUSES,
  AGENT_TAX_AGENCY_REGISTRATION,
  AGENT_AGENCY_REGISTRATION
} from '../../lib/constants'
import {
  APITaxAgencyRegistration,
  GetTaxAgencyRegistrationsResponse
} from '../../lib/api/taxAgencyRegistration'
import { APICompanyAccountsOverview } from '../../lib/api/companyAccountsOverview'
import { parse } from 'qs'
import queryStringSerializer from '../../lib/queryStringSerializer'
import ControlRow from '../../components/ControlRow'
import FilterCollection from '../../components/System/FilterCollection'
import Filter from '../../components/System/Filter'
import TaxAgencyRegistrationDetailCard from './TaxAgencyRegistrationDetailCard'
import AgencyRegistrationDetailCard from './AgencyRegistrationDetailCard'

const { spacing, colors } = theme

const StyledFilterCollection = styled(FilterCollection)`
  flex-wrap: wrap;

  > div > div {
    background-color: ${colors.white} !important;
  }
`

const Wrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${spacing.normal};
  }
`

const Details = styled(({ className }: ContainerProps) => {
  const [registrations, setRegistrations] = useState<
    Array<TaxAgencyRegistration | AgencyRegistration>
  >([])
  const [total, setTotal] = useState(0)
  const [fetching, setFetching] = useState(true)
  const [hasLocals, setHasLocals] = useState(false)

  const { companyId } = useParams<{ companyId: string }>()

  const { push } = useHistory()
  const location = useLocation()
  const { search } = location

  const params = parse(search, { ignoreQueryPrefix: true })

  const stateFilters = STATES.map(state => ({
    label: state.name,
    value: state.abbr
  }))

  const statusOptions = TAX_AGENCY_REGISTRATION_STATUSES.map(status => {
    return {
      label: getTaxAgencyRegistrationStatusLabel(status),
      value: status
    }
  })

  useEffect(() => {
    APICompanyAccountsOverview.index(companyId).then(({ data }) => {
      setHasLocals(data.some(stateData => stateData.has_locals_in_state))
    })
  }, [])

  useEffect(() => {
    setFetching(true)
    const defaultParams = {
      page: DEFAULT_PAGE_NUM,
      per_page: DEFAULT_PER_PAGE_COUNT
    }

    const { ...queryStringParams } = params

    APITaxAgencyRegistration.index(companyId, {
      ...defaultParams,
      ...queryStringParams,
      include_locals: true
    })
      .then((json: GetTaxAgencyRegistrationsResponse) => {
        setRegistrations(json.data)
        setTotal(json.total_count)
      })
      .catch((e: any) => {
        if (e.status !== 404) {
          throw e
        }
      })
      .finally(() => setFetching(false))
  }, [location])

  const {
    state = null,
    tax_types = null,
    status = null,
    per_page = DEFAULT_PER_PAGE_COUNT,
    page = DEFAULT_PAGE_NUM
  }: {
    [key: string]: any
  } = params as any

  const onChange = (nextParams: { [key: string]: any }): void => {
    push(
      `/companies/${companyId}/accounts/details?${queryStringSerializer(
        params,
        nextParams
      )}`
    )
  }

  const onPage = (page: number) => {
    if (fetching) {
      return
    }
    onChange({ page })
  }

  const updateTar = (updateIndex: number, newTar: TaxAgencyRegistration) => {
    setRegistrations([
      ...registrations.slice(0, updateIndex),
      newTar,
      ...registrations.slice(updateIndex + 1)
    ])
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [fetching])

  const stateTaxTypeOptions = [
    {
      label: 'State Tax Withholdings',
      value: STATE_TAX_WITHHOLDINGS_TYPE
    },
    {
      label: 'State Unemployment Insurance',
      value: STATE_UNEMPLOYMENT_INSURANCE_TYPE
    },
    {
      label: 'Secretary of State',
      value: SECRETARY_OF_STATE_TYPE
    }
  ]
  const localTaxTypeOption = {
    label: 'Local Taxes',
    value: 'local_taxes'
  }
  const taxTypeOptions = [
    ...stateTaxTypeOptions,
    ...(hasLocals ? [localTaxTypeOption] : [])
  ]

  const loadingCard = (
    <Card variant='secondary'>
      <span>
        <MiddeskLoader size='medium' />
      </span>
    </Card>
  )

  return (
    <Accounts {...{ className }} companyId={companyId}>
      <ReactTooltip
        textColor={colors.white}
        backgroundColor={colors.graphite}
        borderColor={colors.dawn}
        border
      />
      <ControlRow>
        <StyledFilterCollection>
          <Filter
            placeholder={'State'}
            options={stateFilters}
            currentValue={state}
            onFilter={(value: string) => {
              onChange({ state: value })
            }}
          />
          <Filter
            placeholder={'Tax Types'}
            options={taxTypeOptions}
            currentValue={tax_types ? tax_types[0] : undefined}
            onFilter={(value: string) => {
              onChange({ tax_types: [value] })
            }}
          />
          <Filter
            placeholder={'Status'}
            options={statusOptions}
            currentValue={status}
            onFilter={(value: string) => {
              onChange({ status: value })
            }}
          />
        </StyledFilterCollection>
        <ListPagination
          onPage={onPage}
          page={page}
          perPage={per_page}
          total={total}
        />
      </ControlRow>

      <Wrapper>
        {fetching
          ? loadingCard
          : registrations.map((registration, index) => {
              if (registration.object === AGENT_TAX_AGENCY_REGISTRATION) {
                return (
                  <TaxAgencyRegistrationDetailCard
                    key={`tar-card-${registration.id}`}
                    tax_agency_registration={
                      registration as TaxAgencyRegistration
                    }
                    updateTar={newTar => updateTar(index, newTar)}
                  />
                )
              } else if (registration.object === AGENT_AGENCY_REGISTRATION) {
                return (
                  <AgencyRegistrationDetailCard
                    key={`ar-card-${registration.id}`}
                    agency_registration={registration as AgencyRegistration}
                  />
                )
              }
            })}
      </Wrapper>
    </Accounts>
  )
})`
  ${Card} {
    display: flex;
    flex-direction: column;
    width: 100%;

    > span {
      text-align: center;
    }
  }
`

export default Details
