import * as FullStory from '@fullstory/browser'
import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/react'
import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import API from '../lib/api'
import { Impersonator, User } from '../types'
import qs from 'qs'
import Color from 'color'
import { ComputedTheme } from '../types'

interface AuthProps {
  authenticated: boolean
  fetching: boolean
  user?: User
  internal: boolean
  account?: any
  impersonator?: Impersonator
  transactionalAccount: boolean
  authorizedAccount?: any
  setFetchingUser: (fetching: boolean) => void
  inGuestMode?: boolean
  accountDisabled: boolean
  partnerAccount: boolean
  customTheme: ComputedTheme
  computeTheme: (theme: any) => ComputedTheme
  setCustomTheme: (theme: any) => void
}

export const AuthContext = createContext<AuthProps>({
  authenticated: false,
  fetching: false,
  user: undefined,
  internal: false,
  account: undefined,
  impersonator: undefined,
  transactionalAccount: false,
  authorizedAccount: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFetchingUser: (e: boolean) => undefined,
  inGuestMode: false,
  accountDisabled: false,
  partnerAccount: false,
  customTheme: {},
  computeTheme: () => ({}),
  setCustomTheme: () => ({})
})

interface CustomTheme {
  brand_primary_color: string
  brand_secondary_color: string
}

export const AuthProvider = ({ children }: { children: any }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [impersonator, setImpersonator] = useState<Impersonator | undefined>(
    undefined
  )
  const [account, setAccount] = useState(undefined)
  const [transactionalAccount, setTransactionalAccount] = useState(false)
  const [authorizedAccount, setAuthorizedAccount] = useState(undefined)
  const [internal, setInternal] = useState(false)
  const [fetching, setFetchingUser] = useState(true)
  const [accountDisabled, setAccountDisabled] = useState(false)
  const [partnerAccount, setPartnerAccount] = useState(false)
  const [customTheme, setCustomTheme] = useState({})

  const computeTheme = ({
    brand_primary_color
  }: CustomTheme): ComputedTheme => {
    if (!brand_primary_color) {
      return {}
    }

    return {
      primaryButtonBgColor: brand_primary_color,
      primaryButtonBgColorDisabled: Color(brand_primary_color)
        .lighten(0.5)
        .string(),
      primaryButtonBorderColor: Color(brand_primary_color).darken(0.3).string(),
      primaryButtonBgColorHover: Color(brand_primary_color).darken(0.2).string()
    }
  }

  const { search } = useLocation()
  useEffect(() => {
    const { guest_token, sandbox } = qs.parse(search, {
      ignoreQueryPrefix: true
    })
    if (guest_token) {
      API.guestToken(guest_token as string)
    }

    API.setSandboxMode(sandbox === 'true')
  }, [])

  useEffect(() => {
    if (user) {
      setInternal(user.roles.includes('super_admin') || user.account.internal)

      setAuthenticated(true)
    }
  }, [user])

  useEffect(() => {
    if (fetching) {
      if (API.usingGuestTokenStrategy()) {
        API.guest()
          .then((json: any) => {
            if (!json?.account?.enabled) {
              return
            }

            setUser({
              account: json.account,
              email: json.email,
              name: json.email,
              roles: []
            })
            setAccount(json.account)
            setTransactionalAccount(
              !json.account.agent_account_settings?.bundled
            )
            setCustomTheme(
              computeTheme(json.account.agent_account_settings?.theme)
            )
          })
          .catch(() => {
            setAuthenticated(false)
            API.removeGuestToken()
          })
          .finally(() => setFetchingUser(false))
      } else {
        API.me()
          .then((json: any) => {
            setPartnerAccount(!!json.account.agent_partner_profile?.enabled_at)

            // Handle partner dashboard users attempting to sign into their child accounts
            if (
              !json?.account?.enabled ||
              !json?.account?.agent_account_settings?.enabled_at
            ) {
              setAccountDisabled(true)
              return
            } else {
              setAccountDisabled(false)
            }

            setUser(json)
            setAccount(json.account)
            setImpersonator(json.impersonator)
            setAuthorizedAccount(json.authorized_account)
            setTransactionalAccount(
              !json.account.agent_account_settings?.bundled
            )
            setCustomTheme(
              computeTheme(json.account.agent_account_settings?.theme)
            )

            // These could be moved into a callback so that they're only invoked if
            // the logged in user changes
            amplitude.getInstance().setUserId(json.id)
            amplitude.getInstance().setUserProperties({ email: json.email })
            FullStory.identify(json.id, { email: json.email })

            // Set Sentry Context
            Sentry.setUser({ id: json.id })
            Sentry.setTag('account_id', json.account.id)
          })
          .catch(() => {
            setAuthenticated(false)
          })
          .finally(() => setFetchingUser(false))
      }
    }
  }, [fetching])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        fetching,
        internal,
        account,
        impersonator,
        transactionalAccount,
        authorizedAccount,
        setFetchingUser,
        inGuestMode: API.usingGuestTokenStrategy(),
        accountDisabled,
        partnerAccount,
        customTheme,
        computeTheme,
        setCustomTheme
      }}
    >
      {fetching && !authenticated ? <div /> : children}
    </AuthContext.Provider>
  )
}
