import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { AuthContext } from '../contexts/AuthProvider'
import AccountsCard from '../components/Home/AccountsCard/AccountsCard'
import ConfirmInformationBanner from '../components/Home/ConfirmInformationBanner'
import EmptyCard from '../components/Home/EmptyCard'
import RegistrationCard from '../components/Home/RegistrationCard'
import TransferAccountsCard from '../components/Home/TransferAccountsCard'
import ActionsCard from '../components/Home/ActionsCard'
import UpdatesCard from '../components/Home/UpdatesCard'
import Sidebar from '../components/Sidebar'
import LegalTermsUpgrade from '../components/Home/LegalTermsUpgrade'
import Grid from '../components/System/Layout/Grid'
import Page from '../components/System/Layout/Page'
import {
  AgentApplication,
  ApplicationInvitation,
  ContainerProps
} from '../types'
import ApplicationInvitationsCard from '../components/Home/ApplicationInvitationsCard'
import api from '../lib/api'
import APIAgentApplication from '../lib/api/agentApplication'
import SPACING from '../components/System/Spacing'

const Home = styled(
  ({ className }: ContainerProps): JSX.Element => {
    const history = useHistory()

    const { authenticated, user, transactionalAccount } = useContext(
      AuthContext
    )
    const { companyId } = useParams<{ companyId: string }>()
    const [outstandingInvitations, setOutstandingInvitations] = useState<
      ApplicationInvitation[] | null
    >(null)
    const [
      hasSubmittedApplications,
      setHasSubmittedApplications
    ] = useState<boolean>(false)
    const [showTermsModal, setShowTermsModal] = useState<boolean>(false)

    const orderingAllowed = user?.account?.settings?.agent?.ordering_allowed
    const showTransferExisting =
      !transactionalAccount &&
      !user?.account?.settings?.agent?.hide_transfer_existing_accounts

    useEffect(() => {
      api
        .get(`/v1/agent/companies/${companyId}/application_invitations`, {})
        .then(({ data: applicationInvitations }) => {
          const outstandingInvitations = applicationInvitations?.filter(
            (i: ApplicationInvitation) => !i?.application.submitted_at
          )
          setOutstandingInvitations(outstandingInvitations)
        })
      APIAgentApplication.index(companyId).then(({ data: applications }) => {
        setHasSubmittedApplications(
          applications.some((app: AgentApplication) => app.submitted_at)
        )
      })
    }, [])

    useEffect(() => {
      if (!orderingAllowed) {
        history.push('/direct-upgrade')
      }

      if (!user?.account?.agreed_to_current_terms) {
        setShowTermsModal(true)
      }
    }, [user])

    // If the account has any outstanding invitations and no submitted applications, we only show the ApplicationInvitationsCard
    const limitedView =
      outstandingInvitations &&
      outstandingInvitations.length > 0 &&
      !hasSubmittedApplications

    return (
      <Page {...{ className, title: 'Home' }}>
        <Sidebar />
        <LegalTermsUpgrade
          show={showTermsModal}
          onComplete={() => setShowTermsModal(false)}
        />
        <div>
          <ConfirmInformationBanner companyId={companyId} />
          {outstandingInvitations && outstandingInvitations.length > 0 && (
            <div style={{ marginBottom: SPACING.large }}>
              <ApplicationInvitationsCard
                outstandingInvitations={outstandingInvitations}
              />
            </div>
          )}
          {!limitedView && orderingAllowed && (
            <Grid columns={2}>
              <RegistrationCard companyId={companyId} />
              <AccountsCard companyId={companyId} />
              <UpdatesCard companyId={companyId} />
              <EmptyCard />
              {authenticated && user && showTransferExisting && (
                <TransferAccountsCard companyId={companyId} />
              )}
              <ActionsCard companyId={companyId} />
            </Grid>
          )}
        </div>
      </Page>
    )
  }
)``

export default Home
