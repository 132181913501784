import React from 'react'
import { SelectField, TextField, theme } from '@middesk/components'
import styled from 'styled-components'
import { Stock } from '../types'
import { MAX_WIDTH_MOBILE } from './Body'
import { StyledSelectWrapper } from '../pages/CompanyDetails'

const { spacing } = theme

const StockTableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > label {
    margin-right: ${spacing.compact};
    width: 25%;
  }

  > div {
    margin-right: ${spacing.compact};
    width: 25%;
  }

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${spacing.xsmall};
    align-items: center;
    > label {
      width: 100%;
    }
    > div {
      width: 100%;
    }
  }
`

const StockTableRow = ({
  share,
  shareType,
  index
}: {
  share: Stock
  shareType: string
  index: number
}): JSX.Element => {
  const name = 'stock_table.value'

  return (
    <>
      <StockTableRowContainer key={index}>
        <TextField
          placeholder='Enter number'
          label='Number of shares'
          name={`${name}.${shareType}[${index}].number`}
          type='number'
        />
        <StyledSelectWrapper>
          <SelectField
            name={`${name}.${shareType}[${index}].class`}
            label='Class'
          >
            <option
              key='common'
              value='common'
              label='Common'
              selected={share.class === 'common'}
            />
            <option
              key='preferred'
              value='preferred'
              label='Preferred'
              selected={share.class === 'preferred'}
            />
          </SelectField>
        </StyledSelectWrapper>
        <TextField
          label='Series'
          placeholder='Enter series'
          name={`${name}.${shareType}[${index}].series`}
        />
        <TextField
          label='Par Value'
          placeholder='Enter par value'
          name={`${name}.${shareType}[${index}].par_value`}
          type='number'
        />
      </StockTableRowContainer>
    </>
  )
}

export default StockTableRow
