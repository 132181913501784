import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { COLORS } from './Colors'
import SPACING from './Spacing'
import { Large } from './Typography'

export type SidebarSectionProps = {
  className?: string
  selected?: boolean
  children: string
  onClick: MouseEventHandler<HTMLDivElement>
}

const SidebarSection = styled(
  ({ className, selected, children, onClick }: SidebarSectionProps) => {
    return (
      <div {...{ className, onClick }}>
        {selected ? <span /> : ''}
        <Large bold={selected}>{children}</Large>
      </div>
    )
  }
)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: ${SPACING.large};
  width: 80%;
  margin: 15px 0;

  &:hover {
    cursor: pointer;
  }

  > span:first-child {
    background: ${COLORS.graphite};
    display: flex;
    height: ${SPACING.large};
    margin-right: calc(${SPACING.xlarge} - ${SPACING.xsmall});
    width: ${SPACING.xsmall};
  }

  > div:last-child {
    transition: color 0.5s;

    ${({ selected }: SidebarSectionProps) => {
      if (!selected) {
        return `margin-left: ${SPACING.xlarge};`
      }
    }}

    &:hover {
      color: ${COLORS.black};
    }
  }
`

export default SidebarSection
