import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from '../BorderRadius'
import { COLORS } from '../Colors'

export type CircularCheckMarkType = 'green'

export type CircularCheckMarkProps = {
  className?: string
  variant?: CircularCheckMarkType
}

const CircularCheckMark = styled(({ className }: CircularCheckMarkProps) => {
  const stroke = COLORS.green

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'
  const strokeWidth = BORDER_RADIUS.xxsmall

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M11.6663 7L7.99967 10.6667L6.33301 9'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
    </svg>
  )
})``

export default CircularCheckMark
