import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import qs from 'qs'

import api from '../lib/api'
import Middesk from '../components/System/Icons/Middesk'
import LandingGlobalStyle from '../LandingGlobalStyle'
import LandingCard from '../components/LandingCard'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Loader } from '@middesk/components'
import { AuthContext } from '../contexts/AuthProvider'

const SSOCallback = (): JSX.Element => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const { setFetchingUser } = useContext(AuthContext)

  const history = useHistory()
  const { search } = useLocation()
  const { error_description, code } = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    api
      .ssoCallback({ code: String(code) })
      .then(() => {
        setFetchingUser(true)

        history.push('/home')
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [])

  return (
    <>
      <div style={{ position: 'absolute', top: 0, left: 0, margin: '40px' }}>
        <Middesk />
      </div>
      <LandingGlobalStyle />
      <LandingCard>
        <Row center='xs' style={{ margin: '15px auto' }}>
          <Col xs>
            {loading ? (
              <Loader />
            ) : (
              <div>{(error_description || error) as string}</div>
            )}
          </Col>
        </Row>
      </LandingCard>
    </>
  )
}

export default SSOCallback
