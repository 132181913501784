import React, { FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider'
import { ADMIN_ROLES } from '../lib/constants'

interface Props {
  component: any
  exact?: boolean
  path: string
}

const AdminRoute: FC<Props> = ({ component, exact = false, path }: Props) => {
  const { authenticated, user } = useContext(AuthContext)

  const userHasAdminRole = ADMIN_ROLES.some(role => user?.roles.includes(role))

  if (authenticated && userHasAdminRole) {
    return <Route exact={exact} path={path} component={component} />
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => (
        <Redirect to={{ pathname: '/home', state: { from: location } }} />
      )}
    />
  )
}

export default AdminRoute
