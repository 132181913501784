import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { ActionFooterProps } from '../types'
import Separator from './Separator'
import { Body } from './System/Typography'

const ActionFooter = styled(
  ({
    children,
    secondaryAction,
    className,
    body = '',
    separated = true
  }: ActionFooterProps) => {
    const childrenArray = React.Children.toArray(children)

    return (
      <div {...{ className }}>
        {separated ? <Separator /> : ''}
        <Row between='xs' middle='xs'>
          {secondaryAction && <Col xs>{secondaryAction}</Col>}
          {body && <Body>{body}</Body>}
          <Col xs>
            <Row end='xs'>
              {childrenArray.map((child, i) => (
                <Col key={i} xs>
                  {child}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
)`
  .col-xs {
    max-width: fit-content;
  }

  * {
    white-space: nowrap;
  }
`

export default ActionFooter
