import React, { useEffect, useRef, useState } from 'react'
import {
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import { encode } from 'base64-arraybuffer'
import styled from 'styled-components'
import TransferIntakeCard from '../TransfersIntakeCard'
import {
  CheckboxField,
  DropzoneFile,
  SelectField,
  TextField
} from '@middesk/components'
import ActionFooter from '../../../components/ActionFooter'
import { BASE_64_IDENTIFIER } from '../../../lib/helpers'
import { Body } from '../../../components/System/Typography'
import { COLORS } from '../../../components/System/Colors'
import { ErrorMessage } from 'formik'
import Link from '../../../components/System/Link'
import SignaturePad from '../../../components/SignaturePad'
import SignatureUploader from '../../../components/SignatureUploader'
import Tooltip from '../../ToolTip'
import SPACING from '../../System/Spacing'
import { SIGNATORY_JOB_TITLES } from '../../../lib/constants'
import SignaturePrefill from '../../SignaturePrefill'
import { isString } from 'lodash'

const validationSchema = yup.object().shape({
  signatory: yup.object().shape({
    name: yup.string().required('Name is required').nullable(),
    email: yup.string().email().required('Email is required').nullable(),
    title: yup.string().required('Title is required').nullable(),
    signature: yup
      .string()
      .when('is_current_user', {
        is: true,
        then: yup.string().required().nullable()
      })
      .nullable(),
    authorized: yup.boolean().when('is_current_user', {
      is: true,
      then: yup.bool().oneOf([true], 'Signature authorization required')
    })
  })
})

const ContentWrapper = styled.div`
  margin: ${SPACING.small} 0;
`

const SignatoryErrors = styled(({ validationSchema, className }) => {
  return (
    <div className={className}>
      {Object.keys(validationSchema?.fields?.signatory?.fields).map(
        (k: string) => (
          <div key={k}>
            <ErrorMessage name={`signatory.${k}`} />
          </div>
        )
      )}
    </div>
  )
})`
  color: ${COLORS.magenta};
  display: flex;
  flex-direction: column;
`

const SIGN_MODE = 'sign'
const UPLOAD_SIGN_MODE = 'upload_sign'

const SignatoryInformation = ({
  onNext,
  onBack,
  setTitle,
  setValidationSchema
}: TransfersIntakeComponentProps): JSX.Element => {
  const {
    validateForm,
    values,
    setFieldValue
  } = useFormikContext<TransfersIntakeFormValues>()

  const signatureModeDidMountRef = useRef(false)
  const signatureFileDidMountRef = useRef(false)

  const [signatureFile, setSignatureFile] = useState<DropzoneFile[]>([])
  const [signatureMode, setSignatureMode] = useState<string>(SIGN_MODE)
  const [signature, setSignature] = useState<string | undefined>(
    values?.signatory?.signature
  )
  const [signaturePrefill, setSignaturePrefill] = useState<string | undefined>(
    values?.signatory?.signature
  )

  setValidationSchema && setValidationSchema(validationSchema)
  setTitle && setTitle('')

  const shouldDisableContinue = !validationSchema.isValidSync(values)

  useEffect(() => {
    setFieldValue('signatory.authorized', !!values?.signatory?.signature)
  }, [])

  useEffect(() => {
    validateForm()
  }, [values])

  useEffect(() => {
    if (signatureModeDidMountRef.current) {
      setSignature('')
      setSignatureFile([])
    } else {
      signatureModeDidMountRef.current = true
    }
  }, [signatureMode])

  useEffect(() => {
    setFieldValue('signatory.signature', signature)
  }, [signature])

  useEffect(() => {
    if (signatureFileDidMountRef.current) {
      if (signatureFile.length === 0 && signature) {
        setSignature('')
      }
    } else {
      signatureFileDidMountRef.current = true
    }
  }, [signatureFile])

  const handleDrop = (data: DropzoneFile[]) => {
    setSignatureFile([data[0]])
    isString(data[0].data)
      ? setSignature(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setSignature(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const handleSetFiles = (files: DropzoneFile[]) => {
    setSignatureFile(files.length > 0 ? [files[0]] : [])
  }

  return (
    <TransferIntakeCard {...{ onNext, onBack, shouldDisableContinue }}>
      <TextField
        placeholder='Enter name'
        name='signatory.name'
        label='Legal name of person'
      />
      <Tooltip
        text={`Our team will reach out if an officer's signature is needed`}
        inputType='text'
      >
        <TextField
          placeholder='Enter email'
          name='signatory.email'
          label='Email'
        />
      </Tooltip>
      <SelectField
        name='signatory.title'
        label='Job title'
        placeholder='Select title'
      >
        {SIGNATORY_JOB_TITLES.map(title => (
          <option
            key={title}
            value={title}
            label={title}
            selected={values?.signatory?.title === title}
          />
        ))}
      </SelectField>
      <CheckboxField
        name='signatory.is_current_user'
        label='I am the business signatory'
      />
      {values?.signatory?.is_current_user ? (
        <>
          <br />
          <Body>
            Providing your signature below helps speed up the registration
            process by allowing Middesk to complete multiple account transfers
            using one signature.
          </Body>
          <br />
          {signatureMode === UPLOAD_SIGN_MODE ? (
            <>
              <SignatureUploader
                onDrop={handleDrop}
                onSetFiles={handleSetFiles}
                files={signatureFile}
              />
              {signatureFile.length === 0 ? (
                <ActionFooter separated={false}>
                  <Link onClick={() => setSignatureMode(SIGN_MODE)}>
                    Or sign here
                  </Link>
                </ActionFooter>
              ) : (
                ''
              )}
            </>
          ) : (
            <ContentWrapper>
              {signaturePrefill ? (
                <SignaturePrefill
                  signature={signaturePrefill}
                  onClear={() => {
                    setSignaturePrefill('')
                    setSignature('')
                    setFieldValue('signatory.authorized', false)
                  }}
                />
              ) : (
                <SignaturePad
                  {...{
                    label: 'Sign here',
                    onFinish: e => setSignature(e),
                    onClear: () => {
                      setSignature('')
                      setFieldValue('signatory.authorized', false)
                    }
                  }}
                >
                  <Link onClick={() => setSignatureMode(UPLOAD_SIGN_MODE)}>
                    Or upload a signature
                  </Link>
                </SignaturePad>
              )}
            </ContentWrapper>
          )}
          <br />
          <CheckboxField
            label='I authorize Middesk to use my signature for filings related to the Secretary of State and Payroll Tax.'
            name='signatory.authorized'
          />
        </>
      ) : (
        <></>
      )}
      <SignatoryErrors validationSchema={validationSchema} />
    </TransferIntakeCard>
  )
}

export default SignatoryInformation
