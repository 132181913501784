import { Tabs as MiddeskTabs } from '@middesk/components'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { COLORS } from './Colors'

export type TabsProps = {
  className?: string
  activeIndex: number
  onTab: (e: number) => void
  children?: ReactNode | Array<ReactNode>
}

const Tabs = styled(
  ({ className, activeIndex, onTab, children }: TabsProps) => {
    return (
      <MiddeskTabs {...{ className, activeIndex, onTab }}>
        {children}
      </MiddeskTabs>
    )
  }
)`
  background: transparent;

  ${({ activeIndex }: TabsProps) => {
    const selectorIndex = activeIndex + 1

    return `
      > div:not(:last-child) {
        &:hover, &:active {
          color: ${COLORS.black};
        }
      }

      > div:nth-child(${selectorIndex}) {
        &:hover {
          color: ${COLORS.graphite};
        }
      }
    `
  }}

  > div:last-child {
    background: ${COLORS.graphite};
  }
`

export default Tabs
