import { theme } from '@middesk/components'
import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Brand from '../components/Brand'
import { COLORS } from './System/Colors'

const { spacing } = theme

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLORS.dawn};
  }
`

export const ErrorMessage = styled.div`
  margin-top: ${spacing.compact};
  padding: ${spacing.compact};
  text-align: center;
`

const ErrorPage = styled(({ className }) => (
  <div className={className}>
    <GlobalStyle />
    <div>
      <Brand color={COLORS.black} height='45px' />
      <ErrorMessage>Oops! An error has occurred</ErrorMessage>
    </div>
  </div>
))`
  display: flex;
  justify-content: center;

  > div:first-child {
    padding: 40px 90px;
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: column;
  }
`

export default ErrorPage
