import React, { useContext } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { PackageTypes, PackageVariations } from '../../../lib/constants'
import { PackageType, TransfersIntakeFormValues } from '../../../types'
import { AuthContext } from '../../../contexts/AuthProvider'
import { useFormikContext } from 'formik'
import SPACING from '../../System/Spacing'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
  margin-bottom: 0px;
  width: 100%;
`

const StyledRow = styled(Row)`
  margin-left: 0px;
  width: 100%;
`

const totalPriceText = (packageType: PackageType, count: number) => {
  const packageDetails = PackageVariations.state_filing[packageType]
  if (packageType === PackageTypes.unlimited) {
    return packageDetails.priceText
  }

  return `$${packageDetails.price * count}/month`
}

const packagePriceText = (packageType: PackageType, count: number) => {
  const packageDetails = PackageVariations.state_filing[packageType]
  if (packageType === PackageTypes.unlimited) {
    return packageDetails.priceText
  }

  return `${packageDetails.priceText} x ${count} states`
}

const PriceSection = (): JSX.Element => {
  const { values } = useFormikContext<TransfersIntakeFormValues>()
  const { user } = useContext(AuthContext)
  const selectedPackage = get(
    user,
    'account.settings.agent.package_type',
    'basic'
  )
  const packageDetails =
    PackageVariations.state_filing[selectedPackage as PackageType]

  const transferableStates = values.registered_states.filter(
    state =>
      state.foreignQualificationAction === 'transfer' ||
      state.taxRegistrationAction === 'transfer'
  )
  return (
    <StyledDiv>
      <StyledRow between='xs'>
        <Col>
          <div>{`${packageDetails.prettyTitle}`}</div>
        </Col>
        <Col>
          <div>
            {packagePriceText(selectedPackage, transferableStates.length)}
          </div>
        </Col>
      </StyledRow>
      <StyledRow between='xs'>
        <Col>
          <div>
            <b>Total</b>
          </div>
        </Col>
        <Col>
          <div>
            <b>{totalPriceText(selectedPackage, transferableStates.length)}</b>
          </div>
        </Col>
      </StyledRow>
    </StyledDiv>
  )
}

export default PriceSection
