import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import APIAnnualFiling from '../../lib/api/annualFiling'
import {
  CUSTOMER_INFORMATION_REQUEST_STATUS,
  STATE_MAP
} from '../../lib/constants'
import {
  AnnualFiling,
  AnnualFilingCollection,
  ContainerProps
} from '../../types'
import { Button, Banner, theme } from '@middesk/components'
import { BannerContent } from '../Shared'

const { spacing } = theme

const ConfirmInformationBanner = ({
  companyId
}: ContainerProps & { companyId: string }) => {
  const { push } = useHistory()

  const [states, setStates] = useState<string[]>([])

  const CONFIRM_INFORMATION_MESSAGE = `We need information from you to file your annual reports. Your business may be subject to bad standing or state penalties in ${states
    .join(', ')
    .replace(
      /, ([^,]*)$/,
      ' and $1'
    )}. Please resolve the associated actions as soon as possible.`

  useEffect(() => {
    APIAnnualFiling.index(companyId, {
      status: CUSTOMER_INFORMATION_REQUEST_STATUS,
      information_confirmed: false
    }).then(({ data = [] }: AnnualFilingCollection) => {
      setStates(
        data
          .filter(
            ({ customer_due_date }: AnnualFiling) =>
              new Date(customer_due_date) <= new Date()
          )
          .map(({ state }: AnnualFiling) => STATE_MAP[state].name)
      )
    })
  }, [])

  if (states.length == 0) return null

  return (
    <Banner intent='failure' style={{ marginBottom: spacing.large }}>
      <BannerContent>
        <div>{CONFIRM_INFORMATION_MESSAGE}</div>
        <Button
          type='secondary'
          onClick={() => push(`/companies/${companyId}/confirm-information`)}
          style={{ minWidth: 'fit-content' }}
        >
          Confirm information
        </Button>
      </BannerContent>
    </Banner>
  )
}

export default ConfirmInformationBanner
