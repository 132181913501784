import amplitude from 'amplitude-js'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import AgentApplication from '../../lib/api/agentApplication'
import {
  AgentApplication as AgentApplicationAttributes,
  RegistrationCardProps
} from '../../types'
import LoaderButton from '../LoaderButton'
import Card from '../System/Card'
import IconBubble from '../System/IconBubble'
import SPACING from '../System/Spacing'
import { Body, LargeDisplay } from '../System/Typography'

const RegistrationCard = styled(
  ({ className, companyId }: RegistrationCardProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true)
    const [
      application,
      setApplication
    ] = useState<AgentApplicationAttributes | null>(null)
    const { push } = useHistory()

    const onFinish = (application_id: string) =>
      push(`/applications/${application_id}`)

    const getApplication = async () => {
      const {
        data: inProgressNewRegistrations
      } = await AgentApplication.index(companyId, { status: 'in_progress' })

      if (inProgressNewRegistrations[0]) {
        setApplication(inProgressNewRegistrations[0])
      }

      setIsLoading(false)
    }

    useEffect(() => {
      getApplication()
    }, [])

    const handleApplicationCreate = (shouldContinue: boolean) => {
      if (shouldContinue && application) {
        onFinish(application.id)
        return
      }
      AgentApplication.create(companyId).then(
        (e: AgentApplicationAttributes) => {
          amplitude.getInstance().logEvent('application-started', {
            new_user: false
          })
          setApplication(e)
          onFinish(e.id)
        }
      )
    }

    return (
      <Card {...{ className, variant: 'primary', size: 'large' }}>
        <Row start='xs' top='xs' between='xs'>
          <Col xs>
            <LargeDisplay>Register in a new state</LargeDisplay>
            <Body>
              We’ll create and manage all of the accounts you need to operate
              and pay employees in any state.
            </Body>
            <Row>
              <Col>
                {application && (
                  <LoaderButton
                    {...{
                      submitting: isLoading,
                      submitText: 'Continue last registration',
                      onClick: () => handleApplicationCreate(true)
                    }}
                  />
                )}
              </Col>
              <Col>
                <LoaderButton
                  {...{
                    submitting: isLoading,
                    submitText: application ? 'Register new' : 'Register now',
                    onClick: () => handleApplicationCreate(false),
                    type: application ? 'secondary' : 'primary'
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <img src='/icons/new_registration.png' width={100} />
          </Col>
        </Row>
      </Card>
    )
  }
)`
  ${LargeDisplay} {
    margin-bottom: ${SPACING.small};
  }

  ${Body} {
    margin-bottom: ${SPACING.medium};
    max-width: 90%;
  }

  ${IconBubble} {
    cursor: default;
  }

  ${LoaderButton} {
    margin-right: ${SPACING.small};
  }
`

export default RegistrationCard
