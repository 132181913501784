import React from 'react'
import styled from 'styled-components'
import { theme } from '@middesk/components'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const { colors, typography, spacing } = theme

export const StyledListControls = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.large};
  justify-content: start;
  margin: 10px 0;
  width: 100%;

  .muted {
    color: ${colors.karl};
    font-size: ${typography.sizes.medium};
  }

  > div:last-child:not(:first-child) {
    margin-left: auto;
  }
`

export const SkeletonArray = () => (
  <div>
    {[...Array(4)].map((c, index) => (
      <SkeletonTheme
        key={index}
        baseColor={colors.dawn}
        highlightColor={colors.frost}
      >
        <div
          style={{
            padding: '32px',
            border: `solid ${colors.dawn}`,
            borderWidth: '0 2px 1px 2px'
          }}
        >
          <Skeleton height={12} />
        </div>
      </SkeletonTheme>
    ))}
  </div>
)

export const BannerContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
