import { darken, lighten } from 'polished'

export type Color =
  | 'indigo'
  | 'purple'
  | 'magenta'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'karl'
  | 'frost'
  | 'dawn'
  | 'black'
  | 'graphite'
  | 'white'

export type Variation = 'l1' | 'l2' | 'd1' | 'd2'

export type ColorWithVariation = `${Color}_${Variation}`

export type Pallette = Partial<Record<Color | ColorWithVariation, string>>

export const BASE_COLORS: Record<Color, string> = {
  indigo: '#4263EB',
  purple: '#8732DE',
  magenta: '#EC327C',
  orange: '#F67A20',
  yellow: '#FAE530',
  green: '#1AC356',
  karl: '#5F6874',
  frost: '#D9E0E8',
  dawn: '#ECF0F4',
  black: '#000000',
  graphite: '#333333',
  white: '#FFFFFF'
}

export const COLORS: Pallette = {
  ...BASE_COLORS,
  indigo_l1: lighten(0.1, BASE_COLORS.indigo),
  indigo_l2: lighten(0.25, BASE_COLORS.indigo),
  indigo_d1: darken(0.25, BASE_COLORS.indigo),
  indigo_d2: darken(0.1, BASE_COLORS.indigo),
  purple_l1: lighten(0.1, BASE_COLORS.purple),
  purple_l2: lighten(0.25, BASE_COLORS.purple),
  magenta_l1: lighten(0.1, BASE_COLORS.magenta),
  magenta_l2: lighten(0.25, BASE_COLORS.magenta),
  orange_l1: lighten(0.1, BASE_COLORS.orange),
  orange_l2: lighten(0.25, BASE_COLORS.orange),
  yellow_l1: lighten(0.1, BASE_COLORS.yellow),
  yellow_l2: lighten(0.25, BASE_COLORS.yellow),
  green_l1: lighten(0.1, BASE_COLORS.green),
  green_l2: lighten(0.35, BASE_COLORS.green),
  karl_l1: lighten(0.1, BASE_COLORS.karl),
  karl_l2: lighten(0.35, BASE_COLORS.karl),
  frost_l1: lighten(0.1, BASE_COLORS.frost)
}
