import React from 'react'
import { Row, Col } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import SPACING from './Spacing'
import { Loader as MiddeskLoader } from '@middesk/components'

export type LoaderProps = {
  className?: string
  loading?: boolean
  children: React.ReactNode | Array<React.ReactNode>
}

const Loader = styled(
  ({ className, loading, children }): JSX.Element => {
    return loading ? (
      <div {...{ className }}>
        <Row center='xs' middle='xs'>
          <Col xs>
            <MiddeskLoader size='medium' />
          </Col>
        </Row>
      </div>
    ) : (
      <>{children}</>
    )
  }
)`
  margin: ${SPACING.xlarge};
  width: 100%;
`

export default Loader
