import React, { ReactNode } from 'react'
import { ComputedTheme } from '../../types'
import { RadioField as ComponentRadioField } from '@middesk/components'
import styled from 'styled-components'
import ThemeDecorator from './ThemeDecorator'

interface RadioProps {
  label: string | ReactNode
  checked: boolean
  name: string
  value: string
  style?: any
  onChange: () => void
  customTheme: ComputedTheme
}

interface RadioFieldThemeProps {
  primaryButtonBgColor?: string
}

const RadioFieldWrapper = styled.div<RadioFieldThemeProps>`
  ${({ primaryButtonBgColor }) => {
    if (!primaryButtonBgColor) return ''

    return `input[type='radio'] {
      &:checked {
        border-color: ${primaryButtonBgColor};

        &::before {
          background-color: ${primaryButtonBgColor};
        }
      }
    }`
  }}
`

const InnerRadioField = ({
  label,
  checked,
  name,
  value,
  style,
  onChange,
  customTheme: { primaryButtonBgColor }
}: RadioProps) => (
  <RadioFieldWrapper primaryButtonBgColor={primaryButtonBgColor}>
    <ComponentRadioField
      label={label}
      checked={checked}
      name={name}
      onChange={onChange}
      value={value}
      style={style}
    />
  </RadioFieldWrapper>
)

const RadioField = ThemeDecorator(InnerRadioField)

export default RadioField
