import React from 'react'
import { Attribute, Button } from '@middesk/components'
import ActionFooter from './ActionFooter'
import SignaturePrint from './SignaturePrint'

const SignaturePrefill = ({
  signature,
  onClear
}: {
  signature: string
  onClear: () => void
}): JSX.Element => {
  return (
    <>
      <Attribute label='Signature'>
        <SignaturePrint src={signature || ''} />
      </Attribute>
      <ActionFooter
        secondaryAction={
          <Button
            type='cancel'
            onClick={() => {
              onClear()
            }}
          >
            Clear
          </Button>
        }
        separated={false}
      >
        <></>
      </ActionFooter>
    </>
  )
}

export default SignaturePrefill
