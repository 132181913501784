import React from 'react'
import styled from 'styled-components'
import { List, DotLabel, theme } from '@middesk/components'
import Accounts from './Accounts'
import { stringify } from 'qs'
import {
  getTaxAgencyRegistrationStatusLabel,
  tarStatusToComponentColor
} from '../../lib/helpers'
import { STATE_MAP } from '../../lib/constants'
import { CompanyAccountsOverview } from '../../lib/api/companyAccountsOverview'
import { SkeletonArray } from '../../components/Shared'

const { spacing } = theme

const StatusesWrapper = styled.div`
  display: flex;
`

const StyledDot = styled(DotLabel)`
  margin-right: ${spacing.large};
`

const LocalOverview = styled(
  ({
    companyId,
    fetchingStateStatusDetails,
    stateStatusDetails
  }: {
    companyId: string
    fetchingStateStatusDetails: boolean
    stateStatusDetails: CompanyAccountsOverview[]
  }): JSX.Element => {
    const statusData = (counts_by_status: { [key: string]: number }) => {
      return (
        <StatusesWrapper>
          {Object.keys(counts_by_status).map((status: string, i: number) => {
            if (counts_by_status[status] > 0) {
              return (
                <StyledDot
                  key={`status-${i}`}
                  color={tarStatusToComponentColor(status).color}
                  label={`${
                    counts_by_status[status]
                  } ${getTaxAgencyRegistrationStatusLabel(status)}`}
                />
              )
            }
          })}
        </StatusesWrapper>
      )
    }

    const columns = [
      {
        key: 'state',
        title: 'State',
        sortBy: 'state',
        width: 1,
        render: ({ state }: CompanyAccountsOverview) => (
          <b>{STATE_MAP[state].name}</b>
        )
      },
      {
        key: 'counts_by_status',
        title: 'Statuses',
        width: 4,
        render: ({ counts_by_status }: CompanyAccountsOverview) =>
          statusData(counts_by_status)
      }
    ]

    return (
      <>
        <Accounts companyId={companyId}>
          <List
            {...{
              columns,
              data: stateStatusDetails,
              to: ({ state }: CompanyAccountsOverview) =>
                `/companies/${companyId}/accounts/details?${stringify({
                  state: state
                })}`
            }}
          />
          {fetchingStateStatusDetails && <SkeletonArray />}
        </Accounts>
      </>
    )
  }
)`
  > div > div > div > div {
    overflow: visible !important;
  }
`

export default LocalOverview
