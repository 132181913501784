import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import {
  HttpError,
  TransferIntakeProps,
  TransfersIntakeFormValues,
  TransferBodyContent,
  Department,
  State
} from '../../types'
import Body from '../Body'
import TransfersIntakeSidebar from './TransfersIntakeSidebar'
import {
  StateTransfers,
  ContactInformation,
  BusinessInformation,
  SignatoryInformation,
  AccountInformation,
  ReviewAndSubmit,
  PaymentInformation,
  OrderSubmitted
} from '.'
import Page from '../System/Layout/Page'
import * as yup from 'yup'
import { Form } from '@middesk/components'
import qs from 'qs'
import { useHistory, useLocation, useParams } from 'react-router'
import { transfersPrefillInfo } from '../../lib/helpers'
import { AuthContext } from '../../contexts/AuthProvider'
import api from '../../lib/api'
import { STATES } from '../../lib/constants'
import Loader from '../System/Loader'
import ReactTooltip from 'react-tooltip'
import { COLORS } from '../System/Colors'
import APIAgentTransfer from '../../lib/api/agentTransfer'
import {
  deserializeTransfer,
  transfersPayload
} from '../../lib/transfers/submission'
import { StateDataContext } from '../../contexts/StateDataProvider'
import { get, pickBy, uniqBy } from 'lodash'
import moment from 'moment'
import { AccountContext } from '../../contexts/AccountProvider'

export const saveTransfersApplication = (
  values: TransfersIntakeFormValues,
  departmentsMap: Record<string, Department[]>,
  id: string,
  submit = false
) => {
  return APIAgentTransfer.update({
    id,
    submit,
    ...transfersPayload(values, departmentsMap)
  })
}

const getTransferIntakePages = ({
  showPaymentPage
}: {
  showPaymentPage: boolean
}): Array<TransferBodyContent> => {
  const transferIntakePages = [
    {
      component: StateTransfers,
      title: 'State transfers',
      description: `Add and confirm the states we'll transfer on your behalf.`
    },
    {
      component: ContactInformation,
      title: 'Contact information',
      description: `We'll reach out to you if we require any additional follow-up.`
    },
    {
      component: BusinessInformation,
      title: 'Business information',
      description:
        'Please fill out the following fields to help us transfer your Secretary of State accounts.'
    },
    {
      component: SignatoryInformation,
      title: 'Signatory information',
      description: `Provide information for your company's CEO, President, or Vice President below.`
    },
    {
      component: AccountInformation,
      title: 'Account Information',
      description:
        'Simply copy and paste your tax account information from your payroll provider portal.'
    },
    {
      component: ReviewAndSubmit,
      title: 'Review information',
      description: 'Please confirm the information below is accurate.',
      isSubmitButton: !showPaymentPage
    }
  ]

  if (showPaymentPage) {
    transferIntakePages.push({
      component: PaymentInformation,
      title: 'Payment information',
      description: 'Enter your billing information to submit your state filing.'
    })
  }

  transferIntakePages.push({
    component: OrderSubmitted,
    title: '',
    description: ''
  })

  return transferIntakePages
}

const getPageNumber = (pageQueryParam: string) => {
  if (isNaN(Number(pageQueryParam))) return 1

  const pageParam = Number(pageQueryParam)
  if (pageParam <= 0) {
    return 1
  }

  return pageParam
}

const TransfersIntake = styled(
  ({ className }: TransferIntakeProps): JSX.Element => {
    const [currentPage, setCurrentPage] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [partnerLogo, setPartnerLogo] = useState(undefined)
    const [validationSchema, setValidationSchema] = useState(yup.object())
    const [prefilling, setPrefilling] = useState(true)
    const [fetchingApplication, setFetchingApplication] = useState(true)
    const didMountRef = useRef(false)
    const { search, state } = useLocation()
    const parsedParams = qs.parse(search, { ignoreQueryPrefix: true })
    const { transferDepartmentsMap } = useContext(StateDataContext)
    const { existingSubscription } = useContext(AccountContext)
    const { id } = useParams<{ id: string }>()
    const { account, user, inGuestMode, transactionalAccount } = useContext(
      AuthContext
    )
    const { push, location } = useHistory()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState('')

    const selectedPackage = get(
      user,
      'account.settings.agent.package_type',
      'basic'
    )

    const skipPaymentSetting = get(
      account,
      'settings.agent.skip_payment',
      false
    )
    const showPaymentPage =
      !skipPaymentSetting &&
      !existingSubscription &&
      selectedPackage !== 'unlimited'
    const pages = getTransferIntakePages({ showPaymentPage })

    const [initialValues, prefill] = useState({
      accept_tos: false,
      registered_states: [] as State[],
      contact_name: '',
      contact_email: '',
      contact_phone_number: '',
      contact_title: '',
      legal_name: '',
      same_name_checked: false,
      sos_business_name: '',
      ein: '',
      entity_type: '',
      formation_date: '',
      formation_state: '',
      primary_address: {
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        type: ''
      },
      mailing_address: {
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        type: ''
      },
      signatory: {
        name: '',
        email: '',
        title: '',
        signature: '',
        is_current_user: false
      },
      same_address_checked: false
    })

    useEffect(() => {
      setCurrentPage(getPageNumber(parsedParams.page as string))
    }, [search])

    useEffect(() => {
      if (didMountRef.current) {
        setFetchingApplication(false)
      } else {
        didMountRef.current = true
      }
    }, [prefilling])

    useEffect(() => {
      ReactTooltip.rebuild()
    }, [currentPage])

    useEffect(() => {
      // Do not allow transactional accounts to transfer
      if (account && transactionalAccount) {
        push('/home')
      }
    }, [account])

    useEffect(() => {
      // Redirect any users without a package selected on the account
      // to /select-package
      if (
        user &&
        !user?.account.settings.agent.package_type &&
        !transactionalAccount
      ) {
        push('/select-package')
      }
    }, [user])

    useEffect(() => {
      const loadApplication = async () => {
        let applicationJson

        try {
          applicationJson = await api.get(`/v1/agent/transfers/${id}`)
        } catch (error: unknown) {
          const typedError = error as HttpError
          if (typedError?.status === 404) {
            push('/home')
          }
        }

        if (inGuestMode) {
          setPartnerLogo(applicationJson?.partner_account?.logo)
        }

        let prefillData = {
          ...initialValues,
          ...{
            contact_email: (user?.email || '') as string,
            contact_name: (user?.name || '') as string,
            ...pickBy(deserializeTransfer(applicationJson))
          }
        }

        const datePlaceholder = 'MM/DD/YYYY'

        prefillData.formation_date = prefillData.formation_date
          ? moment(prefillData.formation_date).format(datePlaceholder)
          : ''

        prefillData.signatory.is_current_user =
          !!prefillData.signatory.email &&
          prefillData.signatory.email === user?.email

        const stateRegistrations = get(
          state || { registrations: [] },
          'registrations',
          []
        )

        if (stateRegistrations.length > 0) {
          prefillData = {
            ...prefillData,
            registered_states: uniqBy(
              (prefillData.registered_states as State[]).concat(
                stateRegistrations.map(
                  ({ state }: { state: string }) =>
                    STATES.find(({ abbr }) => abbr === state) as State
                )
              ),
              'abbr'
            )
          }
        }

        prefillData.signatory.is_current_user = !!(
          prefillData.signatory.email &&
          prefillData.signatory.email === user?.email
        )

        if (parsedParams.prefill === 'true') {
          prefillData = { ...prefillData, ...transfersPrefillInfo() }
        }

        prefill(prefillData)
        setPrefilling(false)
      }

      loadApplication()
    }, [])

    const pushUpdatedPage = (page: number): void => {
      // Do not update page number for OrderSubmitted
      if (page !== pages.length) {
        const currentUrlParams = qs.parse(search, {
          ignoreQueryPrefix: true
        })
        const queryString = qs.stringify(
          { ...currentUrlParams, page },
          { addQueryPrefix: true }
        )

        push(location.pathname + queryString)
      }
    }

    const onBack = (values: TransfersIntakeFormValues) => {
      setTitle('')
      const updatedPageNumber = currentPage > 1 ? currentPage - 1 : currentPage
      setCurrentPage(updatedPageNumber)
      saveTransfersApplication(values, transferDepartmentsMap, id)
      pushUpdatedPage(updatedPageNumber)
    }

    const onNext = (values: TransfersIntakeFormValues, submit = false) => {
      const goToNextPage = () => {
        setTitle('')
        const updatedPageNumber =
          currentPage <= pages.length ? currentPage + 1 : currentPage
        setCurrentPage(updatedPageNumber)
        pushUpdatedPage(updatedPageNumber)
      }

      if (submit) {
        setIsSubmitting(true)
        setError('')
        return saveTransfersApplication(
          values,
          transferDepartmentsMap,
          id,
          submit
        )
          .then(goToNextPage)
          .catch((e: any) => setError(e))
          .finally(() => setIsSubmitting(false))
      }

      saveTransfersApplication(values, transferDepartmentsMap, id, submit)
      goToNextPage()
    }

    const CardToRender = pages[currentPage - 1]
    const { isSubmitButton } = CardToRender

    return (
      <Page>
        <TransfersIntakeSidebar
          {...{
            className,
            currentPage,
            logo: partnerLogo,
            fetchingApplication
          }}
        />
        <Body
          {...{ className, ...CardToRender }}
          title={title || CardToRender.title}
          description={description || CardToRender.description}
        >
          {prefilling ? (
            <Loader loading size='medium' />
          ) : (
            <Form
              {...{
                initialValues,
                validationSchema,
                onSubmit: (values: TransfersIntakeFormValues) => {
                  saveTransfersApplication(values, transferDepartmentsMap, id)
                }
              }}
            >
              <CardToRender.component
                {...{
                  onBack,
                  onNext,
                  setValidationSchema,
                  setTitle,
                  setDescription,
                  isSubmitButton,
                  isSubmitting,
                  error
                }}
              />
            </Form>
          )}
          <ReactTooltip
            textColor={COLORS.white}
            backgroundColor={COLORS.graphite}
            border
            borderColor={COLORS.dawn}
          />
        </Body>
      </Page>
    )
  }
)``

export default TransfersIntake
