import { theme } from '@middesk/components'
import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { LayoutProps } from '../types'
import Interpunct from './Interpunct'

const { spacing } = theme

const LinkFooter = styled(({ className, children }: LayoutProps) => {
  const childrenArray = React.Children.toArray(children)

  return (
    <div {...{ className }}>
      <Row center='xs'>
        {childrenArray.map((c: React.ReactNode, i: number) => {
          return (
            <>
              <Col>{c}</Col>
              {i + 1 !== childrenArray.length ? (
                <Col>
                  <Interpunct />
                </Col>
              ) : (
                ''
              )}
            </>
          )
        })}
      </Row>
    </div>
  )
})`
  margin-top: ${spacing.normal};

  ${Interpunct} {
    margin: 0 5px;
  }
`

export default LinkFooter
