import React, { useContext } from 'react'
import * as yup from 'yup'
import { Button, CheckboxField, TextField, theme } from '@middesk/components'
import styled from 'styled-components'
import { PartnerReferral } from '../../../types'
import { ErrorMessage, Formik } from 'formik'
import api from '../../../lib/api'
import { useHistory } from 'react-router'
import { AuthContext } from '../../../contexts/AuthProvider'
import { StyledForm } from '../../PartnerReferrals/PartnerReferralCard'
import { useToasts } from 'react-toast-notifications'
import { SignupConsentLabel } from '../../ConsentLabel'

const { colors, typography } = theme

const StyledErrorMessage = styled.div`
  color: ${colors.magenta};
  font-size: ${typography.sizes.small};
  margin-top: 5px;
`

const ErrorMessageWrapper = ({ name }: { name: string }) => (
  <StyledErrorMessage>
    <ErrorMessage name={name} />
  </StyledErrorMessage>
)

const PartnerReferralSignupForm = ({
  partnerReferral
}: {
  partnerReferral: PartnerReferral
}): JSX.Element => {
  const { setFetchingUser } = useContext(AuthContext)
  const history = useHistory()
  const { addToast } = useToasts()

  const postSignup = ({
    email,
    company_name,
    consent
  }: {
    email: string
    company_name: string
    consent: boolean
  }) =>
    api
      .referralSignup({
        referral_code: partnerReferral.referral_code,
        email,
        company_name,
        consent
      })
      .then(({ application_id }: { application_id: string }) => {
        setFetchingUser(true)

        history.push(`/applications/${application_id}`)
      })
      .catch(e => {
        addToast(e.messages.join(', '), { appearance: 'error' })
      })

  return (
    <Formik
      onSubmit={postSignup}
      initialValues={{
        email: '',
        company_name: '',
        consent: false
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .email('Email must be valid')
          .required('Email is required'),
        company_name: yup.string().required('Business name is required'),
        consent: yup
          .boolean()
          .oneOf([true], 'You must agree to the terms before continuing')
      })}
    >
      {({ dirty, isValid, isSubmitting, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            name='email'
            label='Email'
            placeholder='Enter your email'
            showErrorMessage
          />
          <TextField
            name='company_name'
            label='Legal Business Name'
            placeholder='Enter your legal business name'
            showErrorMessage
          />
          <div>
            <CheckboxField
              name='consent'
              label={
                <SignupConsentLabel
                  partner={{
                    slug: partnerReferral.partner_slug,
                    name: partnerReferral.brand.name
                  }}
                />
              }
            />

            <ErrorMessageWrapper name='consent' />
          </div>
          <div style={{ textAlign: 'center', margin: '25px' }}>
            <Button type='submit' disabled={!dirty || !isValid || isSubmitting}>
              {isSubmitting ? 'Loading...' : 'Start now'}
            </Button>
          </div>
        </StyledForm>
      )}
    </Formik>
  )
}

export default PartnerReferralSignupForm
