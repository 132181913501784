import api from '../api'

export type Insight = {
  key: string
  label: string
  status?: string
  value: number
}

export type GetCompanyInsightsResponse = {
  data: Array<Insight>
}

export const APICompanyInsights = {
  show: (companyId: string): Promise<GetCompanyInsightsResponse> =>
    api.get(`/v1/agent/companies/${companyId}/insights`)
}
