import { theme } from '@middesk/components'
import React from 'react'
import styled from 'styled-components'
import { Body, Large, TYPOGRAPHY_SIZES } from '../components/System/Typography'
import { openFAQ } from '../lib/helpers'
import { ContainerProps } from '../types'
import Link from './System/Link'
import { MAX_SIDEBAR_WIDTH } from './System/Sidebar'
import SPACING from './System/Spacing'

const { spacing } = theme

const OnboardingDialogue = styled(
  ({ className }: ContainerProps): JSX.Element => (
    <div {...{ className }}>
      <Large bold>Maintain business compliance</Large>
      <Body>
        We help manage your business and state registrations so you can focus on
        running your business.
      </Body>
      <Body bold>Business Identity</Body>
      <Body>See your business details and state registrations at a glance</Body>
      <Body bold>Payroll Taxes</Body>
      <Body>
        We’ll create the required tax accounts you need to pay employees in new
        states
      </Body>
      <Body bold>Foreign Qualification</Body>
      <Body>
        We’ll register your business with the Secretary of State and serve as
        your Registered Agent
      </Body>
      <Link onClick={openFAQ}>Learn more in our Help Center</Link>
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.normal};
  max-width: ${MAX_SIDEBAR_WIDTH};
  padding: 0 ${SPACING.xlarge};

  ${Link} {
    font-size: ${TYPOGRAPHY_SIZES.medium}px;
  }
`

export default OnboardingDialogue
