import React from 'react'

import { Icon, theme } from '@middesk/components'
import { Agency } from '../../types'
import styled from 'styled-components'
import { getDowntimeNotice, tatMessage } from '../../lib/helpers'

const { spacing, typography, colors } = theme

export const StyledContainer = styled.div`
  margin-left: 24px;

  .agency {
    padding-bottom: 6px;

    &:not(:first-child) {
      padding-top: ${spacing.xsmall};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.frost};
    }

    h1 {
      font-weight: ${typography.weights.bold};
      color: ${colors.graphite};
      margin-bottom: ${spacing.xxsmall};
      font-size: ${typography.sizes.medium};
    }

    ul {
      margin-top: 0;
      margin-bottom: ${spacing.xsmall};
      padding-left: 25px;
    }

    .tat {
      display: flex;
      align-items: flex-start;

      svg {
        color: ${colors.karl};
        fill: ${colors.karl};
        height: 20px;
        width: 20px;
        margin-right: ${spacing.xsmall};
      }
    }
  }
`

const DowntimeNotice = styled.div`
  color: ${colors.red};
  margin-top: 10px;
`

const AgenciesDescription = ({ agencies }: { agencies: Agency[] }) => {
  return (
    <StyledContainer>
      {agencies.map(
        ({
          name,
          tat_ceiling,
          tat_floor,
          items,
          downtime_start,
          downtime_end,
          downtime_status_copy
        }) => {
          const downtimeNotice = getDowntimeNotice({
            agency_name: name,
            downtime_start,
            downtime_end,
            downtime_status_copy
          })

          return (
            <div key={name} className='agency'>
              <h1>{name}</h1>
              <ul className='items'>
                {items.map(item => (
                  <li key={item.key}>{item.label}</li>
                ))}
              </ul>
              <div className='tat'>
                <Icon name='clock' />
                Average government processing time:{' '}
                {tatMessage((tat_ceiling + tat_floor) / 2)} after registration
                submitted
              </div>

              {downtimeNotice && (
                <DowntimeNotice>{downtimeNotice}</DowntimeNotice>
              )}
            </div>
          )
        }
      )}
    </StyledContainer>
  )
}

export default AgenciesDescription
