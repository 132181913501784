import React, { useContext, useEffect, useState } from 'react'

import { theme } from '@middesk/components'
import { useFormikContext } from 'formik'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

import { Page, PageProps } from '../components/Page'
import TaxRegistrationLineItems from '../components/TaxRegistrationLineItems'
import { COLORS } from '../components/System/Colors'
import Line from '../components/System/Line'
import Loader from '../components/System/Loader'
import Pencil from '../components/System/Icons/Pencil'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { AccountContext } from '../contexts/AccountProvider'
import { AuthContext } from '../contexts/AuthProvider'
import { StateDataContext } from '../contexts/StateDataProvider'
import api from '../lib/api'
import {
  PackageTypes,
  PackageVariations,
  STATE_MAP,
  REGISTRATION_REASONS,
  ACCOUNTING_BASIS_OPTIONS,
  PAYROLL_FREQUENCY_OPTIONS,
  MANAGED_BY_MIDDESK,
  BOTH_SUI_AND_SWH_TYPE,
  AGENT_INDIVIDUAL
} from '../lib/constants'
import {
  formatAddress,
  blockedByPayrollDateMessage,
  blockedByHiredDateMessage,
  localTaxLineItems,
  fqLineItems
} from '../lib/helpers'
import { FormValues, PackageType, RegistrationType, Agency } from '../types'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { MAX_WIDTH_MOBILE } from '../components/Body'

const { spacing, colors, typography } = theme

const ErrorMessage = styled.div`
  color: ${COLORS.magenta};
`

const OfficerReviewItem = styled.div`
  display: flex;
  flex: 1 0 auto;
  margin-top: ${spacing.compact};
  margin-bottom: ${spacing.normal};
  justify-content: space-between;
  > span:first-child {
    color: graphite;
    & + span {
      color: ${COLORS.karl};
    }
  }
  > span {
    font-size: ${typography.sizes.large};
  }
`

const FullRow = styled(Row)`
  width: 100%;
`

const FullDiv = styled.div`
  width: 100%;
  > * {
    margin-top: 5px;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-right: 5px;
  gap: ${spacing.large};

  > div {
    min-width: 45%;

    @media (max-width: ${MAX_WIDTH_MOBILE}) {
      justify-content: center;
      min-width: 50%;
    }
  }
`

const AttributeLabel = styled.div`
  color: ${colors.karl};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.medium};
`

const AttributeContent = styled.div`
  color: ${colors.graphite};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.large};
`

const BlockedByBanner = styled.div`
  padding: ${spacing.large};
  border-radius: ${spacing.small};
  border: 1px solid ${colors.frost};
  background: ${colors.frostLight};
  margin-bottom: ${spacing.large};
`

export const Attribute = ({
  label,
  children
}: {
  label: string
  children: any
}) => (
  <div>
    <AttributeLabel>{label}</AttributeLabel>
    <AttributeContent>{children}</AttributeContent>
  </div>
)

const SectionLabel = styled.div`
  display: flex;
  color: ${colors.graphite};
  font-weight: ${typography.weights.normal};
  font-face: ${typography.faces.display};
  font-size: ${typography.sizes.display.small};
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.large};

  svg {
    margin-left: ${spacing.compact};
    vertical-align: middle;
    cursor: pointer;
  }
`

const Section = ({
  title,
  pageNumber,
  children
}: {
  title: string
  pageNumber?: number
  children: any
}) => {
  const onEditClick = () => {
    window.location.href = `?page=${pageNumber}`
  }

  return (
    <div>
      <SectionLabel>
        {title}
        {pageNumber && (
          <div onClick={onEditClick}>
            <Pencil />
          </div>
        )}
      </SectionLabel>
      {children}
    </div>
  )
}

const ReviewAndFile: Page = ({
  onNext,
  onCancel,
  showSOSPages,
  pageMap,
  pricingData,
  error,
  isSubmitting = false,
  showPoweredByMiddesk,
  progress,
  localJurisdictionRegistrations,
  gustoIntake
}: PageProps) => {
  const {
    taxTypesByIntent,
    localJurisdictionsInState,
    state: filingState
  } = useContext(ApplicationContext)
  const { existingSubscription, fetchingExistingSubscription } = useContext(
    AccountContext
  )
  const { stateAgencyData } = useContext(StateDataContext)
  const [percentOff, setPercentOff] = useState(0.0)
  const { values } = useFormikContext<FormValues>()
  const primaryAddress = values.primary_address
    ? formatAddress(values.primary_address, '\n')
    : undefined
  const { user, account, transactionalAccount } = useContext(AuthContext)
  const selectedPackage = get(
    user,
    'account.settings.agent.package_type',
    'basic'
  )
  const billingProfile = get(account, 'settings.agent.billing_profile')
  const skipPaymentSetting = get(account, 'settings.agent.skip_payment', false)

  const secondaryAddressesCount = (
    values.secondary_addresses[filingState] || []
  ).length

  const isSubmitPage =
    !showSOSPages &&
    (!!existingSubscription ||
      selectedPackage == 'unlimited' ||
      skipPaymentSetting)

  const submitText = gustoIntake ? 'Continue to Gusto' : 'Submit'

  const stateData =
    stateAgencyData.find(({ abbr }) => abbr === filingState) || {}
  const agencies = get(stateData, 'tax_registration.agencies', [])
  const managedAgencies = agencies.filter((agency: Agency) => {
    return (
      agency.tax_type === BOTH_SUI_AND_SWH_TYPE ||
      (taxTypesByIntent[MANAGED_BY_MIDDESK] || []).includes(
        agency.tax_type as RegistrationType
      )
    )
  })
  const payrollDateQuestionKey = `${(
    filingState || ''
  ).toLowerCase()}_payroll_date`
  const hiredDateQuestionKey = `${(filingState || '').toLowerCase()}_hire_date`

  useEffect(() => {
    if (api.sandboxMode()) return

    api.get('/ajax/accounts/coupon').then(json => {
      setPercentOff(json.percent_off)
    })
  }, [])

  const recurringPrices = () => {
    if (selectedPackage !== PackageTypes.unlimited) {
      return (
        <Section title={`${STATE_MAP[filingState]?.name} registration`}>
          <FullDiv>
            <FullRow between='xs'>
              <Col xs>
                <b>{capitalize(selectedPackage)}</b>
              </Col>
              <Col>
                <b>
                  {billingProfile?.label
                    ? billingProfile.label
                    : PackageVariations.state_filing[
                        selectedPackage as PackageType
                      ].priceText}
                </b>
              </Col>
            </FullRow>
            {percentOff > 0.0 && (
              <>
                <FullRow between='xs'>
                  <Col xs>Referral Discount</Col>
                  <Col>{percentOff}%</Col>
                </FullRow>
                <Line />
                <FullRow between='xs'>
                  <Col xs>
                    <b>Total</b>
                  </Col>
                  <Col>
                    <b>
                      $
                      {PackageVariations.state_filing[
                        selectedPackage as PackageType
                      ].price *
                        (1.0 - percentOff / 100.0)}
                      &nbsp; /{' '}
                      {selectedPackage === 'unlimited' ? 'year' : 'month'}
                    </b>
                  </Col>
                </FullRow>
              </>
            )}
          </FullDiv>
        </Section>
      )
    }
  }

  const transactionalPrices = () => {
    if (pricingData?.tax_registration_current_prices && filingState) {
      const managedPricingData = pricingData?.tax_registration_current_prices[
        filingState
      ].filter(data =>
        (taxTypesByIntent[MANAGED_BY_MIDDESK] || []).includes(
          data['type'] as RegistrationType
        )
      )

      const localPricingData = localTaxLineItems(
        pricingData,
        localJurisdictionRegistrations,
        localJurisdictionsInState
      )

      const fqPricingData =
        filingState &&
        values.foreign_qualifications &&
        values.foreign_qualifications[0] &&
        values.foreign_qualifications[0].opted_in
          ? fqLineItems(
              pricingData,
              filingState,
              !!values.foreign_qualifications[0].transfer
            )
          : []

      const registrationPrices = [
        ...managedPricingData,
        ...localPricingData,
        ...fqPricingData
      ]

      return (
        <TaxRegistrationLineItems taxRegistrationPrices={registrationPrices} />
      )
    }
  }

  const showPrices = () => {
    return transactionalAccount ? transactionalPrices() : recurringPrices()
  }

  return (
    <>
      <Loader {...{ loading: fetchingExistingSubscription }}>
        <div>
          <Section
            title='Contact information'
            pageNumber={pageMap['ApplicantDetails']}
          >
            <FlexRow>
              <Attribute label='Name'>{values.contact_name}</Attribute>
              <Attribute label='Phone Number'>
                {values.contact_phone_number}
              </Attribute>
              <Attribute label='Email'>{values.contact_email}</Attribute>
              <Attribute label='Title'>{values.contact_title}</Attribute>
            </FlexRow>
          </Section>
          <Section
            title='Business information'
            pageNumber={pageMap['CompanyDetails']}
          >
            <FlexRow>
              <Attribute label='Legal Business Name'>
                {values.legal_name}
              </Attribute>
              <Attribute label='Doing Business As Name'>
                {values.dba_name}
              </Attribute>
              <Attribute label='Entity Type'>
                {values.entity_type.split('_').join(' ')}
              </Attribute>
              {values?.llc_structure && (
                <Attribute label='LLC Structure'>
                  {values.llc_structure.split('-').join(' ')}
                </Attribute>
              )}
              {values?.taxed_as_entity_type && (
                <Attribute label='Taxed as entity type'>
                  {values.taxed_as_entity_type.split('_').join(' ')}
                </Attribute>
              )}
              <Attribute label='Primary Business Address'>
                {primaryAddress}
              </Attribute>
              <Attribute label='State of Formation'>
                {values.formation_state}
              </Attribute>
              <Attribute label='Date of Formation'>
                {values.formation_date}
              </Attribute>
              <Attribute label='Federal Employer Identification Number'>
                {values.ein}
              </Attribute>
              <Attribute label='Industry'>{values.industry}</Attribute>
              <Attribute label='Fiscal year end date'>
                {values.fiscal_year_end}
              </Attribute>
              {values.futa_year != '0' && (
                <Attribute label='FUTA Year'>{values.futa_year}</Attribute>
              )}
              <Attribute label='Accounting basis'>
                {ACCOUNTING_BASIS_OPTIONS[values.accounting_basis]}
              </Attribute>
              <Attribute label='Payroll frequency'>
                {PAYROLL_FREQUENCY_OPTIONS[values.payroll_frequency]}
              </Attribute>
              <Attribute label='Reason for registration'>
                {REGISTRATION_REASONS[values.registration_reason]}
              </Attribute>
              {secondaryAddressesCount > 0 && (
                <Attribute
                  label={`Number of business locations in ${STATE_MAP[filingState]?.name}`}
                >
                  {secondaryAddressesCount}
                </Attribute>
              )}
            </FlexRow>
          </Section>
          <Section
            title='Officer / Owner information'
            pageNumber={pageMap['Leadership']}
          >
            <FullDiv>
              {values.owners.map(o =>
                o.object === AGENT_INDIVIDUAL ? (
                  <OfficerReviewItem key={o.name + o.titles.join(', ')}>
                    <span>
                      <b>{o.name}</b> • <span>{o.titles.join(', ')}</span>
                    </span>
                    <span>{o.ownership_percentage || 0}% ownership</span>
                  </OfficerReviewItem>
                ) : (
                  <OfficerReviewItem key={o.legal_name}>
                    <span>
                      <b>{o.legal_name}</b>
                    </span>
                    <span>{o.ownership_percentage || 0}% ownership</span>
                  </OfficerReviewItem>
                )
              )}
            </FullDiv>
          </Section>

          {managedAgencies.map((agency: Agency, index: number) => {
            const blockedByPayrollDate = blockedByPayrollDateMessage(
              agency,
              values.questions[filingState][payrollDateQuestionKey]
            )
            const blockedByHiredDate = blockedByHiredDateMessage(
              agency,
              values.questions[filingState][hiredDateQuestionKey]
            )

            if (blockedByPayrollDate) {
              return (
                <BlockedByBanner key={`agency-payroll-${index}`}>
                  {blockedByPayrollDate}
                </BlockedByBanner>
              )
            } else if (blockedByHiredDate) {
              return (
                <BlockedByBanner key={`agency-hired-${index}`}>
                  {blockedByHiredDate}
                </BlockedByBanner>
              )
            }
          })}

          {!skipPaymentSetting && (
            <Section title='Price'>{showPrices()}</Section>
          )}
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <NewRegistrationIntakeFooter
          {...{
            values,
            onNext,
            onCancel,
            isSubmitting,
            error,
            isDisabled: false,
            onClick: () => onNext(values, isSubmitPage),
            progress,
            title: 'Review & submit',
            submitText: isSubmitPage ? submitText : 'Continue',
            showPoweredByMiddesk
          }}
        />
      </Loader>
    </>
  )
}

ReviewAndFile.pageName = 'ReviewAndFile'
ReviewAndFile.title = () => 'Review information'
ReviewAndFile.description = () =>
  'Please confirm the information below is accurate.'

export default ReviewAndFile
