import { TextField } from '@middesk/components'
import { getIn, useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { TransfersIntakeFormValues } from '../../../types'
import SPACING from '../../System/Spacing'

type OnlinePortalCredentialsProps = {
  inputKey: string
  label: string
  validate?: (value: string) => string
}

const StyledWrapper = styled.div`
  margin-top: ${SPACING.xsmall};
`

const StyledDiv = styled(StyledWrapper)`
  padding-bottom: ${SPACING.medium};
`

const OnlinePortalCredentials = ({
  inputKey,
  label,
  validate
}: OnlinePortalCredentialsProps): JSX.Element => {
  const { values } = useFormikContext<TransfersIntakeFormValues>()

  const validateConfirmPassword = (value: string) => {
    return getIn(values, `${inputKey}_password`) !== value
  }

  return (
    <StyledWrapper>
      <TextField
        key={`${inputKey}_email`}
        name={`${inputKey}_email`}
        label={label}
        placeholder='Enter your account email'
        validate={validate}
      ></TextField>
      <TextField
        key={`${inputKey}_username`}
        name={`${inputKey}_username`}
        placeholder='Enter your username'
        validate={validate}
      ></TextField>
      <TextField
        key={`${inputKey}_password`}
        name={`${inputKey}_password`}
        placeholder='Enter your password'
        type='password'
        validate={validate}
      ></TextField>
      <TextField
        key={`${inputKey}_password_confirm`}
        name={`${inputKey}_password_confirm`}
        placeholder='Confirm your password'
        type='password'
        validate={validateConfirmPassword}
      ></TextField>
      <StyledDiv>
        Getting your credentials will allow us to transfer your mailing address.
      </StyledDiv>
    </StyledWrapper>
  )
}

export default OnlinePortalCredentials
