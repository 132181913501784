import React, { useEffect } from 'react'

const PartnerDemo = (): JSX.Element => {
  useEffect(() => {
    window.location.href = process.env.REACT_APP_OAUTH_DEMO_URL as string
  }, [])

  return <div />
}

export default PartnerDemo
