import React from 'react'
import { BackButton } from '@middesk/components'
import { useHistory } from 'react-router-dom'
import {
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../types'
import ActionFooter from '../ActionFooter'
import { useFormikContext } from 'formik'
import LoaderButton from '../LoaderButton'

const TransfersIntakeCardNavigation = ({
  onNext,
  onBack,
  isSubmitting = false,
  isFirst = false,
  isSubmitButton = false,
  shouldDisableContinue = false,
  currentStatePage = 0,
  stateCount = 0
}: TransfersIntakeComponentProps): JSX.Element => {
  const history = useHistory()
  const { push } = history
  const { values } = useFormikContext<TransfersIntakeFormValues>()

  return (
    <ActionFooter
      body={stateCount > 0 ? `${currentStatePage} of ${stateCount} states` : ''}
      secondaryAction={
        <BackButton
          onClick={isFirst ? () => push('/home') : () => onBack(values)}
        />
      }
    >
      <LoaderButton
        submitting={isSubmitting}
        isDisabled={shouldDisableContinue}
        onClick={() => onNext(values)}
        submitText={isSubmitButton ? 'Submit' : 'Continue'}
        type={'primary'}
      ></LoaderButton>
    </ActionFooter>
  )
}

export default TransfersIntakeCardNavigation
