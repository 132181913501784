import React, { useEffect, useState } from 'react'
import api from '../lib/api'
import Loader from '../components/System/Loader'
import Page from '../components/System/Layout/Page'
import Sidebar from '../components/Sidebar'

const Billing = () => {
  const [loading, setLoading] = useState(false)
  const openBillingPortal = () => {
    api
      .get('/ajax/customer_portal', {
        return_url: window.location.href
      })
      .then(json => {
        window.open(json.url, '_blank')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    openBillingPortal()
  }, [])
  return (
    <Page title='Billing'>
      <Sidebar />
      <Loader loading={loading}>
        <div>
          If a billing window did not open,{' '}
          <a onClick={openBillingPortal}>click here</a> or report any issues
          to&nbsp;
          <a href='mailto: agent@middesk.com'>agent@middesk.com</a>
        </div>
      </Loader>
    </Page>
  )
}

export default Billing
