import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'

const GreenCheck = styled(
  ({ className }: ContainerProps): JSX.Element => {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...{ className }}
      >
        <circle cx='12' cy='12' r='12' fill='#9DF2BB' />
        <path
          d='M17.6667 8L10.3333 15.3333L7 12'
          stroke='#1AC356'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
)``

export default GreenCheck
