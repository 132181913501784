import React from 'react'
import { TextTooltip, theme } from '@middesk/components'
import styled from 'styled-components'

const { spacing, typography } = theme

const TooltipWrapper = styled.div`
  display: flex;
  font-size: ${typography.sizes.medium};
`

const StyledTextTooltipContent = styled(TextTooltip.Content)`
  display: flex;
  max-width: 550px;

  ul {
    margin: 0;
    padding-left: ${spacing.large};

    li {
      margin-bottom: ${spacing.compact};
    }
  }
`

const InfoTooltip = () => {
  return (
    <TooltipWrapper
      onClick={e => {
        e.preventDefault()
      }}
    >
      <TextTooltip trigger='What information is needed to register?'>
        <StyledTextTooltipContent>
          <ul>
            <li>
              <b>Addresses:</b> This includes the primary address and mailing
              address of your business.
            </li>
            <li>
              <b>Federal tax information:</b> This includes your business&apos;
              federal EIN, entity type, and legal entity name.
            </li>
            <li>
              <b>Officer information:</b> this includes the officer&apos;s name,
              title, birth date, SSN, and ownership percentage. You&apos;ll also
              be asked to specify whether the officer is a signatory and whether
              they are on the board of directors.
            </li>
            <li>
              <b>Employee information:</b> For the state you&apos;re registering
              in, we&apos;ll need the employee&apos;s name, hire date, payroll
              start date, work address, and compensation information.
            </li>
          </ul>
        </StyledTextTooltipContent>
      </TextTooltip>
    </TooltipWrapper>
  )
}

export default InfoTooltip
