import React from 'react'

type CheckmarkProps = {
  color?: string
}

const Checkmark = ({ color = 'black' }: CheckmarkProps) => (
  <svg
    width='14'
    height='11'
    viewBox='0 0 14 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.25 1L4.375 10L1 6.625'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default Checkmark
