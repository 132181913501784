import { AgentApplication } from '../../types'
import api from '../api'

const APIAgentTransfer = {
  index: (
    companyId: string,
    params: { status?: string }
  ): Promise<{ data: AgentApplication[] }> =>
    api.get(`/v1/agent/companies/${companyId}/applications`, {
      ...params,
      application_type: 'transfer'
    }),
  create: (companyId: string): Promise<AgentApplication> => {
    return api.post(`/v1/agent/companies/${companyId}/transfers`, {})
  },
  update: (params: Record<string, any>): Promise<AgentApplication> => {
    return api.put(`/v1/agent/transfers/${params?.id}`, params)
  },
  delete: (params: Record<string, any>): Promise<any> => {
    return api.delete(`/v1/agent/transfers/${params?.id}`)
  }
}

export default APIAgentTransfer
