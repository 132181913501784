import React, { useState } from 'react'
import api from '../lib/api'
import styled from 'styled-components'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { CheckboxField, Form, Modal, TextField } from '@middesk/components'
import RoleSelector from './RoleSelector'
import { COLORS } from './System/Colors'
import SPACING from '../components/System/Spacing'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'

const Label = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  line-height: 9px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: ${COLORS.karl};
`

const CheckboxContainer = styled.div`
  margin-top: ${SPACING.small};
`

const FormContainer = styled.div`
  label > :first-child {
    color: ${COLORS.karl};
  }
`

type AddUserFormValues = {
  email: string
  role: string
  receive_agent_emails: boolean
}

interface AddUserModalProps {
  onClose: () => void
  onSubmit: () => void
  isOpen: boolean
}

const AddUserModal = ({ onClose, onSubmit, isOpen }: AddUserModalProps) => {
  const { addToast } = useToasts()

  const [values, setValues] = useState({
    email: '',
    role: '',
    receive_agent_emails: false
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    role: yup.string().required()
  })

  const CUSTOM_MODAL_STYLES = {
    content: {
      width: '600px'
    }
  }

  const handleSubmit = (values: AddUserFormValues) => {
    setIsSubmitting(true)

    api
      .post('/users/invite', {
        emails: [values.email],
        role: values.role,
        receive_agent_emails: values.receive_agent_emails,
        invite_target: 'agent'
      })
      .then(() => {
        addToast(`Invite sent to ${values.email}`, { appearance: 'success' })
        onSubmit()
      })
      .catch(() => {
        addToast('Invite has already been sent to email', {
          appearance: 'error'
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      close={onClose}
      closeLabel='Cancel'
      confirm={() => {
        handleSubmit(values)
      }}
      confirmLabel='Add'
      isOpen={isOpen}
      title='Add user'
      style={CUSTOM_MODAL_STYLES}
      isConfirmDisabled={!validationSchema.isValidSync(values) || isSubmitting}
    >
      <FormContainer>
        <Form initialValues={values} onChange={setValues}>
          <TextField
            placeholder='email@company.com'
            name='email'
            label='Invite teammate (email)'
          />
          <RoleSelector values={values} setValues={setValues} />
          <Label>Notifications</Label>
          <CheckboxContainer>
            <CheckboxField
              label='Receive email notifications'
              name='receive_agent_emails'
            />
          </CheckboxContainer>
        </Form>
      </FormContainer>
    </Modal>
  )
}

export default AddUserModal
