import {
  APIResource,
  GetSingleStateQuestionsParameters,
  SingleStateQuestionResponse
} from '../../types'
import api from '../api'

const APISingleStateQuestions: Pick<
  APIResource<GetSingleStateQuestionsParameters, SingleStateQuestionResponse>,
  'get'
> = {
  get: (params): Promise<SingleStateQuestionResponse> =>
    api.get('/v1/agent/questions', params)
}

export default APISingleStateQuestions
