import React from 'react'

import { theme } from '@middesk/components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SelectDropdown from './SelectDropdown'

const { colors } = theme

const Filter = styled(
  ({
    className,
    onFilter,
    options,
    placeholder,
    currentValue,
    withDefault = true
  }) => {
    const defaultFilter = {
      label: 'All',
      value: null
    }

    const styles = {
      container: base => ({
        ...base
      }),
      control: (base, { isFocused }) => ({
        ...base,
        background: 'var(--oc-white)',
        boxSizing: 'border-box',
        borderRadius: '40px',
        padding: '0px',
        borderColor: isFocused ? colors.karl : colors.frost,
        '&:hover': {
          borderColor: colors.karl
        },
        cursor: 'pointer',
        label: {
          cursor: 'pointer'
        },
        boxShadow: 'none',
        maxHeight: '2.1875rem'
      }),
      valueContainer: base => ({
        ...base,
        span: {
          color: colors.karl,
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '18px'
        },
        label: {
          color: colors.graphite,
          fontSize: '14px',
          lineHeight: '18px',
          fontWeight: 'bold'
        },
        padding: '0px',
        'input:not([type="checkbox"]):not([type="radio"])': {
          width: '0px'
        }
      }),
      option: (base, { isSelected }) => ({
        ...base,
        span: {
          display: 'none'
        },
        label: {
          cursor: 'pointer'
        },
        fontSize: '0.875rem',
        color: colors.graphite,
        backgroundColor: isSelected ? colors.frost : colors.white,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: colors.dawn,
          color: colors.graphite
        },
        fontWeight: isSelected ? 'bold' : 'normal',
        padding: '15px 20px'
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none'
      }),
      menu: base => ({
        ...base,
        minWidth: '220px'
      }),
      singleValue: base => ({
        ...base,
        display: 'flex',
        position: 'inherit',
        overflow: 'inherit',
        textOverflow: 'inherit',
        top: '8px',
        marginLeft: '20px',
        marginRight: '0px'
      }),
      indicatorsContainer: base => ({
        ...base,
        cursor: 'pointer',
        paddingRight: '12px'
      })
    }

    let optionPool

    if (withDefault) {
      optionPool = [defaultFilter, ...options]
    } else {
      optionPool = options
    }

    return (
      <SelectDropdown
        className={className}
        value={currentValue}
        onChange={({ value }) => (value ? onFilter(value) : onFilter())}
        styles={styles}
        isSearchable={false}
        defaultValue={withDefault ? defaultFilter : null}
        formatOptionLabel={({ label }) => (
          <label>
            <span>{placeholder}:</span> {label}
          </label>
        )}
        options={optionPool}
      />
    )
  }
)``

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Filter
