import { ApplicationResource, BatchGetResponse } from '../../types'
import api from '../api'

export type Application = {
  id: string
  company_id: string
}

const APIAgentApplication: ApplicationResource = {
  show: (id: string): Promise<Application> =>
    api.get(`/v1/agent/applications/${id}`),
  index: (companyId, params): Promise<BatchGetResponse<Application>> =>
    api.get(`/v1/agent/companies/${companyId}/applications`, {
      ...params,
      application_type: 'new_registration'
    }),
  create: (companyId): Promise<Application> =>
    api.post(`/v1/agent/companies/${companyId}/applications`, {}),
  delete: (id): Promise<any> => api.delete(`/v1/agent/applications/${id}`)
}

export default APIAgentApplication
