import React, { MouseEventHandler, ReactNode } from 'react'
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS } from './Colors'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './Typography'
import { ComputedTheme } from '../../types'
import ThemeDecorator from './ThemeDecorator'

export type LinkProps = {
  className?: string
  disabled?: boolean
  children?: string | ReactNode | Array<ReactNode>
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>
  target?: string
  rel?: string
  href?: string
  customTheme: ComputedTheme
} & Partial<RRLinkProps>

const InnerLink = styled(
  ({
    className,
    to,
    children,
    onClick,
    target,
    rel,
    href
  }: LinkProps): JSX.Element => {
    if (to) {
      return (
        <RRLink {...{ className, to: to || '', onClick, target, rel, href }}>
          {children}
        </RRLink>
      )
    } else {
      return <a {...{ className, onClick, target, rel, href }}>{children}</a>
    }
  }
)`
  ${({
    customTheme: {
      primaryButtonBgColor,
      primaryButtonBgColorHover,
      primaryButtonBorderColor
    }
  }) =>
    `
      color: ${primaryButtonBgColor || COLORS.indigo};

      &:hover,
      &:focus {
        color: ${primaryButtonBgColorHover || COLORS.indigo_d2};
      }

      &:active {
        color: ${primaryButtonBorderColor || COLORS.indigo_d1};
      }

    `}
  cursor: pointer;
  font-family: 'Suisse Intl';
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  text-decoration: none;

  ${({
    disabled,
    customTheme: { primaryButtonBgColorDisabled }
  }: LinkProps) => {
    if (disabled) {
      return `
      color: ${primaryButtonBgColorDisabled || COLORS.indigo_l2};
      cursor: default;
      pointer-events: none;
    `
    }
  }}
`

const Link = ThemeDecorator(InnerLink)
Link.defaultProps = {
  to: ''
}

export default Link
