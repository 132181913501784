import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { DotLabel } from '@middesk/components'
import IconBubble from '../../System/IconBubble'
import Document from '../../System/Icons/Document'
import Link from '../../System/Link'
import Loader from '../../System/Loader'
import { Large } from '../../System/Typography'
import { theme, Bubble } from '@middesk/components'
import {
  getTaxAgencyRegistrationStatusLabel,
  openFAQ,
  tarStatusToComponentColor
} from '../../../lib/helpers'
import { StateStatus } from '../../../lib/api/companyStateStatuses'
import {
  BLOCKED_BY_GOVERNMENT,
  COMPLETED,
  GOVERNMENT_PROCESSING,
  INFORMATION_REQUESTED,
  MIDDESK_PROCESSING,
  NOT_INCLUDED,
  UNMANAGED,
  DUPLICATE
} from '../../../lib/constants'

const { colors, spacing } = theme

const BubbleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.large};
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.large};
`

const LabelRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.large};
`

const States = ({
  companyId,
  loading,
  stateStatuses
}: {
  companyId: string
  loading: boolean
  stateStatuses: StateStatus[]
}) => {
  const { push } = useHistory()

  const primaryStatuses = [
    INFORMATION_REQUESTED,
    BLOCKED_BY_GOVERNMENT,
    MIDDESK_PROCESSING,
    GOVERNMENT_PROCESSING,
    COMPLETED
  ]
  const hasDuplicate = stateStatuses.some(({ status }) => status === DUPLICATE)
  const hasUnmanaged = stateStatuses.some(({ status }) => status === UNMANAGED)
  const hasNotIncluded = stateStatuses.some(
    ({ status }) => status === NOT_INCLUDED
  )
  const duplicateUnmanagedLabel = [
    ...(hasDuplicate ? ['Duplicate'] : []),
    ...(hasUnmanaged ? ['Unmanaged'] : []),
    ...(hasNotIncluded ? ['Not Included'] : [])
  ].join('/')

  return (
    <div>
      <Loader {...{ loading }}>
        {stateStatuses.length === 0 ? (
          <Row data-name='zero' center='xs'>
            <Col xs>
              <IconBubble variant='magenta'>
                <Document />
              </IconBubble>
              <Large bold>Register a state to view your accounts</Large>
              <Link onClick={openFAQ}>Learn more in our Help Center</Link>
            </Col>
          </Row>
        ) : (
          <>
            <BubbleRow>
              {stateStatuses.map(({ status, state }, index) => {
                return (
                  <div key={index}>
                    <Bubble
                      variant={tarStatusToComponentColor(status).name}
                      onClick={() =>
                        push(
                          `/companies/${companyId}/accounts/details?state=${state}`
                        )
                      }
                    >
                      {state}
                    </Bubble>
                  </div>
                )
              })}
            </BubbleRow>
            <LabelRow>
              {primaryStatuses.map(
                primaryStatus =>
                  stateStatuses.some(
                    ({ status }) => status === primaryStatus
                  ) && (
                    <DotLabel
                      key={primaryStatus}
                      color={tarStatusToComponentColor(primaryStatus).color}
                      label={getTaxAgencyRegistrationStatusLabel(primaryStatus)}
                    />
                  )
              )}
              {duplicateUnmanagedLabel && (
                <DotLabel color={colors.karl} label={duplicateUnmanagedLabel} />
              )}
            </LabelRow>
          </>
        )}
      </Loader>
    </div>
  )
}

export default States
