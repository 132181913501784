import React from 'react'
import { Button as MiddeskButton, theme } from '@middesk/components'
import styled from 'styled-components'
import ThemeDecorator from './System/ThemeDecorator'

const { colors, typography } = theme

const InnerButton = styled(({ className, children, ...props }) => {
  return (
    <MiddeskButton className={className} {...props}>
      {children}
    </MiddeskButton>
  )
})`
  ${({
    type = 'primary',
    customTheme: {
      primaryButtonBgColor,
      primaryButtonBgColorHover,
      primaryButtonBorderColor
    },
    disabled
  }) => {
    let styles = ''

    if (['primary', 'submit'].includes(type)) {
      styles += `
        font-weight: ${typography.weights.bold};
      `

      if (primaryButtonBgColor) {
        styles += `
          background-color: ${primaryButtonBgColor} !important;
          border-color: ${primaryButtonBorderColor} !important;

          &:hover, &:focus {
            background-color: ${primaryButtonBgColorHover} !important;
          }
        `
      }
    }

    if (disabled && primaryButtonBgColor) {
      styles = `
        background-color: ${colors.frost} !important;
        cursor: default;
        pointer-events: none;

        &:hover, &:focus {
          background-color: ${colors.frost} !important;
        }
      `
    }

    return styles
  }}
`

const Button = ThemeDecorator(InnerButton)

export default Button
