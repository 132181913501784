import styled from 'styled-components'
import { COLORS } from './Colors'

type TypographySizeName =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
type TypographySizeValue = 10 | 12 | 14 | 16 | 20 | 24 | 32
type TypographyWeightName = 'regular' | 'semibold'
type TypographyWeightValue = 400 | 600

export const TYPOGRAPHY_SIZES: Record<
  TypographySizeName,
  TypographySizeValue
> = {
  xsmall: 10,
  small: 12,
  medium: 14,
  large: 16,
  xlarge: 20,
  xxlarge: 24,
  xxxlarge: 32
}

export const TYPOGRAPHY_WEIGHTS: Record<
  TypographyWeightName,
  TypographyWeightValue
> = {
  regular: 400,
  semibold: 600
}

export const SuisseIntl = styled.div`
  color: ${COLORS.graphite};
  font-family: 'Suisse Intl';
  margin: 0;
  padding: 0;

  font-weight: ${({ bold }: { bold?: boolean }) =>
    bold ? TYPOGRAPHY_WEIGHTS.semibold : TYPOGRAPHY_WEIGHTS.regular};
`

export const Header = styled(SuisseIntl)`
  color: ${COLORS.karl};
  font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  line-height: ${TYPOGRAPHY_SIZES.xsmall}px;
  text-transform: uppercase;
`

export const Small = styled(SuisseIntl)`
  font-size: ${TYPOGRAPHY_SIZES.small}px;
  line-height: ${TYPOGRAPHY_SIZES.small}px;
`

export const Body = styled(SuisseIntl)`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  line-height: ${TYPOGRAPHY_SIZES.xxlarge}px;
`

export const Large = styled(SuisseIntl)`
  font-size: ${TYPOGRAPHY_SIZES.large}px;
  line-height: ${TYPOGRAPHY_SIZES.large}px;
`

export const XLarge = styled(SuisseIntl)`
  font-size: ${TYPOGRAPHY_SIZES.xlarge}px;
  line-height: ${TYPOGRAPHY_SIZES.xlarge}px;
`

export const XXLarge = styled(SuisseIntl)`
  font-size: ${TYPOGRAPHY_SIZES.xxlarge}px;
  line-height: ${TYPOGRAPHY_SIZES.xxlarge}px;
`

export const LargeDisplay = styled(SuisseIntl)`
  font-family: 'Aeonik';
  font-size: ${TYPOGRAPHY_SIZES.xxlarge}px;
  line-height: ${TYPOGRAPHY_SIZES.xxlarge}px;
`

export const XLargeDisplay = styled(LargeDisplay)`
  font-family: 'Aeonik';
  font-size: ${TYPOGRAPHY_SIZES.xxxlarge}px;
  line-height: ${TYPOGRAPHY_SIZES.xxxlarge}px;
`
