import React from 'react'
import styled from 'styled-components'

type Direction = 'up' | 'right' | 'bottom' | 'left'

export type CaretProps = {
  direction?: Direction
  className?: string
}

export const ROTATION: Record<Direction, number> = {
  up: 0,
  bottom: 180,
  right: 90,
  left: -90
}

const Caret = styled(({ className }: CaretProps) => {
  return (
    <svg
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M10.5 6.25L6 1.75L1.5 6.25'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
})`
  ${({ direction = 'up' }: CaretProps) => {
    return `
      transform: rotate(${ROTATION[direction]}deg);
    `
  }}
`

export default Caret
