import styled from 'styled-components'
import StyledCard from './StyledCard'

const WIDTH = '50%'
const MIN_WIDTH = '400px'
const MAX_WIDTH = '600px'

const LandingCard = styled(StyledCard)`
  min-width: ${MIN_WIDTH};
  max-width: ${MAX_WIDTH};
  width: ${WIDTH};

  svg {
    margin-bottom: 5px;
  }

  p {
    margin: 5px 0;
    white-space: nowrap;
  }
`

export default LandingCard
