import React from 'react'
import DropdownBanner from './DropdownBanner'
import { ConfirmCompany, Question } from '../../types'
import { STATES } from '../../lib/constants'
import { useFormikContext } from 'formik'
import { InnerFormColumn } from './index'
import { mapQuestionsToFormFields } from '../DynamicStatePage'
import { statePageValidationSchema } from '../DynamicStatePage'

const getStateName = (state: string) => {
  return STATES.find(({ abbr }) => abbr === state)?.name
}

const StateQuestions = ({
  state,
  questions
}: {
  state: string
  questions: Question[]
}) => {
  const { values, errors } = useFormikContext<ConfirmCompany>()

  return (
    <DropdownBanner
      valid={statePageValidationSchema(questions, state, []).isValidSync(
        values
      )}
      title={`${getStateName(state)} questions`}
    >
      <InnerFormColumn>
        {mapQuestionsToFormFields(questions, state, values, errors, undefined)}
      </InnerFormColumn>
    </DropdownBanner>
  )
}

export default StateQuestions
