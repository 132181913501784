import styled from 'styled-components'
import BORDER_RADIUS from './BorderRadius'
import { COLORS } from './Colors'
import SPACING from './Spacing'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './Typography'

export type BubbleVariant =
  | 'indigo'
  | 'green'
  | 'yellow'
  | 'magenta'
  | 'karl'
  | 'orange'
  | 'purple'
export type BubbleProps = {
  variant: BubbleVariant
}

const Bubble = styled.div`
  align-items: center;
  border-radius: ${BORDER_RADIUS.large};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  height: ${SPACING.xlarge};
  justify-content: center;
  text-transform: uppercase;
  width: ${SPACING.xlarge};

  ${({ variant = 'green' }: BubbleProps) => {
    if (variant === 'green') {
      return `
        color: ${COLORS.green};
        background-color: ${COLORS.green_l2};
      `
    } else if (variant === 'yellow') {
      return `
        color: ${COLORS.karl};
        background-color: ${COLORS.yellow_l2};
      `
    } else if (variant === 'indigo') {
      return `
        color: ${COLORS.indigo};
        background-color: ${COLORS.indigo_l2};
      `
    } else if (variant === 'karl') {
      return `
        color: ${COLORS.karl};
        background-color: ${COLORS.karl_l2};
      `
    } else if (variant === 'orange') {
      return `
        color: ${COLORS.orange};
        background-color: ${COLORS.orange_l2};
      `
    } else if (variant === 'purple') {
      return `
        color: ${COLORS.purple};
        background-color: ${COLORS.purple_l2};
      `
    } else {
      return `
        color: ${COLORS.magenta};
        background-color: ${COLORS.magenta_l2};
      `
    }
  }}
`

export default Bubble
