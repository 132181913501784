import { List } from '@middesk/components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Sidebar from '../components/Sidebar'
import Page from '../components/System/Layout/Page'
import Line from '../components/System/Line'
import SPACING from '../components/System/Spacing'
import Tab from '../components/System/Tab'
import Tabs from '../components/System/Tabs'
import api from '../lib/api'
import { ContainerProps } from '../types'
import { STATES } from '../lib/constants'
import { TYPOGRAPHY_WEIGHTS } from '../components/System/Typography'
import AnnualReportBanner from '../components/Updates/AnnualReportBanner'
import ConfirmInformationBanner from '../components/Home/ConfirmInformationBanner'
import { useParams } from 'react-router'
import { SkeletonArray, StyledListControls } from '../components/Shared'
import Filter from '../components/System/Filter'

const ASCENDING = 'ascending'
const DESCENDING = 'descending'
const AGENT_ANNUAL_FILING_OBJECT = 'agent_annual_filing'

interface UpdateParams {
  completed?: boolean
}

interface Update {
  state: string
  due_date: string
  object: string
}

const UpdateColumn = styled.div`
  cursor: default;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const DueColumn = styled.div`
  cursor: default;
`

const Updates = styled(({ className }: ContainerProps) => {
  const [state, setState] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [fetching, setFetching] = useState(true)
  const [annualReports, setAnnualReports] = useState([])
  const [sortOrder, setSortOrder] = useState(ASCENDING)
  const { companyId } = useParams<{ companyId: string }>()

  const onRenderUpdateColumn = (update: Update) => {
    let subtext = ''

    if (update?.object === AGENT_ANNUAL_FILING_OBJECT) {
      subtext = 'annual report'
    }

    return (
      <UpdateColumn>{`${
        STATES.find(state => state.abbr === update?.state)?.name
      } ${subtext}`}</UpdateColumn>
    )
  }

  const columns = [
    {
      title: 'Update',
      key: 'state',
      width: 8,
      render: onRenderUpdateColumn
    },
    {
      title: 'State filing deadline',
      key: 'due_date',
      width: 2,
      type: 'datetime',
      render: (update: Update) => (
        <DueColumn>{moment(update?.due_date).format('MM/DD/YY')}</DueColumn>
      )
    }
  ]

  useEffect(() => {
    const getAnnualFilings = async () => {
      const params: UpdateParams = {}

      if (activeTab === 0) {
        params.completed = false
      } else {
        params.completed = true
      }

      const annualFilingsResponse = await api.get(
        `/v1/agent/companies/${companyId}/annual_filings`,
        params
      )

      setAnnualReports(annualFilingsResponse.data)
      setFetching(false)
    }

    getAnnualFilings()
  }, [activeTab, companyId])

  const onChangeTab = (tab: number) => {
    setActiveTab(tab)
  }

  const onSort = () => {
    setSortOrder(sortOrder === ASCENDING ? DESCENDING : ASCENDING)
  }

  const annualReportsToDisplay = annualReports
    .filter((annualReportUpdate: Update) => {
      return state === '' || annualReportUpdate?.state === state
    })
    .sort(({ due_date: firstDueDate }, { due_date: secondDueDate }) => {
      const firstMoment = moment(firstDueDate)
      const secondMoment = moment(secondDueDate)

      if (sortOrder === ASCENDING) {
        return firstMoment.diff(secondMoment)
      } else {
        return secondMoment.diff(firstMoment)
      }
    })

  return (
    <Page
      {...{
        className,
        title: 'Updates'
      }}
    >
      <Sidebar />
      <ConfirmInformationBanner companyId={companyId} />
      <AnnualReportBanner companyId={companyId} />

      <Tabs
        {...{
          activeIndex: activeTab,
          onTab: onChangeTab
        }}
      >
        <Tab>Upcoming</Tab>
        <Tab>Completed</Tab>
      </Tabs>
      <Line />
      <List columns={columns} data={annualReportsToDisplay} onSort={onSort}>
        <StyledListControls>
          <Filter
            placeholder='State'
            onFilter={(state: string) => setState(state || '')}
            currentValue={state || null}
            options={STATES.map(({ abbr, name }) => ({
              label: name,
              value: abbr
            }))}
          />
        </StyledListControls>
      </List>
      {fetching && <SkeletonArray />}
    </Page>
  )
})`
  ${Tabs} + ${Line} {
    margin-bottom: ${SPACING.large};
  }
`

export default Updates
