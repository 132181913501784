import React, { useEffect, useState } from 'react'
import { ConfirmCompany, Individual } from '../../types'
import { useFormikContext } from 'formik'
import {
  SIGN_MODE,
  UPLOAD_SIGN_MODE,
  editingIndividualSchema
} from '../EditingIndividual'
import { BASE_64_IDENTIFIER, isLlcEntityType } from '../../lib/helpers'
import { encode } from 'base64-arraybuffer'
import { GapWrapper } from '../../components/Page'
import {
  Button,
  CheckBox,
  DropzoneFile,
  TextField,
  theme
} from '@middesk/components'
import JobTitle from '../../components/JobTitle'
import styled from 'styled-components'
import Address from '../../components/Address'
import CheckboxLabel from '../../components/CheckboxLabel'
import { Body } from '../../components/System/Typography'
import SignatureUploader from '../../components/SignatureUploader'
import ActionFooter from '../../components/ActionFooter'
import Link from '../../components/System/Link'
import SignaturePrefill from '../../components/SignaturePrefill'
import SignaturePad from '../../components/SignaturePad'
import { isString } from 'lodash'
import {
  AGENT_INDIVIDUAL,
  ALL_JOB_TITLES,
  JOB_TITLES
} from '../../lib/constants'

const { spacing } = theme

const ContentWrapper = styled.div`
  margin: ${spacing.small} 0;
`

const FullWidthFlexEndDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const EditingIndividual = ({
  isEditing,
  onAddIndividual
}: {
  isEditing: boolean
  onAddIndividual: (e: React.MouseEvent) => void
}): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<ConfirmCompany>()
  const [preFillSignature, setPreFillSignature] = useState<string | undefined>(
    values.editing_owner?.object === AGENT_INDIVIDUAL
      ? values?.editing_owner?.signature
      : ''
  )
  const [signatureFile, setSignatureFile] = useState<DropzoneFile[]>([])
  const [signatureMode, setSignatureMode] = useState<string>(SIGN_MODE)
  const [signature, setSignature] = useState<string | undefined>()

  useEffect(() => {
    setSignature(undefined)
    setSignatureFile([])
  }, [signatureMode])

  useEffect(() => {
    !preFillSignature && setFieldValue('editing_owner.signature', signature)
  }, [signature])

  useEffect(() => {
    setSignatureMode(SIGN_MODE)
  }, [(values.editing_owner as Individual)?.signatory])

  useEffect(() => {
    if (signatureFile.length === 0 && signature) {
      setSignature(undefined)
    }
  }, [signatureFile])

  const handleDrop = (data: DropzoneFile[]) => {
    setSignatureFile([data[0]])
    isString(data[0].data)
      ? setSignature(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setSignature(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const handleSetFiles = (files: DropzoneFile[]) => {
    setSignatureFile(files.length > 0 ? [files[0]] : [])
  }

  const jobTitles = isLlcEntityType(values.entity_type)
    ? ALL_JOB_TITLES
    : JOB_TITLES

  return (
    <>
      <GapWrapper>
        <TextField
          autoFocus
          name={'editing_owner.name'}
          label='Legal name of person'
          placeholder='Name'
          showErrorMessage
        />
        <TextField
          name={'editing_owner.email'}
          label='Email'
          placeholder='Contact email for person'
          showErrorMessage
        />
        <JobTitle
          name={'editing_owner.titles'}
          titleOptions={Object.values(jobTitles)}
        />
        <TextField
          type='ssn'
          name={'editing_owner.ssn'}
          label='Social Security Number (Or ITIN if this individual does not have a SSN)'
          placeholder='XXX-XX-XXXX'
          showErrorMessage
        />
        <TextField
          label='Date of Birth'
          placeholder='MM/DD/YYYY'
          type='date'
          name={'editing_owner.dob'}
        />
        <TextField
          label='Ownership Percentage'
          placeholder='Ownership percentage of person'
          type='number'
          min='0'
          max='100'
          name={'editing_owner.ownership_percentage'}
          showErrorMessage
        />
        <TextField
          label='Date Hired'
          placeholder='MM/DD/YYYY'
          type='date'
          name={'editing_owner.hired_date'}
          showErrorMessage
        />
        <Address
          name={'editing_owner.address'}
          label='Home Address for person'
          showErrorMessage
        />
      </GapWrapper>
      <ContentWrapper>
        {isLlcEntityType(values.entity_type) ? (
          <CheckBox
            label={
              <CheckboxLabel>
                This individual is an LLC manager or member.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.llc_owner}
            name={'editing_owner.llc_owner'}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.llc_owner
              setFieldValue('editing_owner.llc_owner', !checked)
            }}
          />
        ) : (
          <CheckBox
            label={
              <CheckboxLabel>
                This individual is on the board of directors.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.director}
            name={'editing_owner.director'}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.director
              setFieldValue('editing_owner.director', !checked)
            }}
          />
        )}
        <CheckBox
          label={
            <CheckboxLabel>
              This is the business signatory (they must be listed as an officer
              of the business).
            </CheckboxLabel>
          }
          checked={(values.editing_owner as Individual)?.signatory}
          name={'editing_owner.signatory'}
          onClick={() => {
            const checked = (values.editing_owner as Individual)?.signatory
            if (checked) {
              //unchecking the box
              setFieldValue('editing_owner.authorized', false)
              setFieldValue('editing_owner.signature', undefined)
            }
            setFieldValue('editing_owner.signatory', !checked)
          }}
        />
      </ContentWrapper>
      {(values.editing_owner as Individual)?.signatory && (
        <>
          <Body>
            Providing your signature below helps speed up the registration
            process by allowing Middesk to complete multiple registrations using
            one signature. If you are not the signatory, we will reach out to
            the signatory to obtain their signature.
          </Body>
        </>
      )}
      {(values.editing_owner as Individual)?.signatory &&
        (signatureMode === UPLOAD_SIGN_MODE ? (
          <>
            <SignatureUploader
              onDrop={handleDrop}
              onSetFiles={handleSetFiles}
              files={signatureFile}
            />
            {signatureFile.length === 0 && (
              <ActionFooter separated={false}>
                <Link onClick={() => setSignatureMode(SIGN_MODE)}>
                  Or sign here
                </Link>
              </ActionFooter>
            )}
          </>
        ) : (
          <ContentWrapper>
            {preFillSignature ? (
              <SignaturePrefill
                signature={preFillSignature}
                onClear={() => {
                  setPreFillSignature(undefined)
                  setSignature(undefined)
                  setFieldValue('editing_owner.signature', undefined)
                  setFieldValue('editing_owner.authorized', false)
                }}
              />
            ) : (
              <SignaturePad
                {...{
                  label: 'Sign here',
                  onFinish: e => setSignature(e),
                  onClear: () => {
                    setSignature(undefined)
                    setFieldValue('editing_owner.signature', undefined)
                    setFieldValue('editing_owner.authorized', false)
                  }
                }}
              >
                <FullWidthFlexEndDiv>
                  <Link onClick={() => setSignatureMode(UPLOAD_SIGN_MODE)}>
                    Or upload a signature
                  </Link>
                </FullWidthFlexEndDiv>
              </SignaturePad>
            )}
          </ContentWrapper>
        ))}
      {(values.editing_owner as Individual)?.signatory &&
        (values.editing_owner as Individual)?.signature && (
          <CheckBox
            label={
              <CheckboxLabel>
                I authorize Middesk to use my signature for filings related to
                the Secretary of State and Payroll Tax.
              </CheckboxLabel>
            }
            checked={(values.editing_owner as Individual)?.authorized}
            name={'editing_owner.authorized'}
            onClick={() => {
              const checked = (values.editing_owner as Individual)?.authorized
              setFieldValue('editing_owner.authorized', !checked)
            }}
          />
        )}
      <FullWidthFlexEndDiv>
        <Button
          onClick={onAddIndividual}
          disabled={!editingIndividualSchema.isValidSync(values)}
        >
          {isEditing ? 'Save' : 'Add individual'}
        </Button>
      </FullWidthFlexEndDiv>
    </>
  )
}
export default EditingIndividual
