import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../System/Colors'
import SPACING from '../../System/Spacing'
import { RegisteredState } from '../../../types'
import { Large, TYPOGRAPHY_WEIGHTS } from '../../System/Typography'
import { Col, Row } from 'react-styled-flexboxgrid'
import Separator from '../../Separator'

type StateTransfersFootnoteProps = {
  registeredStates: RegisteredState[]
  handleFQAction: (state: RegisteredState, action: string) => void
}

const StyledFootnote = styled.div`
  background-color: ${COLORS.frost_l1};
  border-radius: 4px;
  font-size: 1rem;
  margin: ${SPACING.small} 0;
  padding: ${SPACING.medium};

  > b {
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const StyledTable = styled.div`
  padding-top: ${SPACING.small};
`

const StyledRow = styled(Row)`
  padding: ${SPACING.small} 0;
`

const StyledUnorderedList = styled.ul`
  margin-top: 0;
  margin-bottom: ${SPACING.medium};
`

const StateTransfersFootnote = ({
  registeredStates,
  handleFQAction
}: StateTransfersFootnoteProps): JSX.Element => {
  const renderedStateRadioGroups = registeredStates.map(state => {
    return (
      <StyledRow key={state.abbr}>
        <Col xs>
          <Large>
            <b>{state.name}</b>
          </Large>
        </Col>
        <StyledCol xs>
          <input
            name={`sos_action_${state.abbr}`}
            key={`sos_action_${state.abbr}_transfer`}
            checked={state?.foreignQualificationAction === 'transfer'}
            value='transfer'
            type='radio'
            onChange={() => handleFQAction(state, 'transfer')}
          />
        </StyledCol>
        <StyledCol xs>
          <input
            name={`sos_action_${state.abbr}`}
            key={`sos_action_${state.abbr}_register`}
            checked={state?.foreignQualificationAction === 'register'}
            value='register'
            type='radio'
            onChange={() => handleFQAction(state, 'register')}
          />
        </StyledCol>
        <StyledCol xs>
          <input
            name={`sos_action_${state.abbr}`}
            key={`sos_action_${state.abbr}_skip`}
            checked={state?.foreignQualificationAction === 'skip'}
            value='skip'
            type='radio'
            onChange={() => handleFQAction(state, 'skip')}
          />
        </StyledCol>
      </StyledRow>
    )
  })

  return (
    <>
      <StyledFootnote>
        <div>
          <Large bold>Not registered with the Secretary of State?</Large>
          <StyledUnorderedList>
            <li>If you want to register for an account, select Register</li>
            <li>
              If you&apos;d like to skip the account transfer, select Skip
            </li>
          </StyledUnorderedList>
        </div>
        <StyledTable>
          <Row>
            <Col xs></Col>
            <StyledCol xs>
              <b>Transfer</b>
            </StyledCol>
            <StyledCol xs>
              <b>Register</b>
            </StyledCol>
            <StyledCol xs>
              <b>Skip</b>
            </StyledCol>
          </Row>
          <Separator customMargin='10px' />
          {renderedStateRadioGroups}
        </StyledTable>
      </StyledFootnote>
    </>
  )
}

export default StateTransfersFootnote
