import React from 'react'
import { Loader, theme } from '@middesk/components'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { BodyProps } from '../types'

const { spacing, colors, typography } = theme

export const BODY_MIN_WIDTH = '300px'
export const BODY_MAX_WIDTH = '800px'
export const WIDE_BODY_MAX_WIDTH = '100%'
export const WIDE_BODY_MOBILE_WIDTH = '1160px'
export const MAX_WIDTH_MOBILE = theme.devices.mobile

const CenteredCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const ContentWidth = styled.div<{ wideContent: boolean }>`
  max-width: ${props =>
    props.wideContent ? WIDE_BODY_MAX_WIDTH : BODY_MAX_WIDTH};
  min-width: ${BODY_MIN_WIDTH};
  width: 100%;
  padding-right: ${props => (props.wideContent ? '60px' : '')};
`

const Header = styled(ContentWidth)`
  color: ${colors.graphite};
  margin: ${spacing.normal} 0;
  margin-top: 5px;
`

const ChildWrapper = styled(ContentWidth)`
  gap: ${spacing.xxlarge};
`

const StyledLoader = styled(Loader)`
  height: 300px;
`

const Title = styled.div`
  color: ${colors.graphite};
  font-family: ${typography.faces.display};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.xlarge};
`

const Subtitle = styled.div`
  color: ${colors.karl};
  font-family: ${typography.faces.default};
  font-weight: ${typography.weights.normal};
  font-size: ${typography.sizes.medium};
`

const Body = styled(
  ({
    className,
    title,
    description,
    actions,
    children,
    contentsLoading,
    wideContent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    background
  }: BodyProps) => {
    return (
      <Row {...{ className }}>
        <CenteredCol xs>
          <>
            <Header wideContent={wideContent || false}>
              <div>
                <Title>{title}</Title>
                <Subtitle>{description}</Subtitle>
              </div>
            </Header>
            <div>{actions}</div>
            <ChildWrapper wideContent={wideContent || false}>
              {contentsLoading ? <StyledLoader></StyledLoader> : children}
            </ChildWrapper>
          </>
        </CenteredCol>
      </Row>
    )
  }
)`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    select,
    textarea,
    input,
    option,
    menu {
      font-size: 16px;
    }
  }

  ${props => props.background && `background-color: ${props.background};`}
`

export default Body
