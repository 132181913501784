import React, { useContext } from 'react'
import { Tooltip, Icons } from '@middesk/components'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { mapStateAbbreviationToName } from '../StateFilter'
import Separator from '../Separator'
import Button from '../Button'
import SPACING from '../System/Spacing'
import { COLORS } from '../System/Colors'
import { TYPOGRAPHY_SIZES } from '../System/Typography'
import { AccountContext } from '../../contexts/AccountProvider'
import { annualFilingText } from '../../pages/SecretaryOfStateRegistration'

const { Info } = Icons

const StyledTooltip = styled.div`
  display: inline-flex;
  vertical-align: middle;
`

const StyledInfo = styled(Info)`
  stroke: ${COLORS.karl};
`

const StyledHeader = styled.span`
  display: block;
  margin-bottom: ${SPACING.small};
`

const StyledRow = styled(Row)`
  padding: ${SPACING.small} 0;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

const LeftCol = styled(Col)`
  padding-top: ${SPACING.small};
  padding-left: ${SPACING.medium};
  font-size: ${TYPOGRAPHY_SIZES.medium}px;

  p {
    padding-top: ${SPACING.small};
  }
`

const RightCol = styled(LeftCol)`
  display: flex;
  justify-content: end;
`

const StyledInput = styled.input`
  margin-top: ${SPACING.xsmall};
  margin-right: ${SPACING.small};
  display: block;
`

const ErrorMessage = styled.div`
  color: ${COLORS.magenta};
`

type Props = {
  sos_registration_selection: string
  state: string
  account_creation_fee_cents: number
  state_fee_cents: number
  annual_fee_cents: number
  entity_type: string
  error: string
  setSosRegistrationSelection: (value: string) => void
  submitAction: (value: string) => void
}

const Preferences = ({
  sos_registration_selection,
  state,
  account_creation_fee_cents,
  state_fee_cents,
  annual_fee_cents,
  entity_type,
  error,
  setSosRegistrationSelection,
  submitAction
}: Props): JSX.Element => {
  const { existingPaymentMethod } = useContext(AccountContext)
  const stateName = mapStateAbbreviationToName(state)
  const submitDisabled = !['middesk', 'self_register'].includes(
    sos_registration_selection
  )
  const totalFeeCents =
    account_creation_fee_cents + state_fee_cents + annual_fee_cents

  const infoTooltip = () => {
    return (
      <StyledTooltip>
        <Tooltip
          trigger={<StyledInfo />}
          content={
            "We'll manage your service of process and notify you of annual report and annual franchise tax filings"
          }
        />
      </StyledTooltip>
    )
  }

  const totalBilledText = () => {
    if (!existingPaymentMethod) {
      return <></>
    }

    return (
      <p>
        Note: You&apos;ll be billed once we&apos;ve set up your Secretary of
        State account.
      </p>
    )
  }

  const buttonText = () => {
    if (
      sos_registration_selection === 'self_register' ||
      existingPaymentMethod
    ) {
      return 'Submit'
    }

    return 'Continue'
  }

  return (
    <div>
      <StyledHeader>
        You need to be registered with the <b>{stateName} Secretary of State</b>{' '}
        in order to register for payroll taxes. Select from the following
        options:
      </StyledHeader>

      <StyledRow>
        <StyledCol>
          <StyledInput
            name='SOS_Registration_Option'
            key={'middesk'}
            checked={sos_registration_selection === 'middesk'}
            value='middesk'
            type='radio'
            onChange={() => setSosRegistrationSelection('middesk')}
          />
        </StyledCol>
        <Col xs>
          <Row>
            <span>I want to register with Middesk</span>
          </Row>
          <Row>
            <LeftCol>
              <span>
                <b>Secretary of State account creation</b>
              </span>
            </LeftCol>
            <RightCol xs>
              <span>
                <b>${account_creation_fee_cents / 100}</b>
              </span>
            </RightCol>
          </Row>
          <Row>
            <LeftCol>
              <span>
                <b>Additional state fees</b>
                <em> (estimated)</em>
              </span>
            </LeftCol>
            <RightCol xs>
              <span>
                <b>${state_fee_cents / 100}</b>
              </span>
            </RightCol>
          </Row>
          <Row>
            <LeftCol>
              <span>
                <b>Registered Agent services</b> {infoTooltip()}
              </span>
            </LeftCol>
            <RightCol xs>
              <span>
                <b>${annual_fee_cents / 100} / year</b>
              </span>
            </RightCol>
          </Row>
          <Separator customMargin={'5px'} />
          <Row>
            <LeftCol>
              <span>
                <b>Total</b>
              </span>
              {totalBilledText()}
            </LeftCol>
            <RightCol xs>
              <span>
                <b>${totalFeeCents / 100}</b>
              </span>
            </RightCol>
          </Row>
          <StyledRow>{annualFilingText(state, entity_type)}</StyledRow>
        </Col>
      </StyledRow>

      <StyledRow>
        <StyledCol>
          <StyledInput
            name='SOS_Registration_Option'
            key={'self_register'}
            checked={sos_registration_selection === 'self_register'}
            value='self_register'
            type='radio'
            onChange={() => setSosRegistrationSelection('self_register')}
          />
        </StyledCol>
        <Col xs>
          <Row>
            <span>I want to register on my own</span>
          </Row>
        </Col>
      </StyledRow>

      <Separator />
      <Col xs>
        <Row end='xs'>
          <Button
            onClick={() => submitAction(sos_registration_selection)}
            disabled={submitDisabled}
          >
            {buttonText()}
          </Button>
        </Row>
      </Col>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
}

export default Preferences
