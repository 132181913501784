import { CheckBox as MiddeskCheckbox } from '@middesk/components'
import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from './BorderRadius'
import { COLORS } from './Colors'
import ThemeDecorator from './ThemeDecorator'
import { ComputedTheme } from '../../types'

const CHECKBOX_DIMENSION = '16px'

export type CheckboxProps = {
  className?: string
  onClick?: (e: MouseEvent) => void
  checked?: boolean
  label?: string
  name?: string
  indeterminate?: boolean
  disabled?: boolean
  customTheme: ComputedTheme
}

const InnerCheckbox = styled(
  ({ className, disabled, ...props }: CheckboxProps) => {
    return (
      <div {...{ className, disabled }}>
        <MiddeskCheckbox {...props} />
      </div>
    )
  }
)`
  box-sizing: border-box;

  ${({ customTheme: { primaryButtonBgColor, primaryButtonBorderColor } }) => {
    if (primaryButtonBgColor) {
      return `
        input[type='checkbox'] {
            &:checked {
              cursor: crosshair;

              ~ span {
                background-color: ${primaryButtonBgColor} !important;
                border: 1px solid ${primaryButtonBorderColor} !important;
              }
            }
          }
      `
    }
    return ''
  }}

  span:first-of-type {
    border-radius: ${BORDER_RADIUS.xxsmall};
    height: ${CHECKBOX_DIMENSION};
    width: ${CHECKBOX_DIMENSION};

    ${({
      disabled,
      customTheme: {
        primaryButtonBgColor,
        primaryButtonBgColorDisabled,
        primaryButtonBgColorHover,
        primaryButtonBorderColor
      }
    }: CheckboxProps) => {
      if (disabled) {
        return `
          background: ${primaryButtonBgColorDisabled || COLORS.dawn};
          border: 1px solid ${primaryButtonBgColorDisabled || COLORS.frost};
          cursor: default;
          pointer-events: none;
        `
      } else {
        return `
          border: 1px solid ${COLORS.frost};

          &:hover {
            border: 1px solid ${primaryButtonBgColorHover || COLORS.karl};
          }

          &:active {
            background: ${primaryButtonBgColor || COLORS.dawn} !important;
            border: 1px solid ${primaryButtonBorderColor || COLORS.karl};
          }

          &:focus {
            background: ${primaryButtonBgColor || COLORS.dawn};
            border: 1px solid ${primaryButtonBorderColor || COLORS.karl};
            outline: 2px solid ${primaryButtonBorderColor || COLORS.indigo_l2};
          }
        `
      }
    }}
  }
`
const Checkbox = ThemeDecorator(InnerCheckbox)
export default Checkbox
