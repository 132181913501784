import React from 'react'
import styled from 'styled-components'
import { theme, Icon } from '@middesk/components'
import { CheckPointProps } from '../types'

const { spacing, colors, typography } = theme

const Highlighted = styled.div`
  color: ${colors.graphite};
  font-weight: ${typography.weights.bold};
`

const CheckPoint = styled(
  ({ className, text, isCurrent, isComplete }: CheckPointProps) => {
    return (
      <div {...{ className }}>
        {isComplete ? (
          <Icon name='checkCircled' color={colors.green} />
        ) : (
          <img src='/icons/circle.svg' />
        )}
        <div>{isCurrent ? <Highlighted>{text}</Highlighted> : <>{text}</>}</div>
      </div>
    )
  }
)`
  align-items: center;
  display: flex;
  margin: ${spacing.normal} 0px 0px 0px;

  > div:last-child {
    display: inline;
    margin-left: ${spacing.compact};
  }
`

export default CheckPoint
