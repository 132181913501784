import React, { useContext } from 'react'
import GuestAccessDenied from './GuestAccessDenied'
import SOSRegistrationSelection from './SOSRegistration/Selection'
import { AuthContext } from '../contexts/AuthProvider'

const SOSRegistrationLanding = () => {
  const { fetching, user } = useContext(AuthContext)

  return (
    <>
      {!fetching && !user ? (
        <GuestAccessDenied />
      ) : (
        <SOSRegistrationSelection />
      )}
    </>
  )
}

export default SOSRegistrationLanding
