import { theme } from '@middesk/components'
import React, { useEffect, useRef, useState } from 'react'
import {
  default as ReactSignatureCanvas,
  default as SignatureCanvas
} from 'react-signature-canvas'
import styled from 'styled-components'
import Button from '../components/Button'
import { SIGNATURE_CANVAS_HEIGHT } from '../lib/constants'
import { SignaturePadProps } from '../types'
import ActionFooter from './ActionFooter'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'

const { spacing } = theme

const SignaturePad = styled(
  ({
    className,
    label,
    onFinish,
    children,
    onClear,
    ...props
  }: SignaturePadProps) => {
    const canvasRef = useRef<ReactSignatureCanvas>(null)
    const [currentSignature, setCurrentSignature] = useState<
      string | undefined
    >('')
    useEffect(() => {
      if (canvasRef.current && currentSignature) {
        canvasRef.current.fromDataURL(currentSignature)
      }
    }, [canvasRef.current, currentSignature])

    return (
      <div {...{ className }}>
        <div>{label}</div>
        <SignatureCanvas
          canvasProps={{
            height: SIGNATURE_CANVAS_HEIGHT
          }}
          {...props}
          ref={canvasRef}
          onEnd={() =>
            onFinish(canvasRef.current?.getTrimmedCanvas().toDataURL())
          }
        />
        <ActionFooter
          separated={false}
          secondaryAction={
            <Button
              type='cancel'
              onClick={() => {
                canvasRef.current?.clear()
                setCurrentSignature('')
                onClear()
              }}
            >
              Clear
            </Button>
          }
        >
          {children}
        </ActionFooter>
      </div>
    )
  }
)`
  position: relative;

  > div:first-child {
    color: ${COLORS.karl};
    font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    line-height: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  > canvas {
    border: 1px solid ${COLORS.frost};
    border-radius: 4px;
    width: 100%;
  }

  ${ActionFooter} {
    margin-top: ${spacing.compact};
  }
`

export default SignaturePad
