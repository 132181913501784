import React, { useEffect, useState } from 'react'
import { Loader } from '@middesk/components'
import api from '../lib/api'
import styled from 'styled-components'
import * as yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { User } from '../pages/Team'
import { Form, Modal, SelectField, TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import { COLORS } from './System/Colors'
import SPACING from '../components/System/Spacing'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'

const ConfirmMessage = styled.div`
  margin-bottom: ${SPACING.medium};
`

const Name = styled.span`
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const FormContainer = styled.div`
  label > :first-child {
    color: ${COLORS.karl};
  }
`

type RemoveUserFormValues = {
  contact_name: string
  contact_email: string
  contact_title: string
  contact_phone_number: string
}

interface ContactNameSelectProps {
  options: User[]
}

interface RemoveUserModalProps {
  onClose: () => void
  user: User
  onSubmit: () => void
  isOpen: boolean
}

const ContactNameSelect = ({ options }: ContactNameSelectProps) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (e: { value: string }) => {
    const email = e.value
    const user = options.find(user => user.email === email)

    setFieldValue('contact_name', user?.name)
    setFieldValue('contact_email', user?.email)
  }

  return (
    <SelectField
      name='contact_name'
      label='Contact name'
      placeholder='Select user'
      onChange={handleChange}
    >
      {options.map(option => {
        return (
          <option key={option.id} value={option.email} label={option.name} />
        )
      })}
    </SelectField>
  )
}

const RemoveUserModal = ({
  onClose,
  user,
  onSubmit,
  isOpen
}: RemoveUserModalProps) => {
  const { addToast } = useToasts()

  const [values, setValues] = useState({
    contact_name: '',
    contact_email: '',
    contact_title: '',
    contact_phone_number: ''
  })
  const [isLoading, setIsLoading] = useState(true)
  const [foundUserAsContact, setFoundUserAsContact] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    api
      .get(`/v1/agent/company_details/search`, { contact_email: user.email })
      .then(json => {
        const userIsAContact = json['ids'].length > 0
        setFoundUserAsContact(userIsAContact)

        if (userIsAContact) {
          buildUserList()
        } else {
          setIsLoading(false)
        }
      })
  }, [])

  const validationSchema = yup.object().shape({
    contact_name: yup.string().required(),
    contact_email: yup.string().required(),
    contact_title: yup.string().required(),
    contact_phone_number: yup.string().required()
  })

  const CUSTOM_MODAL_STYLES = {
    content: {
      width: '600px'
    }
  }

  const buildUserList = async () => {
    let page = 1
    let hasMore = true

    while (hasMore) {
      const usersResponse = await api.get(`/v1/users`, {
        page: page,
        per_page: 25
      })

      hasMore = usersResponse.has_more
      page = page + 1

      setUsers(prevUsers => [...prevUsers, ...usersResponse.data])
    }

    setIsLoading(false)
  }

  const handleRemoveContact = (values: RemoveUserFormValues) => {
    api
      .patch('/v1/agent/company_details/batch', {
        ...values,
        old_contact_email: user.email
      })
      .then(() => {
        handleRemove()
      })
  }

  const handleRemove = () => {
    api
      .delete(`/users/${user.id}`)
      .then(() => {
        addToast(`Removed ${user.name} from team`, { appearance: 'success' })
        onSubmit()
      })
      .catch(() => {
        addToast(`An error occured trying to delete ${user.email}`, {
          appearance: 'error'
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const isConfirmDisabled = () => {
    if (isLoading || isSubmitting) {
      return true
    }

    if (foundUserAsContact) {
      return !validationSchema.isValidSync(values)
    }

    return false
  }

  const contactOptions = users.filter(
    userOption => userOption.email !== user.email
  )

  return (
    <Modal
      close={onClose}
      closeLabel='Cancel'
      confirm={() => {
        setIsSubmitting(true)

        if (foundUserAsContact) {
          handleRemoveContact(values)
        } else {
          handleRemove()
        }
      }}
      confirmLabel='Confirm'
      isOpen={isOpen}
      title='Confirm removal'
      style={CUSTOM_MODAL_STYLES}
      isConfirmDisabled={isConfirmDisabled()}
    >
      {isLoading ? (
        <Loader size='medium' />
      ) : foundUserAsContact ? (
        <>
          <ConfirmMessage>
            Please confirm you&apos;d like to remove <Name>{user.name}</Name>{' '}
            and set a new company contact below.
          </ConfirmMessage>
          <FormContainer>
            <Form initialValues={values} onChange={setValues}>
              <ContactNameSelect options={contactOptions} />
              <TextField
                placeholder='Enter contact email'
                name='contact_email'
                label='Contact email'
              />
              <TextField
                placeholder='Enter contact title'
                name='contact_title'
                label='Contact title'
              />
              <TextField
                placeholder='Enter contact phone number'
                name='contact_phone_number'
                label='Contact phone number'
              />
            </Form>
          </FormContainer>
        </>
      ) : (
        <span>
          Please confirm you&apos;d like to remove <Name>{user.name}</Name>.
        </span>
      )}
    </Modal>
  )
}

export default RemoveUserModal
