import React from 'react'
import { Col } from 'react-styled-flexboxgrid'
import { Tooltip, Icons } from '@middesk/components'
import styled from 'styled-components'
import SPACING from '../../System/Spacing'

const { Info } = Icons

const FlexIcons = styled.div`
  display: flex;

  > div {
    margin-right: ${SPACING.xsmall};
  }
`

const StyledIconContainer = styled(FlexIcons)`
  > div {
    margin-top: auto;
  }
`

const TransferTypeColumnHeader = ({
  type,
  tooltipText,
  size
}: {
  type: string
  tooltipText: string
  size: number
}): JSX.Element => (
  <Col xs={size}>
    <StyledIconContainer>
      <div>
        Transfer<b>{' ' + type + ' '}</b>
        to Middesk Agent
      </div>
      <Tooltip content={tooltipText}>
        <Info height={22} width={22} />
      </Tooltip>
    </StyledIconContainer>
  </Col>
)

export default TransferTypeColumnHeader
