import { Link, theme } from '@middesk/components'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormValues, Stock } from '../types'
import Interpunct from './Interpunct'
import StockTableRow from './StockTableRow'
import { EMPTY_SHARE } from '../lib/helpers'

const { spacing, colors, typography } = theme

const AUTHORIZED_SHARES = 'authorized_shares'
const ISSUED_SHARES = 'issued_shares'

const Section = styled.div`
  margin: ${spacing.compact} 0;
`

const StockTableHeader = styled.div`
  color: ${colors.graphite};
  font-weight: ${typography.weights.normal};
  margin: ${spacing.normal} 0;
  font-size: ${typography.sizes.display.small};
  font-face: ${typography.faces.display};
`

const LinkCollection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${spacing.small};
`

const StockTable = styled(({ className }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const [authorizedShares, setAuthorizedShares] = useState<Stock[]>([])
  const [issuedShares, setIssuedShares] = useState<Stock[]>([])

  const stockTable = values?.stock_table

  useEffect(() => {
    if (stockTable?.value) {
      if (stockTable?.value?.authorized_shares) {
        setAuthorizedShares(stockTable.value.authorized_shares)
      }

      if (stockTable?.value?.issued_shares) {
        setIssuedShares(stockTable.value.issued_shares)
      }
    }
  }, [stockTable])

  const addRow = (shareType: string) => {
    if (shareType === AUTHORIZED_SHARES) {
      const shares = [...authorizedShares, ...[EMPTY_SHARE]]
      setAuthorizedShares(shares)
      setFieldValue(`stock_table.value.${AUTHORIZED_SHARES}`, shares)
    } else if (shareType === ISSUED_SHARES) {
      const shares = [...issuedShares, ...[EMPTY_SHARE]]
      setIssuedShares(shares)
      setFieldValue(`stock_table.value.${ISSUED_SHARES}`, shares)
    }
  }

  const deleteRow = (shareType: string) => {
    let newShares = []
    if (shareType === AUTHORIZED_SHARES) {
      newShares = [...authorizedShares]
      newShares.pop()
      setAuthorizedShares(newShares)
      setFieldValue(`stock_table.value.${AUTHORIZED_SHARES}`, newShares)
    } else if (shareType === ISSUED_SHARES) {
      newShares = [...issuedShares]
      newShares.pop()
      setIssuedShares(newShares)
      setFieldValue(`stock_table.value.${ISSUED_SHARES}`, newShares)
    }
  }

  const renderAddRemoveButtons = ({
    shares,
    shareType
  }: {
    shares: Stock[]
    shareType: string
  }) => {
    const shouldAllowRowDelete =
      shareType === ISSUED_SHARES ? shares.length > 0 : shares.length > 1

    return (
      <LinkCollection>
        <Link onClick={() => addRow(shareType)}>
          <b>Add row</b>
        </Link>
        {shouldAllowRowDelete && (
          <>
            <Interpunct />
            <Link onClick={() => shouldAllowRowDelete && deleteRow(shareType)}>
              <b>Delete last row</b>
            </Link>
          </>
        )}
      </LinkCollection>
    )
  }

  return (
    <div className={className}>
      <Section>
        <StockTableHeader>Authorized Shares</StockTableHeader>
        {authorizedShares.map((share: Stock, index: number) => (
          <>
            <StockTableRow
              {...{ share, index }}
              shareType={AUTHORIZED_SHARES}
              key={`${AUTHORIZED_SHARES}_share${index}`}
            />
            {index < authorizedShares.length - 1 && <br />}
          </>
        ))}
        {renderAddRemoveButtons({
          shares: authorizedShares,
          shareType: AUTHORIZED_SHARES
        })}
      </Section>
      <Section>
        <StockTableHeader>
          Issued Shares (delete all rows if shares have not yet been issued)
        </StockTableHeader>
        {issuedShares.map((share: Stock, index: number) => (
          <>
            <StockTableRow
              {...{ share, index }}
              shareType={ISSUED_SHARES}
              key={`${ISSUED_SHARES}_share${index}`}
            />
            {index < issuedShares.length - 1 && <br />}
          </>
        ))}
        {renderAddRemoveButtons({
          shares: issuedShares,
          shareType: ISSUED_SHARES
        })}
      </Section>
    </div>
  )
})`
  position: relative;
`

export default StockTable
