import {
  Icons,
  List,
  ListPagination,
  theme,
  Dropdown,
  MetaTag,
  Button
} from '@middesk/components'
import { parse, stringify } from 'qs'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Sidebar from '../components/Sidebar'
import Page from '../components/System/Layout/Page'
import api from '../lib/api'
import {
  MAIL_TAGS,
  OWNERSHIP_TAGS,
  STATES,
  STATE_MAP,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  DEFAULT_PER_PAGE_COUNT,
  DEFAULT_PAGE_NUM
} from '../lib/constants'
import queryStringSerializer from '../lib/queryStringSerializer'
import { ContainerProps } from '../types'
import { SkeletonArray, StyledListControls } from '../components/Shared'
import Filter from '../components/System/Filter'
import { useToasts } from 'react-toast-notifications'
import Skeleton from 'react-loading-skeleton'
import pluralize from 'pluralize'

const { colors, spacing } = theme

const AGENT_MAIL = 'agent_mail'

const StyledMetatagContainer = styled.div`
  gap: ${spacing.xxsmall};
  display: flex;
  flex-wrap: wrap;
`

interface Mail {
  id: string
  external_id?: string
  agency_name?: string
  sender_name?: string
  recipient_name?: string
  arrived_at?: string
  created_at: string
  state?: string
  document: Document
  tag_names: Array<string>
  object: string
}

interface Email {
  id: string
  created_at: string
  subject: string
  sender_name: string
  state?: string
  agency_name?: string
  object: string
  arrived_at: string
}

type Communication = Mail | Email

interface Document {
  download_url: string
}

const CallToActionButton = ({
  text,
  onClick
}: {
  text: string
  onClick: () => void
}) => (
  <Button type='secondary' onClick={onClick} style={{ width: '100%' }}>
    {text}
  </Button>
)

const Tags = ({
  mail,
  removeActionRequired
}: {
  mail: Mail
  removeActionRequired: (id: string, tag_names: Array<string>) => Promise<void>
}) => {
  const { tag_names, id } = mail

  const mailTypeTagItems = MAIL_TAGS.filter(({ key }) =>
    tag_names?.includes(key)
  )
  const mailTypeTagItemsToRender = mailTypeTagItems?.map((item, index) => {
    return (
      <MetaTag key={index} type='unknown'>
        {item?.value}
      </MetaTag>
    )
  })

  const ownershipTagItem = OWNERSHIP_TAGS.find(tag =>
    tag_names?.includes(tag.key)
  )

  const removeActionRequiredFromTag = (tag_names: Array<string>) => {
    const tags = tag_names?.filter(str => str !== 'action_required')
    tags?.push('no_action_required')
    return tags
  }

  const ownershipTagItemsToRender = ownershipTagItem ? (
    ownershipTagItem?.key === 'action_required' ? (
      <MetaTag key={ownershipTagItem.key} type={ownershipTagItem.color}>
        <Dropdown>
          <Dropdown.Toggle>
            <div
              style={{
                color: colors.red,
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                gap: spacing.xsmall,
                height: '0.75rem'
              }}
            >
              <div>{ownershipTagItem.value}</div>
              <Icons.ChevronDown color={colors.red} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Option
              onClick={() =>
                removeActionRequired(id, removeActionRequiredFromTag(tag_names))
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.xsmall
                }}
              >
                <Icons.Success />
                <div>Mark complete</div>
              </div>
            </Dropdown.Option>
          </Dropdown.Menu>
        </Dropdown>
      </MetaTag>
    ) : (
      <MetaTag key={ownershipTagItem.key} type={ownershipTagItem.color}>
        {ownershipTagItem.value}
      </MetaTag>
    )
  ) : (
    <></>
  )

  return (
    <StyledMetatagContainer>
      {ownershipTagItemsToRender}
      {mailTypeTagItemsToRender}
    </StyledMetatagContainer>
  )
}

const MailHeader = ({
  mail,
  removeActionRequired
}: {
  mail: Mail
  removeActionRequired: any
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <b style={{ marginRight: '10px' }}>Mail</b>{' '}
    <Tags mail={mail} removeActionRequired={removeActionRequired} />
  </div>
)

const EmailHeader = ({ email: { subject } }: { email: Email }) => (
  <div>
    <b>Email: {subject}</b>
  </div>
)

const StateAndSender = ({
  comm,
  removeActionRequired
}: {
  comm: Mail | Email
  removeActionRequired: any
}) => {
  const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  const Sender = styled.div`
    text-wrap: wrap;
    color: ${colors.karl};
  `

  const Header = styled.div`
    margin-bottom: 2px;
    color: ${colors.graphite};
  `

  const { state, agency_name, sender_name } = comm
  return (
    <FlexContainer>
      <Header>
        {comm.object === AGENT_MAIL ? (
          <MailHeader
            mail={comm as Mail}
            removeActionRequired={removeActionRequired}
          />
        ) : (
          <EmailHeader email={comm as Email} />
        )}
      </Header>
      <Sender>
        {(STATE_MAP[state || ''] || {}).name} <b>・</b>{' '}
        {agency_name || sender_name}
      </Sender>
    </FlexContainer>
  )
}

const Communications = styled(({ className }: ContainerProps) => {
  const [comms, setComms] = useState<{
    data: Communication[]
    total_count: number
    has_more: boolean
  }>()
  const [fetching, setFetching] = useState(true)
  const [mailUpdateSwitch, setMailUpdateSwitch] = useState(true)
  const { push } = useHistory()
  const location = useLocation()
  const { search } = location
  const { companyId } = useParams<{ companyId: string }>()

  const { addToast } = useToasts()

  const params = parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    const defaultParams = {
      page: DEFAULT_PAGE_NUM,
      per_page: DEFAULT_PER_PAGE_COUNT,
      sort_by: 'arrived_at',
      sort_order: 'desc'
    }

    api
      .get(`/v1/agent/companies/${companyId}/communications`, {
        ...defaultParams,
        ...params
      })
      .then(json => {
        setComms(json)
      })
      .finally(() => setFetching(false))
  }, [location, mailUpdateSwitch])

  const removeActionRequired = async (id: string, tag_names: Array<string>) => {
    await api.patch(`/v1/agent/mail/${id}`, {
      tag_names: tag_names
    })
    setMailUpdateSwitch(!mailUpdateSwitch)
  }

  const forwardEmail = ({ id }: Email) => {
    api
      .post(`/v1/agent/companies/${companyId}/inbound_emails/${id}/forward`, {})
      .then(() => addToast('Email forwarded', { appearance: 'success' }))
  }

  const columns = [
    {
      title: 'Details',
      key: 'state',
      width: 10,
      render: (comm: Communication) => (
        <StateAndSender {...{ comm, removeActionRequired }} />
      )
    },
    {
      title: 'Date added',
      key: 'arrived_at',
      width: 2,
      sortBy: 'arrived_at',
      type: 'datetime'
    },
    {
      width: 2,
      key: 'call_to_action',
      render: (comm: Communication) =>
        comm.object === AGENT_MAIL ? (
          <CallToActionButton
            text='View'
            onClick={() =>
              window.open((comm as Mail).document.download_url, '_blank')
            }
          />
        ) : (
          <CallToActionButton
            text='Forward to me'
            onClick={() => forwardEmail(comm as Email)}
          />
        )
    }
  ]

  const {
    state = null,
    per_page = DEFAULT_PER_PAGE_COUNT,
    page = DEFAULT_PAGE_NUM,
    tax_type = null,
    communication_type = null
  }: {
    [key: string]: any
  } = params as any

  const onChange = (nextParams: { [key: string]: any }): void => {
    push(
      `/companies/${companyId}/communications?${queryStringSerializer(
        params,
        nextParams
      )}`
    )
  }

  const onFilter = (params: { [key: string]: string | undefined }) => {
    const query = stringify(
      {
        ...parse(search, { ignoreQueryPrefix: true }),
        ...params
      },
      { skipNulls: true }
    )

    push(`/companies/${companyId}/communications?${query}`)
  }

  const onPage = (page: number) => onChange({ page: page })

  const onSort = (key: string | undefined) => {
    let { sort_by, sort_order } = parse(search, {
      ignoreQueryPrefix: true
    })

    if (key === sort_by) {
      sort_order = sort_order === 'asc' ? 'desc' : 'asc'
    } else {
      sort_by = key
      sort_order = 'desc'
    }

    onFilter({ sort_by, sort_order })
  }

  return (
    <Page {...{ title: 'Communications', className }}>
      <Sidebar />
      <List
        data={comms?.data || []}
        columns={columns}
        onSort={onSort}
        className='list'
      >
        <StyledListControls>
          <Filter
            placeholder='State'
            onFilter={(state: string) => onChange({ state, page: 1 })}
            currentValue={state || null}
            options={STATES.map(({ abbr, name }) => ({
              label: name,
              value: abbr
            }))}
          />
          <Filter
            placeholder='Tax types'
            onFilter={(tax_type: string) => onChange({ tax_type, page: 1 })}
            currentValue={tax_type || null}
            options={[
              {
                value: STATE_TAX_WITHHOLDINGS_TYPE,
                label: 'Tax Withholdings'
              },
              {
                value: STATE_UNEMPLOYMENT_INSURANCE_TYPE,
                label: 'Unemployment Insurance'
              }
            ]}
          />
          <Filter
            placeholder='Communication type'
            onFilter={(communication_type: string) =>
              onChange({ communication_type, page: 1 })
            }
            currentValue={communication_type || null}
            options={[
              {
                value: 'mail',
                label: 'Mail'
              },
              {
                value: 'email',
                label: 'Email'
              }
            ]}
          />
          <div className='muted'>
            {!fetching && comms != undefined ? (
              <>{pluralize('Communication', comms.total_count, true)}</>
            ) : (
              <Skeleton />
            )}
          </div>
          {!fetching && comms ? (
            <ListPagination
              onPage={onPage}
              page={parseInt(page)}
              perPage={per_page}
              total={comms.total_count}
            />
          ) : (
            <div />
          )}
        </StyledListControls>
      </List>
      {fetching && <SkeletonArray />}
    </Page>
  )
})`
  [aria-name='zero'] {
    display: flex;
    flex-direction: column;
    gap: ${spacing.medium};
    justify-content: center;
    align-items: center;
  }

  .list > tbody > tr > td {
    height: min-content;
  }
`

export default Communications
