export default class Env {
  static isStaging(): boolean {
    return /^agent-staging\.middesk\.com/.test(window.location.hostname)
  }

  static isProduction(): boolean {
    return /^agent.middesk\.com/.test(window.location.hostname)
  }

  static isDevelopment(): boolean {
    return !this.isProduction() && !this.isStaging()
  }
}
