import React from 'react'
import { Insight } from '../../../lib/api/companyInsights'
import { DotLabel, Icons, theme } from '@middesk/components'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { tarStatusToComponentColor } from '../../../lib/helpers'
const { spacing, colors, typography } = theme

const InsightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;

  > div {
    padding-bottom: ${spacing.large};
    border-bottom: 2px solid ${colors.dawn};
  }

  > div:not(:first-child) {
    padding-top: ${spacing.large};
  }
`

const InsightRow = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DotLabelInnerWrapper = styled.div`
  display: flex;
  color: ${colors.graphite};
  font-family: ${typography.faces.default};
  gap: ${spacing.xsmall};
`

const StyledChevronRight = styled(Icons.ChevronRight)`
  stroke: ${colors.karl};
`

const Insights = ({
  companyId,
  insights
}: {
  companyId: string
  insights: Insight[]
}) => {
  const { push } = useHistory()
  const viewAccounts = (status: string) =>
    push(`/companies/${companyId}/accounts/details?status=${status}`)

  return (
    <InsightColumn>
      {insights.map(insight =>
        insight.status && insight.value > 0 ? (
          <InsightRow
            key={insight.key}
            onClick={() => insight.status && viewAccounts(insight.status)}
          >
            <DotLabel color={tarStatusToComponentColor(insight.status).color}>
              <DotLabelInnerWrapper>
                <b>{insight.value}</b>
                <div>{insight.label}</div>
              </DotLabelInnerWrapper>
            </DotLabel>
            <StyledChevronRight />
          </InsightRow>
        ) : null
      )}
    </InsightColumn>
  )
}

export default Insights
