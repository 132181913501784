import React from 'react'
import { TransfersIntakeFormValues } from '../../../types'
import styled from 'styled-components'
import SPACING from '../../System/Spacing'
import { useFormikContext } from 'formik'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.medium};
  margin: ${SPACING.medium} 0;
`

const StatesToTransferList = (): JSX.Element => {
  const { values } = useFormikContext<TransfersIntakeFormValues>()

  return (
    <ListContainer>
      {values.registered_states.flatMap(
        ({ name, foreignQualificationAction, taxRegistrationAction }) => {
          if (
            foreignQualificationAction !== 'transfer' &&
            taxRegistrationAction !== 'transfer'
          )
            return []

          let displayText = name
          if (foreignQualificationAction === 'transfer') {
            displayText += '・Secretary of State'
          }

          if (taxRegistrationAction === 'transfer') {
            displayText += '・Payroll Tax'
          }

          return (
            <div key={displayText}>
              <b>{displayText}</b>
            </div>
          )
        }
      )}
    </ListContainer>
  )
}

export default StatesToTransferList
