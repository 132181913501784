import { GetTaxRegistrationsParameters, TaxRegistration } from '../../types'
import api from '../api'

export type GetTaxRegistrationsResponse = {
  data: Array<TaxRegistration>
  has_more: boolean
  total_count: number
}

export const APITaxRegistration = {
  get: (
    companyId: string,
    params: GetTaxRegistrationsParameters
  ): Promise<GetTaxRegistrationsResponse> =>
    api.get(`/v1/agent/companies/${companyId}/tax_registrations`, params)
}
