import { theme } from '@middesk/components'
import styled from 'styled-components'

const { spacing } = theme

const AttributeColumn = styled.div`
  &:first-child {
    flex: 2;
  }

  &:not(:first-child) {
    flex: 1;
  }

  > label {
    margin-bottom: ${spacing.compact};
  }

  > label > div {
    white-space: pre;
  }
`

export default AttributeColumn
