import { Loader } from '@middesk/components'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SubmitButtonProps } from '../types'
import Button from './Button'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'

const MatchingWidthButton = styled(Button)<{ width: number }>`
  ${props => `width: ${props.width}px;`}
`

const LoaderButton = styled(
  ({
    className,
    submitting,
    submitText,
    isDisabled,
    onClick,
    type = 'submit'
  }: SubmitButtonProps) => {
    const buttonRef = useRef<HTMLDivElement>(null)

    const [buttonWidth, setButtonWidth] = useState<number>(0)

    useEffect(() => {
      setButtonWidth(buttonRef.current?.children[0].clientWidth || 0)
    }, [buttonRef.current?.children[0].clientWidth])

    return (
      <div {...{ className }}>
        {submitting ? (
          <MatchingWidthButton type='secondary' disabled width={buttonWidth}>
            <Loader size='small' />
          </MatchingWidthButton>
        ) : (
          <div ref={buttonRef}>
            <Button {...{ onClick, type, disabled: isDisabled }}>
              {submitText}
            </Button>
          </div>
        )}
      </div>
    )
  }
)`
  input {
    min-width: fit-content;
  }

  button {
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  }
`

export default LoaderButton
