import React from 'react'
import { ContainerProps } from 'react-autosuggest'
import styled from 'styled-components'
import { COLORS } from './System/Colors'

const Interpunct = styled(({ className }: ContainerProps) => {
  return <div {...{ className }}>&middot;</div>
})`
  color: ${COLORS.karl};
`

export default Interpunct
