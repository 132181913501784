import { useEffect, useState } from 'react'
import APIAgentApplication, { Application } from './api/agentApplication'

const useApplication = (
  applicationId: string
): { application?: Application; fetchingApplication: boolean } => {
  const [application, setApplication] = useState<Application | undefined>()
  const [fetchingApplication, setFetchingApplication] = useState<boolean>(true)

  useEffect(() => {
    APIAgentApplication.show(applicationId)
      .then(setApplication)
      .finally(() => setFetchingApplication(false))
  }, [])

  return { application, fetchingApplication }
}

export default useApplication
