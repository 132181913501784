import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_WEIGHTS } from './System/Typography'

const StyledSection = styled.div`
  border-bottom: 2px solid ${COLORS.dawn};
  color: ${COLORS.graphite};
  margin-bottom: 20px;

  &:last-child {
    border-bottom: none;
  }

  > div:first-child {
    display: flex;
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    justify-content: space-between;
    margin-bottom: 10px;
  }

  > div:last-child {
    display: flex;
    margin-bottom: 10px;
  }
`

const Section = ({
  title,
  className,
  subTitle,
  children
}: {
  title?: string
  className?: string
  subTitle?: string
  children?: ReactNode
}) => {
  return (
    <StyledSection className={className}>
      <div>
        {title} <span>{subTitle}</span>
      </div>
      <div>{children}</div>
    </StyledSection>
  )
}

export default Section
