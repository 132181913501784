import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useFormikContext } from 'formik'
import { COLORS } from './System/Colors'
import SPACING from './System/Spacing'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'

const StyledInput = styled.input`
  margin-top: ${SPACING.xsmall};
  margin-right: ${SPACING.small};
  display: block;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`

const StyledRow = styled(Row)`
  padding: ${SPACING.small} 0;
`

const AdminLabel = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  margin-right: ${SPACING.xlarge};
`

const MemberLabel = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

const RoleDescription = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  color: ${COLORS.karl};
`

const Label = styled.span`
  font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  line-height: 9px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: ${COLORS.karl};
`

interface RoleSelectorProps {
  values: any
  setValues: (values: any) => void
}

const RoleSelector = ({ values, setValues }: RoleSelectorProps) => {
  const { setFieldValue } = useFormikContext()

  const handleRoleSelection = (role: string) => {
    setFieldValue('role', role)
    setValues({ ...values, role: role })
  }

  return (
    <>
      <Label>Role</Label>
      <StyledRow>
        <StyledCol>
          <StyledInput
            name={'role'}
            key={'admin'}
            checked={values.role === 'admin'}
            value='admin'
            type='radio'
            onChange={() => handleRoleSelection('admin')}
          />
        </StyledCol>
        <Col>
          <AdminLabel>Admin</AdminLabel>
        </Col>
        <Col>
          <RoleDescription>Can manage billing and team</RoleDescription>
        </Col>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <StyledInput
            name={'role'}
            key={'member'}
            checked={values.role === 'member'}
            value='member'
            type='radio'
            onChange={() => handleRoleSelection('member')}
          />
        </StyledCol>
        <Col>
          <MemberLabel>Member</MemberLabel>
        </Col>
      </StyledRow>
    </>
  )
}

export default RoleSelector
