import styled from 'styled-components'
import Bubble from './Bubble'
import SPACING from './Spacing'

const IconBubble = styled(Bubble)`
  height: ${SPACING.xxlarge};
  width: ${SPACING.xxlarge};
`

export default IconBubble
