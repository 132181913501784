import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from '../BorderRadius'
import { COLORS } from '../Colors'

const INFO_HEIGHT = '18'

export type InfoVariant = 'grey'

export type InfoProps = {
  className?: string
  variant?: InfoVariant
}

const Info = styled(({ className, variant = 'grey' }: InfoProps) => {
  let stroke = COLORS.karl

  if (variant === 'grey') {
    stroke = COLORS.karl
  }

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'
  const strokeWidth = BORDER_RADIUS.xxsmall

  return (
    <svg
      width={INFO_HEIGHT}
      height={INFO_HEIGHT}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M9 12V9'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M9 6H9.0075'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
    </svg>
  )
})`
  min-width: ${INFO_HEIGHT}px;
  min-height: ${INFO_HEIGHT}px;
`

export default Info
