import { Form, TextField } from '@middesk/components'
import React, { useState, useContext } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import * as yup from 'yup'
import { ADDRESS_TYPES, PO_BOX_PATTERN } from '../../lib/constants'
import { ContainerProps, FindIdentityFormValues } from '../../types'
import ActionFooter from '../ActionFooter'
import Address from '../Address'
import LoaderButton from '../LoaderButton'
import { AuthContext } from '../../contexts/AuthProvider'
import { hubspotSubmitBusiness } from '../../lib/hubspot'
import { useGeoLocation } from '../../contexts/GeoLocationProvider'

const SELECT_HEIGHT = '41px'

const validationSchema = yup.object().shape({
  business_name: yup.string().required('Legal business name is required'),
  officer_name: yup.string().required('Officer name is required'),
  primary_address: yup.object().shape({
    address_line1: yup
      .string()
      .required('Required')
      .test(
        'po_box',
        'Should not be a PO Box',
        (v: any) => !PO_BOX_PATTERN.test(v)
      ),
    address_line2: yup.string().optional().nullable(),
    city: yup.string().required('Required'),
    state: yup.string().required('Required'),
    postal_code: yup.string().required('Required'),
    type: yup.string().optional()
  })
})

const SubmitBusinessDetailsForm = styled(
  ({ className }: ContainerProps): JSX.Element => {
    const { user, transactionalAccount } = useContext(AuthContext)
    const { state }: { state: FindIdentityFormValues } = useLocation()

    const initialValues: FindIdentityFormValues = state || {
      business_name: '',
      primary_address: {},
      officer_name: ''
    }

    const [values, setValues] = useState<FindIdentityFormValues>(initialValues)
    const [submitting, setSubmitting] = useState(false)
    const geoLocation = useGeoLocation()
    const { push } = useHistory()

    const onSubmit = (values: FindIdentityFormValues): void => {
      setSubmitting(true)
      const nameSplit = user?.name.split(' ') || ['', '']

      Promise.all([
        hubspotSubmitBusiness(
          {
            email: user?.email || '',
            firstname: nameSplit[0],
            lastname: nameSplit[nameSplit.length - 1],
            company: values.business_name,
            address: (
              values.primary_address.address_line1 +
              ' ' +
              (values.primary_address.address_line2 || '')
            ).trim(),
            city: values.primary_address.city,
            state: values.primary_address.state,
            zip: values.primary_address.postal_code,
            lead_capture_url__c: window.location.href,
            use_case__c: 'Agent'
          },
          geoLocation.IPv4
        )
      ]).finally(() => {
        setSubmitting(false)
        if (
          user &&
          !user?.account.settings.agent.package_type &&
          !transactionalAccount
        ) {
          push({ pathname: '/direct-upgrade' })
        } else {
          push({ pathname: '/home' })
        }
      })
    }

    return (
      <Form
        {...{
          className,
          initialValues,
          validationSchema,
          onChange: setValues,
          onSubmit
        }}
      >
        <Col>
          <Row>
            <Col xs>
              <TextField
                placeholder='Enter legal business name'
                name='business_name'
                label='Legal Business Name'
              />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <TextField
                placeholder='Enter full name of person associated to the business'
                name='officer_name'
                label='Officer Name'
              />
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Address
                name='primary_address'
                label='Primary Business Address'
                addressType={ADDRESS_TYPES.primary}
                allowPOBox={false}
              />
            </Col>
          </Row>
          <ActionFooter>
            <LoaderButton
              {...{
                submitting,
                isDisabled: !validationSchema.isValidSync(values),
                submitText: 'Continue'
              }}
            />
          </ActionFooter>
        </Col>
      </Form>
    )
  }
)`
  overflow: visible;

  [class*='-container'],
  [class*='-control'] {
    height: ${SELECT_HEIGHT};
  }
`

export default SubmitBusinessDetailsForm
