import styled from 'styled-components'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES } from './System/Typography'

const SubText = styled.p`
  color: ${COLORS.graphite};
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

export default SubText
