import React from 'react'
import styled from 'styled-components'
import { TransfersIntakeCardProps } from '../../types'
import Separator from '../Separator'
import Card from '../System/Card'
import SPACING from '../System/Spacing'
import TransfersIntakeCardNavigation from './TransfersIntakeCardNavigation'

const StyledTransfersCard = styled(Card)`
  flex-direction: column;
  width: 100%;

  ${Separator} {
    margin: ${SPACING.medium} 0;
  }
`

const TransfersIntakeCard = ({
  onNext,
  onBack,
  isSubmitting = false,
  isFirst = false,
  isSubmitButton = false,
  isLast = false,
  children = [],
  shouldDisableContinue = false,
  currentStatePage = 0,
  stateCount = 0
}: TransfersIntakeCardProps): JSX.Element => {
  return (
    <StyledTransfersCard>
      {children}
      {!isLast && (
        <TransfersIntakeCardNavigation
          {...{
            onNext,
            onBack,
            isSubmitting,
            isFirst,
            isSubmitButton,
            shouldDisableContinue,
            currentStatePage,
            stateCount
          }}
        />
      )}
    </StyledTransfersCard>
  )
}

export default TransfersIntakeCard
