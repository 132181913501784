import React, { useEffect, useState, useContext } from 'react'
import { Form, PasswordStrength, TextField } from '@middesk/components'
import amplitude from 'amplitude-js'
import * as Sentry from '@sentry/browser'
import { parse } from 'qs'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import zxcvbn from 'zxcvbn'
import API from '../../lib/api'
import { AuthenticationParams, CreateAccountFormNewValues } from '../../types'
import LoaderButton from '../LoaderButton'
import StyledCard from '../StyledCard'
import SigninWithGoogle from '../Signin/SigninWithGoogle'
import { AuthContext } from '../../contexts/AuthProvider'
import { identify, track, EVENTS } from '../../lib/segment'
import Separator from '../Separator'
import styled from 'styled-components'

const AGENT_USE_CASE = 'agent'

const MIN_ZXCVBN_SCORE = 2

export const FullWidthCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().required('Confirmed password is required')
})

const CreateAccountFormNew = (): JSX.Element => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { referral_code, email } = parse(search, {
    ignoreQueryPrefix: true
  })

  const initialValues: CreateAccountFormNewValues = {
    email: (email as string) || '',
    password: '',
    confirmPassword: ''
  }
  const [values, setValues] = useState<CreateAccountFormNewValues>(
    initialValues
  )
  const [submitting, setSubmitting] = useState(false)
  const [signingInWithGoogle, setSigningInWithGoogle] = useState(false)

  const { setFetchingUser } = useContext(AuthContext)

  useEffect(() => {
    amplitude.getInstance().logEvent('signup-started', { agent: true })
  }, [])

  const trackUser = (account_id: string, sso: boolean) => {
    amplitude
      .getInstance()
      .logEvent('signup-completed', { agent: true, email: values.email, sso })

    window.gtag('config', process.env.REACT_APP_GOOGLE_ADWORDS_KEY)
    window.gtag('event', 'conversion', {
      send_to: `${process.env.REACT_APP_GOOGLE_ADWORDS_KEY}/NnDpCJy3he8CEKPAlaIC`
    })

    identify(account_id)
    track(EVENTS.agentAccountCreated)
  }

  const onConfirm = () => push('/confirm-email', { email: values.email })

  const onSubmit = (): void => {
    setSubmitting(true)

    API.post('/request-access', {
      email: values.email,
      password: values.password,
      usecase: AGENT_USE_CASE,
      agent: true,
      referral_code
    })
      .then(({ account_id }: { account_id: string }) => {
        trackUser(account_id, false)
        setSubmitting(false)
        onConfirm()
      })
      /* eslint-disable-next-line no-console */
      .catch(console.error)
  }

  const GoogleSignup = async ({
    email,
    name,
    password,
    tokenId
  }: AuthenticationParams) => {
    setSigningInWithGoogle(true)
    const Authorization = tokenId
      ? `Bearer ${tokenId}`
      : `Basic ${btoa(email + ':' + password)}`

    const headers = { Authorization }
    return API.login({
      Authorization
    })
      .then(() => {
        setFetchingUser(true)

        push('/home')
      })
      .catch(e => {
        if (e.status === 401 && tokenId) {
          API.post(
            '/ajax/google_sso/users',
            {
              name,
              email,
              agent: true
            },
            headers
          )
            .then(() =>
              API.login(headers).then(
                ({ account_id }: { account_id: string }) => {
                  trackUser(account_id, true)
                  setFetchingUser(true)
                  push('/set-user-info')
                }
              )
            )
            .catch(e => {
              setSigningInWithGoogle(false)
              Sentry.captureException(e)
            })
        } else {
          setSigningInWithGoogle(false)
          Sentry.captureException(e)
        }
      })
  }

  const isDisabled =
    (values.password && values.password != values.confirmPassword) ||
    zxcvbn(values.password).score < MIN_ZXCVBN_SCORE

  return (
    <StyledCard>
      <Form
        {...{ initialValues, validationSchema, onSubmit, onChange: setValues }}
      >
        <Row>
          <Col xs>
            <TextField
              placeholder='Enter work email'
              name='email'
              label='Email'
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <TextField
              type='password'
              placeholder='Enter password'
              name='password'
              label='Password'
            />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <PasswordStrength password={values.password} />
          </Col>
        </Row>
        <Row>
          <Col xs>
            <TextField
              type='password'
              placeholder='Confirm password'
              name='confirmPassword'
              label='Confirm Password'
            />
          </Col>
        </Row>
        <Separator />
        <FullWidthCenteredDiv>
          <LoaderButton
            {...{
              submitting,
              isDisabled,
              submitText: 'Create account'
            }}
          />
        </FullWidthCenteredDiv>
      </Form>
      <Row>
        <Col xs>
          <SigninWithGoogle
            {...{
              onClick: (e: any) => {
                return GoogleSignup({
                  ...e.profileObj,
                  ...e
                })
              },
              mode: 'sign_up',
              isLoading: signingInWithGoogle
            }}
          />
        </Col>
      </Row>
    </StyledCard>
  )
}

export default CreateAccountFormNew
