import styled from 'styled-components'
import { theme } from '@middesk/components'

const { spacing } = theme

const ControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.normal};
`

export default ControlRow
