import amplitude from 'amplitude-js'

const initializeAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude
      .getInstance()
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
        apiEndpoint: 'amp-proxy.middesk.com',
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true
      })
  }
}

export default initializeAmplitude
