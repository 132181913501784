import React, { FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider'

interface Props {
  component: any
  exact?: boolean
  path: string
}

const PrivateRoute: FC<Props> = ({ component, exact = false, path }: Props) => {
  const { authenticated } = useContext(AuthContext)

  if (authenticated) {
    return <Route exact={exact} path={path} component={component} />
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )}
    />
  )
}

export default PrivateRoute
