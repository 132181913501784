import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { PartnerReferral } from '../../types'
import PartnerReferralSignupForm from '../Forms/PartnerReferralSignupForm'
import { Link } from 'react-router-dom'
import { isPaychex } from '../../lib/helpers'

const StyledLogo = styled.img`
  max-height: 150px;
  max-width: 150px;
  height: auto;
  width: 100%;
`

const StyledLink = styled(Link)`
  color: #4263eb;
  cursor: pointer;
`

export const StyledForm = styled.form`
  label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div,
    input {
      width: 100%;
    }
  }
`

const PartnerReferralCard = ({
  partnerReferral
}: {
  partnerReferral: PartnerReferral
}): JSX.Element => (
  <>
    {partnerReferral.brand.logo_url && (
      <Row center='xs' style={{ margin: '15px auto' }}>
        <Col xs>
          {isPaychex(partnerReferral.brand) ? (
            <img src={'/paychex_secondary_logo.png'} width={200} />
          ) : (
            <StyledLogo src={partnerReferral.brand.logo_url} />
          )}
        </Col>
      </Row>
    )}
    <Row center='xs' style={{ margin: '15px auto' }}>
      <Col xs>
        <h2>Start your applications</h2>
      </Col>
    </Row>
    <Row center='xs' style={{ margin: '15px auto' }}>
      <Col xs>
        You&apos;ve been invited by {partnerReferral.brand.name} to register for
        state payroll taxes.
      </Col>
    </Row>

    <Row>
      <Col xs>
        <PartnerReferralSignupForm partnerReferral={partnerReferral} />
      </Col>
    </Row>
    <Row center='xs'>
      <Col xs>
        Or{' '}
        <StyledLink to='/login'>continue your existing application</StyledLink>
      </Col>
    </Row>
  </>
)

export default PartnerReferralCard
