import { Card, theme } from '@middesk/components'
import styled from 'styled-components'

const { spacing } = theme

const StyledCard = styled(Card)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: visible;
  padding: ${spacing.normal};
`

export default StyledCard
