import React, { createContext, useContext, useEffect, useState } from 'react'
import { Company } from '../types'
import APICompany from '../lib/api/company'
import { useHistory } from 'react-router'
import { AuthContext } from '../contexts/AuthProvider'
import api from '../lib/api'

export const CompanyContext = createContext<{
  activeCompanyId: string | undefined
  setActiveCompanyId: (id: string) => void
  activeCompany: Company | undefined
  fetchingActiveCompany: boolean
  companyInsights: any[]
}>({
  activeCompanyId: undefined,
  setActiveCompanyId: () => undefined,
  activeCompany: undefined,
  fetchingActiveCompany: false,
  companyInsights: []
})

const CompanyProvider = ({ children }: { children: any }) => {
  const { push } = useHistory()
  const { authenticated, inGuestMode } = useContext(AuthContext)

  const [activeCompany, setActiveCompany] = useState<Company | undefined>()
  const [fetchingActiveCompany, setFetchingActiveCompany] = useState<boolean>(
    true
  )
  const [fetchingCompanies, setFetchingCompanies] = useState(true)
  const [activeCompanyId, setActiveCompanyId] = useState<string | undefined>()
  const [companyInsights, setCompanyInsights] = useState<any[]>([])

  const fetchCompanyInsights = async () => {
    api.get('/v1/agent/company_insights').then(({ data }) => {
      setCompanyInsights(data)
    })
  }

  const fetchActiveCompany = async (id?: string) => {
    setFetchingActiveCompany(true)

    if (id) {
      await APICompany.show(id)
        .then(setActiveCompany)
        .catch(e => {
          if (e.status === 404) {
            push('/')
          }
        })
    }

    setFetchingActiveCompany(false)
  }

  useEffect(() => {
    if (authenticated && !inGuestMode) {
      APICompany.index({ sort_by: 'created_at', sort_order: 'asc' })
        .then(({ data }) => {
          setActiveCompanyId(data[0]?.id) // the oldest company
        })
        .finally(() => setFetchingCompanies(false))
      fetchCompanyInsights()
    }
  }, [authenticated, inGuestMode])

  useEffect(() => {
    if (!fetchingCompanies && authenticated && !inGuestMode) {
      fetchActiveCompany(activeCompanyId)
    }
  }, [activeCompanyId, fetchingCompanies, authenticated, inGuestMode])

  return (
    <CompanyContext.Provider
      value={{
        activeCompanyId,
        setActiveCompanyId,
        activeCompany,
        fetchingActiveCompany,
        companyInsights
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyProvider
