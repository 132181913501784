import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthProvider'
import styled from 'styled-components'

const ThemeDecorator = (Component: any) =>
  styled(props => {
    const { customTheme } = useContext(AuthContext)
    return <Component {...{ customTheme, ...props }} />
  })``

export default ThemeDecorator
