import React, { useEffect, useState } from 'react'
import { Banner, theme } from '@middesk/components'
import useLocalStorage, { writeStorage } from '@rehooks/local-storage'
import APIAnnualFiling from '../../lib/api/annualFiling'
import { AnnualFiling } from '../../types'
import { ANNUAL_FILING_MESSAGE, COMPLETED_STATUS } from '../../lib/constants'
import { BannerContent } from '../Shared'

const { spacing } = theme

const ANNUAL_FILING_TOGGLE_KEY = 'annualFilingConfirmed'

const AnnualReportBanner = ({ companyId }: { companyId: string }) => {
  const [annualFilingConfirmed] = useLocalStorage<boolean>(
    ANNUAL_FILING_TOGGLE_KEY
  )

  const [
    isAnnualFilingConfirmed,
    setIsAnnualFilingConfirmed
  ] = useState<boolean>(annualFilingConfirmed || false)
  const [isInitial, setIsInitial] = useState<boolean>(false)

  const onClick = () => {
    writeStorage(ANNUAL_FILING_TOGGLE_KEY, true)
    setIsAnnualFilingConfirmed(true)
  }

  useEffect(() => {
    if (!isAnnualFilingConfirmed) {
      APIAnnualFiling.index(companyId).then(({ data }) =>
        setIsInitial(
          data &&
            (data.length === 1 ||
              !data.find(
                ({ status }: AnnualFiling) => status === COMPLETED_STATUS
              ))
        )
      )
    }
  }, [])

  if (isAnnualFilingConfirmed || !isInitial) return null

  return (
    <Banner
      intent='info'
      onDismiss={onClick}
      style={{ marginBottom: spacing.large }}
    >
      <BannerContent>
        <div>{ANNUAL_FILING_MESSAGE}</div>
      </BannerContent>
    </Banner>
  )
}

export default AnnualReportBanner
