import React from 'react'
import { theme } from '@middesk/components'
import styled from 'styled-components'

import { ApplicationInvitation } from '../types'
import { BODY_MAX_WIDTH, BODY_MIN_WIDTH } from './Body'
import { Col } from 'react-styled-flexboxgrid'

const { colors, spacing } = theme

const Wrapper = styled.div`
  background-color: ${colors.frost};
  border: 2px solid ${colors.karl};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.compact};
  max-width: ${BODY_MAX_WIDTH};
  min-width: ${BODY_MIN_WIDTH};
  padding: 10px 20px;
  width: 100%;
`

const CenteredCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0;
`

type Props = {
  invitation: ApplicationInvitation | null
}

const InvitationAlert = ({ invitation }: Props) => (
  <CenteredCol xs>
    <Wrapper>
      {invitation?.partner?.name} has requested you to register for payroll
      taxes in {invitation?.state}.
    </Wrapper>
  </CenteredCol>
)

export default InvitationAlert
