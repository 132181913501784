import React, { useContext, useEffect, useState } from 'react'
import { SelectField, TextField } from '@middesk/components'
import { FormikErrors, useFormikContext } from 'formik'
import qs from 'qs'
import { useLocation } from 'react-router'
import * as yup from 'yup'
import Address from '../components/Address'
import { Page, PageProps, GapWrapper } from '../components/Page'
import { AuthContext } from '../contexts/AuthProvider'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import {
  ADDRESS_TYPES,
  ACQUIRED_REGISTERED,
  STATE_MAP,
  MONTHS,
  PAYCHEX_ACCOUNT_SLUG
} from '../lib/constants'
import {
  ConfirmCompany,
  FormValues,
  RegistrationType,
  Question,
  DatePartQuestion
} from '../types'
import { InfoRequestValues } from './InfoRequestForm'
import moment from 'moment'
import {
  getStateQuestionPrefills,
  getTextFieldType,
  shouldRaiseDateError,
  getDateFromDatePart,
  disableScrollAction
} from '../lib/helpers'
import { get, intersection, isEmpty } from 'lodash'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import { StyledSelectWrapper } from './CompanyDetails'
import IntakeDocumentUploader from './IntakeDocumentUploader'
import styled from 'styled-components'
import { theme } from '@middesk/components'

const { colors, typography, spacing } = theme

const DatePartComponent = styled.div`
  min-width: 150px;
  flex-grow: 1;
`

export const SQL_UNSIGNED_FOUR_BYTE_INTEGER_MAX = 2147483647

export const checkCompanyDependency = (
  question: Question,
  values: FormValues | ConfirmCompany
) => {
  if (!question.company_dependencies) return true
  for (let i = 0; i < question.company_dependencies.length; i++) {
    const company_dependency = question.company_dependencies[i]
    let dependencyMet = true
    for (let j = 0; j < company_dependency.length; j++) {
      const dependency = company_dependency[j]
      if (
        !compareDependencyValue(
          get(values, dependency.field),
          dependency.value,
          dependency.comparator
        )
      ) {
        dependencyMet = false
        break
      }
    }
    if (dependencyMet) return true
  }
  return false
}

const getTooltip = (
  question: Question,
  parentAccountSlug: string | undefined
) => {
  if (parentAccountSlug === PAYCHEX_ACCOUNT_SLUG) {
    if (question.key.endsWith('_payroll_date')) {
      return {
        text: `The "1st Payroll date" is the date the employee(s) will receive
        their first paycheck. If this date is prior to today OR today's date be
        aware this may indicate to the agency that you have accrued tax
        liability. Even if wages have not been paid, a tax return may be
        required the agency to reconcile your new account.  You, the employer,
        are responsible for remitting any withholdings or applicable UI to the
        agency - including any penalties and interest until the agency is
        successfully set up on TaxPay.`
      }
    }

    if (question.key.endsWith('_hire_date')) {
      return {
        text: `The "Hire date" is the date the employee(s) were hired or will be
        hired. If this date is prior to today OR today's date, be aware this may
        indicate to the agency that you have accrued tax liability. Even if
        wages have not been paid, a tax return may be required by the agency to
        reconcile your new account. You, the employer, are responsible for
        remitting any withholdings or applicable UI to the agency - including
        any penalties and interest until the agency is successfully set up on
        TaxPay.`
      }
    }
  }

  return question.tooltip
    ? { text: question.tooltip, url: question.help_url }
    : undefined
}

export const getQuestionComponent = (
  question: Question,
  name: string,
  questionValue: string,
  index: number,
  values: ConfirmCompany | FormValues | InfoRequestValues,
  parentAccountSlug: string | undefined
) => {
  const tooltip = getTooltip(question, parentAccountSlug)
  let component = <></>

  switch (question.type) {
    case 'address':
      component = (
        <Address
          name={name}
          label={question.label}
          sublabel={question.sublabel}
          addressType={ADDRESS_TYPES.remote}
          optional={question.optional}
          showErrorMessage={true}
          tooltip={tooltip}
        />
      )
      break
    case 'multiselect':
    case 'select': {
      const selectedValues: string[] = [questionValue].flat()

      component = (
        <StyledSelectWrapper>
          <SelectField
            name={name}
            label={question.label}
            sublabel={question.sublabel}
            isClearable={true}
            isMulti={question.type === 'multiselect'}
            showErrorMessage
            tooltip={tooltip}
          >
            {question.options?.map((option: string) => {
              return (
                <option
                  key={option}
                  value={option}
                  label={option}
                  selected={selectedValues.includes(option)}
                />
              )
            })}
          </SelectField>
        </StyledSelectWrapper>
      )
      break
    }
    case 'boolean':
      component = (
        <StyledSelectWrapper>
          <SelectField
            name={name}
            label={question.label}
            sublabel={question.sublabel}
            showErrorMessage
            tooltip={tooltip}
          >
            <option
              key={name + '-true'}
              value='true'
              label='Yes'
              selected={questionValue === 'true'}
            />
            <option
              key={name + '-false'}
              value='false'
              label='No'
              selected={questionValue === 'false'}
            />
          </SelectField>
        </StyledSelectWrapper>
      )
      break
    case 'document':
      component = (
        <StyledSelectWrapper>
          <IntakeDocumentUploader
            label={question.label}
            valueKey={name}
            initialDocument={questionValue}
          />
        </StyledSelectWrapper>
      )
      break
    case 'date_part': {
      let yearComponent
      let quarterComponent
      let monthComponent
      let dayComponent

      if (question.components?.includes('year')) {
        yearComponent = (
          <TextField
            type='number'
            label='Year'
            name={`${name}.year`}
            placeholder='Enter a year'
            onFocus={disableScrollAction}
            optional={!!question.optional}
            showErrorMessage
          />
        )
      }
      if (question.components?.includes('quarter')) {
        quarterComponent = (
          <SelectField
            label='Quarter'
            name={`${name}.quarter`}
            optional={!!question.optional}
            placeholder='Select a quarter'
            showErrorMessage
          >
            {['Q1', 'Q2', 'Q3', 'Q4'].map(q => (
              <option
                value={q}
                label={q}
                selected={get(values, `${name}.quarter`) === q}
                key={q}
              />
            ))}
          </SelectField>
        )
      }
      if (question.components?.includes('month')) {
        monthComponent = (
          <SelectField
            label='Month'
            name={`${name}.month`}
            placeholder='Select a month'
            optional={!!question.optional}
            showErrorMessage
          >
            {MONTHS.map(m => (
              <option
                value={m}
                label={m}
                selected={get(values, `${name}.month`) === m}
                key={m}
              />
            ))}
          </SelectField>
        )
      }
      if (question.components?.includes('day')) {
        dayComponent = (
          <TextField
            type='number'
            label='Day'
            placeholder='Enter a day'
            name={`${name}.day`}
            onFocus={disableScrollAction}
            optional={!!question.optional}
            showErrorMessage
          />
        )
      }
      component = (
        <div key={index}>
          <div
            style={{
              fontSize: typography.sizes.medium,
              fontWeight: typography.weights.bold,
              color: colors.graphite
            }}
          >
            {question.label}
          </div>
          {question.sublabel && (
            <div
              style={{
                fontSize: typography.sizes.medium,
                color: colors.karl
              }}
            >
              {question.sublabel}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: spacing.xsmall,
              marginTop: spacing.xsmall,
              alignItems: 'flex-start'
            }}
          >
            {yearComponent && (
              <DatePartComponent>{yearComponent}</DatePartComponent>
            )}
            {quarterComponent && (
              <DatePartComponent>{quarterComponent}</DatePartComponent>
            )}
            {monthComponent && (
              <DatePartComponent>{monthComponent}</DatePartComponent>
            )}
            {dayComponent && (
              <DatePartComponent>{dayComponent}</DatePartComponent>
            )}
          </div>
        </div>
      )
      break
    }
    default: {
      let placeholder
      let scrollAction

      if (question.placeholder) {
        placeholder = question.placeholder
      } else if (question.type === 'number') {
        placeholder = 'Enter number'
        scrollAction = disableScrollAction
      } else if (question.type === 'date') {
        placeholder = 'MM/DD/YYYY'
      } else {
        placeholder = 'Enter description'
      }

      component = (
        <TextField
          key={index}
          type={getTextFieldType(question.type)}
          label={question.label}
          sublabel={question.sublabel}
          name={name}
          placeholder={placeholder}
          optional={!!question.optional}
          showErrorMessage
          onFocus={scrollAction}
          tooltip={tooltip}
        />
      )
    }
  }

  return component
}

export const filterQuestions = (
  questions: Question[],
  values: FormValues,
  registration_types: RegistrationType[]
) => {
  return questions.filter((question: Question) => {
    const validQuestionType = !isEmpty(
      intersection(question.categories, registration_types)
    )

    return (
      validQuestionType &&
      !question.api_only &&
      question.type !== 'stock_table' &&
      checkCompanyDependency(question, values)
    )
  })
}

const compareDependencyValue = (
  actualValue: any,
  dependencyValue: string | string[],
  comparator: string | undefined
) => {
  if (actualValue instanceof Array) {
    return actualValue.some(
      (element: string) =>
        element.toUpperCase() === String(dependencyValue).toUpperCase()
    )
  }
  if (dependencyValue instanceof Array) {
    return dependencyValue.some(
      (element: string) =>
        element.toUpperCase() === String(actualValue).toUpperCase()
    )
  }

  actualValue = String(actualValue).toUpperCase()
  dependencyValue = String(dependencyValue).toUpperCase()

  switch (comparator) {
    case '!=':
      return actualValue != dependencyValue
    case '>':
      return actualValue > dependencyValue
    case '<':
      return actualValue < dependencyValue
    case '<=':
      return actualValue <= dependencyValue
    case '>=':
      return actualValue >= dependencyValue
    case 'len_gt':
      return actualValue.length > dependencyValue
    case 'starts_with':
      return actualValue.startsWith(dependencyValue)
    default:
      return actualValue == dependencyValue
  }
}

export const generateQuestionSchema = (
  state: string,
  questions: Question[],
  registration_types: RegistrationType[]
) => {
  const questionsSchema: Record<string, any> = {}

  questions.forEach((question: Question) => {
    let yupSchema:
      | yup.StringSchema<string | null | undefined, object>
      | yup.NumberSchema<number | null | undefined, object>
      | yup.NotRequiredNullableArraySchema<string | null | undefined, object>
      | yup.ObjectSchema<
          yup.Shape<
            object | undefined,
            {
              address_line1: string | null | undefined
              address_line2: string | null | undefined
              city: string | null | undefined
              state: string | null | undefined
              postal_code: string | null | undefined
              address_type: string | undefined
            }
          > | null,
          object
        >
      | yup.ObjectSchema<
          yup.Shape<
            object | undefined,
            {
              year: number | null | undefined
              quarter: string | null | undefined
              month: string | null | undefined
              day: number | null | undefined
            }
          > | null,
          object
        >
      | yup.MixedSchema<unknown | null | undefined, object>

    switch (question.type) {
      case 'address': {
        let stateSchema = yup.string()
        if (question.validation?.state) {
          stateSchema = stateSchema.test(
            'in state',
            `Address must be in ${state}`,
            value => !value || value === state
          )
        }

        if (question.optional) {
          yupSchema = yup.object().shape({
            address_line1: yup.string().optional().nullable(),
            address_line2: yup.string().optional().nullable(),
            city: yup.string().optional().nullable(),
            state: stateSchema.optional().nullable(),
            postal_code: yup.string().optional().nullable(),
            address_type: yup.string().optional()
          })
        } else {
          yupSchema = yup.object().shape({
            address_line1: yup.string().required('Required'),
            address_line2: yup.string().optional().nullable(),
            city: yup.string().required('Required'),
            state: stateSchema.required('Required'),
            postal_code: yup.string().required('Required'),
            address_type: yup.string().optional()
          })
        }
        break
      }
      case 'number':
        yupSchema = yup.number().nullable()

        if (question.validation?.min_value != undefined) {
          yupSchema = yupSchema.min(
            question.validation.min_value,
            `Must be greater than or equal to ${question.validation.min_value}`
          )
        }

        if (question.validation?.max_value != undefined) {
          yupSchema = yupSchema.max(
            question.validation.max_value,
            `Must be less than or equal to ${question.validation.max_value}`
          )
        } else {
          // Set the max to be SQL Max Integer
          yupSchema = yupSchema.max(SQL_UNSIGNED_FOUR_BYTE_INTEGER_MAX)
        }
        break
      case 'date':
        yupSchema = yup.string().nullable()
        if (!question.optional) {
          yupSchema = yupSchema.required()
        }
        yupSchema = yupSchema.test(
          'valid date',
          'This must be a valid date',
          value => !value || moment(value).isValid()
        )

        question.validation?.date?.forEach(validation => {
          const {
            before,
            exclusive,
            offset_days,
            key,
            date,
            message,
            categories
          } = validation
          const comparison_label = questions.find(q => q.key === key)?.label

          yupSchema = yupSchema.test(
            'isValid',
            'date comparison',
            function (value) {
              const { path, createError, parent } = this

              if (
                registration_types.length > 0 &&
                (categories || []).length > 0 &&
                intersection(categories, registration_types).length === 0
              ) {
                return true
              }

              const questionDate = value ? moment(value as string) : false
              const comparisonDate = key
                ? parent[key]
                  ? moment(parent[key]).add(offset_days || 0, 'days')
                  : false
                : moment(date).add(offset_days || 0, 'days')

              if (
                shouldRaiseDateError(questionDate, comparisonDate, validation)
              ) {
                return createError({
                  path,
                  message: message
                    ? message
                    : `Date must be ${!exclusive && 'on or'} ${
                        before ? 'before' : 'after'
                      } ${
                        offset_days
                          ? `${offset_days} days ${
                              offset_days < 0 ? 'before' : 'after'
                            }`
                          : ''
                      } ${
                        date
                          ? (comparisonDate as moment.Moment).format(
                              'MM/DD/YYYY'
                            )
                          : `"${comparison_label}"`
                      }`
                })
              }

              return true
            }
          )
        })

        break
      case 'date_part': {
        let yearSchema = yup.number().optional().nullable()
        let quarterSchema = yup.string().optional().nullable()
        let monthSchema = yup.string().optional().nullable()
        let daySchema = yup.number().optional().nullable()
        if ((question.components || []).includes('year')) {
          yearSchema = yearSchema
            .required('Required')
            .min(1900, 'Year must be after 1900')
            .max(
              moment().year() + 2,
              `Year must be before ${moment().year() + 2}`
            )
        }
        if ((question.components || []).includes('quarter')) {
          quarterSchema = quarterSchema.required('Required')
        }
        if ((question.components || []).includes('month')) {
          monthSchema = monthSchema.required('Required')
        }
        if ((question.components || []).includes('day')) {
          daySchema = daySchema
            .required('Required')
            .min(1, 'Day must be valid')
            .max(31, 'Day must be valid')
        }

        yupSchema = yup.object().shape({
          year: yearSchema,
          quarter: quarterSchema,
          month: monthSchema,
          day: daySchema
        })

        question.validation?.date?.forEach(validation => {
          const { before, key } = validation
          const comparison_label = questions.find(q => q.key === key)?.label

          yupSchema = yupSchema.test(
            'isValid',
            'date comparison',
            function (value) {
              const { path, createError, parent } = this

              if (!value) {
                return true
              }

              if (
                !value ||
                ((question.components || []).includes('day') &&
                  !(value as DatePartQuestion).day) ||
                ((question.components || []).includes('month') &&
                  !(value as DatePartQuestion).month) ||
                ((question.components || []).includes('quarter') &&
                  !(value as DatePartQuestion).quarter) ||
                ((question.components || []).includes('year') &&
                  !(value as DatePartQuestion).year)
              ) {
                return true
              }

              const questionDate = getDateFromDatePart(
                !!before,
                value as DatePartQuestion
              )
              const comparisonDate =
                key && parent[key] ? moment(parent[key]) : false

              if (
                shouldRaiseDateError(questionDate, comparisonDate, validation)
              ) {
                return createError({
                  path: `${path}.year`,
                  message: `Must be ${
                    before ? 'before' : 'after'
                  } ${`"${comparison_label}"`}`
                })
              }

              return true
            }
          )
        })

        break
      }
      case 'multiselect':
        yupSchema = yup.array().of(yup.string().nullable()).nullable()
        break
      default:
        yupSchema = yup.string().nullable()

        if (question.validation?.max_length != undefined) {
          yupSchema = yupSchema.max(
            question.validation.max_length,
            `Must be less than ${question.validation.max_length} characters`
          )
        }
    }

    if (question.optional) {
      yupSchema = yupSchema.optional().nullable()
    } else {
      yupSchema = yupSchema.required('Required')
    }

    if (question.question_dependency) {
      yupSchema = yup
        .mixed()
        .when(question.question_dependency.key, {
          is: value =>
            question.question_dependency &&
            compareDependencyValue(
              value,
              question.question_dependency.value,
              question.question_dependency.comparator
            ),
          then: yupSchema
        })
        .nullable()
    }

    questionsSchema[question.key] = yupSchema
  })

  return questionsSchema
}

export const statePageValidationSchema = (
  filteredQuestions: Array<Question>,
  state: string,
  registration_types: RegistrationType[]
) => {
  const questionsSchema = generateQuestionSchema(
    state,
    filteredQuestions,
    registration_types
  )

  return yup.object().shape({
    questions: yup.object().shape({
      [state]: yup.object().shape(questionsSchema)
    })
  })
}

const WarningDiv = styled.div`
  color: ${colors.orange};
  font-size: ${typography.sizes.small};
`

const questionWarningComponent = (
  question: Question,
  values: FormValues | ConfirmCompany,
  errors: FormikErrors<FormValues | ConfirmCompany>,
  state: string
): JSX.Element => {
  const warning = question.warning
  let component = <></>

  const name = `questions.${state}.${question.key}`
  const questionValue = get(values, name)

  if (warning?.date) {
    const questionDate = questionValue ? moment(questionValue as string) : false
    warning.date.forEach(dateWarning => {
      const { key, offset_days, date } = dateWarning
      const comparisonQuestion = key
        ? get(values, `questions.${state}.${key}`)
        : false
      const comparisonDate = key
        ? comparisonQuestion
          ? moment(comparisonQuestion).add(offset_days || 0, 'days')
          : false
        : moment(date).add(offset_days || 0, 'days')

      component = (
        <>
          <>{component}</>
          {questionDate &&
            comparisonDate &&
            !get(errors, name) &&
            !shouldRaiseDateError(
              questionDate,
              comparisonDate,
              dateWarning
            ) && <WarningDiv>{dateWarning.message}</WarningDiv>}
        </>
      )
    })
  }

  return component
}

export const mapQuestionsToFormFields = (
  filteredQuestions: Question[],
  state: string,
  values: FormValues | ConfirmCompany,
  errors: FormikErrors<FormValues | ConfirmCompany>,
  parentAccountSlug: string | undefined
) =>
  filteredQuestions.map((question: Question, index: number) => {
    const name = `questions.${state}.${question.key}`
    const questionValue = get(values, name)

    let component = getQuestionComponent(
      question,
      name,
      questionValue,
      index,
      values,
      parentAccountSlug
    )
    if (question.warning) {
      component = (
        <>
          <div>{component}</div>
          <div>{questionWarningComponent(question, values, errors, state)}</div>
        </>
      )
    }

    const override_dependency =
      question.show_for_acquisition &&
      get(values, 'registration_reason') === ACQUIRED_REGISTERED

    if (question.question_dependency && !override_dependency) {
      return (
        compareDependencyValue(
          get(values, `questions.${state}.${question.question_dependency.key}`),
          question.question_dependency.value,
          question.question_dependency.comparator
        ) && <div key={question.key}>{component}</div>
      )
    }
    return <div key={question.key}>{component}</div>
  })

const DynamicStatePage: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  progress,
  filteredStateQuestions,
  managedRegistrationTypesWithFQ,
  showPoweredByMiddesk
}: PageProps) => {
  const { account } = useContext(AuthContext)
  const { questions, state, fetchingQuestions } = useContext(ApplicationContext)
  const { values, setFieldValue, errors } = useFormikContext<FormValues>()
  const [schema, setSchema] = useState<any>(yup.object())
  const parentAccountSlug = account.parent_account?.slug

  const { search } = useLocation()
  useEffect(() => {
    const { prefill } = qs.parse(search, { ignoreQueryPrefix: true })
    if (prefill === 'true') {
      setFieldValue('questions', {
        ...values.questions,
        ...{ [state]: getStateQuestionPrefills(questions) }
      })
    }
  }, [search, questions])

  if (!(state in values.questions)) {
    values.questions[state] = {}

    questions.forEach((question: Question) => {
      // Ensure optional questions are added so they'll be sent on submission.
      if (question.optional) {
        values.questions[state][question.key] = null
      }
    })
  }

  useEffect(() => {
    const validationSchema = statePageValidationSchema(
      filteredStateQuestions,
      state,
      managedRegistrationTypesWithFQ
    )
    updateValidationSchema && updateValidationSchema(validationSchema)

    setSchema(validationSchema)
  }, [fetchingQuestions, managedRegistrationTypesWithFQ])

  return (
    <>
      <GapWrapper>
        {mapQuestionsToFormFields(
          filteredStateQuestions,
          state,
          values,
          errors,
          parentAccountSlug
        )}
      </GapWrapper>
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: !schema.isValidSync(values),
          onClick: () => {
            onNext(values)
          },
          progress,
          title: 'State information',
          showPoweredByMiddesk
        }}
      />
    </>
  )
}

DynamicStatePage.pageName = 'DynamicStatePage'
DynamicStatePage.title = ({ saved_tax_registrations }) => {
  const abbr = saved_tax_registrations && saved_tax_registrations[0]?.state
  if (!abbr) return ''

  const stateName = STATE_MAP[abbr]?.name
  return `${stateName} state information`
}
DynamicStatePage.description = ({ saved_tax_registrations }) => {
  const abbr = saved_tax_registrations && saved_tax_registrations[0]?.state
  if (!abbr) return ''

  const stateName = STATE_MAP[abbr]?.name
  return `Provide information about your current or expected ${stateName} operations below.`
}

export { DynamicStatePage }
