import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const QuestionWrapper = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const TabbedStep = styled.div`
  padding-left: 15px;
`

const DepartmentOfRevenue = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link
          href={'https://www.mndor.state.mn.us/tp/eservices/_/'}
          target='_blank'
        >
          https://www.mndor.state.mn.us/tp/eservices/_/
        </Link>
      </Body>
      <Body>2. Log in using your credentials</Body>
      <Body>
        3. Select <b>I Want To...</b> on the toolbar near the top
      </Body>
      <Body>
        4. Find the <b>Customer Information</b> section and click{' '}
        <b>Manage names and addresses</b>
      </Body>
      <Body>
        5. Select <b>Addresses</b> on the toolbar near the top
      </Body>
      <Body>
        6. Go to the <b>Withholding Tax</b> section and select the address
        listed next to <b>Mailing</b>
        <TabbedStep>
          a. If there&apos;s no address listed there, click on <b>Add</b>
        </TabbedStep>
      </Body>
      <Body>
        7. If you see a screen showing your current mailing address, select{' '}
        <b>Change this address</b>
        <TabbedStep>a. If not, skip to the next step</TabbedStep>
      </Body>
      <Body>
        8. Input the address as <b>56 Broad St STE 14028, Boston, MA 02109</b>
      </Body>
      <Body>
        9. Select <b>Click here to verify Address</b>
        <TabbedStep>
          a. If you see more than one verified address option, select the one
          that includes the PMB number, otherwise mail will not be received
          correctly.
        </TabbedStep>
      </Body>
      <Body>
        10. Select <b>Next</b>
      </Body>
      <Body>
        11. Select <b>Submit</b>
      </Body>
      <br />
    </div>
  )
})`
  ${Styles}
`

const Minnesota = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Unemployment Insurance Minnesota',
    link: 'https://www1.uimn.org/ui_employer/employer/login.do',
    children: (
      <QuestionWrapper>
        {
          (departments || []).find(
            ({ name }) => name === 'Unemployment Insurance Minnesota'
          )?.children
        }
      </QuestionWrapper>
    )
  },
  {
    name: 'Secretary of State',
    children: (
      <QuestionWrapper>
        {
          (departments || []).find(({ name }) => name === 'Secretary of State')
            ?.children
        }
      </QuestionWrapper>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://www.mndor.state.mn.us/tp/eservices/_/',
    name: 'Department of Revenue',
    children: <DepartmentOfRevenue />,
    renderCompleteLater: true
  }
]

export default Minnesota
