import styled from 'styled-components'
import { COLORS } from './Colors'

export type LineVariant = 'thin' | 'thick'
export type LineProps = {
  variant?: LineVariant
}

const Line = styled.div`
  background: ${COLORS.dawn};
  width: 100%;

  height: ${({ variant }: LineProps) => {
    if (variant === 'thin') {
      return '1px'
    } else {
      return '2px'
    }
  }};
`

Line.defaultProps = {
  variant: 'thin'
}

export default Line
