import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { theme } from '@middesk/components'
import styled, { createGlobalStyle } from 'styled-components'
import { Description, SectionHeader } from './Page'
import Card from './System/Card'
import { COLORS } from './System/Colors'

const { spacing } = theme

const AccessDeniedCard = styled(Card)`
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const AccessDeniedContainer = styled.div`
  padding: ${spacing.normal};
`

const StyledIcon = styled.img`
  height: 100px;
  margin: 0;
  width: 100px;
`

const PageBackground = createGlobalStyle`
  html, body {
    background-color: ${COLORS.frost_l1};
    height: 100%;
  }
`

const GuestAccessDenied = () => {
  return (
    <>
      <PageBackground />
      <AccessDeniedCard variant='secondary'>
        <AccessDeniedContainer>
          <Row center='xs'>
            <Col>
              <StyledIcon src='/icons/cog.svg' />
              <SectionHeader>Access denied</SectionHeader>
              <Description>
                Your link has expired or is invalid. Please reach out to the
                sender to request a new link.
              </Description>
            </Col>
          </Row>
        </AccessDeniedContainer>
      </AccessDeniedCard>
    </>
  )
}

export default GuestAccessDenied
