import styled from 'styled-components'
import { COLORS } from '../../System/Colors'
import SPACING from '../../System/Spacing'
import { TYPOGRAPHY_SIZES } from '../../System/Typography'

export const StyledSection = styled.div`
  background: ${COLORS.frost_l1};
  border-radius: 4px;
  display: flex;
  font-size: ${TYPOGRAPHY_SIZES.large}px;
  justify-content: space-between;
  margin: ${SPACING.medium} 0;
  padding: ${SPACING.xsmall} ${SPACING.medium};
`

export const StyledColumn = styled(StyledSection)`
  flex-direction: column;
  gap: ${SPACING.medium};
`
