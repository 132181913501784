import React from 'react'
import styled from 'styled-components'
import { SigninWithGoogleProps } from '../../types'
import { Loader } from '@middesk/components'
import Button from '../System/Button'
import { useGoogleLogin } from '@react-oauth/google'
import SPACING from '../System/Spacing'
import BORDER_RADIUS from '../System/BorderRadius'
import { COLORS } from '../System/Colors'
import { TYPOGRAPHY_SIZES } from '../System/Typography'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

const RELATIVE_GOOGLE_POSITION = 2

type AuthorizeProps = {
  code: string
}

const authorize = (onSuccess: (props: { tokenId: string }) => void) => async ({
  code
}: AuthorizeProps) => {
  const { id_token: tokenId } = await fetch(
    `${process.env.REACT_APP_API_HOST}/auth/google`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code, redirect_uri: window.location.origin })
    }
  ).then(res => res.json())

  return onSuccess({ tokenId })
}

const SigninWithGoogle = styled(
  ({
    mode = 'sign_in',
    className,
    isLoading,
    onClick
  }: SigninWithGoogleProps) => {
    const onLogin = useGoogleLogin({
      onSuccess: authorize(onClick),
      flow: 'auth-code'
    })

    return (
      <div className={className}>
        <div>
          <span />
          <div>Or {lowerCase(startCase(mode))} with</div>
          <span />
        </div>
        <Button onClick={() => onLogin()} type='secondary'>
          {isLoading ? (
            <Loader size='small' />
          ) : (
            <>
              <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M17.6175 9.2025C17.6175 8.61 17.565 8.0475 17.475 7.5H9V10.8825H13.8525C13.635 11.9925 12.9975 12.93 12.0525 13.5675V15.8175H14.9475C16.6425 14.25 17.6175 11.94 17.6175 9.2025Z'
                  fill='#4285F4'
                />
                <path
                  d='M9 18C11.43 18 13.4625 17.19 14.9475 15.8175L12.0525 13.5675C11.2425 14.1075 10.215 14.4375 9 14.4375C6.6525 14.4375 4.665 12.855 3.9525 10.7175H0.967499V13.035C2.445 15.975 5.4825 18 9 18Z'
                  fill='#34A853'
                />
                <path
                  d='M3.9525 10.7175C3.765 10.1775 3.6675 9.59999 3.6675 8.99999C3.6675 8.39999 3.7725 7.8225 3.9525 7.2825V4.965H0.967499C0.352499 6.18 0 7.54499 0 8.99999C0 10.455 0.352499 11.82 0.967499 13.035L3.9525 10.7175Z'
                  fill='#FBBC05'
                />
                <path
                  d='M9 3.5625C10.3275 3.5625 11.5125 4.02 12.45 4.9125L15.015 2.3475C13.4625 0.892501 11.43 0 9 0C5.4825 0 2.445 2.025 0.967499 4.965L3.9525 7.2825C4.665 5.145 6.6525 3.5625 9 3.5625Z'
                  fill='#EA4335'
                />
              </svg>
              <div>Google</div>
            </>
          )}
        </Button>
      </div>
    )
  }
)`
  > div:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: ${SPACING.medium} 0;

    > div {
      color: ${COLORS.karl};
      font-size: ${TYPOGRAPHY_SIZES.medium}px;
      padding: 0 ${SPACING.medium};
    }

    > span {
      border: 1px solid ${COLORS.frost};
      flex-grow: 1;
    }
  }

  > button {
    background: white;
    border: 1px solid ${COLORS.frost};
    border-radius: ${BORDER_RADIUS.xsmall};
    color: ${COLORS.karl};
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: ${SPACING.small};
    justify-content: center;
    margin-top: ${SPACING.medium};
    width: 100%;

    svg {
      position: relative;
      bottom: ${RELATIVE_GOOGLE_POSITION}px;
    }
  }
`

export default SigninWithGoogle
