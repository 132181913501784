import { CheckboxField } from '@middesk/components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import amplitude from 'amplitude-js'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { FC, useContext, useEffect, useState, ReactNode } from 'react'
import styled from 'styled-components'
import CardCapture from './CardCapture'
import LineItem from './LineItem'
import { COLORS } from './System/Colors'
import SPACING from './System/Spacing'
import { TYPOGRAPHY_SIZES } from './System/Typography'
import { AuthContext } from '../contexts/AuthProvider'
import api from '../lib/api'
import { PackageVariations } from '../lib/constants'
import { FormValues, PackageType, TransfersIntakeFormValues } from '../types'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

const ErrorMessage = styled.div`
  color: ${COLORS.magenta};
`

const Content = styled.div`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

const Footer = styled.div`
  margin: ${SPACING.small} 0px;
`

export const trackUser = (newUser: boolean, type: string) => {
  amplitude.getInstance().logEvent('application-completed', {
    new_user: newUser,
    type: type
  })
  window.gtag('config', process.env.REACT_APP_GOOGLE_ADWORDS_KEY)
  window.gtag('event', 'conversion', {
    send_to: `${process.env.REACT_APP_GOOGLE_ADWORDS_KEY}/tNanCJuCh-8CEKPAlaIC`
  })
}

interface PaymentCaptureProps {
  setPaymentReady: any
  submitting: boolean
  setSubmitting: any
  applicationUpdate: () => void
  error: string
  setError: any
  setupSecret: string
  lineItems?: ReactNode | null
  label?: string | null
}

const PaymentCapture: FC<PaymentCaptureProps> = ({
  setPaymentReady,
  submitting,
  setSubmitting,
  applicationUpdate,
  error,
  setError,
  setupSecret,
  lineItems,
  label
}: PaymentCaptureProps) => {
  const { values, validateForm } = useFormikContext<
    FormValues | TransfersIntakeFormValues
  >()
  const [percentOff, setPercentOff] = useState(0.0)

  const { user, account } = useContext(AuthContext)
  const selectedPackage =
    get(user, 'account.settings.agent.package_type') || 'basic'
  const billingProfile = get(account, 'settings.agent.billing_profile')

  useEffect(() => {
    validateForm()
    api
      .get('/ajax/accounts/coupon')
      .then(json => setPercentOff(json.percent_off))
  }, [values])

  const recurringLineItem = () => {
    return (
      <LineItem name='Total'>
        <b>
          {billingProfile?.label ? (
            billingProfile.label
          ) : (
            <>
              $
              {PackageVariations.state_filing[selectedPackage as PackageType]
                .price *
                (1.0 - percentOff / 100.0)}
              &nbsp; / {selectedPackage === 'unlimited' ? 'year' : 'month'}
            </>
          )}
        </b>
      </LineItem>
    )
  }

  return (
    <>
      <Elements stripe={stripePromise}>
        <CardCapture
          submitting={submitting}
          setSubmitting={setSubmitting}
          setPaymentReady={setPaymentReady}
          setError={setError}
          setupSecret={setupSecret}
          applicationUpdate={applicationUpdate}
        />
      </Elements>
      <Content>
        {lineItems || recurringLineItem()}
        <Footer>
          <CheckboxField
            name='accept_tos'
            label={label || 'I authorize Middesk to charge my credit card'}
          />
          <ErrorMessage>{error}</ErrorMessage>
        </Footer>
      </Content>
    </>
  )
}

export default PaymentCapture
