import React, { ReactElement } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { COLORS } from './Colors'
import Info from './Icons/Info'

const InfoTooltip = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, text }): ReactElement => {
    return (
      <>
        <p data-tip={text}>
          <Info />
        </p>
        <ReactTooltip
          textColor={COLORS.white}
          backgroundColor={COLORS.graphite}
          effect='solid'
        />
      </>
    )
  }
)``

export default InfoTooltip
