import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { List, MetaTag } from '@middesk/components'
import { ContainerProps } from '../../types'
import Accounts from './Accounts'
import { stringify } from 'qs'
import { getStatusTagColor } from '../../components/StatusTag'
import { getTaxAgencyRegistrationStatusLabel } from '../../lib/helpers'
import {
  SECRETARY_OF_STATE_TYPE,
  STATE_MAP,
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE,
  TAX_AGENCY_REGISTRATION_STATUSES
} from '../../lib/constants'
import {
  APICompanyAccountsOverview,
  CompanyAccountsOverview
} from '../../lib/api/companyAccountsOverview'
import Filter from '../../components/System/Filter'
import { SkeletonArray, StyledListControls } from '../../components/Shared'
import LocalOverview from './LocalOverview'

const WITHHOLDING_LABEL = 'State Withholding Tax'
const UNEMPLOYMENT_LABEL = 'State Unemployment Insurance'
const FOREIGN_QUALIFICATION_LABEL = 'Foreign Qualification'

export const getTaxTypeLabel = (tax_type: string) => {
  switch (tax_type) {
    case STATE_TAX_WITHHOLDINGS_TYPE:
      return WITHHOLDING_LABEL
    case STATE_UNEMPLOYMENT_INSURANCE_TYPE:
      return UNEMPLOYMENT_LABEL
    case SECRETARY_OF_STATE_TYPE:
      return FOREIGN_QUALIFICATION_LABEL
  }
}

const Overview = styled(
  ({ className }: ContainerProps): JSX.Element => {
    const [
      fetchingStateStatusDetails,
      setFetchingStateStatusDetails
    ] = useState(true)
    const [stateStatusDetails, setStateStatusDetails] = useState<
      Array<CompanyAccountsOverview>
    >([])
    const [status, setStatus] = useState<string>()

    const { companyId } = useParams<{ companyId: string }>()

    const statusOptions = TAX_AGENCY_REGISTRATION_STATUSES.map(status => {
      return {
        label: getTaxAgencyRegistrationStatusLabel(status),
        value: status
      }
    })

    useEffect(() => {
      APICompanyAccountsOverview.index(companyId).then(({ data }) => {
        setStateStatusDetails(data)
        setFetchingStateStatusDetails(false)
      })
    }, [])

    if (
      stateStatusDetails.some(stateDetails => stateDetails.has_locals_in_state)
    ) {
      return (
        <LocalOverview
          companyId={companyId}
          fetchingStateStatusDetails={fetchingStateStatusDetails}
          stateStatusDetails={stateStatusDetails}
        />
      )
    }

    const renderStatusColumn = (
      statusByType: {
        tax_type: string
        status?: string
      }[],
      type: string
    ) => {
      const status = statusByType.find(({ tax_type }) => tax_type === type)
        ?.status

      return (
        status && (
          <MetaTag type={getStatusTagColor(status)}>
            {getTaxAgencyRegistrationStatusLabel(status)}
          </MetaTag>
        )
      )
    }

    const filteredStateStatusDetails = (
      details: CompanyAccountsOverview[],
      filterStatus?: string
    ) => {
      if (!filterStatus) return details
      return details.filter(({ status_by_type }) =>
        status_by_type.map(({ status }) => status).includes(filterStatus)
      )
    }

    const columns = [
      {
        key: 'state',
        title: 'State',
        sortBy: 'state',
        width: 1,
        render: ({ state }: CompanyAccountsOverview) => (
          <b>{STATE_MAP[state].name}</b>
        )
      },
      {
        key: 'state_unemployment_insurance',
        title: 'State Unemployment Insurance',
        width: 2,
        render: ({ status_by_type }: CompanyAccountsOverview) =>
          renderStatusColumn(status_by_type, 'state_unemployment_insurance')
      },
      {
        key: 'state_tax_withholdings',
        title: 'State Withholding Tax',
        width: 2,
        render: ({ status_by_type }: CompanyAccountsOverview) =>
          renderStatusColumn(status_by_type, 'state_tax_withholdings')
      },
      {
        key: 'secretary_of_state',
        title: 'Foreign Qualification',
        width: 2,
        render: ({ status_by_type }: CompanyAccountsOverview) =>
          renderStatusColumn(status_by_type, 'secretary_of_state')
      }
    ]

    return (
      <>
        <Accounts {...{ className }} companyId={companyId}>
          <List
            {...{
              columns,
              data: filteredStateStatusDetails(stateStatusDetails, status),
              to: ({ state }: CompanyAccountsOverview) =>
                `/companies/${companyId}/accounts/details?${stringify({
                  state: state
                })}`
            }}
          >
            <StyledListControls>
              <Filter
                placeholder='Status'
                onFilter={(value: string) => {
                  setStatus(value)
                }}
                currentValue={status || null}
                options={statusOptions}
              />
            </StyledListControls>
          </List>
          {fetchingStateStatusDetails && <SkeletonArray />}
        </Accounts>
      </>
    )
  }
)`
  > div > div > div > div {
    overflow: visible !important;
  }
`

export default Overview
