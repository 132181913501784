import { theme } from '@middesk/components'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { COLORS } from './System/Colors'
import Pencil from './System/Icons/Pencil'
import Trash from './System/Icons/Trash'

const { spacing, colors, typography } = theme

const EntityList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &:empty {
    margin-bottom: 60px;
  }
`

const ListItem = styled.li<{ error?: boolean }>`
  align-items: center;
  border: 1px solid ${props => (props.error ? colors.red : colors.frost)};
  border-radius: 10px;
  color: ${COLORS.graphite};
  display: flex;
  margin: 10px 0;
  padding: ${spacing.normal} 30px;
  position: relative;

  > div:first-child {
    flex: 1;

    > div:first-child {
      font-weight: ${typography.weights.bold};
      margin-bottom: ${spacing.normal};

      > span:last-child {
        color: ${colors.graphite};
        font-weight: ${typography.weights.normal};
      }
    }

    > div:nth-child(3) {
      font-size: ${typography.sizes.medium}px;

      > span:first-child {
        display: inline-flex;
        align-items: center;
        margin: 10px 0;

        > svg {
          margin-right: 10px;
        }
      }
    }
  }

  > span:last-child {
    color: ${colors.frost};
    cursor: pointer;
    font-size: ${typography.sizes.large}px;
  }
`

const EntityRemove = styled.div`
  cursor: pointer;
  font-size: ${typography.sizes.medium}px;
  position: absolute;
  right: 30px;
  top: ${spacing.normal};
`
const EntityEdit = styled.div`
  cursor: pointer;
  font-size: ${typography.sizes.medium}px;
  position: absolute;
  right: 60px;
  top: ${spacing.normal};
`

interface EntityListItemProps {
  children: ReactNode
  error?: boolean
  onRemove: () => void
  onEdit?: () => void
}

export const EntityListItem: FC<EntityListItemProps> = ({
  children,
  error,
  onEdit,
  onRemove
}: EntityListItemProps) => (
  <ListItem error={error}>
    <div>{children}</div>
    {onEdit ? (
      <EntityEdit onClick={() => onEdit()}>
        <Pencil />
      </EntityEdit>
    ) : (
      ''
    )}
    <EntityRemove onClick={() => onRemove()}>
      <Trash />
    </EntityRemove>
  </ListItem>
)

export default EntityList
