import React, { useContext } from 'react'
import Banner from '../components/System/Banner'
import { useHistory } from 'react-router'
import { AuthContext } from '../contexts/AuthProvider'
import styled from 'styled-components'
import API from '../lib/api'
import { startCase } from 'lodash'
import { Icon, theme } from '@middesk/components'

const { colors, typography } = theme

const OldStyledBanner = styled(Banner)`
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 800px;
`

const StyledBanner = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  padding: 10px 40px;
  background-color: ${colors.greenAccent};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${typography.sizes.medium};
`

const ImpersonationBanner = () => {
  const {
    authorizedAccount,
    account,
    user,
    impersonator,
    setFetchingUser
  } = useContext(AuthContext)
  const { push } = useHistory()

  const deprecatedImpersonatingAccount =
    authorizedAccount && account.id !== authorizedAccount.id

  if (deprecatedImpersonatingAccount) {
    return (
      <OldStyledBanner
        variant='warning'
        message={`Currently logged in as ${account?.name}`}
        label='End Session'
        onClick={() => {
          API.logout()
          push('/signout')
        }}
      />
    )
  }

  if (impersonator && user) {
    return (
      <StyledBanner>
        <div>
          <b>Impersonating&nbsp;&nbsp;</b>
          {[
            user.name,
            user.email,
            user.roles.map(startCase).join(', '),
            user.account.name
          ].join(' · ')}
        </div>
        <Icon
          name='cross2'
          height={15}
          width={15}
          fill={colors.black}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            API.logout()
            setFetchingUser(true)
          }}
        />
      </StyledBanner>
    )
  }
  return <></>
}

export default ImpersonationBanner
