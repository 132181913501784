import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik'
import { Company, FormValues } from '../types'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Page, PageProps } from '../components/Page'
import StockTable from '../components/StockTable'
import SPACING from '../components/System/Spacing'
import APICompany from '../lib/api/company'
import NewRegistrationIntakeFooter from '../components/NewRegistrationIntakeFooter'
import RedactedDisplay from '../components/Forms/StockTableField/RedactedDisplay'
import getSchema from '../lib/stockTableValidator'
import { useParams } from 'react-router'
import useApplication from '../lib/useApplication'

export const StyledStockTable = styled(StockTable)`
  margin-top: ${SPACING.small};
`

const CompanyStockTable: Page = ({
  onNext,
  onCancel,
  isSubmitting,
  updateValidationSchema,
  error,
  showPoweredByMiddesk,
  progress
}: PageProps) => {
  const { id: applicationId } = useParams<{ id: string }>()
  const { application } = useApplication(applicationId)
  const {
    values,
    setFieldValue,
    initialValues
  } = useFormikContext<FormValues>()

  const [editMode, setEditMode] = useState<boolean>(true)
  const [company, setCompany] = useState<Company | undefined>()

  useEffect(() => {
    updateValidationSchema &&
      updateValidationSchema(getSchema({ validateValue: editMode }))
  }, [])

  useEffect(() => {
    setFieldValue('state', values?.tax_registrations[0]?.state)
  }, [values])

  useEffect(() => {
    if (application) {
      APICompany.show(application.company_id).then(setCompany)
    }
  }, [application])

  const isRedacted = initialValues?.stock_table?.redacted
  useEffect(() => {
    if (isRedacted) {
      setEditMode(false)
      updateValidationSchema &&
        updateValidationSchema(getSchema({ validateValue: false }))
    }
  }, [isRedacted])

  const updateCompany = () => {
    const stockTable = values?.stock_table
    if (stockTable && company?.id) {
      APICompany.update({
        set_stock_table: !isRedacted || editMode,
        id: company.id,
        stock_table: {
          value: stockTable?.value
        }
      })
    }
  }

  const stockTable = values?.stock_table
  return (
    <Col>
      <Row>
        {isRedacted && !editMode ? (
          <RedactedDisplay />
        ) : (
          <Col xs>
            <Field name='stock_table' component={StyledStockTable} />
          </Col>
        )}
      </Row>
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled:
            !getSchema({ validateValue: editMode }).isValidSync(stockTable) ||
            isSubmitting,
          onClick: () => {
            updateCompany()
            onNext(values)
          },
          ...{
            ...(isRedacted
              ? {
                  secondaryButton: {
                    text: editMode ? 'Cancel' : 'Clear',
                    onClick: e => {
                      e.preventDefault()

                      setEditMode(!editMode)
                    }
                  }
                }
              : {})
          },
          progress,
          title: 'Company share information',
          showPoweredByMiddesk
        }}
      />
    </Col>
  )
}

CompanyStockTable.pageName = 'CompanyStockTable'
CompanyStockTable.title = () => 'Share table information'
CompanyStockTable.description = () =>
  'Provide authorized and issued share information about your business.'

export default CompanyStockTable
