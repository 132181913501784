import { createGlobalStyle } from 'styled-components'
import { COLORS } from './components/System/Colors'
import { TYPOGRAPHY_SIZES } from './components/System/Typography'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Suisse Intl', sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  p {
    color: ${COLORS.karl};
    font-size: ${TYPOGRAPHY_SIZES.medium}px;
  }

  a {
    color: ${COLORS.indigo};
    cursor: pointer;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }
`

export default GlobalStyle
