import React from 'react'
import { ContainerProps } from '../../../types'
import { theme } from '@middesk/components'

const { colors } = theme

const RedInfoCircled = ({ className }: ContainerProps): JSX.Element => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <circle cx='15' cy='15' r='15' fill={colors.redLight} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9999 7.96704C11.1158 7.96704 7.96716 11.1157 7.96716 14.9997C7.96716 18.8837 11.1158 22.0324 14.9999 22.0324C18.8839 22.0324 22.0325 18.8837 22.0325 14.9997C22.0325 11.1157 18.8839 7.96704 14.9999 7.96704ZM8.91716 14.9997C8.91716 11.6404 11.6405 8.91704 14.9999 8.91704C18.3592 8.91704 21.0825 11.6404 21.0825 14.9997C21.0825 18.3591 18.3592 21.0824 14.9999 21.0824C11.6405 21.0824 8.91716 18.3591 8.91716 14.9997ZM15.7999 11.8C15.7999 12.2418 15.4417 12.6 14.9999 12.6C14.558 12.6 14.1999 12.2418 14.1999 11.8C14.1999 11.3582 14.558 11 14.9999 11C15.4417 11 15.7999 11.3582 15.7999 11.8ZM13.4333 13.4333H13.9333H15C15.2761 13.4333 15.5 13.6572 15.5 13.9333V17.7H16.0667H16.5667V18.7H16.0667H15H13.9333H13.4333V17.7H13.9333H14.5V14.4333H13.9333H13.4333V13.4333Z'
        fill={colors.red}
      />
    </svg>
  )
}

export default RedInfoCircled
