import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'
import SPACING from '../Spacing'

const Updates = styled(({ className }: ContainerProps) => {
  return (
    <div className={className}>
      <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='15' cy='15' r='15' fill='#B5C2F7' />
        <path
          d='M12.2 8.59998H17.5483C17.8845 8.59998 18.2052 8.74097 18.4325 8.98863L21.8842 12.7501C22.0873 12.9714 22.2 13.261 22.2 13.5614V21.8C22.2 22.4627 21.6627 23 21 23H12.2C11.5373 23 11 22.4627 11 21.8V9.79998C11 9.13723 11.5373 8.59998 12.2 8.59998Z'
          fill='white'
        />
        <path
          d='M12.2 15.8C11.9791 15.8 11.8 15.6209 11.8 15.4C11.8 15.1791 11.9791 15 12.2 15H17.8C18.0209 15 18.2 15.1791 18.2 15.4C18.2 15.6209 18.0209 15.8 17.8 15.8H12.2Z'
          fill='#333333'
        />
        <path
          d='M12.2 18.2C11.9791 18.2 11.8 18.0209 11.8 17.8C11.8 17.5791 11.9791 17.4 12.2 17.4H17.8C18.0209 17.4 18.2 17.5791 18.2 17.8C18.2 18.0209 18.0209 18.2 17.8 18.2H12.2Z'
          fill='#333333'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.9482 7.79999H10.5999C9.93716 7.79999 9.3999 8.33724 9.3999 8.99999V21C9.3999 21.6627 9.93716 22.2 10.5999 22.2H19.3999C20.0626 22.2 20.5999 21.6627 20.5999 21V12.7614C20.5999 12.461 20.4872 12.1715 20.2841 11.9501L16.8324 8.18864C16.6051 7.94098 16.2844 7.79999 15.9482 7.79999ZM10.1999 8.99999C10.1999 8.77908 10.379 8.59999 10.5999 8.59999H15.9482C16.0603 8.59999 16.1672 8.64699 16.2429 8.72954L19.6946 12.491C19.7623 12.5648 19.7999 12.6613 19.7999 12.7614V21C19.7999 21.2209 19.6208 21.4 19.3999 21.4H10.5999C10.379 21.4 10.1999 21.2209 10.1999 21V8.99999Z'
          fill='#333333'
        />
        <path
          d='M15.8 12.6H20.2C20.4209 12.6 20.6 12.7791 20.6 13C20.6 13.2209 20.4209 13.4 20.2 13.4H15.4C15.1791 13.4 15 13.2209 15 13V8.19999C15 7.97908 15.1791 7.79999 15.4 7.79999C15.6209 7.79999 15.8 7.97908 15.8 8.19999V12.6Z'
          fill='#333333'
        />
      </svg>
    </div>
  )
})`
  align-items: center;
  display: flex;

  > svg {
    height: 30px;
    margin-right: ${SPACING.small};
    width: 30px;
  }
`

export default Updates
