import api from '../api'

export type StateStatus = {
  state: string
  status: string
}

export type GetCompanyStateStatusesResponse = {
  data: Array<StateStatus>
}

export const APICompanyStateStatuses = {
  show: (companyId: string): Promise<GetCompanyStateStatusesResponse> =>
    api.get(`/v1/agent/companies/${companyId}/state_statuses`)
}
