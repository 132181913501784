import Cookies from 'js-cookie'

const PARTNER_REFERRAL_CODE_COOKIE_KEY = 'partner_referral_code'
const PARTNER_REFERRAL_CODE_COOKIE_EXPIRATION_DAYS = 30

export const setPartnerReferralCookieCode = (referralCode: string): void => {
  Cookies.set(PARTNER_REFERRAL_CODE_COOKIE_KEY, referralCode, {
    expires: PARTNER_REFERRAL_CODE_COOKIE_EXPIRATION_DAYS
  })
}

export const getPartnerReferralCookieCode = (): string | undefined => {
  const referralCode = Cookies.get(PARTNER_REFERRAL_CODE_COOKIE_KEY)

  if (referralCode && referralCode.length > 0) {
    return referralCode
  }
}
