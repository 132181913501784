import Cookies from 'js-cookie'
import React, { FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider'

interface Props {
  component: any
  path: string
}

const OpenRoute: FC<Props> = ({ component, path }: Props) => {
  const { authenticated } = useContext(AuthContext)

  const logoutCurrentUser = Cookies.get('current_user')

  if (authenticated && logoutCurrentUser) {
    return (
      <Route path={path}>
        <Redirect to='/' />
      </Route>
    )
  }

  return <Route path={path} component={component} />
}

export default OpenRoute
