import React from 'react'
import { ContainerProps } from '../../../types'

const Trash = ({ className }: ContainerProps): JSX.Element => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.86673 1.6001C5.59059 1.6001 5.36673 1.82396 5.36673 2.1001C5.36673 2.37624 5.59059 2.6001 5.86673 2.6001H10.1334C10.4095 2.6001 10.6334 2.37624 10.6334 2.1001C10.6334 1.82396 10.4095 1.6001 10.1334 1.6001H5.86673ZM5.26673 4.73343H10.7334V13.3668H5.26673V4.73343ZM10.7334 3.7001C10.8222 3.7001 10.9084 3.71168 10.9904 3.73343H12.2667C12.5429 3.73343 12.7667 3.95729 12.7667 4.23343C12.7667 4.50957 12.5429 4.73343 12.2667 4.73343H11.7334V13.3668C11.7334 13.919 11.2857 14.3668 10.7334 14.3668H5.26673C4.71445 14.3668 4.26673 13.919 4.26673 13.3668V4.73343H3.7334C3.45726 4.73343 3.2334 4.50957 3.2334 4.23343C3.2334 3.95729 3.45726 3.73343 3.7334 3.73343H5.00973C5.09174 3.71168 5.17788 3.7001 5.26673 3.7001H10.7334Z'
        fill='#5F6874'
      />
    </svg>
  )
}

export default Trash
