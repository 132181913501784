import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import Body from '../components/Body'
import Sidebar from '../components/Sidebar'
import Card from '../components/System/Card'
import Page from '../components/System/Layout/Page'
import Link from '../components/System/Link'
import SPACING from '../components/System/Spacing'
import API from '../lib/api'
import { ContainerProps, SignupToConfirmEmailState } from '../types'

const ConfirmEmail = styled(({ className }: ContainerProps) => {
  const { state }: { state: SignupToConfirmEmailState } = useLocation()

  if (!state) {
    return null
  }

  const [emailResent, setEmailResent] = useState(false)

  const resendEmail = () => {
    API.post('/confirm-email', {
      email: state.email,
      agent: true
    })
      .then(() => {
        setEmailResent(true)
      })
      /* eslint-disable-next-line no-console */
      .catch(console.error)
  }

  return (
    <Page {...{ className }}>
      <Sidebar />
      <Body
        title='Confirming account'
        description={`An email verification link was sent to ${state.email}`}
      >
        <Card size='large'>
          <Row center='xs'>
            <Col xs>
              <p>
                {emailResent ? (
                  'Email was resent'
                ) : (
                  <>
                    <Link onClick={resendEmail}>
                      Click here to resend email
                    </Link>
                  </>
                )}
              </p>
            </Col>
          </Row>
        </Card>
      </Body>
    </Page>
  )
})`
  ${Card} {
    display: flex;
    justify-content: center;
    padding: ${SPACING.medium};
  }
`

export default ConfirmEmail
