import React, { useContext, useState, useEffect } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import LandingCard from '../../components/LandingCard'
import LinkFooter from '../../components/LinkFooter'
import Link from '../../components/System/Link'
import LandingGlobalStyle from '../../LandingGlobalStyle'
import { AuthContext } from '../../contexts/AuthProvider'
import { ErrorMessage } from '../../components/ErrorPage'
import styled from 'styled-components'
import { theme } from '@middesk/components'
import PasswordlessCard from './PasswordlessCard'
import PasswordCard from './PasswordCard'
import Brand from '../../components/Brand'
import { COLORS } from '../../components/System/Colors'
import { getPartnerReferralCookieCode } from '../../lib/partnerCookie'

const { typography, spacing, colors } = theme

const StyledErrorMessage = styled(ErrorMessage)`
  font-size: ${typography.sizes.medium};
  border-radius: ${spacing.compact};
  background-color: ${colors.redLight};
  border: solid 1px ${colors.red};
  text-align: left;
  line-height: 1.25rem;
  margin-top: 25px;

  a {
    color: ${colors.blue};
  }
`

const DisabledAccountMessage = ({
  partnerAccount
}: {
  partnerAccount: boolean
}): JSX.Element => (
  <>
    Your account doesn&apos;t have access to the Agent Employer Dashboard.{' '}
    {partnerAccount && (
      <>
        Did you mean to sign into the{' '}
        <a href='https://app.middesk.com/agent'>Partner Dashboard</a>?
      </>
    )}
  </>
)

const Login = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [passwordlessFlow, setPasswordlessFlow] = useState<boolean>(true)
  const { accountDisabled, partnerAccount } = useContext(AuthContext)
  const [showSignup, setShowSignup] = useState<boolean>(true)

  useEffect(() => {
    const referralCode = getPartnerReferralCookieCode()

    if (referralCode) {
      setShowSignup(false)
    }
  }, [])

  return (
    <>
      <LandingGlobalStyle />
      <LandingCard>
        <Row center='xs'>
          <Col>
            <Brand color={COLORS.black} height='45px' />
          </Col>
        </Row>
        <Row>
          <div
            style={{
              margin: '20px auto',
              fontWeight: typography.weights.bold,
              color: colors.graphite
            }}
          >
            Sign in to your account
          </div>
        </Row>
        {passwordlessFlow ? (
          <PasswordlessCard setErrorMessage={setErrorMessage} />
        ) : (
          <PasswordCard setErrorMessage={setErrorMessage} />
        )}
        {accountDisabled && (
          <Row>
            <Col xs>
              <StyledErrorMessage>
                <DisabledAccountMessage partnerAccount={partnerAccount} />
              </StyledErrorMessage>
            </Col>
          </Row>
        )}
        {errorMessage && (
          <Row>
            <Col xs>
              <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
            </Col>
          </Row>
        )}
      </LandingCard>
      <LinkFooter>
        {passwordlessFlow ? (
          <Link onClick={() => setPasswordlessFlow(false)}>
            Sign in with password
          </Link>
        ) : (
          <Link to='/forgot-password'>Forgot password</Link>
        )}
        {showSignup && <Link href='/signup'>Sign up</Link>}
      </LinkFooter>
    </>
  )
}

export default Login
