import styled from 'styled-components'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES } from './System/Typography'

const CheckboxLabel = styled.div`
  color: ${COLORS.graphite};
  cursor: pointer;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  line-height: 1.3;
  margin-left: 2px;

  > p {
    display: inline;
  }
`

export default CheckboxLabel
