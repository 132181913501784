import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { parse } from 'qs'
import { Banner, List, ListPagination, theme } from '@middesk/components'
import { ContainerProps, Action } from '../types'
import {
  DEFAULT_PER_PAGE_COUNT,
  DEFAULT_PAGE_NUM,
  CREATED_AT,
  STATES
} from '../lib/constants'
import Page from '../components/System/Layout/Page'
import Sidebar from '../components/Sidebar'
import {
  TYPOGRAPHY_SIZES,
  TYPOGRAPHY_WEIGHTS
} from '../components/System/Typography'
import APIAgentActions from '../lib/api/agentActions'
import queryStringSerializer from '../lib/queryStringSerializer'
import {
  getActionIcon,
  getActionUrl,
  getActionSubheaderColor
} from '../lib/helpers'
import { CompanyContext } from '../components/CompanyProvider'
import { SkeletonArray, StyledListControls } from '../components/Shared'
import Skeleton from 'react-loading-skeleton'
import RedInfoCircled from '../components/System/Icons/RedInfoCircled'
import RedCircledPencil from '../components/System/Icons/RedCircledPencil'
import Filter from '../components/System/Filter'

const { spacing, colors } = theme

const ActionContainer = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.medium};
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  color: ${colors.graphite};
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const Subheader = styled.div`
  color: ${props => (props.color == 'red' ? colors.red : colors.karl)};
  margin-top: 3px;
  font-size: ${TYPOGRAPHY_SIZES.small}px;
`

const checkEmailNotification = (contact_email: string): ReactElement => {
  return (
    <Banner intent='failure'>
      Please check your email<b>{contact_email}</b>for instructions on what
      actions are needed.
    </Banner>
  )
}

export const actionIconAndHeaderRow = (action: Action) => {
  const { header, subheader } = action
  const icon =
    getActionIcon(action) === 'clipboard' ? (
      <RedCircledPencil />
    ) : (
      <RedInfoCircled />
    )

  return (
    <ActionContainer>
      <IconWrapper>{icon}</IconWrapper>
      <ContentWrapper>
        <Header>{header}</Header>
        {subheader && (
          <Subheader color={getActionSubheaderColor(action)}>
            {subheader}
          </Subheader>
        )}
      </ContentWrapper>
    </ActionContainer>
  )
}

const Actions = ({ className }: ContainerProps): ReactElement => {
  const { push } = useHistory()
  const location = useLocation()
  const { search } = location
  const params = parse(search, { ignoreQueryPrefix: true })
  const { companyId } = useParams<{ companyId: string }>()
  const { activeCompany } = useContext(CompanyContext)

  const {
    per_page = DEFAULT_PER_PAGE_COUNT,
    page = DEFAULT_PAGE_NUM,
    state = ''
  }: {
    [key: string]: any
  } = params as any

  const [fetching, setFetching] = useState(true)
  const [selectedState, setSelectedState] = useState(state || '')
  const [actions, setActions] = useState<Array<Action>>([])
  const [total, setTotal] = useState(0)
  const [showEmailNotification, setShowEmailNotification] = useState(false)

  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: 7,
      render: (action: Action) => actionIconAndHeaderRow(action)
    },
    {
      key: CREATED_AT,
      width: 2,
      title: 'Date added',
      type: 'datetime'
    }
  ]

  useEffect(() => {
    const getActions = async () => {
      APIAgentActions.index(companyId, {
        state: selectedState,
        sort: CREATED_AT
      }).then(({ actions, states_requiring_action }) => {
        let shouldSeeEmailNotification = false
        if (actions.length === 0) {
          if (
            selectedState &&
            states_requiring_action.includes(selectedState)
          ) {
            shouldSeeEmailNotification = true
          } else if (!selectedState && states_requiring_action.length > 0) {
            shouldSeeEmailNotification = true
          }
        }

        setShowEmailNotification(shouldSeeEmailNotification)
        setActions(actions.slice((page - 1) * per_page, page * per_page))
        setTotal(actions.length)
        setFetching(false)
      })
    }

    getActions()
  }, [selectedState, page, companyId])

  const pushQueryParams = (nextParams: { [key: string]: any }): void => {
    push(
      `/companies/${companyId}/actions?${queryStringSerializer(
        params,
        nextParams
      )}`
    )
  }
  const onPage = (page: number) => pushQueryParams({ page: page })

  const onStateChange = (state: string): void => {
    pushQueryParams({ state: state || null })
    setSelectedState(state || '')
  }

  return (
    <Page {...{ title: 'Actions', className }}>
      <Sidebar />

      {showEmailNotification && (
        <div style={{ marginBottom: spacing.compact }}>
          {checkEmailNotification(activeCompany?.contact_email || '')}
        </div>
      )}
      {!showEmailNotification && (
        <List
          data={actions}
          columns={columns}
          to={(action: Action) => getActionUrl(action, companyId)}
        >
          <StyledListControls>
            <Filter
              placeholder='State'
              onFilter={onStateChange}
              currentValue={selectedState || null}
              options={STATES.map(({ abbr, name }) => ({
                label: name,
                value: abbr
              }))}
            />
            <div className='muted'>
              {fetching ? (
                <Skeleton />
              ) : (
                <>{pluralize('Action', total, true)}</>
              )}
            </div>

            {total > per_page ? (
              <ListPagination
                onPage={onPage}
                page={parseInt(page)}
                perPage={per_page}
                total={total}
              />
            ) : (
              <div />
            )}
          </StyledListControls>
        </List>
      )}
      {fetching && <SkeletonArray />}
    </Page>
  )
}

export default Actions
