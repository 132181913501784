import React from 'react'
import { Link, Tooltip, Icons } from '@middesk/components'
import { useFormikContext } from 'formik'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { DEFAULT_TRANSFER_TR_ACTION } from '../../../lib/constants'
import { RegisteredState, TransfersIntakeFormValues } from '../../../types'
import Separator from '../../Separator'
import { COLORS } from '../../System/Colors'
import SPACING from '../../System/Spacing'
import { TYPOGRAPHY_SIZES } from '../../System/Typography'
import { DepartmentElement } from './AccountInformation'
import { StyledColumn, StyledSection } from './styles'

const { Info } = Icons

type ApplicationInformationFootnoteProps = {
  currentState: RegisteredState
  values: TransfersIntakeFormValues
  stateDepartments: DepartmentElement[]
}

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
`

const FlexCol = styled(Col)`
  display: flex;
`

const StyledLabel = styled.label`
  padding-left: ${SPACING.xsmall};
`

const StyledInput = styled.input`
  margin-top: ${SPACING.xsmall};
  display: inline;
`

const StyledInputContainer = styled(FlexCol)`
  padding: ${SPACING.xsmall} 0;
  display: flex;
  align-items: baseline;
`

const StyledLink = styled(Link)`
  padding-top: ${SPACING.small};
  font-size: ${TYPOGRAPHY_SIZES.large}px;
  font-weight: 700;
`

const StyledTooltipWrapper = styled.div`
  margin-left: ${SPACING.xsmall};
`

const StyledInfo = styled(Info)`
  stroke: ${COLORS.karl};
`

const WITH_SEPARATOR = ['ME', 'WV', 'PA', 'UT']

export const ApplicationInformationFootnote = ({
  values,
  currentState,
  stateDepartments
}: ApplicationInformationFootnoteProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<TransfersIntakeFormValues>()

  const handleTRAction = (action: string) => {
    const registeredStates = values.registered_states.map(state =>
      state.abbr === currentState.abbr
        ? { ...state, taxRegistrationAction: action }
        : state
    )

    setFieldValue('registered_states', registeredStates)
  }

  const hasManyStateDepartments = stateDepartments.length > 1

  return (
    <>
      <StyledSection>
        <StyledColumn>
          <div>
            <b>Not registered?</b> You&apos;re not registered if you don&apos;t
            have an account number in your payroll provider portal.
          </div>
          <StyledRow>
            <StyledInputContainer xs>
              <StyledInput
                name={`payroll_action_${currentState.abbr}`}
                key={`payroll_action_${currentState.abbr}_register`}
                checked={currentState.taxRegistrationAction === 'register'}
                value='register'
                type='radio'
                onChange={() => handleTRAction('register')}
              />
              {hasManyStateDepartments ? (
                <FlexCol>
                  <StyledLabel>Register for accounts</StyledLabel>
                  <StyledTooltipWrapper>
                    <Tooltip content='If you only need to register for one account, please contact us at agent@middesk.com.'>
                      <StyledInfo />
                    </Tooltip>
                  </StyledTooltipWrapper>
                </FlexCol>
              ) : (
                <StyledLabel>Register for account</StyledLabel>
              )}
            </StyledInputContainer>
            <StyledInputContainer xs>
              <StyledInput
                name={`payroll_action_${currentState.abbr}`}
                key={`payroll_action_${currentState.abbr}_skip`}
                checked={currentState.taxRegistrationAction === 'skip'}
                value='skip'
                type='radio'
                onChange={() => handleTRAction('skip')}
              />
              <StyledLabel>
                {hasManyStateDepartments
                  ? 'Skip account transfers'
                  : 'Skip account transfer'}
              </StyledLabel>
            </StyledInputContainer>
            {currentState.taxRegistrationAction &&
              currentState.taxRegistrationAction !==
                DEFAULT_TRANSFER_TR_ACTION && (
                <FlexCol xs>
                  <StyledLink onClick={() => handleTRAction('transfer')}>
                    Deselect
                  </StyledLink>
                </FlexCol>
              )}
          </StyledRow>
        </StyledColumn>
      </StyledSection>
      {WITH_SEPARATOR.includes(currentState.abbr) && (
        <Separator customMargin={SPACING.small} />
      )}
    </>
  )
}
