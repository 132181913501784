import React from 'react'
import { STATES } from '../lib/constants'
import Dropdown from './System/Dropdown'

type Props = {
  state: string | null
  onChange: ({ state }: { state: string | undefined }) => void
}

export const mapStateAbbreviationToName = (
  abbr: string | null
): string | undefined => {
  return STATES.find(state => state.abbr === abbr)?.name
}

const StateFilter = ({ state, onChange }: Props) => {
  const mapStateNameToAbbreviation = (name: string): string | undefined => {
    return STATES.find(state => state.name === name)?.abbr
  }

  const handleStateChange = (name: string) => {
    onChange({ state: mapStateNameToAbbreviation(name) })
  }

  return (
    <Dropdown
      label={'State'}
      placeholder={'All'}
      options={['All', ...STATES.map(state => state.name)]}
      selectedOption={mapStateAbbreviationToName(state)}
      onSelect={handleStateChange}
    />
  )
}

export default StateFilter
