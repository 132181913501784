import React from 'react'

import PropTypes from 'prop-types'
import Select from 'react-select'

const styles = {
  container: provided => ({
    ...provided,
    minWidth: '200px'
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    borderColor: isFocused ? 'var(--oc-blue-3)' : 'var(--oc-gray-4)',
    boxShadow: isFocused ? '0 0 1px 1px rgba(231, 245, 255, 0.8)' : null,
    cursor: 'pointer',
    fontSize: '0.875rem',
    '&:hover': {
      borderColor: isFocused ? 'var(--oc-blue-3)' : 'var(--oc-gray-5)'
    }
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 6
  }),
  menu: provided => ({
    ...provided,
    fontSize: '0.875rem'
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer'
  }),
  placeholder: provided => ({
    ...provided,
    color: 'var(--oc-gray-6)'
  })
}

const theme = provided => ({
  ...provided,
  borderRadius: 4,
  colors: {
    primary: 'var(--oc-blue-5)',
    primary75: 'var(--oc-blue-3)',
    primary50: 'var(--oc-blue-1)',
    primary25: 'var(--oc-blue-0)',
    danger: 'var(--oc-red-5)',
    dangerLight: 'var(--oc-red-0)',
    neutral0: 'white',
    neutral5: 'var(--oc-gray-0)',
    neutral10: 'var(--oc-gray-4)',
    neutral20: 'var(--oc-gray-4)',
    neutral30: 'var(--oc-gray-4)',
    neutral40: 'var(--oc-gray-4)',
    neutral50: 'var(--oc-gray-5)',
    neutral60: 'var(--oc-gray-6)',
    neutral70: 'var(--oc-gray-7)',
    neutral80: 'var(--oc-gray-8)',
    neutral90: 'var(--oc-gray-9)'
  },
  spacing: {
    ...provided.spacing,
    controlHeight: '34px'
  }
})

const SelectDropdown = ({ value, options, ...props }) => {
  const selected = options ? options.find(({ value: v }) => value === v) : null

  return (
    <Select
      className={'react-select-container'}
      classNamePrefix={'react-select'}
      theme={theme}
      styles={styles}
      {...props}
      value={selected}
      options={options}
    />
  )
}

SelectDropdown.propTypes = {
  options: PropTypes.any,
  value: PropTypes.any
}

export default SelectDropdown
