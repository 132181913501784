import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'
import { COLORS } from '../Colors'
import SPACING from '../Spacing'

const Mail = styled(({ className }: ContainerProps) => {
  return (
    <svg
      width={SPACING.xlarge}
      height={SPACING.large}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M32.4 9.9H10.8C9.30881 9.9 8.09998 11.1088 8.09998 12.6V28.8C8.09998 30.2912 9.30881 31.5 10.8 31.5H32.4C33.8911 31.5 35.1 30.2912 35.1 28.8V12.6C35.1 11.1088 33.8911 9.9 32.4 9.9Z'
        fill={COLORS.white}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.6 7.2H5.4C4.90295 7.2 4.5 7.60295 4.5 8.1V27.9C4.5 28.397 4.90295 28.8 5.4 28.8H30.6C31.097 28.8 31.5 28.397 31.5 27.9V8.1C31.5 7.60295 31.097 7.2 30.6 7.2ZM6.3 27V9H29.7V27H6.3Z'
        fill={COLORS.graphite}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.1836 8.78519L18.5026 19.5853C18.1646 19.8731 17.6673 19.8715 17.3312 19.5815L4.81221 8.78146C4.18061 8.23658 4.56595 7.2 5.40009 7.2H30.6001C31.4371 7.2 31.8208 8.24251 31.1836 8.78519ZM28.1553 9H7.82116L17.9228 17.7146L28.1553 9Z'
        fill={COLORS.graphite}
      />
    </svg>
  )
})``

export default Mail
