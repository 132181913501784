import React, { useContext } from 'react'
import { FOREIGN_QUALIFICATION_TYPE } from '../../lib/constants'
import { useFormikContext } from 'formik'
import { FormValues, TransactionalPricingData } from '../../types'
import { RadioField, TextField, theme } from '@middesk/components'
import { fqLineItems } from '../../lib/helpers'
import { StyledContainer } from './AgenciesDescription'
import { StyledContainer as OptionsContainer } from './RegistrationOptions'
import { mapStateAbbreviationToName } from '../StateFilter'
import { ApplicationContext } from '../../contexts/ApplicationProvider'

const { spacing } = theme

const ForeignQualificationOptions = ({
  selectedJurisdictions,
  pricingData
}: {
  selectedJurisdictions: string[]
  pricingData?: TransactionalPricingData
}) => {
  const {
    setFieldValue,
    values: { foreign_qualifications }
  } = useFormikContext<FormValues>()
  const { state: filingState } = useContext(ApplicationContext)

  const stateStr =
    foreign_qualifications && foreign_qualifications[0]
      ? mapStateAbbreviationToName(foreign_qualifications[0].state) + ' '
      : ''

  const isTransfer =
    foreign_qualifications &&
    foreign_qualifications[0] &&
    foreign_qualifications[0].transfer

  const setTransfer = (transfer: boolean) =>
    setFieldValue('foreign_qualifications[0].transfer', transfer)

  const fqPrice = (transfer: boolean) =>
    pricingData && filingState
      ? fqLineItems(pricingData, filingState, transfer).reduce(
          (sum, item) => sum + item.amount_cents,
          0
        ) / 100
      : 0

  return selectedJurisdictions.includes(FOREIGN_QUALIFICATION_TYPE) ? (
    <OptionsContainer>
      <RadioField
        label={
          <div className='label'>
            Register for foreign qualification{' '}
            {pricingData ? `(est. $${fqPrice(false)})` : ''}
          </div>
        }
        checked={!isTransfer}
        value={'false'}
        name={'foreign_qualifications[0].transfer'}
        onChange={() => setTransfer(false)}
        style={{ marginBottom: spacing.small }}
      />
      <StyledContainer>
        <div className='agency'>
          <h1>{stateStr}Secretary of State</h1>
          <ul className='items'>
            <li>Account creation</li>
            <li>Registered agent services</li>
          </ul>
        </div>
      </StyledContainer>
      <RadioField
        label={
          <div className='label'>
            Transfer existing foreign qualification{' '}
            {pricingData ? `($${fqPrice(true)})` : ''}
          </div>
        }
        checked={isTransfer}
        value={'true'}
        name={'foreign_qualifications[0].transfer'}
        onChange={() => setTransfer(true)}
        style={{ marginBottom: spacing.small }}
      />
      <StyledContainer>
        <div className='agency'>
          <h1>{stateStr}Secretary of State</h1>
          <ul className='items'>
            <li>Account management update</li>
            <li>Registered agent services</li>
          </ul>
        </div>
        {isTransfer && (
          <div style={{ marginTop: spacing.compact }}>
            <TextField
              placeholder='Enter Secretary of State registration id...'
              name='foreign_qualifications[0].sos_registration_id'
              label='Secretary of State registration id'
            />
          </div>
        )}
      </StyledContainer>
    </OptionsContainer>
  ) : (
    <></>
  )
}

export default ForeignQualificationOptions
