import { Dropdown as MiddeskDropdown } from '@middesk/components'
import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from './BorderRadius'
import { COLORS } from './Colors'
import Caret from './Icons/Caret'
import SPACING from './Spacing'
import { Body, TYPOGRAPHY_WEIGHTS } from './Typography'

const DROPDOWN_POSITION = 'bottom-start'
const DROPDOWN_MENU_HEIGHT = '350px'

type DropdownProps = {
  className?: string
  onToggle?: (e: boolean, value: string) => void
  label: string
  options: Array<string>
  selectedOption?: string
  isOpen?: boolean
  placeholder?: string
  onSelect: (e: string) => void
  disabled?: boolean
}

const Dropdown = styled(
  ({
    className,
    onToggle,
    onSelect,
    label,
    options,
    selectedOption,
    isOpen,
    placeholder,
    disabled
  }: DropdownProps) => {
    return (
      <div {...{ className }}>
        <MiddeskDropdown
          {...{
            onToggle: !disabled ? onToggle : null,
            isOpen,
            position: DROPDOWN_POSITION
          }}
        >
          <MiddeskDropdown.Toggle>
            <span>{label}:</span>{' '}
            <Body bold>{selectedOption || placeholder}</Body>
            <Caret direction='bottom' />
          </MiddeskDropdown.Toggle>
          <MiddeskDropdown.Menu>
            {options.map(e => (
              <MiddeskDropdown.Option
                data-selected={e === selectedOption}
                onClick={() => {
                  if (!disabled) {
                    onSelect(e)
                  }
                }}
                key={e}
              >
                {e}
              </MiddeskDropdown.Option>
            ))}
          </MiddeskDropdown.Menu>
        </MiddeskDropdown>
      </div>
    )
  }
)`
  font-family: 'Suisse Intl';

  [data-selected='true'] {
    background: ${COLORS.dawn};
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  }

  ${Caret} {
    display: inline;
    margin-left: ${SPACING.small};

    path {
      stroke: ${COLORS.karl};
    }
  }

  > div:first-child {
    a {
      background-color: ${COLORS.white};
      border: 1px solid ${COLORS.frost};
      border-radius: ${BORDER_RADIUS.large};
      padding: ${SPACING.small} ${SPACING.medium};

      &:hover,
      &:active,
      &:focus {
        border: 1px solid ${COLORS.karl};
      }

      &:focus {
        outline: 3px solid ${COLORS.indigo_l2};
      }

      > span:first-child {
        color: ${COLORS.karl};
      }

      > ${Body} {
        display: inline;
      }

      ${({ disabled }: DropdownProps) => {
        if (disabled) {
          return `
            background-color: ${COLORS.dawn};
            border: none;
            cursor: default;
            pointer-events: none;

            &:hover,
            &:active,
            &:focus {
              border: none;
            }
          `
        }
      }}
    }

    > div:last-child {
      padding: 0;
      max-height: ${DROPDOWN_MENU_HEIGHT};
      overflow-y: scroll;
    }
  }
`

export default Dropdown
