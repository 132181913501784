import React, { useCallback, useState } from 'react'

import { Search } from '@middesk/components'
import debounce from 'lodash/debounce'

const DebouncedField = ({
  value: initialValue = '',
  placeholder,
  updateValue,
  delay = 1000
}: {
  value?: string
  placeholder?: string
  updateValue: (newValue: string) => void
  delay?: number
}) => {
  const [value, setValue] = useState(initialValue)

  const debounceFnc = debounce(newValue => updateValue(newValue), delay)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateValue = useCallback(debounceFnc, [updateValue, delay])

  return (
    <div style={{ width: '100%' }}>
      <Search
        loading={false}
        placeholder={placeholder}
        terms={null}
        keywords={null}
        onClear={null}
        onSearch={() => {
          updateValue(value)
          debounceFnc.cancel()
        }}
        {...{
          value,
          onChange: ({
            currentTarget: { value: newValue }
          }: {
            currentTarget: { value: string }
          }) => {
            setValue(newValue)
            debounceUpdateValue(newValue)
          }
        }}
      />
    </div>
  )
}

export default DebouncedField
