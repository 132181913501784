import {
  APIResource,
  GetQuestionsFilingsParameters,
  QuestionsCollection
} from '../../types'
import api from '../api'

const APIQuestions: Pick<
  APIResource<GetQuestionsFilingsParameters, QuestionsCollection>,
  'get'
> = {
  get: (params): Promise<QuestionsCollection> =>
    api.get('/v1/agent/questions', params)
}

export default APIQuestions
