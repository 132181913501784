import { Tab as MiddeskTab } from '@middesk/components'
import styled from 'styled-components'
import { COLORS } from './Colors'
import SPACING from './Spacing'

const Tab = styled(MiddeskTab)`
  color: ${COLORS.graphite};
  font-family: 'Suisse Intl';
  padding-bottom: ${SPACING.small} !important;
  padding-top: ${SPACING.small} !important;
`

export default Tab
