import { stringify } from 'qs'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'

const queryStringSerializer = (
  params: { [key: string]: any } = {},
  nextParams: { [key: string]: any } = {}
) =>
  `${stringify({
    ...omit(
      params,
      Object.keys(nextParams).filter(e => !nextParams[e])
    ),
    ...omitBy(nextParams, isNil),
    ...(nextParams.page ? {} : { page: 1 })
  })}`

export default queryStringSerializer
