import React, { PropsWithChildren, useState } from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@middesk/components'

const { spacing, typography, colors } = theme

type DropdownBannerProps = {
  valid: boolean
  title: string
  initialExpanded?: boolean
}

const Wrapper = styled.div`
  align-items: center;
  background: ${colors.frostLight};
  border: 1px solid ${colors.frost};
  border-radius: ${spacing.xsmall};
  cursor: pointer;
  display: flex;
  padding: ${spacing.large};
  justify-content: space-between;
`

const IconAndTitle = styled.div`
  align-items: center;
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
  display: flex;
  justify-content: center;
  gap: ${spacing.xsmall};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
const ChildrenWrapper = styled.div`
  margin-top: ${spacing.large};
`

const StyledIcon = styled.img``

const DropdownBanner = ({
  valid,
  title,
  initialExpanded = false,
  children
}: PropsWithChildren<DropdownBannerProps>) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  return (
    <div>
      <Wrapper onClick={() => setExpanded(!expanded)}>
        <IconAndTitle>
          <IconWrapper>
            {valid ? (
              <Icon name='success' />
            ) : (
              <StyledIcon src='/icons/circle.svg' />
            )}
          </IconWrapper>
          <div>{title}</div>
        </IconAndTitle>
        <IconWrapper>
          {expanded ? <Icon name='chevronUp' /> : <Icon name='chevronDown' />}
        </IconWrapper>
      </Wrapper>
      {expanded && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </div>
  )
}

export default DropdownBanner
