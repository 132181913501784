import React, { useContext } from 'react'
import api from '../../lib/api'
import styled from 'styled-components'
import { AuthContext } from '../../contexts/AuthProvider'
import { useToasts } from 'react-toast-notifications'
import { Modal, theme } from '@middesk/components'

const termsUrl = 'https://agent.middesk.com/terms'

const StyledP = styled.p`
  padding: ${theme.spacing.xsmall} 0;
`
type Props = {
  show: boolean
  onComplete: () => void
}

const LegalTermsUpgrade = ({ show, onComplete }: Props) => {
  const { addToast } = useToasts()
  const { setFetchingUser } = useContext(AuthContext)

  const CUSTOM_MODAL_STYLES = {
    content: {
      width: '500px'
    }
  }

  const handleSubmit = (e: any) => {
    api
      .post('/v1/agent/accept_terms', {})
      .then(() => {
        addToast('Terms accepted', { appearance: 'success' })
        onComplete()
        setFetchingUser(true)
        e.preventDefault()
      })
      .catch(() => {
        addToast('error', {
          appearance: 'error'
        })
      })
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      close={() => {
        window.open(termsUrl, '_blank')
      }}
      closeLabel='View Terms'
      confirm={e => {
        handleSubmit(e)
      }}
      confirmLabel='Accept Terms'
      isOpen={show}
      title="We've updated our terms"
      style={CUSTOM_MODAL_STYLES}
    >
      <div>
        <StyledP>
          The updated version of our terms is now available for your review:{' '}
          <a href={termsUrl} target='_blank' rel='noreferrer'>
            <strong>Middesk Agent Terms of Use.</strong>
          </a>
        </StyledP>

        <StyledP>
          These updated terms will replace the previous version you agreed to
          and automatically be effective.
        </StyledP>
      </div>
    </Modal>
  )
}

export default LegalTermsUpgrade
