import React from 'react'
import styled from 'styled-components'
import SubmitBusinessDetailsForm from '../components/Forms/SubmitBusinessDetailsForm'
import Card from '../components/System/Card'
import SPACING from '../components/System/Spacing'
import {
  XLarge,
  Body as Medium,
  TYPOGRAPHY_WEIGHTS
} from '../components/System/Typography'
import { SignupHeader } from './SignupNew'

const PAGE_WIDTH = '60%'
const MAX_PAGE_WIDTH = '800px'

const DetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${MAX_PAGE_WIDTH};
  width: ${PAGE_WIDTH};

  ${Medium} {
    margin-top: ${SPACING.xsmall};
    text-align: center;

    > span {
      font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    }
  }

  ${Card} {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin-top: ${SPACING.medium};
    padding: ${SPACING.xlarge};
    width: 100%;

    > form {
      width: 100%;
    }
  }
`

const SubmitBusinessDetails = styled(
  ({ className }): JSX.Element => {
    return (
      <div {...{ className }}>
        <SignupHeader />
        <DetailsContainer>
          <XLarge bold>Tell us about your business</XLarge>
          <div>
            <Medium>
              Please fill out the following fields to see all of the state
              accounts you can transfer to Middesk.
            </Medium>
          </div>
          <Card variant='secondary' size='medium'>
            <SubmitBusinessDetailsForm />
          </Card>
        </DetailsContainer>
      </div>
    )
  }
)``

export default SubmitBusinessDetails
