import React from 'react'

import Section from '../../Section'

const RedactedDisplay = (): JSX.Element => (
  <div>
    <Section>
      <p>
        Your business share table information has already been entered. If
        you&apos;d like to re-enter the information, please select
        &quot;Clear&quot;.
      </p>
    </Section>
  </div>
)

export default RedactedDisplay
