import React, { useContext, useState, useEffect } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import MagicLinkForm from '../../components/Forms/MagicLinkForm'
import SigninWithGoogle from '../../components/Signin/SigninWithGoogle'
import { AuthenticationParams } from '../../types'
import API from '../../lib/api'
import { AuthContext } from '../../contexts/AuthProvider'
import { useHistory } from 'react-router'
import { getPartnerReferralCookieCode } from '../../lib/partnerCookie'

const PasswordlessCard = ({
  setErrorMessage
}: {
  setErrorMessage: (message?: string) => void
}): JSX.Element => {
  const [signingInWithGoogle, setSigningInWithGoogle] = useState(false)
  const { setFetchingUser } = useContext(AuthContext)
  const { from } = (location as any).state || { from: { pathname: '/' } }
  const { push } = useHistory()
  const [showGoogleSignup, setShowGoogleSignup] = useState(true)

  useEffect(() => {
    const referralCode = getPartnerReferralCookieCode()

    if (referralCode) {
      setShowGoogleSignup(false)
    }
  }, [])

  const authenticateWithGoogle = async ({
    email,
    name,
    tokenId
  }: AuthenticationParams) => {
    const Authorization = `Bearer ${tokenId}`
    setSigningInWithGoogle(true)

    return API.login({
      Authorization
    })
      .then(() => {
        setFetchingUser(true)

        push(from.pathname)
      })
      .catch(e => {
        if (e.status === 401) {
          push({
            pathname: '/signup',
            state: {
              headers: {
                Authorization
              },
              name,
              email,
              fromSSO: true
            }
          })
        }
      })
  }

  return (
    <>
      <Row>
        <Col xs>
          <MagicLinkForm setErrorMessage={setErrorMessage} />
        </Col>
      </Row>
      {showGoogleSignup && (
        <Row>
          <Col xs>
            <SigninWithGoogle
              onClick={(e: any) => {
                authenticateWithGoogle({
                  ...e.profileObj,
                  ...e
                })
              }}
              isLoading={signingInWithGoogle}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default PasswordlessCard
