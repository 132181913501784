import React, { useContext, useState, useEffect } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import moment from 'moment'
import styled from 'styled-components'
import { Body, Small, LargeDisplay } from '../System/Typography'
import { AuthContext } from '../../contexts/AuthProvider'
import Button from '../System/Button'
import Card from '../System/Card'
import { COLORS } from '../System/Colors'
import Updates from '../System/Icons/Updates'
import SPACING from '../System/Spacing'
import { mapStateAbbreviationToName } from '../StateFilter'
import API from '../../lib/api'
import { AnnualFiling } from '../../types'

interface UpdatesCardProps {
  className?: string
  companyId: string
}

const AnnualFilingSeparator = styled.div`
  background-color: ${COLORS.dawn};
  height: 2px;
  margin: ${SPACING.medium} 0px;
`

const SubtitleBody = styled(Body)`
  margin-top: ${SPACING.large};
  max-width: 90%;
`

const UpdatesCard = styled(
  ({ className, companyId }: UpdatesCardProps): JSX.Element => {
    const [annualFilings, setAnnualFilings] = useState<Array<AnnualFiling>>([])

    const { push } = useHistory()
    const { user } = useContext(AuthContext)

    useEffect(() => {
      if (user && user.account.id) {
        API.get(`/v1/agent/companies/${companyId}/annual_filings`, {
          sort_by: 'due_date',
          sort_order: 'desc',
          per_page: 3,
          upcoming: true
        }).then(({ data }: { data: AnnualFiling[] }) => {
          setAnnualFilings(data)
        })
      }
    }, [user])

    const formatDate = (date: string) => {
      return moment(date).format('MM/DD/YY')
    }

    return (
      <Card {...{ className, variant: 'primary', size: 'large' }}>
        <Row middle='xs' between='xs'>
          <Col xs>
            <LargeDisplay>Updates</LargeDisplay>
          </Col>
          <Col>
            <Button
              {...{
                onClick: () => push(`/companies/${companyId}/updates`),
                type: 'secondary',
                disabled: false
              }}
            >
              View <span>All</span>
            </Button>
          </Col>
        </Row>
        {annualFilings.length === 0 ? (
          <SubtitleBody>
            You’ll be notified of any upcoming updates here.
          </SubtitleBody>
        ) : (
          <>
            <SubtitleBody>
              We’ll help file your annual report to ensure your business remains
              in good standing with the Secretary of State.
            </SubtitleBody>
            {annualFilings.map(annualFiling => (
              <div key={annualFiling.id}>
                <AnnualFilingSeparator />
                <Row>
                  <Updates />
                  <Col xs>
                    <Body bold>
                      {mapStateAbbreviationToName(annualFiling.state)} annual
                      report
                    </Body>
                    {annualFiling.due_date && (
                      <Small>
                        State filing deadline:{' '}
                        {formatDate(annualFiling.due_date)}
                      </Small>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </>
        )}
      </Card>
    )
  }
)`
  display: flex;
  flex-direction: column;

  ${LargeDisplay} {
    margin-bottom: ${SPACING.small};
  }
`

export default UpdatesCard
