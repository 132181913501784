import React, { useEffect } from 'react'
import {
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import TransferIntakeCard from '../TransfersIntakeCard'
import { Error } from '../../Page'
import { TextField } from '@middesk/components'

const validationSchema = yup.object().shape({
  contact_name: yup.string().required('Name is required').nullable(),
  contact_email: yup.string().email().required('Email is required').nullable(),
  contact_phone_number: yup.string().required('Phone is required').nullable()
})

const ContactInformation = ({
  onNext,
  onBack,
  setValidationSchema
}: TransfersIntakeComponentProps): JSX.Element => {
  setValidationSchema && setValidationSchema(validationSchema)
  const { validateForm, values } = useFormikContext<TransfersIntakeFormValues>()
  const shouldDisableContinue = !validationSchema.isValidSync(values)

  useEffect(() => {
    validateForm()
  }, [values])

  return (
    <TransferIntakeCard {...{ onNext, onBack, shouldDisableContinue }}>
      <TextField
        placeholder='Enter your name'
        name='contact_name'
        label='Your Name'
      />
      <TextField
        placeholder='Enter your email'
        name='contact_email'
        label='Contact Email'
      />
      <TextField
        placeholder='Enter your phone number'
        name='contact_phone_number'
        label='Contact Phone Number'
        type='phone'
      />
      <TextField
        placeholder='Enter your title'
        name='contact_title'
        label='Contact Title'
      />
      <Error validationSchema={validationSchema} />
    </TransferIntakeCard>
  )
}

export default ContactInformation
