import { Button as MiddeskButton, ButtonProps } from '@middesk/components'
import styled from 'styled-components'
import { COLORS } from './Colors'
import { TYPOGRAPHY_WEIGHTS } from './Typography'

export type ButtonType = 'primary' | 'secondary'

const Button = styled(MiddeskButton)`
  font-family: 'Suisse Intl';
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};

  ${({ type }: ButtonProps) => {
    if (type === 'secondary') {
      return `
        &:disabled {
          color: ${COLORS.karl_l2};
        }

        &:hover:not([disabled]) {
          border-color: ${COLORS.karl};
          box-shadow: none;
        }

        &:active:not([disabled]) {
          background-color: ${COLORS.dawn};
          border-color: ${COLORS.karl};
          box-shadow: none;
        }

        &:focus:not([disabled]) {
          border-color: ${COLORS.karl};
          outline: 3px solid ${COLORS.indigo_l2};
          border-color: ${COLORS.karl};
          box-shadow: none;
        }
      `
    } else {
      return `
        &:focus:not([disabled]) {
          outline: 3px solid ${COLORS.indigo_l2};
          box-shadow: none;
        }
      `
    }
  }}
`

export default Button
