import { theme } from '@middesk/components'
import remove from 'lodash/remove'
import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { FilesProps } from '../types'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES } from './System/Typography'

const { spacing } = theme

const Files = styled(({ className, files = [], setFiles }: FilesProps) => {
  return (
    <div {...{ className }}>
      {files.map((file, index) => {
        return (
          <>
            <Row key={file?.name}>
              <Col xs={10}>{file?.name}</Col>
              <Col xs={2}>
                <Row end='xs'>
                  <Col xs>
                    <div onClick={() => setFiles(remove(files, index))}>
                      Remove
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )
      })}
    </div>
  )
})`
  div {
    color: ${COLORS.graphite};
    font-size: ${TYPOGRAPHY_SIZES.medium}px;
  }

  > div {
    margin-top: ${spacing.compact};

    > div:last-child {
      cursor: pointer;

      > div > div > div:hover {
        color: ${COLORS.magenta};
      }
    }
  }
`

export default Files
