import React, {
  Children,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import SPACING from '../Spacing'
import { Body as SubtitleDisplay, LargeDisplay } from '../Typography'
import Body from './Body'
import SandboxBanner, { bannerPadStyle } from '../SandboxBanner'
import api from '../../../lib/api'
import { MAX_WIDTH_MOBILE } from '../../Body'
import { AuthContext } from '../../../contexts/AuthProvider'

export type PageProps = {
  className?: string
  children?: Array<ReactNode> | ReactNode
  title?: string
  subtitle?: string
  background?: string
  displaySubtitle?: boolean
}

export type PageDimensions = {
  height: number
  width: number
}

const Page = styled(
  ({
    className,
    children,
    title,
    subtitle,
    background,
    displaySubtitle = true
  }: PageProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null)
    const { impersonator } = useContext(AuthContext)
    const [, setDimensions] = useState<PageDimensions>({
      height: window.innerHeight,
      width: innerWidth
    })

    useEffect(() => {
      setDimensions({
        width: window.innerHeight,
        height: innerHeight
      })
    }, [window])

    useEffect(() => {
      const resize = () => {
        setDimensions({
          width: window.innerHeight,
          height: innerHeight
        })
      }

      window.addEventListener('resize', resize)

      return () => window.removeEventListener('resize', resize)
    })

    return (
      <div {...{ className }}>
        <SandboxBanner />
        <div ref={ref}>{Children.toArray(children)[0]}</div>
        <Body
          {...{ left: ref.current?.children[0].clientWidth || 0, background }}
        >
          <div style={bannerPadStyle(api.sandboxMode(), !!impersonator)}>
            <LargeDisplay>{title}</LargeDisplay>
            {displaySubtitle && <SubtitleDisplay>{subtitle}</SubtitleDisplay>}
            {Children.toArray(children).splice(1)}
          </div>
        </Body>
      </div>
    )
  }
)`
  overflow: hidden;

  > div:last-child > div > ${SubtitleDisplay} {
    margin-bottom: ${SPACING.xlarge};

    @media (max-width: ${MAX_WIDTH_MOBILE}) {
      margin-bottom: ${SPACING.small};
    }
  }
`

export default Page
