import api from '../api'

export type CompanyAccountsOverview = {
  state: string
  status_by_type: {
    tax_type: string
    status?: string
  }[]
  has_locals_in_state: boolean
  counts_by_status: {
    [key: string]: number
  }
}

export type getCompanyAccountsOverviewResponse = {
  data: CompanyAccountsOverview[]
  has_more: boolean
  total_count: number
}

export const APICompanyAccountsOverview = {
  index: (companyId: string): Promise<getCompanyAccountsOverviewResponse> =>
    api.get(`/v1/agent/companies/${companyId}/accounts_overview`)
}
