import { Card, List, MetaTag } from '@middesk/components'
import { startCase } from 'lodash'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../lib/api'
import { STATE_MAP } from '../lib/constants'
import { COLORS } from './System/Colors'

const Container = styled.div`
  background-color: ${COLORS.dawn};
  padding: 2.5rem;
  min-height: 100vh;
`

const StatusColumn = ({ status }: { status: string }) => {
  let tagType = status

  if (status === 'action_required') {
    tagType = 'warning'
  }

  if (status == 'blocked_by_government') {
    tagType = 'pending'
  }

  if (status == 'submitted') {
    tagType = 'completed'
  }

  if (status == 'processing') {
    tagType = 'pending'
  }

  if (status == 'active') {
    tagType = 'completed'
  }

  return <MetaTag type={tagType}>{startCase(status)}</MetaTag>
}

const StateColumn = ({ state }: { state: string }) => (
  <>{STATE_MAP[state].name}</>
)

const OAuthDemo = () => {
  const [taxRegistrations, setTaxRegistrations] = useState<
    {
      state: string
      status: string
      created_at: string
    }[]
  >([])
  const [fetching, setFetching] = useState(true)
  const columns = [
    {
      title: 'Tax Registration State',
      key: 'state',
      render: StateColumn
    },
    {
      title: 'Status',
      key: 'status',
      render: StatusColumn
    },
    {
      title: 'Submitted At',
      key: 'created_at',
      type: 'datetime'
    }
  ]

  const { application_id } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    api.get(`/v1/agent/applications/${application_id}`).then(data => {
      setTaxRegistrations(data.tax_registrations)
      setFetching(false)
    })
  }, [])

  if (fetching) {
    return ''
  }

  return (
    <Container>
      <Card>
        <List data={taxRegistrations} columns={columns} />
      </Card>
    </Container>
  )
}

export default OAuthDemo
