import React from 'react'

const SomethingWentWrong = (): JSX.Element => {
  return (
    <>
      <img src='./icons/cog.svg' height={80} />
      <h1>Something went wrong</h1>
      <p>
        Looks like something went wrong. Please contact{' '}
        <a href='mailto:agent@middesk.com'>agent@middesk.com</a>.
      </p>
    </>
  )
}

export default SomethingWentWrong
