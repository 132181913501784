import { omit, pick, get, flatten } from 'lodash'
import {
  Address,
  Department,
  Individual,
  RegisteredState,
  TransfersIntakeDynamicInputFields,
  TransfersIntakeFormValues
} from '../../types'
import {
  ADDRESS_TYPES,
  DEFAULT_TRANSFER_FQ_ACTION,
  DEFAULT_TRANSFER_TR_ACTION,
  SIGNATORY_JOB_TITLES,
  STATE_MAP
} from '../constants'
import { BASE_64_IDENTIFIER } from '../helpers'

const formatStateInformation = (
  values: TransfersIntakeFormValues,
  departmentsMap: Record<string, Department[]>
) => {
  const stateInformation = []
  for (const state of values.registered_states) {
    const formattedInputs = []
    const userInput = get(
      values,
      state.abbr
    ) as TransfersIntakeDynamicInputFields
    if (!userInput) {
      continue
    }
    const stateInputs = flatten(
      departmentsMap[state.abbr].map(({ inputs }) => inputs)
    )
    let value
    for (const input of stateInputs) {
      if (input.transfer_category === 'account') {
        value = extractAccountCredentials(input.key, userInput)
      } else {
        value = { value: userInput[input.key] }
      }

      formattedInputs.push({
        key: input.key,
        values: value,
        agency: input.department,
        ...omit(input, ['input'])
      })
    }

    stateInformation.push({
      state: state.abbr,
      inputs: formattedInputs
    })
  }
  return stateInformation
}

const extractAccountCredentials = (
  keyPrefix: string,
  stateValues: Record<string, string>
) => {
  return {
    username: stateValues[`${keyPrefix}_username`],
    password: stateValues[`${keyPrefix}_password`],
    email: stateValues[`${keyPrefix}_email`]
  }
}

export const transfersPayload = (
  values: TransfersIntakeFormValues,
  departmentsMap: Record<string, Department[]>
) => {
  const foreignQualificationStates = values.registered_states.flatMap(s => {
    if (s.foreignQualificationAction === 'skip') return []

    return {
      state: s.abbr,
      action: s.foreignQualificationAction
    }
  })

  const payrollStates = values.registered_states.flatMap(s => {
    if (s.taxRegistrationAction === 'skip') return []

    return { state: s.abbr, action: s.taxRegistrationAction }
  })

  return {
    ...pick(values, [
      'legal_name',
      'sos_business_name',
      'formation_state',
      'formation_date',
      'entity_type',
      'contact_email',
      'contact_name',
      'contact_phone_number',
      'contact_title',
      'ein'
    ]),
    tax_registrations: payrollStates,
    foreign_qualifications: foreignQualificationStates,
    individuals: [
      {
        ...omit(values.signatory, ['is_current_user', 'authorized', 'title']),
        signatory: true,
        titles: [values.signatory.title],
        signature: (values.signatory.signature || '').replace(
          BASE_64_IDENTIFIER,
          ''
        )
      }
    ],
    addresses: [values.mailing_address, values.primary_address],
    state_information: formatStateInformation(values, departmentsMap)
  }
}

const deserializeRegisteredStates = (
  payload: Record<string, any>
): RegisteredState[] => {
  // Stores the states as keys and sets the action values from both
  // `saved_foreign_qualifications` and `saved_tax_registrations`.
  const registeredStates: {
    [key: string]: {
      taxRegistrationAction?: string
      foreignQualificationAction?: string
    }
  } = {}

  payload.saved_foreign_qualifications.forEach(
    (fqState: { state: string; action: string }) => {
      if (registeredStates[fqState?.state] === undefined) {
        registeredStates[fqState.state] = {}
      }

      registeredStates[fqState.state].foreignQualificationAction =
        fqState?.action || DEFAULT_TRANSFER_FQ_ACTION
    }
  )

  payload.saved_tax_registrations.forEach(
    (trState: { state: string; action: string }) => {
      if (registeredStates[trState?.state] === undefined) {
        registeredStates[trState.state] = {}
      }

      registeredStates[trState.state].taxRegistrationAction =
        trState?.action || DEFAULT_TRANSFER_TR_ACTION
    }
  )

  return Object.keys(registeredStates).map(state => {
    return {
      abbr: state,
      name: STATE_MAP[state].name,
      taxRegistrationAction:
        registeredStates[state].taxRegistrationAction ||
        DEFAULT_TRANSFER_TR_ACTION,
      foreignQualificationAction:
        registeredStates[state].foreignQualificationAction ||
        DEFAULT_TRANSFER_FQ_ACTION,
      isSelectable: false
    }
  })
}

const deserializeSignatory = (payload: Record<string, any>) => {
  const signatory = payload.individuals
    .filter(
      (i: Individual) =>
        i.titles.some(t => SIGNATORY_JOB_TITLES.includes(t)) && i.signatory
    )
    .sort((a: Individual, b: Individual) => {
      if (a.signature) {
        return -1
      } else if (b.signature) {
        return 1
      }

      return 0
    })[0]
  if (!signatory) {
    return null
  }
  return {
    ...pick(signatory, [
      'address',
      'director',
      'email',
      'id',
      'llc_owner',
      'name',
      'signatory',
      'signature'
    ]),
    title: signatory.titles[0]
  }
}

const deserializeSavedStateQuestions = (payload: Record<string, any>) => {
  if (!payload.saved_state_questions) {
    return {}
  }
  const state_information: any = {}
  for (const state of payload.saved_state_questions) {
    const state_abbr = state.state
    state_information[state_abbr] = {}
    for (const i of state.inputs) {
      if (i.transfer_category === 'account') {
        state_information[state_abbr][i.key + '_username'] = i.values.username
        state_information[state_abbr][i.key + '_password'] = i.values.password
        state_information[state_abbr][i.key + '_email'] = i.values.email
      } else {
        state_information[state_abbr][i.key] = i.values.value
      }
    }
  }
  return state_information
}

export const deserializeTransfer = (payload: Record<string, any>) => {
  return {
    ...pick(payload, [
      'contact_name',
      'contact_email',
      'contact_phone_number',
      'contact_title',
      'ein',
      'formation_state',
      'formation_date',
      'entity_type',
      'legal_name',
      'sos_business_name'
    ]),
    // This is subject to change
    primary_address: payload.addresses.find(
      (a: Address) => a.type === ADDRESS_TYPES.primary
    ),
    mailing_address: payload.addresses.find(
      (a: Address) => a.type === ADDRESS_TYPES.mailing
    ),
    signatory: deserializeSignatory(payload),
    registered_states: deserializeRegisteredStates(payload),
    ...deserializeSavedStateQuestions(payload)
  }
}
