import { Form, TextField } from '@middesk/components'
import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import * as yup from 'yup'
import ActionFooter from '../components/ActionFooter'
import Brand from '../components/Brand'
import LandingCard from '../components/LandingCard'
import LinkFooter from '../components/LinkFooter'
import LoaderButton from '../components/LoaderButton'
import { COLORS } from '../components/System/Colors'
import Link from '../components/System/Link'
import LandingGlobalStyle from '../LandingGlobalStyle'
import API from '../lib/api'

const pwValidationSchema = yup.object().shape({
  email: yup.string().email().required('Email is required')
})

const ForgotPassword = () => {
  const [values, setValues] = useState({ email: '' })
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = () => {
    setSubmitting(true)
    API.post('/password/reset', { email: values.email, agent: true }).then(
      () => {
        setSubmitted(true)
        setSubmitting(false)
      }
    )
  }
  return (
    <>
      <LandingGlobalStyle />
      <LandingCard>
        <Row center='xs'>
          <Col>
            <Brand color={COLORS.black} height='45px' />
            <p>Update password</p>
          </Col>
        </Row>
        <Row>
          <Col xs>
            {!submitted ? (
              <Form
                initialValues={{ email: '' }}
                validationSchema={pwValidationSchema}
                onChange={(e: any) => setValues(e)}
                onSubmit={onSubmit}
              >
                <TextField
                  placeholder='Enter email'
                  name='email'
                  label='Email'
                />
                <ActionFooter>
                  <LoaderButton
                    type='submit'
                    submitText='Submit'
                    submitting={submitting}
                    isDisabled={!pwValidationSchema.isValidSync(values)}
                  />
                </ActionFooter>
              </Form>
            ) : (
              <Row center='xs'>
                <Col xs>
                  <p>An password reset link was sent to {values.email}</p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </LandingCard>
      <LinkFooter>
        <Link href='/login'>Return to sign in</Link>
      </LinkFooter>
    </>
  )
}
export default ForgotPassword
