import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import { Button, Form, TextField, theme } from '@middesk/components'
import api from '../../lib/api'
import styled from 'styled-components'

const { typography } = theme

const SubmitButton = () => {
  const { dirty, isValid, isSubmitting } = useFormikContext()

  return (
    <Button
      type='submit'
      disabled={!dirty || !isValid || isSubmitting}
      style={{ marginTop: '10px', width: '100%' }}
    >
      Continue
    </Button>
  )
}

const CheckYourEmail = styled(
  ({
    className,
    email
  }: {
    className?: string
    email: string
  }): JSX.Element => (
    <div className={className}>
      <img src='/icons/email.svg' height={100} width={100} />
      <h2>Check your email</h2>
      <div>We sent a sign in link to {email}.</div>
    </div>
  )
)`
  margin: 10px 0;
  text-align: center;

  h2 {
    font-size: ${typography.sizes.large};
    margin: 10px 0;
  }
`

const MagicLinkForm = ({
  setErrorMessage
}: {
  setErrorMessage: (message?: string) => void
}): JSX.Element => {
  const [email, setEmail] = useState<string>()

  const postPasswordlessAuth = ({ email }: { email: string }) => {
    setErrorMessage()
    return (
      api
        .post(`/sessions/passwordless_auth`, {
          email,
          destination: 'agent'
        })
        .then(() => setEmail(email))
        /* eslint-disable-next-line no-console */
        .catch(e => setErrorMessage(e.message))
    )
  }

  if (email) {
    return <CheckYourEmail email={email} />
  }

  return (
    <Form
      initialValues={{
        email: ''
      }}
      onSubmit={postPasswordlessAuth}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .email('Email must be valid')
          .required('Email is required')
      })}
    >
      <TextField
        name='email'
        label='Email'
        placeholder='Enter your email to receive a magic link'
        showErrorMessage
      />

      <SubmitButton />
    </Form>
  )
}

export default MagicLinkForm
