import React, { useContext } from 'react'
import TransfersIntake from './TransfersIntake/TransfersIntake'
import GuestAccessDenied from './GuestAccessDenied'
import { AuthContext } from '../contexts/AuthProvider'

const GuestTransfersIntake = () => {
  const { fetching, user } = useContext(AuthContext)

  return <>{!fetching && !user ? <GuestAccessDenied /> : <TransfersIntake />}</>
}

export default GuestTransfersIntake
