import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from '../BorderRadius'
import { COLORS } from '../Colors'

const WARNING_HEIGHT = 18

export type WarningV2Variant = 'magenta' | 'grey'

export type WarningV2Props = {
  className?: string
  variant?: WarningV2Variant
}

const WARNING_V2_OFFSET_DOWN = '1px'

const WarningV2 = styled(({ className, variant = 'grey' }: WarningV2Props) => {
  let stroke = COLORS.karl

  if (variant === 'magenta') {
    stroke = COLORS.magenta
  }

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'
  const strokeWidth = BORDER_RADIUS.xxsmall

  return (
    <svg
      width={WARNING_HEIGHT}
      height={WARNING_HEIGHT}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path d='M7.32117 3.58628L2.16321 12.1971C2.05687 12.3813 2.0006 12.5901 2 12.8027C1.99941 13.0154 2.05451 13.2245 2.15982 13.4093C2.26513 13.594 2.41699 13.748 2.60028 13.8558C2.78356 13.9637 2.9919 14.0217 3.20455 14.024H13.5205C13.7331 14.0217 13.9415 13.9637 14.1247 13.8558C14.308 13.748 14.4599 13.594 14.5652 13.4093C14.6705 13.2245 14.7256 13.0154 14.725 12.8027C14.7244 12.5901 14.6681 12.3813 14.5618 12.1971L9.40384 3.58628C9.29528 3.40731 9.14243 3.25934 8.96003 3.15664C8.77762 3.05395 8.57183 3 8.36251 3C8.15319 3 7.94739 3.05395 7.76499 3.15664C7.58259 3.25934 7.42974 3.40731 7.32117 3.58628Z' />
      <path
        d='M6.32117 2.58628L1.16321 11.1971C1.05687 11.3813 1.0006 11.5901 1 11.8027C0.999409 12.0154 1.05451 12.2245 1.15982 12.4093C1.26513 12.594 1.41699 12.748 1.60028 12.8558C1.78356 12.9637 1.9919 13.0217 2.20455 13.024H12.5205C12.7331 13.0217 12.9415 12.9637 13.1247 12.8558C13.308 12.748 13.4599 12.594 13.5652 12.4093C13.6705 12.2245 13.7256 12.0154 13.725 11.8027C13.7244 11.5901 13.6681 11.3813 13.5618 11.1971L8.40384 2.58628C8.29528 2.40731 8.14243 2.25934 7.96003 2.15664C7.77762 2.05395 7.57183 2 7.36251 2C7.15319 2 6.94739 2.05395 6.76499 2.15664C6.58259 2.25934 6.42974 2.40731 6.32117 2.58628Z'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M7.3625 5.71631V8.15218'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M7.3625 10.5881H7.36917'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
    </svg>
  )
})`
  position: relative;
  top: ${WARNING_V2_OFFSET_DOWN};
  min-height: ${WARNING_HEIGHT}px;
  min-width: ${WARNING_HEIGHT}px;
`

export default WarningV2
