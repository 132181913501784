import React from 'react'

const UnknownKeySuccess = (): JSX.Element => {
  return (
    <>
      <img src='./icons/unplug.png' height={120} />
      <h1>Your request was proccessed successfully</h1>
      <p>
        If you submitted this request by mistake, please contact{' '}
        <a href='mailto:agent@middesk.com'>agent@middesk.com</a>.
      </p>
    </>
  )
}

export default UnknownKeySuccess
