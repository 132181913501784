export type BorderRadiusSize =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
export type BorderRadiusUnit = 2 | 4 | 10 | 24 | 34
export type BorderRadiusTerm = `${BorderRadiusUnit}px`

const BORDER_RADIUS: Record<BorderRadiusSize, BorderRadiusTerm> = {
  xxsmall: '2px',
  xsmall: '4px',
  small: '10px',
  medium: '24px',
  large: '34px'
}

export default BORDER_RADIUS
