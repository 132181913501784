import React from 'react'

import { SelectField, TextField, theme } from '@middesk/components'
import styled from 'styled-components'
import { formatItemValidations } from '.'
import {
  AGENCY_DELIVERY_METHOD_MAIL,
  AGENCY_DELIVERY_METHOD_EMAIL,
  AGENCY_DELIVERY_METHOD_ONLINE_PORTAL,
  AGENCY_DELIVERY_METHOD_IMMEDIATELY,
  AGENCY_DELIVERY_METHOD_MAIL_AND_EMAIL,
  FREQUENCY_OPTIONS
} from '../../lib/constants'
import { get, isString } from 'lodash'
import { useFormikContext } from 'formik'
import { FormValues, Agency, Item } from '../../types'

const { spacing, typography, colors } = theme

const StyledContainer = styled.div`
  margin-left: 29px;

  h1 {
    font-size: ${typography.sizes.medium};
    font-weight: ${typography.weights.normal};
  }

  .agency {
    padding: ${spacing.small} 0;

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.frost};
    }

    h2 {
      font-weight: ${typography.weights.bold};
      color: ${colors.graphite};
      margin-bottom: 4px;
      font-size: ${typography.sizes.medium};
    }

    .contact {
      margin-bottom: ${spacing.small};

      .mode {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        color: ${colors.graphite};

        img {
          height: 18px;
          width: 18px;
          margin-right: ${spacing.xsmall};
        }
      }
    }

    .field {
      width: 100%;
      margin-bottom: 4px;

      label {
        width: 100%;
        margin: 0;
      }
    }

    .delivery-method {
      color: ${colors.karl};
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const deliveryMethodFormatter = (delivery_method: string) => {
  switch (delivery_method) {
    case AGENCY_DELIVERY_METHOD_MAIL:
      return 'IDs are returned via physical mail'
    case AGENCY_DELIVERY_METHOD_EMAIL:
      return 'IDs are returned via email'
    case AGENCY_DELIVERY_METHOD_ONLINE_PORTAL:
      return 'IDs are returned via account portal'
    case AGENCY_DELIVERY_METHOD_IMMEDIATELY:
      return 'IDs are returned via the confirmation page during registration'
    case AGENCY_DELIVERY_METHOD_MAIL_AND_EMAIL:
      return 'IDs are returned via email and physical mail'
  }
}

const formatPhone = (
  phone_number: string,
  phone_number_extension: string | undefined
) => {
  const extension = phone_number_extension ? `, ${phone_number_extension}` : ''

  return `${phone_number.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3'
  )}${extension}`
}

export const transformQuestionFormatToMask = (
  format: string
): (RegExp | string)[] => {
  const mask: (RegExp | string)[] = []

  format.split('').map(validationCharacter => {
    if (validationCharacter === 'X') {
      mask.push(/[A-Za-z0-9]/)
    } else {
      mask.push(validationCharacter)
    }
  })

  return mask
}

const itemValueEntry = (item: Item, value: string | undefined) => {
  const formats = formatItemValidations(item.validation?.format)
  const itemFormat = item.validation?.format

  if (item.key.includes('frequency') || item.key.includes('deposit_schedule')) {
    return (
      <div key={item.key} className='field'>
        <SelectField
          name={`tax_registrations[0].${item.key}`}
          label={item.label}
          placeholder='Select Frequency'
          optional
          isClearable
        >
          {FREQUENCY_OPTIONS.map(freq => (
            <option
              key={freq}
              value={freq}
              label={freq}
              selected={freq === value}
            />
          ))}
        </SelectField>
      </div>
    )
  }

  return (
    <div key={item.key} className='field'>
      <TextField
        name={`tax_registrations[0].${item.key}`}
        label={item.label}
        placeholder={formats.join(', ')}
        optional={!item.is_tax_id}
        showErrorMessage
        type={itemFormat && isString(itemFormat) ? 'masked' : undefined}
        mask={
          itemFormat && isString(itemFormat)
            ? transformQuestionFormatToMask(itemFormat)
            : undefined
        }
      />
    </div>
  )
}

const UnmanagedItemsForm = ({ agencies }: { agencies: any }) => {
  const { values } = useFormikContext<FormValues>()

  return (
    <StyledContainer>
      <h1>Store your account information in Middesk:</h1>
      {agencies.map(
        ({
          name,
          link,
          phone_number,
          phone_number_extension,
          items,
          delivery_method
        }: Agency) => (
          <div key={name} className='agency'>
            <h2>{name}</h2>
            <div className='contact'>
              <div className='mode'>
                <img src='/globe.svg' />
                <b>
                  <a href={link} target='_blank' rel='noreferrer'>
                    {link}
                  </a>
                </b>
              </div>
              <div className='mode'>
                <img src='/phone.svg' />
                <span>{formatPhone(phone_number, phone_number_extension)}</span>
              </div>
            </div>
            <div>
              {items.map(item =>
                itemValueEntry(
                  item,
                  get(values, `tax_registrations[0].${item.key}`)
                )
              )}
            </div>
            <div className='delivery-method'>
              {deliveryMethodFormatter(delivery_method)}
            </div>
          </div>
        )
      )}
    </StyledContainer>
  )
}

export default UnmanagedItemsForm
