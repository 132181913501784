import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const WorkforceWestVirginia = styled(
  ({ className, children }): JSX.Element => {
    return <div {...{ className }}>{children}</div>
  }
)`
  ${Styles}
`

const StateTaxDepartment = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://mytaxes.wvtax.gov/_/' target='_blank'>
          https://mytaxes.wvtax.gov/_/
        </Link>
      </Body>
      <Body>2. Log in using your WV Tax Department credentials</Body>
      <Body>
        3. Scroll to the <b>Accounts/Names/Addresses</b> section
      </Body>
      <Body>
        4. Select <b>Manage Addresses</b>
      </Body>
      <Body>
        5. Edit current Mailing address to{' '}
        <b>56 Broad St STE 14028, Boston, MA 02109</b>
      </Body>
      <Body>
        6. Review verified address to ensure PMB 93738 is still showing
      </Body>
      <Body>
        7. Select <b>Submit</b>
      </Body>
      <Body>8. Review the address ensuring PMB 73838 is still showing</Body>
      <Body>
        9. Select <b>Submit</b>
      </Body>
    </div>
  )
})`
  ${Styles}
`

const WestVirginia = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'WorkForce West Virginia',
    link: 'https://workforcewv.org/',
    children: (
      <WorkforceWestVirginia>
        {
          (departments || []).find(
            ({ name }) => name === 'WorkForce West Virginia'
          )?.children
        }
      </WorkforceWestVirginia>
    )
  },
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    name: 'State Tax Department',
    link: 'https://mytaxes.wvtax.gov/_/',
    children: <StateTaxDepartment />,
    renderCompleteLater: true
  }
]

export default WestVirginia
