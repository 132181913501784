import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthProvider'
import api from '../lib/api'

type AccountProviderProps = {
  existingSubscription?: boolean
  existingPaymentMethod?: boolean
  requiresSOSPaymentInformation: boolean
  fetchingExistingPaymentMethod?: boolean
  fetchingExistingSubscription: boolean
  setFetchingExistingPaymentMethod: (e: boolean) => void
  setFetchingExistingSubscription: (e: boolean) => void
}

export const AccountContext = createContext<AccountProviderProps>({
  existingSubscription: false,
  existingPaymentMethod: false,
  requiresSOSPaymentInformation: false,
  fetchingExistingSubscription: false,
  fetchingExistingPaymentMethod: false,
  setFetchingExistingPaymentMethod: () => undefined,
  setFetchingExistingSubscription: () => undefined
})

const AccountProvider = ({ children }: { children: any }) => {
  const [
    fetchingExistingSubscription,
    setFetchingExistingSubscription
  ] = useState(true)
  const [existingPaymentMethod, setExistingPaymentMethod] = useState<boolean>(
    false
  )
  const [
    fetchingExistingPaymentMethod,
    setFetchingExistingPaymentMethod
  ] = useState(true)
  const [
    requiresSOSPaymentInformation,
    setRequiresSOSPaymentInformation
  ] = useState<boolean>(false)
  const [existingSubscription, setExistingSubscription] = useState<boolean>(
    false
  )

  const { authenticated, account } = useContext(AuthContext)
  const skipPaymentSetting = account?.settings?.agent?.skip_payment

  useEffect(() => {
    if (authenticated && fetchingExistingPaymentMethod) {
      api
        .get('/ajax/agent/existing_payment_method')
        .then(json => {
          setExistingPaymentMethod(json.existing_payment_method)
          setRequiresSOSPaymentInformation(
            !!skipPaymentSetting && !json.existing_payment_method
          )
        })
        .finally(() => setFetchingExistingPaymentMethod(false))
    }
  }, [fetchingExistingPaymentMethod])

  useEffect(() => {
    if (authenticated && fetchingExistingSubscription) {
      api
        .get('/ajax/agent/existing_customer')
        .then(json => {
          setExistingSubscription(json.existing_customer)
        })
        .finally(() => setFetchingExistingSubscription(false))
    }
  }, [fetchingExistingSubscription])

  return (
    <AccountContext.Provider
      value={{
        existingSubscription,
        existingPaymentMethod,
        requiresSOSPaymentInformation,
        fetchingExistingSubscription,
        fetchingExistingPaymentMethod,
        setFetchingExistingPaymentMethod,
        setFetchingExistingSubscription
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
