import Cookies from 'js-cookie'
import Env from './env'

interface HubspotSubmitBusinessValues {
  email: string
  company: string
  address: string
  city: string
  state: string
  zip: string
  firstname: string
  lastname: string
  lead_capture_url__c: string
  use_case__c: string
}

interface HubspotContext {
  ipAddress?: string
  hutk?: any
}

const HUBSPOT_AGENT_FORM_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/21979453/be36fc7a-f079-428e-9e98-5aa95cc1ce0c'

const toHubspotField = (key: string, value: string) => {
  return {
    // https://developers.hubspot.com/docs/api/crm/crm-custom-objects
    objectTypeId: '0-1',
    name: key,
    value: value
  }
}

export const hubspotSubmitBusiness = (
  values: HubspotSubmitBusinessValues,
  ipAddress?: string
) => {
  if (!Env.isProduction()) {
    return
  }
  const fields = []
  for (const [key, value] of Object.entries(values)) {
    fields.push(toHubspotField(key, value))
  }
  const hutk = Cookies.get('hubspotutk')
  const context: HubspotContext = {}

  if (ipAddress) {
    context.ipAddress = ipAddress
  }

  if (hutk) {
    context.hutk = hutk
  }

  return fetch(HUBSPOT_AGENT_FORM_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ fields, context })
  })
}
