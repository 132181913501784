import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import Sidebar from '../../components/Sidebar'
import Page from '../../components/System/Layout/Page'
import Line from '../../components/System/Line'
import SPACING from '../../components/System/Spacing'
import Tab from '../../components/System/Tab'
import Tabs from '../../components/System/Tabs'
import { ChildrenProps, ContainerProps } from '../../types'

const Accounts = styled(
  ({
    className,
    children,
    companyId
  }: ContainerProps & ChildrenProps & { companyId: string }): JSX.Element => {
    const {
      push,
      location: { pathname }
    } = useHistory()

    const routes = [
      `/companies/${companyId}/accounts/overview`,
      `/companies/${companyId}/accounts/details`
    ]

    return (
      <Page
        {...{
          className,
          title: 'Accounts'
        }}
      >
        <Sidebar />
        <div>
          <Tabs
            {...{
              activeIndex: routes.findIndex(e => e === pathname),
              onTab: e => push(routes[e])
            }}
          >
            <Tab>Overview</Tab>
            <Tab>Details</Tab>
          </Tabs>
          <Line />
          {children}
        </div>
      </Page>
    )
  }
)`
  ${Tabs} + ${Line} {
    margin-bottom: ${SPACING.large};
  }
`

export default Accounts
