import React from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import { DateTime, MetaTag, theme } from '@middesk/components'
import Card from '../../components/System/Card'
import TaxAccountContainer from './TaxAccountContainer'
import { AgencyRegistration } from '../../types'
import { STATE_MAP } from '../../lib/constants'
import {
  StyledAttribute,
  TaxAccountWrapper,
  LineSeparator,
  TimestampText,
  StateAndAgencyName,
  TaxTypesAndTimestampRow,
  TaxTypes
} from './TaxAgencyRegistrationDetailCard'

const { spacing } = theme

const AgencyRegistrationItems = styled.div`
  margin-top: ${spacing.compact};
`

const getARStatusTagColor = (status: string) => {
  switch (status) {
    case 'information_request':
      return 'warning'
    case 'completed':
      return 'primary'
    case 'blocked_payroll_date':
    case 'blocked_portal_unavailable':
      return 'inactive'
    case 'open':
      return 'secondary'
    case 'waiting_on_mail':
      return 'yellow'
    case 'duplicate':
    case 'unmanaged':
      return 'unknown'
    default:
      return 'info'
  }
}

const getARStatusTagLabel = (status: string) => {
  switch (status) {
    case 'information_request':
      return 'Action required'
    case 'completed':
      return 'Completed'
    case 'blocked_payroll_date':
    case 'blocked_portal_unavailable':
      return 'Blocked by goverment'
    case 'open':
      return 'Middesk processing'
    case 'waiting_on_mail':
      return 'Government processing'
    case 'duplicate':
      return 'Duplicate'
    case 'unmanaged':
      return 'Unmanaged'
    default:
      return capitalize(status.replaceAll('_', ' '))
  }
}

const Timestamp = ({ completed_at, updated_at }: AgencyRegistration) => {
  if (completed_at) {
    return (
      <TimestampText>
        Completed{' '}
        <DateTime relative formatter={undefined}>
          {completed_at}
        </DateTime>
      </TimestampText>
    )
  } else {
    return (
      <TimestampText>
        Updated{' '}
        <DateTime relative formatter={undefined}>
          {updated_at}
        </DateTime>
      </TimestampText>
    )
  }
}

const AgencyRegistrationDetailCard = ({
  agency_registration
}: {
  agency_registration: AgencyRegistration
}) => {
  return (
    <Card key={agency_registration.id} variant='secondary'>
      <div>
        <StateAndAgencyName>
          <b>{STATE_MAP[agency_registration.state].name}・</b>
          {agency_registration.agency.name}
        </StateAndAgencyName>
        <TaxTypesAndTimestampRow>
          <TaxTypes>Local Tax</TaxTypes>
          <div>
            <Timestamp {...agency_registration} />

            <MetaTag type={getARStatusTagColor(agency_registration.status)}>
              {getARStatusTagLabel(agency_registration.status)}
            </MetaTag>
          </div>
        </TaxTypesAndTimestampRow>

        <LineSeparator />

        <AgencyRegistrationItems>
          {agency_registration.agency_registration_items.map((item, index) => (
            <StyledAttribute key={`ar-item-${index}`} label={item.label}>
              {item.value}
            </StyledAttribute>
          ))}
        </AgencyRegistrationItems>

        {agency_registration.agency_account && (
          <TaxAccountWrapper>
            <TaxAccountContainer
              account={agency_registration.agency_account}
              status={agency_registration.status}
            />
          </TaxAccountWrapper>
        )}
      </div>
    </Card>
  )
}

export default AgencyRegistrationDetailCard
