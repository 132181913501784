import React, { useContext } from 'react'

import { AuthContext } from '../contexts/AuthProvider'
import Invitations from '../components/Invitations'
import GuestAccessDenied from '../components/GuestAccessDenied'

const GuestInvitations = (): JSX.Element => {
  const { fetching, user } = useContext(AuthContext)

  return <>{!fetching && !user ? <GuestAccessDenied /> : <Invitations />}</>
}

export default GuestInvitations
