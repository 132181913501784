import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../contexts/AuthProvider'
import { Form, TextField } from '@middesk/components'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Card from '../components/System/Card'
import SPACING from '../components/System/Spacing'
import {
  XLarge,
  Body as Medium,
  TYPOGRAPHY_WEIGHTS
} from '../components/System/Typography'
import { ContainerProps } from '../types'
import { SignupHeader } from './SignupNew'
import ActionFooter from '../components/ActionFooter'
import * as yup from 'yup'
import LoaderButton from '../components/LoaderButton'
import api from '../lib/api'
import { useHistory } from 'react-router'
import APICompany from '../lib/api/company'
import { CompanyContext } from '../components/CompanyProvider'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  accountName: yup.string().required('Company name is required')
})

const SetUserInfo = styled(({ className }: ContainerProps) => {
  const [loading, setLoading] = useState(false)
  const { user, setFetchingUser } = useContext(AuthContext)
  const { push } = useHistory()

  const { activeCompany } = useContext(CompanyContext)

  const [values, setValues] = useState({
    firstName: user?.name?.split(' ')[1] || '',
    lastName: user?.name?.split(' ')[1] || '',
    accountName: user?.account?.name
  })

  const onSubmit = async () => {
    setLoading(true)
    const updateProfile = api.patch('/me/profile', {
      name: `${values.firstName} ${values.lastName}`
    })
    const updateAccount = api.put(`/ajax/accounts/${user?.account?.id}`, {
      name: values.accountName
    })

    await APICompany.create({})

    Promise.all([updateProfile, updateAccount]).then(() => {
      setFetchingUser(true)
      setLoading(false)
      push({
        pathname: '/submit-business',
        state: values
      })
    })
  }

  useEffect(() => {
    if (user && user.name) {
      setValues({
        ...values,
        firstName: user.name.split(' ')[0],
        lastName: user.name.split(' ')[1]
      })
    }
  }, [user])

  useEffect(() => {
    if (activeCompany) {
      push(`/companies/${activeCompany.id}/home`)
    }
  }, [activeCompany])

  return (
    <div className={className}>
      <SignupHeader />
      <div>
        <XLarge bold>Welcome to Middesk Agent</XLarge>
        <div>
          <Medium>
            Let&apos;s get started with some information about you!
          </Medium>
        </div>
        <Card variant='secondary' size='medium'>
          <Form
            {...{
              validationSchema,
              onSubmit,
              onChange: setValues,
              enableReinitialize: true,
              initialValues: values
            }}
          >
            <Row>
              <Col xs>
                <TextField
                  placeholder='Enter first name'
                  name='firstName'
                  label='First Name'
                />
              </Col>
              <Col xs>
                <TextField
                  placeholder='Enter last name'
                  name='lastName'
                  label='Last Name'
                />
              </Col>
            </Row>
            <Row>
              <Col xs>
                <TextField
                  placeholder='Enter account name'
                  name='accountName'
                  label='Account Name'
                />
              </Col>
            </Row>
            <ActionFooter>
              <LoaderButton
                {...{
                  submitting: loading,
                  isDisabled: !validationSchema.isValidSync(values),
                  submitText: 'Continue'
                }}
              />
            </ActionFooter>
          </Form>
        </Card>
      </div>
    </div>
  )
})`
  > div:nth-child(2) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  ${Medium} {
    > span {
      font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    }
  }

  ${Card} {
    display: flex;
    justify-content: center;
    margin-top: ${SPACING.medium};
    padding: ${SPACING.xlarge};

    > *:first-child {
      width: 100%;
    }
  }
`

export default SetUserInfo
