import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from '../BorderRadius'
import { theme } from '@middesk/components'

const { colors } = theme

export type ErrorVariant = 'red'

export type ErrorProps = {
  className?: string
  variant?: ErrorVariant
}

const Error = styled(({ className, variant = 'red' }: ErrorProps) => {
  let stroke = colors.karl

  if (variant === 'red') {
    stroke = colors.red
  }

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'
  const strokeWidth = BORDER_RADIUS.xxsmall

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M9 6V9'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M9 12H9.0075'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
    </svg>
  )
})``

export default Error
