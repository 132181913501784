import chunk from 'lodash/chunk'
import React, { Children } from 'react'
import styled from 'styled-components'
import { GridProps } from '../../../types'
import SPACING from '../Spacing'

const Grid = styled(({ className, columns, children }: GridProps) => {
  return (
    <div {...{ className }}>
      <div>
        {Array.from(Array(columns).keys()).map((_, row) => (
          <div key={row}>
            {chunk(
              Children.toArray(children),
              Math.ceil(Children.toArray(children).length / (columns || 1))
            ).filter((_, col) => row == col)}
          </div>
        ))}
      </div>
    </div>
  )
})`
  > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${SPACING.xlarge};

    > div {
      display: flex;
      flex-grow: 1;
      flex-basis: ${({ columns = 1 }: GridProps) => {
        return `
          calc(${100 / columns}% - ${(2 * (columns - 2) + 2) * 10}px);
        `
      }}
      flex-direction: column;
      gap: ${SPACING.xlarge};
    }
  }
`

export default Grid
