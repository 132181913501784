import { Attribute, TextField } from '@middesk/components'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import APICompany from '../../lib/api/company'
import { Company } from '../../types'
import useApplication from '../../lib/useApplication'
import { useParams } from 'react-router'

const EinField = ({
  showPresentAttribute
}: {
  showPresentAttribute: boolean
}): JSX.Element | null => {
  const { id: applicationId } = useParams<{ id: string }>()
  const { application } = useApplication(applicationId)
  const [company, setCompany] = useState<Company | null>(null)
  const [fetchingCompany, setFetchingCompany] = useState(true)
  const { values, setFieldValue } = useFormikContext<{ ein: string }>()

  useEffect(() => {
    if (application) {
      APICompany.show(application.company_id)
        .then(setCompany)
        .then(() => setFetchingCompany(false))
    }
  }, [application])

  useEffect(() => {
    if (!values.ein && !!company?.ein) {
      setFieldValue('ein', company.ein)
    }
  }, [company])

  if (fetchingCompany) return null

  if (company?.ein) {
    if (showPresentAttribute) {
      return (
        <Attribute
          label='Federal Employer Identification Number'
          sublabel='If you need to change this value, please email agent@middesk.com'
        >
          {values.ein}
        </Attribute>
      )
    }
    return null
  }

  return (
    <TextField
      label='Federal Employer Identification Number'
      placeholder='XX-XXXXXXX'
      name='ein'
      type='ein'
      showErrorMessage
      tooltip={{
        text:
          'This is your business’s Federal Employer Identification Number (FEIN), assigned by the IRS. It should be a 9-digit number of the format XX-XXXXXXX.'
      }}
    />
  )
}

export default EinField
