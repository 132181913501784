import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const DepartmentOfRevenue = styled(
  ({ className }): JSX.Element => {
    return (
      <div {...{ className }}>
        <Body>
          1. Navigate to{' '}
          <Link href='https://gtc.dor.ga.gov/_/' target='_blank'>
            https://gtc.dor.ga.gov/_/
          </Link>
        </Body>
        <Body>
          2. Login using your credentials for your Georgia DOR account
        </Body>
        <Body>
          3. Select the <b>Name and Addresses</b> tab
        </Body>
        <Body>4. Click on your Mailing address</Body>
        <Body>
          5. Click on <b>Change this address</b>
        </Body>
        <Body>
          6. Edit current Mailing address to{' '}
          <b>56 Broad St STE 14028, Boston, MA 02109</b>
        </Body>
        <Body>
          7. Click on <b>Verify Address</b>. An address verification pop up will
          appear.
        </Body>
        <Body>
          8. Click on <b>Select this address</b> next to the version of the
          address that includes the PMB number. Click <b>Save</b>.
        </Body>
        <Body>
          9. Click <b>Next</b>
        </Body>
        <Body>
          10. Click <b>Submit</b>
        </Body>
        <br />
      </div>
    )
  }
)`
  ${Styles}
`

const DepartmentOfLabor = styled(({ className, children }) => {
  return <div {...{ className }}>{children}</div>
})`
  ${Styles}
`

const Georgia = (
  departments?: Array<DepartmentElement>
): Array<DepartmentElement> => [
  {
    name: 'Department of Labor',
    link: 'https://eresponse.gdol.ga.gov/idp/sso/employer/login',
    children: (
      <DepartmentOfLabor>
        {(departments || [])
          .filter(({ name }) => name === 'Department of Labor')
          .flat()
          .map(e => e?.children)}
      </DepartmentOfLabor>
    ),
    renderUnableToChangeMailing: true
  },
  {
    name: 'Department of Revenue',
    link: 'https://gtc.dor.ga.gov/_/',
    children: <DepartmentOfRevenue />,
    renderCompleteLater: true,
    renderPayrollCallout: true
  }
]

export default Georgia
