import { Form, Modal, TextField, theme } from '@middesk/components'
import * as Sentry from '@sentry/browser'
import React, { useState } from 'react'
import styled from 'styled-components'
import API from '../lib/api'
import { COLORS } from './System/Colors'

const { spacing } = theme

const Error = styled.div`
  color: red;
  margin: 0 0 ${spacing.compact} ${spacing.normal};
`

const StyledTextField = styled(TextField)`
  margin: 0 !important;
`

interface FeedbackFormProps {
  isOpen: boolean
  onClose: () => void
}

const FeedbackForm = ({ isOpen, onClose }: FeedbackFormProps) => {
  const customStyles = {
    overlay: {
      background: 'rgba(255, 255, 255, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: spacing.normal,
      zIndex: 1
    } as React.CSSProperties,
    content: {
      backgroundColor: COLORS.white,
      borderRadius: '10px',
      boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
      height: '500px',
      padding: '0px',
      width: '500px'
    } as React.CSSProperties
  }

  const [values, setValues] = useState({
    feedback: ''
  })

  const [error, setError] = useState('')

  const submitFeedback = () => {
    if (!values.feedback) {
      return
    }
    API.post('/agent_feedback', {
      feedback: values.feedback,
      referrer: window.location.href
    })
      .then(() => {
        onClose()
        setError('')
      })
      .catch((error: any) => {
        setError('Feedback could not be submitted at this time')
        if (error.json && error.json.errors) {
          const errors = error.json.errors
            .map((error: any) => error.message)
            .join(', ')
          Sentry.captureException(errors)
        } else {
          Sentry.captureException(error)
        }
      })
  }

  return (
    <Modal
      close={onClose}
      closeLabel='Cancel'
      confirm={submitFeedback}
      confirmLabel='Submit'
      isOpen={isOpen}
      style={customStyles}
      title='Send Feedback'
    >
      <Form initialValues={values} onChange={setValues}>
        <StyledTextField
          height={300}
          name='feedback'
          placeholder='Please share any feedback with us. Thank you!'
          type='textarea'
        />
        {error && <Error>{error}</Error>}
      </Form>
    </Modal>
  )
}

export default FeedbackForm
