import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'
import { Icons } from '@middesk/components'
import { Action } from '../../types'
import Card from '../System/Card'
import { LargeDisplay, TYPOGRAPHY_SIZES } from '../System/Typography'
import Loader from '../System/Loader'
import Button from '../System/Button'
import SPACING from '../System/Spacing'
import Line from '../System/Line'
import { COLORS } from '../System/Colors'
import { APIError } from '../../lib/api'
import APIAgentActions from '../../lib/api/agentActions'
import { getActionUrl } from '../../lib/helpers'
import { actionIconAndHeaderRow } from '../../pages/Actions'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ActionsContainer = styled.div`
  flex-direction: column;
  max-height: 350px;
  margin-top: ${SPACING.small};
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const WideRow = styled(Row)`
  width: 100%;
`

const ActionsRow = styled.div`
  align-items: center;
  display: flex;
  color: ${COLORS.black};
  font-size: ${TYPOGRAPHY_SIZES.medium};
  padding: ${SPACING.medium} ${SPACING.small};
  width: 100%;

  > *:last-child {
    margin-left: auto;
  }
`

const StyledChevronRight = styled(Icons.ChevronRight)`
  stroke: ${COLORS.black};
`

const ActionsRowComponent = ({
  link,
  children,
  last
}: {
  link: string
  children: any
  last: boolean
}) => (
  <Link to={link}>
    <Row>
      <ActionsRow>
        {children}
        <StyledChevronRight />
      </ActionsRow>
      {!last && <Line />}
    </Row>
  </Link>
)

const ActionsList = ({
  actions,
  companyId
}: {
  actions: Action[]
  companyId: string
}) => {
  return (
    <>
      {actions.map((action: Action, index) => {
        const { type, id } = action

        return (
          <ActionsRowComponent
            key={`${type}-${id}`}
            link={getActionUrl(action, companyId)}
            last={index == actions.length - 1}
          >
            {actionIconAndHeaderRow(action)}
          </ActionsRowComponent>
        )
      })}
    </>
  )
}

const ActionsCard = ({ companyId }: { companyId: string }) => {
  const { push } = useHistory()
  const [loading, setLoading] = useState(true)
  const [actions, setActions] = useState<Action[]>([])

  const viewActions = () => push(`/companies/${companyId}/actions`)

  useEffect(() => {
    const fetchApplicationsAndInfoRequests = async () => {
      APIAgentActions.index(companyId)
        .then(response => setActions(response.actions))
        .catch((e: APIError) => Sentry.captureException(e))
        .finally(() => setLoading(false))
    }

    fetchApplicationsAndInfoRequests()
  }, [])

  if (!loading && actions.length === 0) {
    return <></>
  }

  return (
    <Card variant='primary' size='large'>
      <CardContainer>
        <WideRow>
          <Col xs>
            <LargeDisplay>Actions</LargeDisplay>
          </Col>
          <Col>
            <Button
              {...{
                onClick: viewActions,
                type: 'secondary'
              }}
            >
              View all
            </Button>
          </Col>
        </WideRow>
        <WideRow>
          <Col xs>
            <ActionsContainer>
              <Loader loading={loading} />
              {!loading && (
                <ActionsList actions={actions} companyId={companyId} />
              )}
            </ActionsContainer>
          </Col>
        </WideRow>
      </CardContainer>
    </Card>
  )
}

export default ActionsCard
