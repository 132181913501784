import React from 'react'
import { SelectField } from '@middesk/components'

export type BooleanFieldProps = {
  name: string
  label: string
  sublabel?: string
  value: string | null
}

const Checkbox = ({
  name,
  label,
  sublabel,
  value
}: BooleanFieldProps): JSX.Element => {
  return (
    <SelectField name={name} label={label} sublabel={sublabel} showErrorMessage>
      <option
        key={name + '-true'}
        value='true'
        label='Yes'
        selected={value === 'true'}
      />
      <option
        key={name + '-false'}
        value='false'
        label='No'
        selected={value === 'false'}
      />
    </SelectField>
  )
}

export default Checkbox
