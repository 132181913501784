import React, { useEffect, useState } from 'react'

import { encode } from 'base64-arraybuffer'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { Dropzone, DropzoneFile, theme } from '@middesk/components'

import Link from '../components/System/Link'
import { BASE_64_IDENTIFIER } from '../lib/helpers'
import { FormValues } from '../types'
import { isString } from 'lodash'

const { typography, colors, spacing } = theme

const PROCESSING_DURATION = 500

const FileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
  margin-bottom: ${spacing.xsmall};
`

const IntakeDocumentUploader = ({
  label,
  valueKey,
  initialDocument
}: {
  label?: string
  valueKey: string
  initialDocument: string
}): JSX.Element => {
  const { setFieldValue } = useFormikContext<FormValues>()

  const [document, setDocument] = useState<DropzoneFile[]>([])
  const [documentValue, setDocumentValue] = useState<string>(initialDocument)

  useEffect(() => {
    setFieldValue(valueKey, documentValue)
  }, [valueKey, documentValue])

  const handleDrop = (data: DropzoneFile[]) => {
    setDocument([data[0]])
    isString(data[0].data)
      ? setDocumentValue(`${BASE_64_IDENTIFIER}${data[0].data}`)
      : setDocumentValue(`${BASE_64_IDENTIFIER}${encode(data[0].data)}`)
  }

  const onDeleteFile = () => {
    setDocument([])
    setDocumentValue('')
  }

  const uploadedDocument = () => {
    const fileLabel = document[0] ? (
      <span>
        : <b>{document[0].name}</b>
      </span>
    ) : (
      ''
    )

    return (
      <>
        {!fileLabel && <Label>{label}</Label>}
        <FileWrapper>
          <div>Document uploaded{fileLabel}</div>
          <Link {...{ onClick: onDeleteFile }}>Delete</Link>
        </FileWrapper>
      </>
    )
  }

  return (
    <div>
      {documentValue && uploadedDocument()}
      {!documentValue && (
        <Dropzone
          {...{
            label: label || 'Upload document',
            name: 'document',
            onDrop: (
              data: DropzoneFile[],
              setProcessing: (e: boolean) => void
            ) => {
              handleDrop(data)

              setTimeout(() => {
                setProcessing(false)
              }, PROCESSING_DURATION)
            },
            activeText: 'Add document',
            inactiveText: 'Add document here',
            loadingText: 'Processing document'
          }}
        />
      )}
    </div>
  )
}

export default IntakeDocumentUploader
