export const isSegmentEnabled = process.env.NODE_ENV === 'production'

export const EVENTS = Object.freeze({
  agentAccountCreated: 'agent-account-created'
})

export const track = (...args: any) => {
  if (!isSegmentEnabled) return

  window.analytics.track(...args)
}

export const identify = (userId: string, ...args: any) => {
  if (!isSegmentEnabled) return

  window.analytics.identify(userId, args)
}

export const page = () => {
  if (!isSegmentEnabled) return

  window.analytics.page()
}
