import React, { useContext } from 'react'
import { TransferIntakeSidebarProps } from '../../types'
import Sidebar from '../System/Sidebar'
import IntakeSidebarContent from '../IntakeSidebarContent'
import Link from '../System/Link'
import { AuthContext } from '../../contexts/AuthProvider'

const GROUPS = [
  { title: 'State transfers', page: 0 },
  { title: 'Contact information', page: 1 },
  { title: 'Business information', page: 2 },
  { title: 'Signatory information', page: 3 },
  { title: 'Account information', page: 4 },
  { title: 'Review & submit', page: 5 }
]
const title = 'Transfer existing accounts'
const content = (
  <span>
    In order to transfer your state accounts, you&apos;ll need to complete the
    following steps. If you have questions, feel free to contact us at{' '}
    <Link target='_blank' href='mailto:agent@middesk.com'>
      agent@middesk.com
    </Link>
    .
  </span>
)
const guestModeContent = (
  <span>
    In order to transfer your state accounts, we need the following information
    about your business.
  </span>
)

const TransfersIntakeSidebar = ({
  className,
  currentPage,
  logo,
  fetchingApplication,
  companyId
}: TransferIntakeSidebarProps): JSX.Element => {
  const { inGuestMode } = useContext(AuthContext)

  return (
    <Sidebar {...{ className, logo, fetchingApplication, companyId }}>
      <IntakeSidebarContent
        groups={GROUPS}
        current={currentPage - 1}
        {...{ title, content: inGuestMode ? guestModeContent : content }}
      />
    </Sidebar>
  )
}

export default TransfersIntakeSidebar
