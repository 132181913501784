import React, { useContext } from 'react'
import { get } from 'lodash'
import { TransfersIntakeComponentProps } from '../../../types'
import TransferIntakeCard from '../TransfersIntakeCard'
import Section from '../../Section'
import AttributeColumn from '../../AttributeColumn'
import { Attribute } from '@middesk/components'
import { useFormikContext } from 'formik'
import { AuthContext } from '../../../contexts/AuthProvider'
import { PackageTypes } from '../../../lib/constants'
import { TransfersIntakeFormValues } from '../../../types'
import StatesToTransferList from './StatesToTransferList'
import { formatAddress } from '../../../lib/helpers'
import styled from 'styled-components'
import PriceSection from './PriceSection'
import { COLORS } from '../../System/Colors'
import Loader from '../../System/Loader'
import { AccountContext } from '../../../contexts/AccountProvider'

const LastSection = styled(Section)`
  border-bottom: none;
  margin-bottom: 0px;
`

const PriceSectionContainer = styled(Section)`
  border-bottom: none;
  border-top: 2px solid ${COLORS.dawn};
  margin-bottom: 0px;
  padding-top: 10px;
`

const LimitedWidthImg = styled.img`
  width: inherit;
`

const ErrorMessage = styled.div`
  color: ${COLORS.magenta};
`

const ReviewAndSubmit = ({
  onNext,
  onBack,
  isSubmitButton,
  isSubmitting,
  error
}: TransfersIntakeComponentProps): JSX.Element => {
  const { values } = useFormikContext<TransfersIntakeFormValues>()
  const { user, account } = useContext(AuthContext)
  const { fetchingExistingSubscription } = useContext(AccountContext)
  const selectedPackage = get(
    user,
    'account.settings.agent.package_type',
    'basic'
  )
  const skipPaymentSetting = get(account, 'settings.agent.skip_payment', false)

  return (
    <Loader {...{ loading: fetchingExistingSubscription }}>
      <TransferIntakeCard
        {...{
          isSubmitButton,
          isSubmitting,
          onNext: async values => {
            onNext(values, isSubmitButton)
          },
          onBack
        }}
      >
        <Section title='Contact information'>
          <AttributeColumn>
            <Attribute label='Name'>{values.contact_name}</Attribute>
            <Attribute label='Phone Number'>
              {values.contact_phone_number}
            </Attribute>
          </AttributeColumn>
          <AttributeColumn>
            <Attribute label='Email'>{values.contact_email}</Attribute>
          </AttributeColumn>
        </Section>
        <Section title='Business information'>
          <AttributeColumn>
            <Attribute label='Legal Business Name'>
              {values.legal_name}
            </Attribute>
            <Attribute label='Primary Business Address'>
              {formatAddress(values.primary_address, '\n')}
            </Attribute>
            <Attribute label='Entity Type'>
              {values.entity_type.split('_').join(' ')}
            </Attribute>
          </AttributeColumn>
          <AttributeColumn>
            <Attribute label='Business Name registered with Secretary of State'>
              {values.sos_business_name}
            </Attribute>
            <Attribute label='Primary Mailing Address'>
              {formatAddress(values.mailing_address, '\n')}
            </Attribute>
            <Attribute label='State of Formation'>
              {values.formation_state}
            </Attribute>
            <Attribute label='Date of Formation'>
              {values.formation_date}
            </Attribute>
          </AttributeColumn>
        </Section>
        <Section title='Signatory information'>
          <AttributeColumn>
            <Attribute label='Legal Name of Person'>
              {values.signatory.name}
            </Attribute>
            <Attribute label='Job Title'>{values.signatory.title}</Attribute>
          </AttributeColumn>
          <AttributeColumn>
            <Attribute label='Officer Email'>
              {values.signatory.email}
            </Attribute>
            <Attribute label='Signature'>
              <LimitedWidthImg src={values.signatory.signature} />
            </Attribute>
          </AttributeColumn>
        </Section>
        <LastSection title='Accounts to transfer'>
          <StatesToTransferList />
        </LastSection>
        {!skipPaymentSetting && selectedPackage !== PackageTypes.unlimited && (
          <PriceSectionContainer>
            <PriceSection />
          </PriceSectionContainer>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </TransferIntakeCard>
    </Loader>
  )
}

export default ReviewAndSubmit
