import React, { useContext } from 'react'
import NewRegistrationIntake from './NewRegistrationIntake'
import GuestAccessDenied from './GuestAccessDenied'
import { AuthContext } from '../contexts/AuthProvider'

const GuestNewRegistrationIntake = () => {
  const { fetching, user } = useContext(AuthContext)

  return (
    <>
      {!fetching && !user ? <GuestAccessDenied /> : <NewRegistrationIntake />}
    </>
  )
}

export default GuestNewRegistrationIntake
