import React, { useEffect } from 'react'
import { Col } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import api from '../../lib/api'

const Icon = styled.img`
  display: block;
  justify: center;
  height: 60px;
  width: 60px;
  margin: 1rem auto;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

type Props = {
  sos_registration_selection: string
  applicationId: string
  errorAction: () => void
}

const Complete = ({
  sos_registration_selection,
  applicationId,
  errorAction
}: Props): JSX.Element => {
  useEffect(() => {
    api
      .post(
        `/v1/agent/applications/${applicationId}/sos_registration_selection`,
        {
          sos_registration_selection
        }
      )
      .catch((e: any) => {
        /* eslint-disable-next-line no-console */
        console.error(`error saving sos_registration_selection: ${e}`)
        errorAction()
      })
  }, [])

  return (
    <div>
      <Icon src='/icons/order_submitted.svg' />
      <StyledCol>Thank you for submitting your preference!</StyledCol>
      {sos_registration_selection === 'middesk' && (
        <StyledCol>
          We&apos;ll get started on submitting your Secretary of State
          registration.
        </StyledCol>
      )}
      {sos_registration_selection === 'self_register' && (
        <>
          <StyledCol>
            We&apos;ll send you weekly reminder emails to confirm when
            you&apos;re foreign qualified.
          </StyledCol>
          <StyledCol>
            Your payroll tax registration will be on hold until you confirm your
            Secretary of State registration.
          </StyledCol>
        </>
      )}
    </div>
  )
}

export default Complete
