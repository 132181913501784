import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import SPACING from '../../System/Spacing'
import GreenCheck from '../../System/Icons/GreenCheck'
import { Icons, Tooltip } from '@middesk/components'
import { StateRowProps } from '../../../types'
import GreyX from '../../System/Icons/GreyX'

const { Delete } = Icons
const DELETE_ICON_SIZE = 22

const StyledDelete = styled(Delete)`
  cursor: pointer;
`

const StyledRow = styled(Row)`
  margin: ${SPACING.medium} 0;
`

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: ${SPACING.large}; // Margin for the Tooltip width
`

const StyledStateOverride = styled(Col)`
  text-align: left;
`

const StateRow = ({
  state,
  onDelete,
  foreignQualificationAction
}: StateRowProps): JSX.Element => {
  return (
    <StyledRow center='xs'>
      <StyledStateOverride xs>
        <b>{state.name}</b>
      </StyledStateOverride>
      <Col xs={3}>
        <StyledIcon>
          <GreenCheck />
        </StyledIcon>
      </Col>
      <Col xs={4}>
        {foreignQualificationAction == 'transfer' ||
        foreignQualificationAction == 'register' ? (
          <StyledIcon>
            <GreenCheck />
          </StyledIcon>
        ) : (
          <StyledIcon>
            <Tooltip content=''>
              <GreyX />
            </Tooltip>
          </StyledIcon>
        )}
      </Col>
      <Col xs>
        <StyledDelete
          height={DELETE_ICON_SIZE}
          width={DELETE_ICON_SIZE}
          onClick={() => onDelete(state.abbr)}
        />
      </Col>
    </StyledRow>
  )
}

export default StateRow
