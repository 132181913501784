import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AgentTransfer from '../../lib/api/agentTransfer'
import { Col, Row } from 'react-styled-flexboxgrid'
import LoaderButton from '../LoaderButton'
import Card from '../System/Card'
import IconBubble from '../System/IconBubble'
import {
  AgentApplication as AgentApplicationAttributes,
  TransferAccountsCardProps
} from '../../types'
import styled from 'styled-components'
import { Body, LargeDisplay } from '../System/Typography'
import SPACING from '../System/Spacing'
import APIAgentTransfer from '../../lib/api/agentTransfer'

const TransferAccountsCard = styled(
  ({ className, companyId }: TransferAccountsCardProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true)
    const [transfer, setTransfer] = useState<AgentApplicationAttributes | null>(
      null
    )

    const { push } = useHistory()

    const getTransfer = async () => {
      const {
        data: inProgressTransfers
      } = await APIAgentTransfer.index(companyId, { status: 'in_progress' })

      if (inProgressTransfers[0]) {
        setTransfer(inProgressTransfers[0])
      }

      setIsLoading(false)
    }

    useEffect(() => {
      getTransfer()
    }, [])

    const navigateToTransfer = (id: string) => {
      push(`/transfers/${id}?page=1`)
    }

    const createTransfer = () => {
      AgentTransfer.create(companyId)
        .then((e: AgentApplicationAttributes) => {
          setTransfer(e)
          navigateToTransfer(e.id)
        })
        /* eslint-disable-next-line no-console */
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false))
    }

    const handleTransferCreate = () => {
      setIsLoading(true)

      if (transfer) {
        navigateToTransfer(transfer.id)
      } else {
        createTransfer()
      }
    }

    return (
      <Card {...{ className, variant: 'primary', size: 'large' }}>
        <Row start='xs' top='xs' between='xs'>
          <Col xs>
            <LargeDisplay>Transfer existing accounts</LargeDisplay>
            <Body>
              Let Middesk manage all of your state accounts, regardless of who
              set them up.
            </Body>
            <LoaderButton
              {...{
                submitting: isLoading,
                submitText: transfer ? 'Continue transfer' : 'Transfer now',
                onClick: handleTransferCreate
              }}
            />
          </Col>
          <Col>
            <img src='/icons/transfer.png' width={100} />
          </Col>
        </Row>
      </Card>
    )
  }
)`
  ${LargeDisplay} {
    margin-bottom: ${SPACING.small};
  }

  ${Body} {
    margin-bottom: ${SPACING.medium};
    max-width: 90%;
  }

  ${IconBubble} {
    cursor: default;
  }
`

export default TransferAccountsCard
