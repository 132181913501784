import React from 'react'
import DropdownBanner from './DropdownBanner'
import { StyledStockTable } from '../CompanyStockTable'
import { Field } from 'formik'
import { InnerFormColumn } from './index'
import { useFormikContext } from 'formik'
import { ConfirmCompany } from '../../types'
import * as yup from 'yup'
import { shareSchema } from '../../lib/stockTableValidator'

const StockTable = () => {
  const { values } = useFormikContext<ConfirmCompany>()

  return (
    <DropdownBanner
      valid={stockTableSchema.isValidSync(values)}
      title={'Stock table'}
    >
      <InnerFormColumn>
        <Field name='stock_table' component={StyledStockTable} />
      </InnerFormColumn>
    </DropdownBanner>
  )
}

const authorizedSharesSchema = yup
  .array()
  .of(shareSchema)
  .min(1, 'Missing Authorized Shares')
  .required()
const issuedSharesSchema = yup.array().of(shareSchema).optional()

export const stockTableSchema = yup.object().shape({
  stock_table: yup.object().shape({
    value: yup.object().shape({
      authorized_shares: authorizedSharesSchema,
      issued_shares: issuedSharesSchema
    })
  })
})

export default StockTable
