import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'
import { COLORS } from '../Colors'
import SPACING from '../Spacing'

const Document = styled(({ className }: ContainerProps) => {
  return (
    <svg
      width={SPACING.xlarge}
      height={SPACING.large}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M11.7 3.59985H23.7337C24.4901 3.59985 25.2117 3.91709 25.7231 4.47433L33.4894 12.9376C33.9464 13.4356 34.2 14.0871 34.2 14.7631V33.2999C34.2 34.791 32.9911 35.9999 31.5 35.9999H11.7C10.2088 35.9999 9 34.791 9 33.2999V6.29985C9 4.80868 10.2088 3.59985 11.7 3.59985Z'
        fill={COLORS.white}
      />
      <path
        d='M11.7 19.8C11.203 19.8 10.8 19.397 10.8 18.9C10.8 18.403 11.203 18 11.7 18H24.3C24.797 18 25.2 18.403 25.2 18.9C25.2 19.397 24.797 19.8 24.3 19.8H11.7Z'
        fill={COLORS.graphite}
      />
      <path
        d='M11.7 25.2001C11.203 25.2001 10.8 24.7971 10.8 24.3001C10.8 23.8032 11.203 23.4001 11.7 23.4001H24.3C24.797 23.4001 25.2 23.8032 25.2 24.3001C25.2 24.7971 24.797 25.2001 24.3 25.2001H11.7Z'
        fill={COLORS.graphite}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1337 1.80005H8.10002C6.60885 1.80005 5.40002 3.00887 5.40002 4.50005V31.5C5.40002 32.9912 6.60885 34.2 8.10002 34.2H27.9C29.3911 34.2 30.6 32.9912 30.6 31.5V12.9633C30.6 12.2873 30.3464 11.6358 29.8894 11.1377L22.1231 2.67452C21.6117 2.11728 20.8901 1.80005 20.1337 1.80005ZM7.20002 4.50005C7.20002 4.003 7.60297 3.60005 8.10002 3.60005H20.1337C20.3859 3.60005 20.6264 3.7058 20.7969 3.89154L28.5631 12.3548C28.7154 12.5208 28.8 12.7379 28.8 12.9633V31.5C28.8 31.997 28.397 32.4 27.9 32.4H8.10002C7.60297 32.4 7.20002 31.997 7.20002 31.5V4.50005Z'
        fill={COLORS.graphite}
      />
      <path
        d='M19.8 12.6H29.7C30.197 12.6 30.6 13.003 30.6 13.5C30.6 13.9971 30.197 14.4 29.7 14.4H18.9C18.403 14.4 18 13.9971 18 13.5V2.70005C18 2.203 18.403 1.80005 18.9 1.80005C19.397 1.80005 19.8 2.203 19.8 2.70005V12.6Z'
        fill={COLORS.graphite}
      />
    </svg>
  )
})``

export default Document
