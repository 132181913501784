import { GetAnnualFilingsParameters, AnnualFilingCollection } from '../../types'
import api from '../api'

const APIAnnualFiling = {
  index: (
    companyId: string,
    params?: GetAnnualFilingsParameters
  ): Promise<AnnualFilingCollection> =>
    api.get(`/v1/agent/companies/${companyId}/annual_filings`, params)
}

export default APIAnnualFiling
