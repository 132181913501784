import React from 'react'
import { theme } from '@middesk/components'
import styled from 'styled-components'
import { COLORS } from './System/Colors'

const { spacing } = theme

type SeparatorProps = {
  className?: string
  customMargin?: string
}

const Separator = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, customMargin }: SeparatorProps): JSX.Element => {
    return <div {...{ className, margin: customMargin }} />
  }
)`
  background: ${COLORS.dawn};
  display: flex;
  flex-direction: row;
  height: 1px;
  margin: ${props =>
      props.customMargin != undefined ? props.customMargin : spacing.normal}
    0;
`

export default Separator
