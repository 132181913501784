import React from 'react'
import { ContainerProps } from '../../../types'

const Pencil = ({ className }: ContainerProps): JSX.Element => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6201 1.74654C12.4248 1.55128 12.1082 1.55128 11.913 1.74654L3.98105 9.67846C3.8911 9.76841 3.81913 9.87471 3.76902 9.99164L2.20695 13.6365C2.12641 13.8244 2.1684 14.0424 2.31297 14.187C2.45753 14.3316 2.67556 14.3735 2.86348 14.293L6.50831 12.7309C6.62524 12.6808 6.73154 12.6089 6.82149 12.5189L14.7534 4.58698C14.8472 4.49322 14.8999 4.36604 14.8999 4.23343C14.8999 4.10082 14.8472 3.97365 14.7534 3.87988L12.6201 1.74654ZM4.68816 10.3856L12.2665 2.8072L13.6927 4.23343L6.11439 11.8118L4.52574 12.4926L4.00731 11.9742L4.68816 10.3856Z'
        fill='#5F6874'
      />
    </svg>
  )
}

export default Pencil
