import React from 'react'
import styled from 'styled-components'

import { theme } from '@middesk/components'

import CloseX from './Icons/CloseX'
import api from '../../lib/api'

const { colors, typography } = theme

const SANDBOX_PADDING = 70
const IMPERSONATION_PADDING = 70

export const bannerPadStyle = (
  sandbox: boolean,
  impersonator: boolean
): { [key: string]: string } => {
  if (!sandbox && !impersonator) return {}

  let totalPad = 0
  if (sandbox) {
    totalPad += SANDBOX_PADDING
  }
  if (impersonator) {
    totalPad += IMPERSONATION_PADDING
  }
  return { paddingTop: `${totalPad}px` }
}

const SANDBOX_GUIDE =
  'https://docs.middesk.com/docs/entity-management-sandbox-environment'

const SandboxBannerContainer = styled.div`
  position: fixed;
  z-index: 2;
  padding: 15px;
  width: 100%;
  height: 55px;
  background-color: ${colors.yellowLight};
  border-bottom: 1px solid ${colors.frost};
`

const SandboxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.black};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};

  span {
    font-weight: ${typography.weights.normal};
  }

  a {
    color: ${colors.black};
    cursor: pointer;
    font-weight: ${typography.weights.normal};
    text-decoration: underline;
  }
`

const ButtonWrapper = styled.div`
  margin-right: 20px;

  svg {
    height: 20px;
    width: 20px;
    stroke: ${colors.graphite};
  }
`

const SandboxBanner = (): JSX.Element => {
  const onClick = () => {
    api.setSandboxMode(false)
    window.location.href = '/home'
  }

  if (!api.sandboxMode()) {
    return <></>
  }

  return (
    <SandboxBannerContainer>
      <SandboxContent>
        <div>
          Sandbox&nbsp;&nbsp;<span>To learn more</span>{' '}
          <a href={SANDBOX_GUIDE} target='_blank' rel='noreferrer'>
            view our docs
          </a>
        </div>
        <ButtonWrapper {...{ onClick }}>
          <CloseX />
        </ButtonWrapper>
      </SandboxContent>
    </SandboxBannerContainer>
  )
}

export default SandboxBanner
