import { theme } from '@middesk/components'
import styled from 'styled-components'
import { EnabledProps } from '../types'

const { spacing, colors } = theme

const Circle = styled.div<EnabledProps>`
  align-items: center;
  background-color: ${props => (props.enabled ? colors.indigo : colors.dawn)};
  border-radius: 50%;
  display: inline-flex;
  height: ${spacing.normal};
  justify-content: center;
  width: ${spacing.normal};
`

export default Circle
