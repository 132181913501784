import React, { useEffect } from 'react'
import { InformationCircleOutline } from 'react-ionicons'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { COLORS } from './System/Colors'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tooltip = styled(({ className, text, children, url }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const icon = <InformationCircleOutline height='18px' width='18px' />

  return (
    <div className={className}>
      <div
        data-multiline={true}
        data-tip={text}
        data-place='bottom'
        data-effect='solid'
      >
        {url ? (
          <a href={url} target='_blank' rel='noreferrer'>
            {icon}
          </a>
        ) : (
          icon
        )}
      </div>

      {children}
    </div>
  )
})`
  &&& {
    position: relative;
    > div:first-child {
      position: absolute;
      right: ${props =>
        ['multiselect', 'select', 'boolean', 'number'].includes(props.inputType)
          ? '28px'
          : '10px'};
      ${props =>
        props.inputType === 'address' ? 'top: 28px' : 'bottom: 12px'};
      z-index: 2;

      > span {
        > svg {
          color: ${COLORS.karl};
          fill: ${COLORS.karl};
        }

        > svg:hover {
          color: ${COLORS.graphite};
          fill: ${COLORS.graphite};
        }
      }
    }
  }
`

export default Tooltip
