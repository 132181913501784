import { useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import {
  Department,
  ItemInfo,
  ItemValidation,
  TransferQuestion,
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import { StateDataContext } from '../../../contexts/StateDataProvider'
import TransferIntakeCard from '../TransfersIntakeCard'
import { TextField, SelectField, Loader } from '@middesk/components'
import styled from 'styled-components'
import { COLORS } from '../../System/Colors'
import { TYPOGRAPHY_WEIGHTS } from '../../System/Typography'
import SPACING from '../../System/Spacing'
import { OnlinePortalCredentials } from '.'
import Address from '../../Address'
import {
  Georgia,
  Hawaii,
  Indiana,
  Maine,
  Massachusetts,
  Minnesota,
  NorthCarolina,
  Oklahoma,
  Pennsylvania,
  Tennessee,
  Utah,
  WestVirginia
} from './OutlierStates'
import ReactTooltip from 'react-tooltip'
import Tooltip from '../../../components/ToolTip'
import { StyledColumn, StyledSection } from './styles'
import { ApplicationInformationFootnote } from './AccountInformationFootnote'
import { DEFAULT_TRANSFER_TR_ACTION } from '../../../lib/constants'
import { saveTransfersApplication } from '../TransfersIntake'
import { useParams } from 'react-router'
import { isArray } from 'lodash'

const OUTLIER_DESCRIPTION =
  'Please complete the following to transfer your account.'

export type DepartmentElement = {
  name: string
  children: Array<JSX.Element> | JSX.Element
  renderPayrollCallout?: boolean
  renderMailingAddressCallout?: boolean
  renderCompleteLater?: boolean
  renderUnableToChangeMailing?: boolean
  link?: string
}

const StyledDepartmentHeader = styled.a`
  color: ${COLORS.indigo};
  cursor: pointer;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  text-decoration: none;
`

const OUTLIER_STATE_ABBREVIATIONS_MAPPING: Record<
  string,
  (e?: Array<DepartmentElement>) => Array<DepartmentElement>
> = {
  HI: Hawaii,
  MA: Massachusetts,
  TN: Tennessee,
  GA: e => Georgia(e),
  IN: e => Indiana(e),
  ME: e => Maine(e),
  MN: e => Minnesota(e),
  NC: e => NorthCarolina(e),
  OK: e => Oklahoma(e),
  PA: e => Pennsylvania(e),
  UT: e => Utah(e),
  WV: e => WestVirginia(e)
}

const EndingLine = styled.div`
  background: ${COLORS.dawn};
  display: flex;
  flex-direction: row;
  height: 1px;
  margin: ${SPACING.small} 0 ${SPACING.medium} 0;
`

const DepartmentUnderline = styled.div`
  background: ${COLORS.dawn};
  display: flex;
  flex-direction: row;
  height: 2px;
  margin: ${SPACING.small} 0 ${SPACING.medium} 0;
`

const getEndingLine = (ind: number, deptLength: number) => {
  if (ind < deptLength - 1) {
    return <EndingLine />
  } else {
    return <></>
  }
}

const CUSTOM_PAYROLL_CALLOUT = ['HI', 'MA']

const CompleteLater = () => (
  <StyledSection>
    <StyledColumn>
      <div>
        <b>Want to complete this later?</b> We&apos;ll send you an email with
        these instructions after you submit your order.
      </div>
    </StyledColumn>
  </StyledSection>
)

const UnableToChangeMailing = () => (
  <StyledSection>
    <div>
      You&apos;ve selected a state where Middesk is unable to change your
      mailing address for you.{' '}
      <b>
        Please follow the instructions below to log in to your payroll tax
        account portal and change the mailing address on file.
      </b>{' '}
      <br />
      <br />
      Middesk will manage your account and ensure you stay in compliance with
      the state by receiving and responding to your mail. When the state
      requires a response from you, we&apos;ll let you know.
    </div>
  </StyledSection>
)

const MailingAddressCallout = () => (
  <StyledColumn>
    <div>
      You&apos;ve selected a state where Middesk is unable to change your
      mailing address for you.{' '}
      <b>
        Please follow the instructions below to log in to your payroll tax
        account portal and change the mailing address on file.
      </b>
    </div>
    <div>
      Middesk will manage your account and ensure you stay in compliance with
      the state by receiving and responding to your mail. When the state
      requires a response from you, we&apos;ll let you know.
    </div>
  </StyledColumn>
)

const validateTextField = (value: string) => {
  return !value ? 'Required' : ''
}

const validateFormattedField = (
  value: string,
  itemValidation: ItemValidation,
  ein: string
) => {
  if (!value) return 'Required'
  if (itemValidation.format) {
    const regExps = [itemValidation.format].flat().map((format: string) =>
      format
        .split('')
        .map((c: string) => (c === 'X' ? '[A-Za-z0-9]' : c))
        .join('')
    )
    if (
      !regExps.some((exp: string) => {
        const match = value.match(exp)
        return match && match[0] == value
      })
    )
      return `Value must be of format ${[itemValidation.format]
        .flat()
        .join(' or ')}`
  }
  if (itemValidation.includes_ein && !value.includes(ein)) {
    return 'Value must include the company ein'
  }
  if (itemValidation.is_ein && value != ein) {
    return 'Value must be the company ein'
  }

  return ''
}

const AccountInformation = ({
  onNext,
  onBack,
  setTitle,
  setDescription
}: TransfersIntakeComponentProps): JSX.Element => {
  const {
    values,
    validateForm,
    errors
  } = useFormikContext<TransfersIntakeFormValues>()
  const { transferDepartmentsMap, fetching, stateDataMap } = useContext(
    StateDataContext
  )
  const validRegisteredStates = values?.registered_states
  const [currentStatePage, setCurrentStatePage] = useState(1)
  const [stateDepartments, setStateDepartments] = useState<
    Array<DepartmentElement>
  >([])
  const { id } = useParams<{ id: string }>()

  const currentState = validRegisteredStates[currentStatePage - 1]
  const isOutlier = Object.keys(OUTLIER_STATE_ABBREVIATIONS_MAPPING).includes(
    currentState?.abbr
  )
  const currentItemInfo =
    stateDataMap[currentState?.abbr]?.tax_registration.items

  useEffect(() => {
    validateForm()
  }, [values, stateDepartments])

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [stateDepartments])

  useEffect(() => {
    if (!currentState) {
      return
    }

    if (fetching) {
      return
    }

    const currentStateName = isOutlier
      ? `Transfer your ${currentState.name} account`
      : `${currentState.name} account information` || ''

    setTitle && setTitle(currentStateName)
    setDescription && isOutlier && setDescription(OUTLIER_DESCRIPTION)

    let tempStateDepartments: Array<DepartmentElement> = []

    const generateInputs = (stateDepartments: Array<DepartmentElement>) => {
      transferDepartmentsMap[currentState?.abbr].forEach((dept: Department) => {
        if (dept && dept.inputs) {
          const deptObj: DepartmentElement = {
            name: dept.name,
            children: [],
            renderMailingAddressCallout: false,
            renderPayrollCallout: false,
            renderCompleteLater: false,
            renderUnableToChangeMailing: false
          }

          if (
            dept?.name === 'Secretary of State' &&
            values.registered_states.find(
              registeredState => (registeredState.abbr = currentState.abbr)
            )?.foreignQualificationAction !== 'transfer'
          ) {
            return
          }

          dept.inputs.forEach((deptInput: TransferQuestion) => {
            // Nest the formik values by state abbreviation
            const key = `${currentState.abbr}.${deptInput.key}`
            const itemValidation = currentItemInfo?.find(
              (item: ItemInfo) => item.key == deptInput.key
            )?.validation

            const deptObjChildren = deptObj.children as Array<JSX.Element>

            if (deptInput.transfer_category === 'account') {
              deptObjChildren.push(
                <OnlinePortalCredentials
                  key={key}
                  inputKey={key}
                  label={deptInput.label}
                  validate={validateTextField}
                />
              )
            } else if (
              deptInput.transfer_category === 'item' ||
              deptInput.transfer_category === 'question'
            ) {
              const label = deptInput.label
              const validation = deptInput?.validation
              const preText =
                deptInput.transfer_category === 'question'
                  ? `Enter the ${label.toLowerCase()}`
                  : validation?.format
                  ? `${validation?.format}`
                  : deptInput.label

              const placeholder = validation?.max_length
                ? `${preText} (${validation?.max_length} digits)`
                : preText

              let input = <></>

              if (deptInput?.type === 'address') {
                input = (
                  <Address
                    key={key}
                    name={key}
                    label={label}
                    placeholder={placeholder}
                    validate={validateTextField}
                  />
                )
              } else if (deptInput?.type === 'select') {
                input = (
                  <SelectField
                    {...{
                      key,
                      name: key,
                      label,
                      placeholder,
                      validate: validateTextField
                    }}
                  >
                    {(deptInput?.options || []).map(option => (
                      <option
                        key={option}
                        {...{ label: option, value: option }}
                      />
                    ))}
                  </SelectField>
                )
              } else if (itemValidation?.format) {
                input = (
                  <TextField
                    key={key}
                    name={key}
                    label={label}
                    placeholder={
                      isArray(itemValidation.format)
                        ? itemValidation.format.join(' or ')
                        : itemValidation.format
                    }
                    validate={(value: string) =>
                      validateFormattedField(value, itemValidation, values.ein)
                    }
                    showErrorMessage
                  ></TextField>
                )
              } else {
                input = (
                  <TextField
                    key={key}
                    name={key}
                    label={label}
                    placeholder={placeholder}
                    validate={validateTextField}
                  />
                )
              }

              if (deptInput.tooltip) {
                deptObjChildren.push(
                  <Tooltip text={deptInput.tooltip} inputType={deptInput.type}>
                    {input}
                  </Tooltip>
                )
              } else {
                deptObjChildren.push(input)
              }
            }
          })

          stateDepartments = [...stateDepartments, deptObj]
        }
      })

      return stateDepartments
    }

    if (isOutlier) {
      tempStateDepartments = tempStateDepartments.concat(
        OUTLIER_STATE_ABBREVIATIONS_MAPPING[currentState.abbr](
          currentState.abbr in transferDepartmentsMap
            ? generateInputs(tempStateDepartments)
            : []
        )
      )
    } else {
      tempStateDepartments = tempStateDepartments.concat(generateInputs([]))
    }

    setStateDepartments(tempStateDepartments)
  }, [currentStatePage, fetching])

  const stateCount = validRegisteredStates.length

  const onNextWrapped = (values: TransfersIntakeFormValues) => {
    if (currentStatePage + 1 > stateCount) {
      onNext(values)
    } else {
      saveTransfersApplication(values, transferDepartmentsMap, id)
      setCurrentStatePage(currentStatePage + 1)
    }
  }

  const onBackWrapper = (values: TransfersIntakeFormValues) => {
    if (currentStatePage - 1 <= 0) {
      onBack(values)
    } else {
      saveTransfersApplication(values, transferDepartmentsMap, id)
      setCurrentStatePage(currentStatePage - 1)
    }
  }

  // Disable Continue if the state is not a static callout and
  // there are validation errors or this state doesn't exist in Formik values
  let shouldDisableContinue =
    !CUSTOM_PAYROLL_CALLOUT.includes(currentState?.abbr) &&
    (Object.keys(errors).length > 0 ||
      !values.registered_states.find(
        state => state.abbr === currentState?.abbr
      ))

  // Override disabled button if a radio button is selected for the state or
  // no states exist
  if (
    validRegisteredStates?.length === 0 ||
    (currentState.taxRegistrationAction &&
      currentState.taxRegistrationAction !== DEFAULT_TRANSFER_TR_ACTION)
  )
    shouldDisableContinue = false
  if (fetching) {
    return <Loader />
  }

  return (
    <TransferIntakeCard
      onNext={onNextWrapped}
      onBack={onBackWrapper}
      {...{ currentStatePage, stateCount, shouldDisableContinue }}
    >
      <ReactTooltip
        textColor={COLORS.white}
        backgroundColor={COLORS.graphite}
        border
        borderColor={COLORS.dawn}
      />
      {validRegisteredStates?.length > 0 ? (
        <>
          {stateDepartments.map((dept: DepartmentElement, index) => {
            if (
              (dept.children as Array<JSX.Element>).length === 0 &&
              dept.name.length === 0
            ) {
              return (
                <>
                  {dept.renderMailingAddressCallout && (
                    <MailingAddressCallout />
                  )}
                  {dept.renderCompleteLater && <CompleteLater />}
                </>
              )
            } else if (dept?.children) {
              return (
                <>
                  <StyledDepartmentHeader href={dept.link} target='_blank'>
                    {dept.name}
                  </StyledDepartmentHeader>
                  <DepartmentUnderline />
                  {dept.children}
                  {dept.renderCompleteLater && <CompleteLater />}
                  {dept.renderUnableToChangeMailing && (
                    <UnableToChangeMailing />
                  )}
                  {getEndingLine(index, stateDepartments.length)}
                </>
              )
            } else {
              return (
                <>
                  <StyledDepartmentHeader href={dept.link} target='_blank'>
                    {dept.name}
                  </StyledDepartmentHeader>
                  <DepartmentUnderline />
                  <div>No additional information required!</div>
                  {getEndingLine(index, stateDepartments.length)}
                </>
              )
            }
          })}
          <ApplicationInformationFootnote
            {...{ values, currentState, stateDepartments }}
          />
        </>
      ) : (
        <>
          No additional account information required for the selected state(s).
          Continue to Review and Submit.
        </>
      )}
    </TransferIntakeCard>
  )
}

export default AccountInformation
