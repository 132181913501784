import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import Card from '../components/System/Card'
import Link from '../components/System/Link'
import SPACING from '../components/System/Spacing'
import {
  XLarge,
  Body as Medium,
  TYPOGRAPHY_WEIGHTS
} from '../components/System/Typography'
import API from '../lib/api'
import { ContainerProps, SignupToConfirmEmailState } from '../types'
import { SignupHeader } from './SignupNew'

const ConfirmEmailNew = styled(({ className }: ContainerProps) => {
  const { state }: { state: SignupToConfirmEmailState } = useLocation()

  if (!state) {
    return null
  }

  const [emailResent, setEmailResent] = useState(false)

  const resendEmail = () => {
    API.post('/confirm-email', {
      email: state.email,
      agent: true
    })
      .then(() => {
        setEmailResent(true)
      })
      /* eslint-disable-next-line no-console */
      .catch(console.error)
  }

  return (
    <div className={className}>
      <SignupHeader />
      <div>
        <XLarge bold>Confirm your account</XLarge>
        <div>
          <Medium>
            An email confirmation link was sent to <span>{state.email}</span>
          </Medium>
        </div>
        <Card variant='secondary' size='medium'>
          <Row center='xs'>
            <Col xs data-cy='resend-email-link'>
              <p>
                {emailResent ? (
                  'Email was resent'
                ) : (
                  <>
                    <Link onClick={resendEmail}>
                      Click here to resend email
                    </Link>
                  </>
                )}
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
})`
  > div:nth-child(2) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  ${Medium} {
    > span {
      font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    }
  }

  ${Card} {
    display: flex;
    justify-content: center;
    margin-top: ${SPACING.medium};
    padding: ${SPACING.medium};
  }
`

export default ConfirmEmailNew
