import React, { useContext } from 'react'
import Link from '../System/Link'
import styled from 'styled-components'
import Middesk from './Icons/Middesk'
import { AuthContext } from '../../contexts/AuthProvider'
import { bannerPadStyle } from './SandboxBanner'
import API from '../../lib/api'
import { MAX_WIDTH_MOBILE } from '../Body'
import { theme } from '@middesk/components'

const { spacing } = theme

const PartnerLogo = styled.img`
  max-height: 34px;
  max-width: calc(${MAX_WIDTH_MOBILE} - 66px);
`

const MiddeskLogo = styled(Middesk)`
  height: 34px;
  margin-left: -15px;
`

const MobileTopbar = styled(
  ({ className, logo, fetchingApplication }): JSX.Element => {
    const { inGuestMode, impersonator } = useContext(AuthContext)

    const sidebarLogo = () => {
      if (fetchingApplication) {
        return null
      }

      if (logo) {
        return (
          <div>
            <PartnerLogo src={logo} />
          </div>
        )
      }

      return (
        <Link to='/' disabled={inGuestMode}>
          <MiddeskLogo />
        </Link>
      )
    }

    return (
      <div
        style={bannerPadStyle(API.sandboxMode(), !!impersonator)}
        {...{ className }}
      >
        {sidebarLogo()}
      </div>
    )
  }
)`
  width: 100%;
  margin-bottom: ${spacing.normal};

  @media (min-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }
`

export default MobileTopbar
