import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

import api from '../../lib/api'
import { AuthContext } from '../../contexts/AuthProvider'
import Page from '../../components/System/Layout/Page'
import Bubble from '../../components/System/Bubble'
import Loader from '../../components/System/Loader'
import { Small } from '../../components/System/Typography'
import { ErrorMessage, Formik } from 'formik'
import { CheckboxField, Icon, theme } from '@middesk/components'
import { ButtonContainer } from '../../pages/OrderComplete'
import Button from '../Button'
import { CompanyContext } from '../CompanyProvider'
import { ExistingAccountConsentLabel } from '../ConsentLabel'
import Sidebar from '../System/Sidebar'
import { TransactionalPricingData } from '../../types'
import {
  STATE_TAX_WITHHOLDINGS_TYPE,
  STATE_UNEMPLOYMENT_INSURANCE_TYPE
} from '../../lib/constants'
import { isPaychex } from '../../lib/helpers'

const { typography, colors, spacing } = theme

type Application = {
  id: string
  submitted_at: string
}

type Partner = {
  name: string
  logo: string
}

type Invitation = {
  id: string
  created_at: string
  updated_at: string
  state: string
  application: Application
  partner: Partner
}

const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
`

const FullWidthDiv = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-face: ${typography.faces.display};
  font-size: ${typography.sizes.display.medium};
`

const BubbleWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const StyledIcon = styled.img`
  height: 100px;
  margin: 0;
  width: 100px;
`

const RedirectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TextWrapper = styled.p`
  display: flex;
  max-width: 370px;
  margin: 10px 0 20px 0;
`

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const InnerSidebarWrapper = styled.div`
  padding: 0 40px;
`

const StyledImage = styled.img`
  height: 130px;
  width: 110px;
`

const DisplayText = styled.div`
  font-size: ${typography.sizes.display.medium};
  font-family: ${typography.faces.display};
  color: ${colors.graphite};
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
`

const SubText = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
  margin: ${spacing.large} 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
`

const TaxTypeContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: ${spacing.xxsmall};
  margin-bottom: ${spacing.medium};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const KarlText = styled.div`
  color: ${colors.karl};
  font-size: ${typography.sizes.medium};
`

const GraphiteText = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
`

const InvitationsSidebar = ({ partnerName }: { partnerName?: string }) => {
  const {
    account: {
      settings: {
        agent: { skip_payment = false }
      }
    }
  } = useContext(AuthContext)

  const [pricingData, setPricingData] = useState<TransactionalPricingData>()

  const statePrices =
    !skip_payment && pricingData
      ? pricingData.tax_registration_current_prices['CA']
      : []

  useEffect(() => {
    api
      .get('/v1/agent/transactional_prices', { tax_registration_types: [] })
      .then(setPricingData)
  }, [])

  const whPrice = statePrices.find(
    regPrice => (regPrice.type = STATE_TAX_WITHHOLDINGS_TYPE)
  )?.amount_cents
  const uiPrice = statePrices.find(
    regPrice => (regPrice.type = STATE_UNEMPLOYMENT_INSURANCE_TYPE)
  )?.amount_cents

  return (
    <Sidebar fetchingApplication={false} backgroundColor={colors.frostLight}>
      <InnerSidebarWrapper>
        <StyledImage src='/icons/fast-forward.svg' />
        <DisplayText>
          Tax registrations made easy with
          {` Middesk ${partnerName && `+ ${partnerName}`}`}
        </DisplayText>
        <SubText>
          Set up all the state accounts that you need to operate and pay
          employees in minutes.
        </SubText>
        {uiPrice && (
          <TaxTypeContainer>
            <Icon name='success' />
            <Column>
              <GraphiteText>State Unemployment Insurance</GraphiteText>
              <KarlText>${uiPrice / 100.0}/registration</KarlText>
            </Column>
          </TaxTypeContainer>
        )}
        {whPrice && (
          <TaxTypeContainer>
            <Icon name='success' />
            <Column>
              <GraphiteText>State Withholdings Tax</GraphiteText>
              <KarlText>${whPrice / 100.0}/registration</KarlText>
            </Column>
          </TaxTypeContainer>
        )}
      </InnerSidebarWrapper>
    </Sidebar>
  )
}

const Invitations = (): JSX.Element => {
  const { push } = useHistory()
  const { inGuestMode, user, account } = useContext(AuthContext)
  const [loaded, setLoaded] = useState(false)
  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const [finishedInvitations, setFinishedInvitations] = useState<
    Array<Invitation>
  >([])
  const [partner, setPartner] = useState<Partner | null>(null)
  const [openInvitations, setOpenInvitations] = useState<Array<Invitation>>([])
  const { companyId } = useParams<{ companyId: string }>()
  const { setActiveCompanyId } = useContext(CompanyContext)

  useEffect(() => {
    const getInvitations = async (url: string) => {
      const response = await api.get(url, { track_view: 'true' })

      if (response.data.length > 0) {
        setPartner(response.data[0]?.partner)

        setOpenInvitations(
          response.data.filter(
            (i: Invitation) => i.application.submitted_at === null
          )
        )
        setFinishedInvitations(
          response.data.filter(
            (i: Invitation) => i.application.submitted_at !== null
          )
        )
      }

      setLoaded(true)
    }

    if (inGuestMode) {
      getInvitations('/guest/agent/application_invitations')
    } else {
      getInvitations(`/v1/agent/companies/${companyId}/application_invitations`)
    }
  }, [inGuestMode, companyId])

  useEffect(() => {
    if (companyId) {
      setActiveCompanyId(companyId)
    }
  }, [companyId])

  const alreadyConsented = !!user?.account?.agreed_to_current_terms
  const updateConsentAndContinue = async (
    { consent }: { consent: boolean },
    { setErrors }: { setErrors: any }
  ) =>
    api
      .patch('/v1/agent/accounts', {
        parent_account_authorization: { consent }
      })
      .then(
        ({
          parent_account: {
            authorization: { consented_at }
          }
        }) => {
          if (consented_at) {
            handleContinue()
          }
        }
      )
      .catch(({ errors }) => {
        const errorMessages = errors.map(
          ({ message }: { message: string }) => message
        )
        return setErrors({ consent: errorMessages })
      })

  const handleContinue = () => {
    if (openInvitations.length > 0) {
      const firstInvitation = openInvitations[0]
      let applicationPath = `/applications/${firstInvitation.application.id}`
      if (api.usingGuestTokenStrategy()) {
        applicationPath = `/guest/applications/${firstInvitation.application.id}`
      }
      push(applicationPath)
    }
  }

  const goToAgentDashboard = () => {
    push('/home')
  }

  const resetPassword = () => {
    if (!user?.email) {
      return
    }

    api.post('/password/reset', { email: user.email, agent: true }).then(() => {
      setPasswordResetSent(true)
    })
  }

  const RedirectToAgent = () => {
    if (inGuestMode) {
      if (!user?.email) {
        return null
      }

      if (passwordResetSent) {
        return <p>A password reset link was sent to {user?.email}</p>
      }

      return (
        <RedirectWrapper>
          <TextWrapper>
            Finish setting up your Middesk Agent account to view the status of
            your application and tax account information when its available.
          </TextWrapper>
          <Button onClick={resetPassword}>Set password</Button>
        </RedirectWrapper>
      )
    }

    return (
      <div>
        <Button onClick={goToAgentDashboard}>Back to dashboard</Button>
      </div>
    )
  }

  return (
    <Page displaySubtitle={false} background={colors.white}>
      <InvitationsSidebar partnerName={partner?.name} />
      <>
        <CenteredWrapper>
          <Loader loading={!loaded} />

          {partner && (
            <div>
              <img
                src={
                  isPaychex(partner)
                    ? '/paychex_secondary_logo.png'
                    : partner.logo
                }
                width={isPaychex(partner) ? 200 : 150}
              />
            </div>
          )}

          {openInvitations.length > 0 ? (
            <CenterDiv>
              <Title>Complete your registration requests</Title>
              <p>
                {partner?.name || 'A Middesk partner'} has requested you to
                register for payroll taxes in the following states:
              </p>
            </CenterDiv>
          ) : (
            <CenterDiv>
              <StyledIcon src='/icons/usa_pin.svg' />
              <Title>You have no outstanding requests</Title>
            </CenterDiv>
          )}

          {finishedInvitations.length > 0 && (
            <>
              <Small>Completed requests</Small>
              <BubbleWrapper>
                {finishedInvitations.map(invitation => (
                  <div key={invitation.id}>
                    <Bubble key={invitation.state} variant='green'>
                      {invitation.state}
                    </Bubble>
                  </div>
                ))}
              </BubbleWrapper>
            </>
          )}

          {openInvitations.length > 0 && (
            <>
              <Small>Outstanding requests</Small>
              <BubbleWrapper>
                {openInvitations.map(invitation => (
                  <div key={invitation.id}>
                    <Bubble key={invitation.state} variant='orange'>
                      {invitation.state}
                    </Bubble>
                  </div>
                ))}
              </BubbleWrapper>
            </>
          )}

          {openInvitations.length > 0 ? (
            <CenteredDiv>
              <FullWidthDiv>
                {alreadyConsented ? (
                  <ButtonContainer>
                    <Button onClick={handleContinue}>Continue</Button>
                  </ButtonContainer>
                ) : (
                  <Formik
                    initialValues={{
                      consent: false
                    }}
                    onSubmit={updateConsentAndContinue}
                    validationSchema={yup.object().shape({
                      consent: yup
                        .boolean()
                        .oneOf(
                          [true],
                          'You must agree to the terms before continuing'
                        )
                    })}
                  >
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => (
                      <form>
                        <div style={{ maxWidth: '500px' }}>
                          <CheckboxField
                            name='consent'
                            label={
                              <div style={{ paddingLeft: '0px' }}>
                                <ExistingAccountConsentLabel
                                  partner={account.parent_account}
                                />
                                <div
                                  style={{
                                    color: colors.magenta,
                                    fontSize: typography.sizes.small,
                                    marginTop: '5px',
                                    paddingLeft: 0
                                  }}
                                >
                                  <ErrorMessage name='consent' />
                                </div>
                              </div>
                            }
                          />
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%'
                          }}
                        >
                          <ButtonContainer>
                            <Button
                              onClick={handleSubmit}
                              disabled={!dirty || !isValid || isSubmitting}
                            >
                              {isSubmitting ? 'Loading...' : 'Continue'}
                            </Button>
                          </ButtonContainer>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </FullWidthDiv>
            </CenteredDiv>
          ) : (
            <>{loaded && <RedirectToAgent />}</>
          )}
        </CenteredWrapper>
      </>
    </Page>
  )
}

export default Invitations
