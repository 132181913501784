import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import BORDER_RADIUS from './BorderRadius'
import CircularCheckMark from './Icons/CircularCheckMark'
import CloseX from './Icons/CloseX'
import Error from './Icons/Error'
import Info from './Icons/Info'
import WarningV2 from './Icons/WarningV2'
import SPACING from './Spacing'
import { Body, TYPOGRAPHY_WEIGHTS } from './Typography'
import { theme } from '@middesk/components'

const { colors } = theme

export type BannerVariant = 'success' | 'info' | 'warning' | 'error'

export type BannerProps = {
  className?: string
  label?: string
  message?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>
  variant?: BannerVariant
}

const Banner = styled(
  ({ className, label, message, onClick, variant = 'info' }: BannerProps) => {
    return (
      <div {...{ className }}>
        <div>
          {variant === 'success' ? (
            <CircularCheckMark />
          ) : variant === 'info' ? (
            <Info />
          ) : variant === 'error' ? (
            <Error />
          ) : (
            <WarningV2 />
          )}
        </div>
        <Body bold>{message}</Body>
        {onClick && label && ['warning', 'error'].includes(variant) ? (
          <div>
            <Button {...{ onClick, type: 'secondary' }}>{label}</Button>
          </div>
        ) : (
          ''
        )}
        {onClick && ['success', 'info'].includes(variant) && !label ? (
          <div {...{ onClick }}>
            <CloseX variant={variant === 'success' ? 'green' : 'grey'} />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
)`
  align-items: center;
  border-radius: ${BORDER_RADIUS.small};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: ${SPACING.medium};

  > div:nth-child(2) {
    flex-grow: 1;
  }

  ${Body} {
    margin-left: ${SPACING.small};
  }

  ${Button} {
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    overflow: hidden;
    margin-left: auto;
  }

  ${({ variant }: BannerProps) => {
    switch (variant) {
      case 'error':
        return `
        background: ${colors.redLight};

        ${Body} {
          color: ${colors.red};
        }
      `
      case 'warning':
        return `
        background: ${colors.yellowLight};

        ${Body} {
          color: ${colors.karl};
        }
      `
      case 'info':
        return `
        background: ${colors.dawn};

        ${Body} {
          color: ${colors.karl};
        }
      `
      case 'success':
        return `
          background: ${colors.greenLight};

          ${Body} {
            color: ${colors.green};
          }
        `
      default:
        return `
        background: transparent;
        border: transparent;
      `
    }
  }}
`

export default Banner
