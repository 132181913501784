import React, { ReactElement, useEffect, useState } from 'react'
import pluralize from 'pluralize'
import Page from '../components/System/Layout/Page'
import Sidebar from '../components/Sidebar'
import { ContainerProps } from '../types'
import { List, ListPagination, Link, MetaTag } from '@middesk/components'
import {
  DEFAULT_PER_PAGE_COUNT,
  DEFAULT_PAGE_NUM,
  STATES,
  ANNUAL_FILING_DOCUMENT_TYPE,
  FOREIGN_QUALIFICATION_DOCUMENT_TYPE,
  SECRETARY_OF_STATE,
  CREATED_AT
} from '../lib/constants'
import api from '../lib/api'
import queryStringSerializer from '../lib/queryStringSerializer'
import { useHistory, useLocation, useParams } from 'react-router'
import { parse } from 'qs'
import { SkeletonArray, StyledListControls } from '../components/Shared'
import Filter from '../components/System/Filter'
import Skeleton from 'react-loading-skeleton'

interface Document {
  id: string
  state: string
  dateAdded: string
  source: {
    type: string
    metadata?: {
      status: string
      state: string
    }
  }
  download_url?: string
}

const DOCUMENT_TYPES = [
  {
    key: ANNUAL_FILING_DOCUMENT_TYPE.key,
    value: ANNUAL_FILING_DOCUMENT_TYPE.value
  },
  {
    key: FOREIGN_QUALIFICATION_DOCUMENT_TYPE.key,
    value: FOREIGN_QUALIFICATION_DOCUMENT_TYPE.value
  }
]

const Documents = ({ className }: ContainerProps): ReactElement => {
  const { push } = useHistory()
  const location = useLocation()
  const { search } = location
  const params = parse(search, { ignoreQueryPrefix: true })
  const { companyId } = useParams<{ companyId: string }>()

  const {
    per_page = DEFAULT_PER_PAGE_COUNT,
    page = DEFAULT_PAGE_NUM,
    document_type = '',
    state = ''
  }: {
    [key: string]: any
  } = params as any

  const [fetching, setFetching] = useState(true)
  const [selectedState, setSelectedState] = useState(state || '')
  const [documents, setDocuments] = useState<Array<Document>>([])
  const [documentType, setDocumentType] = useState(document_type || '')
  const [total, setTotal] = useState(0)

  const renderTag = (info: Document) => {
    const documentType =
      info?.source.type === ANNUAL_FILING_DOCUMENT_TYPE.key
        ? ANNUAL_FILING_DOCUMENT_TYPE.value
        : FOREIGN_QUALIFICATION_DOCUMENT_TYPE.value

    return <MetaTag type='unknown'>{documentType}</MetaTag>
  }

  const columns = [
    {
      key: 'state',
      title: 'State',
      render: (document: Document) => (
        <b>
          {STATES.find(s => s.abbr === document?.source?.metadata?.state)?.name}
        </b>
      ),
      width: 2
    },
    {
      key: 'agency',
      title: 'Agency',
      render: () => SECRETARY_OF_STATE || '',
      width: 2
    },
    {
      title: 'Document Type',
      key: 'tag',
      width: 2,
      render: renderTag
    },
    {
      key: CREATED_AT,
      title: 'Date Added',
      type: 'datetime',
      width: 2
    },
    {
      title: 'Link',
      key: 'document',
      render: (info: Document) => {
        return (
          <Link target='_blank' href={info?.download_url}>
            View
          </Link>
        )
      },
      width: 1
    }
  ]

  useEffect(() => {
    const getDocuments = async () => {
      const defaultParams = {
        page: DEFAULT_PAGE_NUM,
        per_page: DEFAULT_PER_PAGE_COUNT
      }

      if (documentType === ANNUAL_FILING_DOCUMENT_TYPE.key) {
        params.documentable_type = ANNUAL_FILING_DOCUMENT_TYPE.key
      } else if (documentType === FOREIGN_QUALIFICATION_DOCUMENT_TYPE.key) {
        params.documentable_type = FOREIGN_QUALIFICATION_DOCUMENT_TYPE.key
      }

      const documentsResponse = await api.get(
        `/v1/agent/companies/${companyId}/documents`,
        {
          ...defaultParams,
          ...params
        }
      )

      setDocuments(documentsResponse.data)
      setTotal(documentsResponse.total_count)
      setFetching(false)
    }

    getDocuments()
  }, [documentType, selectedState, page])

  const pushQueryParams = (nextParams: { [key: string]: any }): void => {
    push(
      `/companies/${companyId}/documents?${queryStringSerializer(
        params,
        nextParams
      )}`
    )
  }
  const onPage = (page: number) => pushQueryParams({ page: page })

  const onStateChange = (state?: string): void => {
    pushQueryParams({ state: state || null })
    setSelectedState(state || '')
  }

  const onDocumentTypeChange = (val: string): void => {
    const type = DOCUMENT_TYPES.find(item => item.value === val)?.key || ''

    pushQueryParams({ document_type: type || null })
    setDocumentType(type)
  }

  return (
    <Page {...{ title: 'Documents', className }}>
      <Sidebar />

      <List data={documents} columns={columns}>
        <StyledListControls>
          <Filter
            placeholder='State'
            onFilter={onStateChange}
            currentValue={selectedState || null}
            options={STATES.map(({ abbr, name }) => ({
              label: name,
              value: abbr
            }))}
          />
          <Filter
            placeholder='Document type'
            onFilter={onDocumentTypeChange}
            currentValue={documentType}
            options={DOCUMENT_TYPES.map(({ value }) => ({
              label: value,
              value: value
            }))}
          />
          <div className='muted'>
            {fetching ? (
              <Skeleton />
            ) : (
              <>{pluralize('Document', total, true)}</>
            )}
          </div>
          {total > per_page ? (
            <ListPagination
              onPage={onPage}
              page={parseInt(page)}
              perPage={per_page}
              total={total}
            />
          ) : (
            <div />
          )}
        </StyledListControls>
      </List>
      {fetching && <SkeletonArray />}
    </Page>
  )
}

export default Documents
