import TransferIntakeCard from '../TransfersIntakeCard'
import * as Sentry from '@sentry/browser'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import Loader from '../../System/Loader'
import { AuthContext } from '../../../contexts/AuthProvider'
import { StateDataContext } from '../../../contexts/StateDataProvider'
import api from '../../../lib/api'
import APIAgentTransfer from '../../../lib/api/agentTransfer'
import { transfersPayload } from '../../../lib/transfers/submission'
import {
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import PaymentCapture, { trackUser } from '../../PaymentCapture'

const validationSchema = yup.object().shape({
  accept_tos: yup.bool().oneOf([true], 'You Must Accept Payment Usage Terms')
})

const PaymentInformation = ({
  onNext,
  onBack,
  setValidationSchema
}: TransfersIntakeComponentProps): JSX.Element => {
  const { values, submitForm } = useFormikContext<TransfersIntakeFormValues>()
  const { transferDepartmentsMap } = useContext(StateDataContext)
  const { internal } = useContext(AuthContext)
  const params = useParams()
  const transferID = get(params, 'id')
  const [loading, setLoading] = useState(true)
  const [newUser, setNewUser] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [paymentReady, setPaymentReady] = useState(false)
  const [updatingApplication, setUpdatingApplication] = useState(false)
  const [error, setError] = useState('')
  const [setupSecret, setSetupSecret] = useState('')

  setValidationSchema && setValidationSchema(validationSchema)

  useEffect(() => {
    api
      .get('/v1/agent/tax_registrations')
      .then(json => setNewUser(json.data && json.data.length === 0))
  }, [])

  useEffect(() => {
    if (internal) {
      setLoading(false)
      return
    }

    api
      .get('/ajax/setup_card')
      .then(json => setSetupSecret(json.client_secret))
      .finally(() => setLoading(false))
  }, [])

  const submitApplication = () => {
    return APIAgentTransfer.update({
      id: transferID,
      submit: true,
      ...transfersPayload(values, transferDepartmentsMap)
    })
  }

  const applicationUpdate = () => {
    if (updatingApplication) {
      return
    }

    setUpdatingApplication(true)

    submitApplication()
      .then(() => {
        if (!internal) {
          trackUser(newUser, 'transfer')
        }

        submitForm().then(() => {
          onNext(values)
          setSubmitting(false)
          setUpdatingApplication(false)
        })
      })
      .catch((e: any) => {
        if (e.json && e.json.errors) {
          const errors = e.json.errors
            .map((error: any) => error.message)
            .join(', ')
          Sentry.captureException(new Error(errors))
          setError(errors)
        } else {
          Sentry.captureException(e)
          setError('Something went wrong. Please try again')
        }
        setSubmitting(false)
        setUpdatingApplication(false)
      })
  }

  const shouldDisableContinue =
    !validationSchema.isValidSync(values) || !paymentReady
  return (
    <Loader {...{ loading }}>
      <TransferIntakeCard
        {...{
          onNext: () => {
            setSubmitting(true)
          },
          isSubmitting: submitting,
          isSubmitButton: true,
          onBack,
          shouldDisableContinue
        }}
      >
        <PaymentCapture
          setPaymentReady={setPaymentReady}
          submitting={submitting}
          setSubmitting={setSubmitting}
          applicationUpdate={applicationUpdate}
          error={error}
          setError={setError}
          setupSecret={setupSecret}
        />
      </TransferIntakeCard>
    </Loader>
  )
}

export default PaymentInformation
