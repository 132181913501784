import React from 'react'
import styled from 'styled-components'
import Separator from './Separator'
import { COLORS } from './System/Colors'
import SPACING from './System/Spacing'

const SeparatorYellow = styled(({ className }) => {
  return <Separator {...{ className }} />
})`
  && {
    background: ${COLORS.yellow};
    height: ${SPACING.xsmall};
  }
`

export default SeparatorYellow
