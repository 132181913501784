import { Form, PasswordStrength, TextField, theme } from '@middesk/components'
import { parse, stringify } from 'qs'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import Brand from '../components/Brand'
import ErrorPage, { ErrorMessage } from '../components/ErrorPage'
import LandingGlobalStyle from '../LandingGlobalStyle'
import API from '../lib/api'
import ActionFooter from './ActionFooter'
import LandingCard from './LandingCard'
import LinkFooter from './LinkFooter'
import LoaderButton from './LoaderButton'
import { COLORS } from './System/Colors'
import Link from './System/Link'

const { spacing } = theme

interface Client {
  client_id: string
  name: string
  logo: string
}

const SIGN_IN = 'SIGN_IN'
const SIGN_UP = 'SIGN_UP'
const VERIFY_EMAIL = 'VERIFY_EMAIL'
const SHOW_ERROR = 'SHOW_ERROR'
type View =
  | typeof SIGN_IN
  | typeof SIGN_UP
  | typeof VERIFY_EMAIL
  | typeof SHOW_ERROR

const EmailSent = styled.div`
  text-align: center;
`

const Divider = styled.div`
  background-color: ${COLORS.frost};
  height: 35px;
  margin: 0px ${spacing.normal};
  width: 2px;
`

const Header = styled(({ className, client }) => {
  return (
    <div className={className}>
      <Brand color={COLORS.black} />
      {client.logo && (
        <>
          <Divider />
          <img src={client.logo} />
        </>
      )}
    </div>
  )
})`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: ${spacing.normal};

  > img,
  svg {
    height: auto !important;
    width: 40%;
  }
`

const ConnectToMiddesk = () => {
  const { search } = useLocation()
  const parsedParams = parse(search, { ignoreQueryPrefix: true })
  const { client_id } = parsedParams
  const { push } = useHistory()

  const formData = stringify(
    { response_type: 'code', approve: 'true', ...parsedParams },
    { addQueryPrefix: false }
  )

  const initialValues = {
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    company_name: ''
  }

  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [view, setView] = useState<View>(SIGN_UP)
  const [client, setClient] = useState<Client>({
    name: '',
    client_id: '',
    logo: ''
  })
  const validationSchema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required')
  })

  const signUpValidationSchema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
  })

  useEffect(() => {
    API.get(`/oauth/clients/${client_id}`)
      .then(json => {
        setClient(json)
      })
      .catch(err => {
        /* eslint-disable-next-line no-console */
        console.error(err)
        setView(SHOW_ERROR)
      })
  }, [])

  const login = () => {
    setLoading(true)

    API.login({
      Authorization: `Basic ${btoa(values.email + ':' + values.password)}`
    })
      .then(() => {
        API.post('/oauth/authorizations', formData, {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        })
          .then(({ redirect_uri }) => {
            if (redirect_uri) {
              window.location.href = redirect_uri
            }
          })
          .catch(e => {
            setLoading(false)

            if (e.messages && e.messages.length > 0) {
              setError(e.messages[0])
            } else {
              setError('An unknown error has occurred')
            }
          })
      })
      .catch(() => {
        setError('Invalid email or password')
        setLoading(false)
      })
  }

  const signUp = () => {
    setLoading(true)
    API.post('/oauth/signup', {
      redirect_uri: window.location.href,
      client_id,
      ...values
    })
      .then(() => setView(VERIFY_EMAIL))
      .catch(e => {
        if (e.messages && e.messages.length > 0) {
          setError(e.messages[0])
        } else {
          setError('An unknown error has occurred')
        }
      })
      .finally(() => setLoading(false))
  }
  return (
    <>
      <LandingGlobalStyle />
      {
        <>
          {view === SIGN_IN && (
            <>
              <LandingCard>
                <Row center='xs'>
                  <Col>
                    <Header client={client} />
                    <p>
                      Sign in to connect your Middesk account with&nbsp;
                      {client.name}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {error ? <ErrorMessage>{error}</ErrorMessage> : ''}
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <Form
                      {...{
                        initialValues,
                        validationSchema,
                        onSubmit: login,
                        onChange: setValues
                      }}
                    >
                      <Row>
                        <Col xs>
                          <TextField
                            placeholder='Enter email'
                            name='email'
                            label='Email'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            placeholder='Enter password'
                            name='password'
                            label='Password'
                            type='password'
                          />
                        </Col>
                      </Row>
                      <ActionFooter>
                        <LoaderButton
                          {...{
                            submitting: loading,
                            type: 'submit',
                            submitText: 'Connect',
                            isDisabled: !validationSchema.isValidSync(values)
                          }}
                        />
                      </ActionFooter>
                    </Form>
                  </Col>
                </Row>
              </LandingCard>
              <LinkFooter>
                <p>
                  Need an account?{' '}
                  <Link onClick={() => setView(SIGN_UP)}>Sign up here</Link>
                </p>
                <p>
                  <Link onClick={() => push('/forgot-password')}>
                    Forgot password
                  </Link>
                </p>
              </LinkFooter>
            </>
          )}
          {view === SIGN_UP && (
            <>
              <LandingCard>
                <Row center='xs'>
                  <Col>
                    <Header client={client} />
                    <p>
                      Create your Middesk account to connect with {client.name}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {error ? <ErrorMessage>{error}</ErrorMessage> : ''}
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <Form
                      {...{
                        initialValues,
                        validationSchema: signUpValidationSchema,
                        onChange: setValues,
                        onSubmit: signUp
                      }}
                    >
                      <Row>
                        <Col xs>
                          <TextField
                            placeholder='Enter full name'
                            name='name'
                            label='Full Name'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            placeholder='Enter company Name'
                            name='company_name'
                            label='Company Name'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            placeholder='email@company.com'
                            name='email'
                            label='Company Email'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            type='password'
                            placeholder='Enter Password'
                            name='password'
                            label='Password'
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <PasswordStrength password={values.password} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs>
                          <TextField
                            type='password'
                            placeholder='Confirm Password'
                            name='confirm_password'
                            label='Confirm Password'
                          />
                        </Col>
                      </Row>
                      <ActionFooter>
                        <LoaderButton
                          {...{
                            submitting: loading,
                            type: 'submit',
                            submitText: 'Create account',
                            isDisabled: !signUpValidationSchema.isValidSync(
                              values
                            )
                          }}
                        />
                      </ActionFooter>
                    </Form>
                  </Col>
                </Row>
              </LandingCard>
              <LinkFooter>
                <p>
                  By creating an account you agree to our{' '}
                  <Link target='_blank' to='/terms'>
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link
                    target='_blank'
                    rel='noreferrer'
                    to='https://middesk.com/privacy'
                  >
                    Privacy Policy
                  </Link>
                </p>
                <p>
                  Already have an account?{' '}
                  <Link onClick={() => setView(SIGN_IN)}>Sign in</Link>
                </p>
              </LinkFooter>
            </>
          )}
          {view === VERIFY_EMAIL && (
            <LandingCard>
              <Row center='xs'>
                <Col>
                  <Header client={client} />
                </Col>
              </Row>
              <Row>
                <Col xs>
                  <EmailSent>
                    An email verification link was sent to {values.email}
                  </EmailSent>
                </Col>
              </Row>
            </LandingCard>
          )}
          {view === SHOW_ERROR && <ErrorPage />}
        </>
      }
    </>
  )
}

export default ConnectToMiddesk
