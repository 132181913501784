import React, { useContext } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Attribute, DateTime, theme } from '@middesk/components'
import Card from '../../components/System/Card'
import Link from '../../components/System/Link'
import StatusTag from '../../components/StatusTag'
import { TYPOGRAPHY_SIZES } from '../../components/System/Typography'
import TaxAccountContainer from './TaxAccountContainer'
import { StateDataContext } from '../../contexts/StateDataProvider'
import { TaxAgencyRegistration } from '../../types'
import { STATE_MAP, SECRETARY_OF_STATE_TYPE } from '../../lib/constants'

import { getTaxTypeLabel } from './Overview'
import TurnAroundTimeBanner from './TurnAroundTimeBanner'
import TaxRegistrationItems from './TaxRegistrationItems'

const { spacing, typography, colors } = theme

const ELEMENT_SPACING = '80px'

export const StyledAttribute = styled(Attribute)`
  margin-right: ${ELEMENT_SPACING};
`

const ForeignQualificationContainer = styled.div`
  color: ${colors.graphite};
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.normal};

  > div {
    display: flex;
  }
`

export const TaxAccountWrapper = styled.div`
  margin-bottom: 30px;
`

export const LineSeparator = styled.div<LineSeparatorProps>`
  background-color: ${colors.dawn};
  height: 1px;
  margin: ${props => {
    if (!props.spacing) {
      return '0 0'
    } else if (props.spacing === 'normal') {
      return `${spacing.normal} 0px`
    } else if (props.spacing === 'compact') {
      return `${spacing.compact} 0px`
    } else {
      return '0 0'
    }
  }};
`

interface LineSeparatorProps {
  spacing?: 'normal' | 'compact'
}

export const TimestampText = styled.span`
  color: ${colors.graphite};
  font-size: ${TYPOGRAPHY_SIZES.small}px;
  margin-right: ${spacing.compact};
`

export const StateAndAgencyName = styled.div`
  display: flex;
  font-size: ${typography.sizes.large};
`

export const TaxTypesAndTimestampRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.xsmall};
  margin-top: ${spacing.xxsmall};
`

export const TaxTypes = styled.div`
  font-size: ${typography.sizes.medium};
  color: ${colors.graphite};
`

const Timestamp = ({ status_updated_at }: TaxAgencyRegistration) => {
  if (status_updated_at) {
    return (
      <TimestampText>
        <DateTime relative formatter={undefined}>
          {status_updated_at}
        </DateTime>
      </TimestampText>
    )
  }

  return <></>
}

const TaxAgencyRegistrationDetailCard = ({
  tax_agency_registration,
  updateTar
}: {
  tax_agency_registration: TaxAgencyRegistration
  updateTar: (tax_agency_registration: TaxAgencyRegistration) => void
}) => {
  const { companyId } = useParams<{ companyId: string }>()
  const { agencyData } = useContext(StateDataContext)

  return (
    <Card key={tax_agency_registration.id} variant='secondary'>
      <div>
        <StateAndAgencyName>
          <b>{STATE_MAP[tax_agency_registration.state].name}・</b>
          {tax_agency_registration.agency}
        </StateAndAgencyName>
        <TaxTypesAndTimestampRow>
          <TaxTypes>
            {(tax_agency_registration.tax_types || [])
              .map((type: string) => getTaxTypeLabel(type))
              .join(', ')}
          </TaxTypes>
          <div>
            <Timestamp {...tax_agency_registration} />
            <StatusTag taxAgencyRegistration={tax_agency_registration} />
          </div>
        </TaxTypesAndTimestampRow>

        <LineSeparator />
        <TurnAroundTimeBanner
          taxAgencyRegistration={tax_agency_registration}
          agencyData={agencyData}
        />
        {tax_agency_registration.tax_types.includes(SECRETARY_OF_STATE_TYPE) ? (
          <ForeignQualificationContainer>
            <div>
              <StyledAttribute label='Filing Date'>
                {tax_agency_registration.foreign_qualification?.filing_date}
              </StyledAttribute>
              <StyledAttribute label='Registered Agent'>
                {
                  tax_agency_registration.foreign_qualification
                    ?.registered_agent
                }
              </StyledAttribute>
              {tax_agency_registration.foreign_qualification?.documents[0] && (
                <StyledAttribute label='Foreign Qualification document'>
                  <Link
                    href={`/companies/${companyId}/documents?state=${tax_agency_registration.state}`}
                  >
                    View document
                  </Link>
                </StyledAttribute>
              )}
            </div>
          </ForeignQualificationContainer>
        ) : (
          <TaxRegistrationItems
            tar={tax_agency_registration}
            updateTar={newTar => updateTar(newTar)}
          />
        )}

        {tax_agency_registration.tax_account && (
          <TaxAccountWrapper>
            <TaxAccountContainer
              account={tax_agency_registration.tax_account}
              status={tax_agency_registration.status}
            />
          </TaxAccountWrapper>
        )}
      </div>
    </Card>
  )
}

export default TaxAgencyRegistrationDetailCard
