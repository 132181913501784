import { Loader } from '@middesk/components'
import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../types'
import StyledCard from './StyledCard'

const LoaderCard = styled(({ className }: ContainerProps) => {
  return (
    <StyledCard {...{ className }}>
      <Loader size='medium' />
    </StyledCard>
  )
})`
  align-items: center;
  display: flex;
  justify-content: center !important;
`

export default LoaderCard
