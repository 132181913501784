import React, { useContext } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { AuthenticationParams } from '../../types'
import API from '../../lib/api'
import { AuthContext } from '../../contexts/AuthProvider'
import { useHistory } from 'react-router'
import { Form, TextField } from '@middesk/components'
import * as yup from 'yup'
import { useFormikContext } from 'formik'
import LoaderButton from '../../components/LoaderButton'
import styled from 'styled-components'

const SubmitButton = styled(
  ({ className }: { className?: string }): JSX.Element => {
    const { isSubmitting: submitting, isValid, dirty } = useFormikContext()

    return (
      <LoaderButton
        {...{
          submitting,
          isDisabled: !isValid || !dirty,
          submitText: 'Sign in',
          className
        }}
      />
    )
  }
)`
  input {
    margin-top: 10px;
    width: 100%;
  }
`

const PasswordCard = ({
  setErrorMessage
}: {
  setErrorMessage: (message?: string) => void
}): JSX.Element => {
  const { setFetchingUser } = useContext(AuthContext)
  const { from } = (location as any).state || { from: { pathname: '/' } }
  const { push } = useHistory()

  const login = async ({ email, password }: AuthenticationParams) => {
    return API.login({
      Authorization: `Basic ${btoa(email + ':' + password)}`
    })
      .then(() => {
        setFetchingUser(true)

        push(from.pathname)
      })
      .catch(e => {
        setErrorMessage(
          e.status === 401 ? 'Invalid email or password' : e.message
        )
      })
  }

  return (
    <>
      <Row>
        <Col xs>
          <Form
            {...{
              initialValues: { email: '', password: '' },
              validationSchema: yup.object().shape({
                email: yup.string().email().required('Email is required'),
                password: yup.string().required('Password is required')
              }),
              onSubmit: (e: AuthenticationParams) => login(e)
            }}
          >
            <Row>
              <Col xs>
                <TextField
                  placeholder='Enter email'
                  name='email'
                  label='Email'
                />
              </Col>
            </Row>
            <Row>
              <Col xs>
                <TextField
                  placeholder='Enter password'
                  name='password'
                  label='Password'
                  type='password'
                />
              </Col>
            </Row>
            <SubmitButton />
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default PasswordCard
