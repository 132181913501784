import React, { useContext, useEffect, useState } from 'react'
import {
  ForeignQualification,
  PackageType,
  RegisteredState,
  TaxRegistration,
  TransfersIntakeComponentProps,
  TransfersIntakeFormValues
} from '../../../types'
import { get, capitalize, intersection } from 'lodash'
import TransferIntakeCard from '../TransfersIntakeCard'
import { useFormikContext } from 'formik'
import { SelectField, Tooltip, Icons } from '@middesk/components'
import * as Sentry from '@sentry/browser'
import {
  STATES,
  PackageVariations,
  DEFAULT_PAGE_NUM,
  UNMANAGED_FQ_STATUS,
  DEFAULT_TRANSFER_FQ_ACTION,
  DEFAULT_TRANSFER_TR_ACTION
} from '../../../lib/constants'
import Separator from '../../Separator'
import { Col, Row } from 'react-styled-flexboxgrid'
import StateRow from './StateRow'
import TransferTypeColumnHeader from './TransferTypeColumnHeader'
import styled from 'styled-components'
import SPACING from '../../System/Spacing'
import { AuthContext } from '../../../contexts/AuthProvider'
import SeparatorYellow from '../../SeparatorYellow'
import { APIError } from '../../../lib/api'
import { APITaxRegistration } from '../../../lib/api/taxRegistration'
import Loader from '../../System/Loader'
import { APIForeignQualification } from '../../../lib/api/foreignQualification'
import StateTransfersFootnote from './StateTransfersFootnote'
import Link from '../../../components/System/Link'
import { useHistory, useParams } from 'react-router'
import APIAgentTransfer from '../../../lib/api/agentTransfer'
import useApplication from '../../../lib/useApplication'

const { Info } = Icons

const FlexIcons = styled.div`
  display: flex;

  > div {
    margin-right: ${SPACING.xsmall};
  }
`

const StyledPricingPackages = styled(Row)`
  margin: ${SPACING.medium} 0 0 0;
`

const StyledColumnHeaderRow = styled(Row)`
  display: flex;
  font-size: 0.9rem;
`

const StyledRightTextCol = styled(Col)`
  text-align: right;
`

const StyledStateColumnHeader = styled(Col)`
  align-self: flex-end;

  > div {
    padding-left: ${SPACING.small};
  }
`

const StyledLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
`

const RightJustified = styled.div`
  display: flex;
  justify-content: right;
`

const PAGE_TOTAL = 50
const DEFAULT_PARAMS = {
  sort_by: 'state',
  sort_order: 'asc',
  page: DEFAULT_PAGE_NUM,
  per_page: PAGE_TOTAL
}

const filterManagedStates = (
  registrations: TaxRegistration[],
  foreignQualifications: ForeignQualification[]
): string[] => {
  const registrationStates = registrations.map(tr => tr.state)
  const foreignQualificationStates = foreignQualifications
    .filter(fq => fq.status !== UNMANAGED_FQ_STATUS)
    .map(fq => fq.state)

  return intersection(registrationStates, foreignQualificationStates)
}

const filterUnmanagedStates = (
  foreignQualifications: ForeignQualification[]
): RegisteredState[] => {
  const unmanagedStates: string[] = foreignQualifications
    .filter(fq => fq.status === UNMANAGED_FQ_STATUS)
    .map(fq => fq.state)

  return STATES.map(st => {
    return unmanagedStates.includes(st.abbr)
      ? {
          name: st.name,
          abbr: st.abbr,
          hasUnmanagedForeignQualification: true,
          foreignQualificationAction: 'transfer',
          taxRegistrationAction: DEFAULT_TRANSFER_TR_ACTION,
          isSelectable: true
        }
      : {
          name: st.name,
          abbr: st.abbr,
          foreignQualificationAction: DEFAULT_TRANSFER_FQ_ACTION,
          taxRegistrationAction: DEFAULT_TRANSFER_TR_ACTION,
          isSelectable: true
        }
  })
}

const StateTransfers = ({
  onNext,
  onBack
}: TransfersIntakeComponentProps): JSX.Element => {
  const { user, inGuestMode } = useContext(AuthContext)
  const { id } = useParams<{ id: string }>()
  const {
    values,
    setFieldValue
  } = useFormikContext<TransfersIntakeFormValues>()

  // Remove states from Formik context
  // (if user continues and goes back, this maintains correct lists)
  const [states, setStates] = useState<RegisteredState[]>([])
  const [fetching, setFetching] = useState(true)

  const { id: applicationId } = useParams<{ id: string }>()
  const { application } = useApplication(applicationId)

  const selectedPackage = get(
    user,
    'account.settings.agent.package_type',
    'basic'
  )

  const skipPaymentSetting = get(
    user,
    'account.settings.agent.skip_payment',
    false
  )

  const history = useHistory()
  const { push } = history

  useEffect(() => {
    if (!application) {
      return
    }
    const fetchRegistrationsAndUpdateStates = async () => {
      const [
        registrationsResponse,
        foreignQualificationsResponse
      ] = await Promise.all([
        APITaxRegistration.get(application.company_id, DEFAULT_PARAMS)
          .then(res => res)
          .catch((error: unknown) => {
            Sentry.captureException(error)
            return Promise.reject(new APIError(error))
          }),
        APIForeignQualification.index(application.company_id, DEFAULT_PARAMS)
          .then(res => res)
          .catch((error: unknown) => {
            Sentry.captureException(error)
            return Promise.reject(new APIError(error))
          })
      ])

      const registrations = registrationsResponse?.data
      const foreignQualifications = foreignQualificationsResponse?.data

      const managedStates: string[] = filterManagedStates(
        registrations,
        foreignQualifications
      )

      const statesWithUnmanagedMapping: RegisteredState[] = filterUnmanagedStates(
        foreignQualifications
      )

      const selectedStates: string[] = values.registered_states.map(
        st => st.abbr
      )

      // Remove states from Dropdown that are managed by Middesk and selected
      // by setting isSelectable: false
      const updatedStates = statesWithUnmanagedMapping.map(state => {
        return managedStates.includes(state.abbr) ||
          selectedStates.includes(state.abbr)
          ? { ...state, isSelectable: false }
          : { ...state, isSelectable: true }
      })

      setStates(updatedStates)
      setFetching(false)
    }

    fetchRegistrationsAndUpdateStates()
  }, [values, application])

  const onDelete = (stateAbbr: string) => {
    const stateToRemove = values.registered_states.find(
      state => state.abbr === stateAbbr
    )

    // remove state from selected states list
    setFieldValue(
      'registered_states',
      values.registered_states.filter(
        state => state.abbr !== stateToRemove?.abbr
      )
    )

    // remove state specific account information
    setFieldValue(`${stateToRemove?.abbr}`, undefined)

    // set state back to selectable
    const modifiedStates = states.map(st =>
      st === stateToRemove ? { ...st, isSelectable: true } : st
    )

    setStates(modifiedStates as RegisteredState[])
  }

  const onSelect = (e: { value: string }) => {
    const selectedState = states.find(state => state.abbr === e.value)

    // append state to the selected states list
    setFieldValue('registered_states', [
      ...values.registered_states,
      selectedState
    ])

    // remove state from states dropdown
    const modifiedStates = states.map(st =>
      st === selectedState ? { ...st, isSelectable: false } : st
    )

    setStates(modifiedStates)
  }

  const handleFQAction = (state: RegisteredState, action: string) => {
    const updatedStates = values.registered_states.map(st =>
      st.abbr === state.abbr
        ? { ...st, foreignQualificationAction: action }
        : st
    )

    setFieldValue('registered_states', updatedStates)
  }

  const onDeleteTransfer = async () => {
    await APIAgentTransfer.delete({ id: id })
      /* eslint-disable-next-line no-console */
      .catch((error: unknown) => console.error(error))
      .finally(() => push('/home'))
  }

  const StateOptions = states.flatMap((state: RegisteredState) => {
    return state.isSelectable ? (
      <option label={state.name} value={state.abbr} key={state.abbr} />
    ) : (
      []
    )
  })

  const StateRows = values.registered_states.map(state => {
    const foreignQualificationAction = state.foreignQualificationAction
    const mappedDropdownState =
      states.find(st => state.abbr === st.abbr) || state
    return (
      <StateRow
        key={mappedDropdownState.abbr}
        state={mappedDropdownState}
        foreignQualificationAction={foreignQualificationAction}
        {...{ onDelete }}
      />
    )
  })

  const userHasMadeSelection = StateRows.length > 0

  if (fetching) return <StyledLoader loading size='medium' />

  return (
    <TransferIntakeCard
      {...{ onNext, onBack }}
      isFirst
      shouldDisableContinue={!userHasMadeSelection}
    >
      <FlexIcons>
        <div>Add states you&apos;re registered for payroll taxes in</div>
        <Tooltip
          content={`You can find which states you're registered in via your payroll provider portal`}
        >
          <Info />
        </Tooltip>
      </FlexIcons>
      {fetching ? (
        <StyledLoader loading size='medium' />
      ) : (
        <SelectField
          value={null}
          name='registered_states'
          onChange={onSelect}
          placeholder='Add a state'
        >
          {StateOptions}
        </SelectField>
      )}
      {!skipPaymentSetting && selectedPackage !== 'unlimited' && (
        <StyledPricingPackages>
          <Col xs>
            <b>{capitalize(selectedPackage)}</b>
          </Col>
          <Col xs />
          <Col xs />
          <StyledRightTextCol xs>
            {`$${
              PackageVariations.state_filing[selectedPackage as PackageType]
                .price
            }/state/month`}
          </StyledRightTextCol>
        </StyledPricingPackages>
      )}
      <Separator />
      <StyledColumnHeaderRow middle='xs'>
        <StyledStateColumnHeader xs>
          <div>State</div>
        </StyledStateColumnHeader>
        <TransferTypeColumnHeader
          type='Payroll Tax'
          tooltipText={`We'll process your state mail and monitor SUI rate changes`}
          size={3}
        />
        <TransferTypeColumnHeader
          type='Secretary of State'
          tooltipText={`We'll manage your annual report filings, annual franchise tax filings, and service of process.`}
          size={4}
        />
        <Col xs></Col>
      </StyledColumnHeaderRow>
      <SeparatorYellow />
      {StateRows}
      {userHasMadeSelection && (
        <StateTransfersFootnote
          registeredStates={values.registered_states}
          {...{ handleFQAction }}
        />
      )}
      {!inGuestMode && (
        <RightJustified>
          <Link {...{ onClick: onDeleteTransfer }}>Delete Filing</Link>
        </RightJustified>
      )}
    </TransferIntakeCard>
  )
}

export default StateTransfers
