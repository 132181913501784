import React from 'react'
import styled from 'styled-components'
import { theme } from '@middesk/components'

import Page from '../components/System/Layout/Page'
import Button from '../components/Button'
import Middesk from '../components/System/Icons/Middesk'

const { colors } = theme

const MIDDESK_PRICING_URL = 'https://www.middesk.com/pricing-agent'
const MIDDESK_CONTACT_SALES_URL = 'https://www.middesk.com/contact-sales'

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
`

const Logo = () => (
  <Flex>
    <Middesk />
  </Flex>
)

const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: normal;
`

const StyledIcon = styled.img`
  height: 100px;
  margin: 0;
  width: 100px;
`

const Wrapper = styled.div`
  margin: 25px auto;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 800px;
  padding: 40px 20px;
`

const CenteredWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 2px solid ${colors.dawn};
`

const NoWidth = styled.div`
  width: 0px;
`

const PricingLink = styled.a`
  color: ${colors.karl};
  font-size: 0.9rem;
`

const Plans = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  padding: 0 50px;
`

const Plan = styled.div`
  width: 100%;
  background-color: ${colors.frost};
  border-radius: 3px;
  padding: 20px;

  h2 {
    font-size: 1.2rem;
    font-weight: normal;
  }

  h3 {
    font-size: 0.9rem;
    font-weight: normal;
    margin: 10px 0;
  }

  .price {
    font-size: 1.2rem;
    color: ${colors.black};

    span {
      color: ${colors.karlLight1};
      font-size: 0.9rem;
      margin-left: 10px;
    }
  }
`

const DirectUpgrade = (): JSX.Element => {
  return (
    <Page>
      <NoWidth />
      <>
        <Logo />

        <CenteredWrapper>
          <>
            <StyledIcon src='/icons/sales.svg' />
            <Title>Contact our sales team</Title>
            <p>
              Please contact our sales team to sign up for one of our pricing
              plans.
            </p>

            <Plans>
              <Plan>
                <h2>Growth</h2>
                <h3>Get started with 8 states at a discount</h3>
                <p className='price'>
                  $8,000
                  <span>per year</span>
                </p>
              </Plan>
              <Plan>
                <h2>Unlimited</h2>
                <h3>Coverage for all 50 states at one flat fee.</h3>
                <p className='price'>
                  $15,000
                  <span>per year</span>
                </p>
              </Plan>
            </Plans>

            <div>
              <PricingLink href={MIDDESK_PRICING_URL} target='_blank'>
                <Flex>
                  <span>Learn more on our pricing page</span>
                  <img src='/icons/arrow-up-right.svg' />
                </Flex>
              </PricingLink>
            </div>

            <div>
              <Button href={MIDDESK_CONTACT_SALES_URL} target='_blank'>
                Contact sales
              </Button>
            </div>
          </>
        </CenteredWrapper>
      </>
    </Page>
  )
}

export default DirectUpgrade
