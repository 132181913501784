import React, { FC } from 'react'
import { SelectField } from '@middesk/components'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { FormValues, JobTitle as JobTitleOptions } from '../types'
import { StyledSelectWrapper } from '../pages/CompanyDetails'

interface JobTitleProps {
  label?: string
  name: string
  titleOptions: JobTitleOptions[]
  onChange?: () => void
}

const JobTitle: FC<JobTitleProps> = ({
  label = 'Job Title(s)',
  name,
  titleOptions,
  onChange
}: JobTitleProps) => {
  const { values } = useFormikContext<FormValues>()

  return (
    <StyledSelectWrapper>
      <SelectField name={name} label={label} onChange={onChange} isMulti={true}>
        {titleOptions.map(title => (
          <option
            key={title}
            value={title}
            label={title}
            selected={
              Array.isArray(get(values, name)) &&
              get(values, name).includes(title)
            }
          />
        ))}
      </SelectField>
    </StyledSelectWrapper>
  )
}

export default JobTitle
