import * as Sentry from '@sentry/browser'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from './System/Colors'
import SPACING from './System/Spacing'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'
import { AuthContext } from '../contexts/AuthProvider'
import { FormValues } from '../types'

const inputStyle = {
  style: {
    base: {
      color: COLORS.graphite,
      fontFamily:
        'IBM Plex Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSize: `${TYPOGRAPHY_SIZES.medium}px`,
      fontSmoothing: 'antialiased',
      fontWeight: TYPOGRAPHY_WEIGHTS.regular
    },
    '::placeholder': {
      color: COLORS.karl,
      fontWeight: TYPOGRAPHY_WEIGHTS.regular
    }
  }
}

const Label = styled.div`
  color: ${COLORS.karl};
  font-size: ${TYPOGRAPHY_SIZES.xsmall}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  line-height: 0.75rem;
  text-transform: uppercase;
`

const ExpCvcLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${SPACING.small} 0px;
`

const ExpContainer = styled.div`
  margin-right: ${SPACING.small};
  width: 50%;
`
const CvcContainer = styled.div`
  width: 50%;
`

interface CardCaptureProps {
  submitting: boolean
  setSubmitting: any
  setPaymentReady: any
  setError: any
  setupSecret: string
  applicationUpdate: () => void
}

const CardCapture: FC<CardCaptureProps> = ({
  submitting,
  setSubmitting,
  setPaymentReady,
  setError,
  setupSecret,
  applicationUpdate
}: CardCaptureProps) => {
  const { values } = useFormikContext<FormValues>()
  const [paid, setPaid] = useState(false)
  const { internal } = useContext(AuthContext)
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardNumberElement)
      if (cardElement) {
        cardElement.on('change', event => {
          setPaymentReady(event.complete)
        })
      }
    }
  }, [elements])

  useEffect(() => {
    if (!submitting || !elements) {
      return
    }
    setError('')

    if (internal || paid) {
      applicationUpdate()
      return
    }

    const card = elements.getElement(CardNumberElement)

    if (!card || !stripe || !setupSecret) {
      return
    }

    stripe
      .confirmCardSetup(setupSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: values.legal_name,
            email: values.contact_email,
            phone: values.contact_phone_number
          }
        }
      })
      .then(result => {
        if (result.error) {
          setError(result.error.message)
          Sentry.captureException(result.error)
          setSubmitting(false)
        } else {
          setPaid(true)
          applicationUpdate()
        }
      })
  }, [submitting])

  return (
    <div>
      <Label>Card Number</Label>
      <CardNumberElement options={{ ...inputStyle, showIcon: true }} />
      <ExpCvcLayout>
        <ExpContainer>
          <Label>Exp. Date</Label>
          <CardExpiryElement options={inputStyle} />
        </ExpContainer>
        <CvcContainer>
          <Label>CVC</Label>
          <CardCvcElement options={inputStyle} />
        </CvcContainer>
      </ExpCvcLayout>
    </div>
  )
}

export default CardCapture
