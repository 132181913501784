import React, { useState } from 'react'
import { matchPath, useHistory } from 'react-router'

import SystemSidebarSection from '../System/SidebarSection'
import FeedbackForm from '../FeedbackForm'
import DropdownMenu from './DropdownMenu'

const CompanyLinks = ({ companyId }: { companyId: string }): JSX.Element => {
  const {
    push,
    location: { pathname }
  } = useHistory()

  const [feedbackIsOpen, setFeedbackIsOpen] = useState(false)

  const basePath = `/companies/${companyId}`
  return (
    <>
      <DropdownMenu openFeedback={() => setFeedbackIsOpen(true)} />
      <div>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/home`)}
          selected={matchPath(pathname, `${basePath}/home`)?.isExact}
        >
          Home
        </SystemSidebarSection>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/accounts/overview`)}
          selected={
            matchPath(pathname, {
              path: `${basePath}/accounts`,
              exact: false
            }) !== null
          }
        >
          Accounts
        </SystemSidebarSection>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/documents`)}
          selected={matchPath(pathname, `${basePath}/documents`)?.isExact}
        >
          Documents
        </SystemSidebarSection>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/communications`)}
          selected={matchPath(pathname, `${basePath}/communications`)?.isExact}
        >
          Communications
        </SystemSidebarSection>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/actions`)}
          selected={matchPath(pathname, `${basePath}/actions`)?.isExact}
        >
          Actions
        </SystemSidebarSection>
        <SystemSidebarSection
          onClick={() => push(`${basePath}/updates`)}
          selected={matchPath(pathname, `${basePath}/updates`)?.isExact}
        >
          Updates
        </SystemSidebarSection>
      </div>
      <FeedbackForm
        isOpen={feedbackIsOpen}
        onClose={() => setFeedbackIsOpen(false)}
      />
    </>
  )
}

export default CompanyLinks
