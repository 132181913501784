import React from 'react'
import { Button } from '@middesk/components'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { ApplicationInvitation } from '../../types'

import Card from '../System/Card'
import Bubble from '../System/Bubble'
import { Body, LargeDisplay } from '../System/Typography'
import SPACING from '../System/Spacing'
import { COLORS } from '../System/Colors'

const CollectionSection = styled.div`
  display: flex;
  gap: ${SPACING.small};
  margin: ${SPACING.medium};
  justify-content: center;
`

const ApplicationInvitationsCard = ({
  outstandingInvitations
}: {
  outstandingInvitations: ApplicationInvitation[]
}): JSX.Element => {
  if (outstandingInvitations.length === 0) {
    return <></>
  }

  const { push } = useHistory()

  const partner = outstandingInvitations[0]?.partner
  return (
    <Card
      {...{ variant: 'primary', size: 'large' }}
      style={{ display: 'block', border: `${COLORS.orange} 2px solid` }}
    >
      <LargeDisplay style={{ margin: 'auto', textAlign: 'center' }}>
        Complete your registration requests
      </LargeDisplay>

      <Body style={{ textAlign: 'center' }}>
        {partner?.logo && (
          <div style={{ margin: SPACING.medium }}>
            <img src={partner.logo} width={150} />
          </div>
        )}
        <div
          style={{
            margin: `${SPACING.medium} 30px`
          }}
        >
          {partner?.name || 'A Middesk partner'} has requested that you register
          for payroll taxes in the following states:
        </div>
        <CollectionSection>
          {outstandingInvitations.map(invitation => (
            <Bubble key={invitation?.state} variant='orange'>
              {invitation?.state}
            </Bubble>
          ))}
        </CollectionSection>
      </Body>

      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Button
          onClick={() => {
            const firstInvitation = outstandingInvitations[0]
            push(`/applications/${firstInvitation?.application.id}`)
          }}
          style={{ fontWeight: 'bold' }}
        >
          Register Now
        </Button>
      </div>
    </Card>
  )
}

export default ApplicationInvitationsCard
