import { theme } from '@middesk/components'
import React, { useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { SIGNATURE_CANVAS_HEIGHT } from '../lib/constants'
import { SignaturePrintProps } from '../types'
import { COLORS } from './System/Colors'

const { spacing } = theme

const SignaturePrint = styled(({ className, src }: SignaturePrintProps) => {
  const [error, setError] = useState(false)
  return !error ? (
    <div {...{ className }}>
      <Row center='xs'>
        <Col xs>
          <img {...{ src }} onError={() => setError(true)} />
        </Col>
      </Row>
    </div>
  ) : (
    <div />
  )
})`
  background: ${COLORS.dawn};
  border-radius: ${spacing.compact};
  margin-top: 5px;
  width: 100%;
  max-height: ${SIGNATURE_CANVAS_HEIGHT}px;

  img {
    padding: ${spacing.normal};
    max-width: 100%;
    transform: scale(0.75);
    max-height: ${SIGNATURE_CANVAS_HEIGHT}px;
  }
`

export default SignaturePrint
