import React from 'react'

const UnsubscribedFromAppInvitationReminders = (): JSX.Element => {
  return (
    <>
      <img src='./icons/unplug.png' height={120} />
      <h1>You&apos;re unsubscribed</h1>
      <p>
        You’ve been successfully unsubscribed from reminder emails for all
        outstanding requests. If you unsubscribed by mistake, please contact{' '}
        <a href='mailto:agent@middesk.com'>agent@middesk.com</a>.
      </p>
    </>
  )
}

export default UnsubscribedFromAppInvitationReminders
