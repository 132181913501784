import { Button } from '@middesk/components'
import qs from 'qs'
import React, { ReactElement, useContext, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import ActionFooter from '../components/ActionFooter'
import Body from '../components/Body'
import Checkmark from '../components/Checkmark'
import LoaderButton from '../components/LoaderButton'
import Separator from '../components/Separator'
import StyledCard from '../components/StyledCard'
import { COLORS } from '../components/System/Colors'
import Middesk from '../components/System/Icons/Middesk'
import InfoTooltip from '../components/System/InfoTooltip'
import Page from '../components/System/Layout/Page'
import SPACING from '../components/System/Spacing'
import {
  TYPOGRAPHY_SIZES,
  TYPOGRAPHY_WEIGHTS,
  XLarge
} from '../components/System/Typography'
import { AuthContext } from '../contexts/AuthProvider'
import api from '../lib/api'

const TO_START_PAGE_DELAY = 1000

const REGISTERED_AGENT_SERVICES = 'registered_agent_services'
const ESSENTIAL_COMPONENTS = 'essential_components'

interface GridButtonParams {
  type: string
  hasContact: boolean
}

interface PricingSelectionTableRowParams {
  title: string
  type: string
  tooltipText?: string
  lastItem?: boolean
}

const LeftCol = styled(Col)`
  display: flex;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  font-size: ${TYPOGRAPHY_SIZES.small}px;
  justify-content: space-between;
  text-align: left;
`

const StyledButton = styled(Button)`
  max-width: 150px;
  width: 100%;
`

const CenteredHeader = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-top: ${SPACING.medium};
  margin-bottom: ${SPACING.large};
`

const LogoContainer = styled.div`
  display: flex;
  padding-left: ${SPACING.xlarge};

  ${XLarge} {
    padding: ${SPACING.xsmall} ${SPACING.small};
  }
`

const StyledSubheaderText = styled.div`
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

const StyledHeaderText = styled.div`
  font-size: ${TYPOGRAPHY_SIZES.xxlarge}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const StyledCardPadded = styled(StyledCard)`
  font-size: ${TYPOGRAPHY_SIZES.small}px;
  max-width: 800px;
  margin: 0 auto;
  padding: ${SPACING.xlarge};
`

const StyledRow = styled(Row)`
  margin-top: ${SPACING.medium};
`

const StyledSeparator = styled(Separator)`
  height: 2px;
  margin: ${SPACING.small} 0;
`

const Title = styled.div`
  color: ${COLORS.graphite};
  font-size: ${TYPOGRAPHY_SIZES.large}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  margin-bottom: ${SPACING.small};
`

const PricingTableHeaderSeparator = styled.div`
  background-color: ${COLORS.yellow};
  height: ${SPACING.xsmall};
  margin: ${SPACING.small} 0;
`

const PricingTableHeader = styled.div`
  margin-top: ${SPACING.large};
`

const CardTitle = styled.div`
  color: ${COLORS.black};
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
`

const StyledUnlimitedText = styled.b`
  background: ${COLORS.yellow};
`

const getRedirectPath = (search: any) => {
  const urlParams = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return urlParams.redirect_path
}

const PackageSelection = (): ReactElement => {
  const [selectedPackage, setSelectedPackage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const { push } = useHistory()

  const location = useLocation()
  const { setFetchingUser } = useContext(AuthContext)
  const redirectPath = getRedirectPath(location.search)

  const onClick = (e: React.FormEvent<HTMLFormElement>, value: string) => {
    if (value === 'unlimited') {
      e.preventDefault()
      window.open('https://www.middesk.com/contact-sales', '_blank')
      return
    }
    setSelectedPackage(value)
  }

  const onContinue = () => {
    const onSuccessPath = (redirectPath as string) || '/'

    setSubmitting(true)
    api
      .patch(`/ajax/agent/settings`, {
        package_type: selectedPackage
      })
      .then(() => {
        setFetchingUser(true)
        setTimeout(async () => {
          setSubmitting(false)
          push(onSuccessPath)
        }, TO_START_PAGE_DELAY)
      })
      /* eslint-disable-next-line no-console */
      .catch(console.error)
  }

  const selectedText = (value: string) => {
    return selectedPackage === value ? 'Selected' : 'Select'
  }

  const CheckMarkColumn = () => (
    <Col xs>
      <Checkmark color={COLORS.green} />
    </Col>
  )

  const GridButton = ({ type, hasContact }: GridButtonParams): ReactElement => (
    <Col xs>
      <StyledButton
        type='secondary'
        onClick={(e: React.MouseEvent<HTMLFormElement>) => onClick(e, type)}
        disabled={selectedPackage === type}
      >
        <b>{hasContact ? 'Contact Us' : selectedText(type)}</b>
      </StyledButton>
    </Col>
  )

  const PricingSelectionTableRow = ({
    title,
    type,
    tooltipText,
    lastItem
  }: PricingSelectionTableRowParams): ReactElement => {
    const leftColumn = (
      <LeftCol xs>
        <span>{title}</span>
        {tooltipText?.length ? <InfoTooltip text={tooltipText} /> : <></>}
      </LeftCol>
    )

    if (type === ESSENTIAL_COMPONENTS) {
      return (
        <>
          <Row center='xs' middle='xs'>
            {leftColumn}
            <CheckMarkColumn />
            <CheckMarkColumn />
            <CheckMarkColumn />
          </Row>
          {!lastItem && <StyledSeparator />}
        </>
      )
    } else if (type === REGISTERED_AGENT_SERVICES) {
      return (
        <>
          <Row center='xs' middle='xs'>
            {leftColumn}
            <Col xs>Only when required for payroll accounts</Col>
            <Col xs>All 50 states</Col>
            <Col xs>All 50 states</Col>
          </Row>
          {!lastItem && <StyledSeparator />}
        </>
      )
    }

    return <></>
  }

  return (
    <Page>
      <Body title='' description=''>
        <LogoContainer>
          <Middesk />
        </LogoContainer>
        <CenteredHeader>
          <StyledHeaderText>Select package</StyledHeaderText>
          <StyledSubheaderText>
            Please set the default package for your orders.
          </StyledSubheaderText>
        </CenteredHeader>
        <StyledCardPadded>
          <Row center='xs' middle='xs'>
            <Col xs></Col>
            <Col xs>
              <Title>Essentials</Title>
            </Col>
            <Col xs>
              <Title>Premium</Title>
            </Col>
            <Col xs>
              <Title>Unlimited</Title>
            </Col>
          </Row>
          <Row center='xs' middle='xs'>
            <Col xs></Col>
            <Col xs>
              Register for state payroll tax accounts in less than 10 mins
            </Col>
            <Col xs>Essentials + Registered Agent Services</Col>
            <Col xs>
              Premium coverage for
              <br />
              <StyledUnlimitedText>all 50 states</StyledUnlimitedText> at one
              flat fee
            </Col>
          </Row>
          <PricingTableHeader>
            <CardTitle>
              The essential components to hire in a new state
            </CardTitle>
          </PricingTableHeader>
          <PricingTableHeaderSeparator />
          <Row center='xs' middle='xs'>
            <LeftCol xs>
              <span>Payroll tax account setup</span>
              <InfoTooltip text='Withholding + Unemployment Insurance' />
            </LeftCol>
            <Col xs>All 50 states</Col>
            <Col xs>All 50 states</Col>
            <Col xs>All 50 states</Col>
          </Row>
          <StyledSeparator />
          <PricingSelectionTableRow
            title='Waived state filing fees (Up to $800)'
            type={ESSENTIAL_COMPONENTS}
          />
          <PricingSelectionTableRow
            title='Submission within 24 hours to the state'
            type={ESSENTIAL_COMPONENTS}
            tooltipText='Processing time may vary between states'
          />
          <PricingSelectionTableRow
            title='State mail processing'
            type={ESSENTIAL_COMPONENTS}
            tooltipText={`We’ll recieve, scan, and tag incoming mail so you can stay on top of critical notices`}
          />
          <PricingSelectionTableRow
            title='SUI rate change monitoring'
            type={ESSENTIAL_COMPONENTS}
          />
          <PricingSelectionTableRow
            title='Intuitive dashboard to track and manage state accounts'
            type={ESSENTIAL_COMPONENTS}
            lastItem
          />
          <PricingTableHeader>
            <CardTitle>
              Registered Agent Services + protection against business risks
            </CardTitle>
          </PricingTableHeader>
          <PricingTableHeaderSeparator />
          <PricingSelectionTableRow
            title='Secretary of State account creation'
            type={REGISTERED_AGENT_SERVICES}
          />
          <PricingSelectionTableRow
            title='Annual report filings'
            type={REGISTERED_AGENT_SERVICES}
          />
          <PricingSelectionTableRow
            title='Service of process'
            type={REGISTERED_AGENT_SERVICES}
            lastItem
          />
          <StyledRow center='xs' middle='xs'>
            <Col xs></Col>
            <Col xs>
              <b>$45/state/month</b>
            </Col>
            <Col xs>
              <b>$75/state/month</b>
            </Col>
            <Col xs>
              <b>$15,000/year</b>
            </Col>
          </StyledRow>
          <StyledRow center='xs' middle='xs'>
            <Col xs></Col>
            <GridButton type='basic' hasContact={false} />
            <GridButton type='premium' hasContact={false} />
            <GridButton type='unlimited' hasContact />
          </StyledRow>
          <ActionFooter>
            <LoaderButton
              {...{
                submitting,
                isDisabled: !selectedPackage,
                onClick: onContinue,
                submitText: 'Continue',
                type: 'submit'
              }}
            />
          </ActionFooter>
        </StyledCardPadded>
      </Body>
    </Page>
  )
}

export default PackageSelection
