import React, { useState } from 'react'

import {
  CaretDownOutline as CaretDown,
  CaretForwardOutline as CaretForward
} from 'react-ionicons'
import styled from 'styled-components'
import { Attribute, theme } from '@middesk/components'

import { COLORS } from '../../components/System/Colors'
import Link from '../../components/System/Link'
import {
  TYPOGRAPHY_SIZES,
  TYPOGRAPHY_WEIGHTS
} from '../../components/System/Typography'
import { TaxAccount, AccountSecurityQuestion } from '../../types'
import {
  BLOCKED_BY_GOVERNMENT,
  GOVERNMENT_PROCESSING,
  INFORMATION_REQUESTED,
  MIDDESK_PROCESSING
} from '../../lib/constants'

const { spacing, typography, colors } = theme

const BoldAttribute = styled(Attribute)`
  margin: 0 ${spacing.normal} ${spacing.normal} 0;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};

  > div:nth-child(2) {
    padding-top: 5px;
    font-weight: ${TYPOGRAPHY_WEIGHTS.regular};
  }
`

const StyledCaretDown = styled(CaretDown)`
  > svg {
    vertical-align: -3px;
    margin-left: -5px;
  }
`

const StyledCaretForward = styled(CaretForward)`
  > svg {
    vertical-align: -3px;
    margin-left: -5px;
  }
`

const SubHeader = styled.div`
  color: ${COLORS.graphite};
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const EyesIcon = styled.img`
  cursor: pointer;
`

const CredentialHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const CredentialsContainer = styled.div`
  margin-top: ${spacing.normal};
`

const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
`

const Password = styled.div`
  min-width: 135px;
  margin-right: ${spacing.compact};
`

const QuestionsHeader = styled.div`
  display: flex;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};

  span {
    min-width: 145px;
  }
`

const QuestionWrapper = styled.div`
  margin-top: 20px;
`

const NoCredentials = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
`

const TaxAccountContainer = ({
  account,
  status
}: {
  account: TaxAccount
  status: string
}) => {
  const [showCredentials, setShowCredentials] = useState(false)
  const [revealPassword, setPasswordRevealed] = useState(false)
  const [revealQuestions, setQuestionsRevealed] = useState(false)
  const [revealPin, setPinRevealed] = useState(false)
  const {
    email,
    username,
    password,
    agency_link,
    agency,
    pin,
    security_questions
  } = account

  const Caret = showCredentials ? StyledCaretDown : StyledCaretForward

  const CredentialsSection = () => (
    <>
      {agency_link && agency && (
        <BoldAttribute label='Website'>
          <Link target='_blank' href={agency_link}>
            {agency}
          </Link>
        </BoldAttribute>
      )}
      {email && <BoldAttribute label='Email'>{email}</BoldAttribute>}
      {username && <BoldAttribute label='Username'>{username}</BoldAttribute>}
      {password && (
        <BoldAttribute label='Password'>
          <PasswordContainer>
            <Password>{revealPassword ? password : '•'.repeat(15)}</Password>
            <EyesIcon
              src='/password.svg'
              onClick={() => setPasswordRevealed(!revealPassword)}
            />
          </PasswordContainer>
        </BoldAttribute>
      )}
      {pin && (
        <BoldAttribute label='PIN'>
          <PasswordContainer>
            <Password>{revealPin ? pin : '•'.repeat(pin.length)}</Password>
            <EyesIcon
              src='/password.svg'
              onClick={() => setPinRevealed(!revealPin)}
            />
          </PasswordContainer>
        </BoldAttribute>
      )}
      {(security_questions || []).length > 0 && (
        <>
          <QuestionsHeader>
            <span>Security Questions</span>
            <EyesIcon
              src='/password.svg'
              onClick={() => setQuestionsRevealed(!revealQuestions)}
            />
          </QuestionsHeader>
          {revealQuestions && (
            <QuestionWrapper>
              {(security_questions || []).map(
                (question: AccountSecurityQuestion, index: number) => {
                  return (
                    <div key={`security-question-${index}`}>
                      <BoldAttribute label={question.question}>
                        {question.answer}
                      </BoldAttribute>
                    </div>
                  )
                }
              )}
            </QuestionWrapper>
          )}
        </>
      )}
    </>
  )

  return (
    <div>
      <CredentialHeader onClick={() => setShowCredentials(!showCredentials)}>
        <Caret height={'17px'} width={'17px'} />
        <SubHeader>Credentials</SubHeader>
      </CredentialHeader>
      {showCredentials && (
        <CredentialsContainer>
          {[
            MIDDESK_PROCESSING,
            INFORMATION_REQUESTED,
            GOVERNMENT_PROCESSING,
            BLOCKED_BY_GOVERNMENT
          ].includes(status) ? (
            <NoCredentials>
              <b>No credentials available:</b> Middesk will set up an online
              account if necessary for the registration process. The account
              credentials will appear here once your registration has been
              completed.
            </NoCredentials>
          ) : (
            <CredentialsSection />
          )}
        </CredentialsContainer>
      )}
    </div>
  )
}

export default TaxAccountContainer
