import React, { FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthProvider'
import { CompanyContext } from './CompanyProvider'

interface Props {
  exact?: boolean
  path: string
  redirectPath: string
}

const DefaultCompanyRedirect: FC<Props> = ({
  path,
  exact = false,
  redirectPath
}: Props) => {
  const { authenticated } = useContext(AuthContext)

  const { activeCompanyId, fetchingActiveCompany } = useContext(CompanyContext)

  if (!authenticated) {
    return (
      <Route
        exact={exact}
        path={path}
        render={({ location }) => (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )}
      />
    )
  }

  if (fetchingActiveCompany) {
    return null
  }

  const pathname = activeCompanyId
    ? redirectPath.replace(/:companyId/, activeCompanyId)
    : '/set-user-info'

  return <Redirect exact={exact} from={path} to={{ pathname }} />
}

export default DefaultCompanyRedirect
