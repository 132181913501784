import styled from 'styled-components'
import BORDER_RADIUS from './BorderRadius'
import { COLORS } from './Colors'
import SPACING from './Spacing'

export const CARD_BORDER_WIDTH = 2

export const MIN_CARD_WIDTH = 300
export const SMALL_CARD_MAX_WIDTH = 500
export const MEDIUM_CARD_MAX_WIDTH = 750
export const LARGE_CARD_MAX_WIDTH = 1000

export type CardSize = 'small' | 'medium' | 'large'
export type CardVariant = 'primary' | 'secondary' | 'tertiary'
export type CardProps = {
  size?: CardSize
  variant?: CardVariant
}

const Card = styled.div`
  background: ${COLORS.white};
  border: ${CARD_BORDER_WIDTH}px solid ${COLORS.dawn};
  border-radius: ${BORDER_RADIUS.small};
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  min-width: ${MIN_CARD_WIDTH}px;
  padding: ${SPACING.xlarge};

  ${({ size }: CardProps) => {
    if (size === 'small') {
      return `
        max-width: ${SMALL_CARD_MAX_WIDTH}px;
        width: 48.5%;
      `
    } else if (size === 'medium') {
      return `
        max-width: ${MEDIUM_CARD_MAX_WIDTH}px;
        width: 75%;
      `
    } else if (size === 'large') {
      return `
        width: 100%;
      `
    } else {
      return `
        width: fit-content;
      `
    }
  }}

  ${({ variant }: CardProps) => {
    if (variant === 'tertiary') {
      return `
        background: ${COLORS.frost_l1};
      `
    } else {
      return ``
    }
  }}
`

Card.defaultProps = {
  variant: 'primary'
}

export default Card
