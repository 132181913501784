import React from 'react'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { FormValues, LocalQuestion } from '../../types'
import { getQuestionComponent } from '../DynamicStatePage'

const mapQuestionsToFormFields = (
  questions: LocalQuestion[],
  localJurisdiction: string,
  values: FormValues
) =>
  questions.map((question: LocalQuestion, index: number) => {
    const name = `questions.${localJurisdiction}.${question.key}`
    const questionValue = get(values, name)

    return (
      <div key={question.key}>
        {getQuestionComponent(
          { ...question, categories: [] },
          name,
          questionValue,
          index,
          values,
          undefined
        )}
      </div>
    )
  })

const DynamicLocalQuestions = ({
  localQuestions,
  jurisdictionSlug
}: {
  localQuestions: LocalQuestion[]
  jurisdictionSlug: string
}) => {
  const { values } = useFormikContext<FormValues>()

  const filteredQuestions = localQuestions.filter((question: LocalQuestion) => {
    return !question.api_only
  })

  if (filteredQuestions.length === 0) {
    return <></>
  }

  return (
    <>{mapQuestionsToFormFields(filteredQuestions, jurisdictionSlug, values)}</>
  )
}

export default DynamicLocalQuestions
