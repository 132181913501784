import { get, isNumber } from 'lodash'
import { array, object, number, string } from 'yup'
import { Stock } from '../types'
import {
  STATES_VALIDATING_AUTHORIZED_AND_ISSUED_SHARES,
  STATES_VALIDATING_AUTHORIZED_SHARES,
  STATES_VALIDATING_PAR_VALUE
} from './constants'

export const shareSchema = object().shape({
  number: number().required(),
  series: string().required(),
  class: string().required(),
  par_value: number().test(
    'conditionally-require-par-value',
    'Par Value is required for this state',
    // https://github.com/jquense/yup/issues/735#issuecomment-873828710
    function (this: any, fieldValue: any): boolean {
      const { from } = this
      const state = get(from, '[2].value.tax_registrations[0].state', '')

      fieldValue = fieldValue as number

      if (
        state &&
        !!fieldValue &&
        STATES_VALIDATING_PAR_VALUE.includes(state)
      ) {
        return !!fieldValue || isNumber(fieldValue)
      }

      return true
    }
  )
})

const authorizedSharesSchema = array()
  .of(shareSchema)
  .test(
    'conditonally-require-authorized',
    'Authorized Shares are required',
    // https://github.com/jquense/yup/issues/735#issuecomment-873828710
    function (this: any, fieldValue: any): boolean {
      const { from } = this
      const state = get(from, '[1].value.tax_registrations[0].state', '')
      fieldValue = fieldValue as Stock[]

      if (
        state &&
        !!fieldValue &&
        (STATES_VALIDATING_AUTHORIZED_SHARES.includes(state) ||
          STATES_VALIDATING_AUTHORIZED_AND_ISSUED_SHARES.includes(state))
      ) {
        return fieldValue.length >= 1
      }

      return true
    }
  )

const issuedSharesSchema = array()
  .of(shareSchema)
  .test(
    'conditonally-require-issued',
    'Issued Shares are required',
    // https://github.com/jquense/yup/issues/735#issuecomment-873828710
    function (this: any, fieldValue: any): boolean {
      const { from } = this
      const state = get(from, '[1].value.tax_registrations[0].state', '')
      fieldValue = fieldValue as Stock[]

      if (
        state &&
        !!fieldValue &&
        STATES_VALIDATING_AUTHORIZED_AND_ISSUED_SHARES.includes(state)
      ) {
        return fieldValue.length >= 0
      }

      return true
    }
  )

const getSchema = ({ validateValue }: { validateValue?: boolean }) => {
  if (validateValue) {
    return object().shape({
      state: string(),
      value: object().shape({
        authorized_shares: authorizedSharesSchema,
        issued_shares: issuedSharesSchema
      })
    })
  }

  return object().shape({
    state: string()
  })
}

export default getSchema
