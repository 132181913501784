import React, { createContext, useState, useEffect, useContext } from 'react'

interface GeoLocationProps {
  IPv4: string
}

const GeoLocationContext = createContext<GeoLocationProps>({ IPv4: '' })

const GeoLocationProvider = ({ children }: { children: any }) => {
  const [geoLocation, setGeoLocation] = useState<GeoLocationProps>({
    IPv4: ''
  })

  useEffect(() => {
    fetch('https://geolocation-db.com/json/')
      .then(res => res.json())
      .then(json => {
        setGeoLocation(json)
      })
      .catch(() => {
        setGeoLocation({ IPv4: '' })
      })
  }, [])

  return (
    <GeoLocationContext.Provider value={{ IPv4: geoLocation.IPv4 }}>
      {children}
    </GeoLocationContext.Provider>
  )
}

export const useGeoLocation = () => {
  return useContext(GeoLocationContext)
}

export default GeoLocationProvider
