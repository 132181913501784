import React, { useState, useEffect, useContext } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import * as yup from 'yup'
import { Form } from '@middesk/components'
import api from '../../lib/api'
import Separator from '../Separator'
import LoaderButton from '../LoaderButton'
import Loader from '../System/Loader'
import { TYPOGRAPHY_WEIGHTS } from '../System/Typography'
import { COLORS } from '../System/Colors'
import PaymentCapture from '../PaymentCapture'
import { AuthContext } from '../../contexts/AuthProvider'

const LineItems = styled.div`
  padding-top: 5px;
`

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  color: ${COLORS.graphite};
`

const TotalItem = styled(LineItem)`
  padding-top: 10px;
  margin-top: 10px;
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  border-top: 2px solid ${COLORS.dawn};
`

type Props = {
  account_creation_fee_cents: number
  state_fee_cents: number
  annual_fee_cents: number
  submitAction: () => void
}

const Payment = ({
  account_creation_fee_cents,
  state_fee_cents,
  annual_fee_cents,
  submitAction
}: Props): JSX.Element => {
  const initialValues = { accept_tos: false }

  const { internal } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [setupSecret, setSetupSecret] = useState('')
  const [error, setError] = useState('')
  const [paymentReady, setPaymentReady] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState(initialValues)

  const validationSchema = yup.object().shape({
    accept_tos: yup.bool().oneOf([true], 'You Must Accept Payment Usage Terms')
  })

  useEffect(() => {
    if (internal) {
      setLoading(false)
      return
    }

    api
      .get('/ajax/setup_card')
      .then(json => setSetupSecret(json.client_secret))
      .finally(() => setLoading(false))
  }, [])

  const cardSubmitted = () => {
    setSubmitting(false)
    submitAction()
  }

  const foreignQualificationLineItems = () => {
    const total_cents =
      account_creation_fee_cents + state_fee_cents + annual_fee_cents

    return (
      <LineItems>
        <LineItem>
          <span>Secretary of State account creation</span>
          <span>${account_creation_fee_cents / 100}</span>
        </LineItem>
        <LineItem>
          <span>Additional state fees (estimated)</span>
          <span>${state_fee_cents / 100}</span>
        </LineItem>
        <LineItem>
          <span>Registered Agent services</span>
          <span>${annual_fee_cents / 100} / year</span>
        </LineItem>
        <TotalItem>
          <span>Total</span>
          <span>${total_cents / 100}</span>
        </TotalItem>
      </LineItems>
    )
  }

  return (
    <Form
      {...{
        initialValues,
        validationSchema,
        onChange: (e: any) => setValues(e)
      }}
    >
      <Loader {...{ loading }}>
        <PaymentCapture
          setPaymentReady={setPaymentReady}
          submitting={submitting}
          setSubmitting={setSubmitting}
          applicationUpdate={cardSubmitted}
          error={error}
          setError={setError}
          setupSecret={setupSecret}
          lineItems={foreignQualificationLineItems()}
          label={
            "By clicking this box, you authorize Middesk to charge your credit card. You'll be billed once we've set up your Secretary of State account."
          }
        />
        <Separator />
        <Row end='xs'>
          <Col xs>
            <LoaderButton
              {...{
                submitting,
                isDisabled:
                  !validationSchema.isValidSync(values) || !paymentReady,
                onClick: e => {
                  const formEvent = e as React.FormEvent<HTMLFormElement>

                  formEvent.preventDefault()

                  setSubmitting(true)
                },
                submitText: 'Submit',
                type: 'primary'
              }}
            />
          </Col>
        </Row>
      </Loader>
    </Form>
  )
}

export default Payment
