import React, { useContext } from 'react'
import styled from 'styled-components'
import { SignupHeader } from '../pages/SignupNew'
import { Body as Medium, TYPOGRAPHY_WEIGHTS, XLarge } from './System/Typography'
import SPACING from './System/Spacing'
import { useHistory, useParams } from 'react-router'
import Card from './System/Card'
import Bubble from './System/Bubble'
import Separator from './Separator'
import Button from './System/Button'
import Document from './System/Icons/Document'
import Mail from './System/Icons/Mail'
import { get } from 'lodash'
import { AuthContext } from '../contexts/AuthProvider'
import APIAgentTransfer from '../lib/api/agentTransfer'

export type TransferRegistrationsProps = {
  className: string
}

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.small};
  justify-content: flex-end;
`

const ItemSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > div:first-child {
    margin-right: ${SPACING.small};
  }
`

const CollectionSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.small};
`

const TransferRegistrations = styled(
  ({ className }: TransferRegistrationsProps) => {
    const {
      location: { state }
    } = useHistory()
    const { companyId } = useParams<{ companyId: string }>()

    const { user } = useContext(AuthContext)

    const { push } = useHistory()

    if (!state) {
      push('/')
    }

    const onSkip_ = () => {
      let pathname

      if (user && !user?.account.settings.agent.package_type) {
        pathname = '/select-package'
      } else {
        pathname = '/home'
      }

      push({
        pathname,
        state: null
      })
    }

    const onNext = async () => {
      if (user && !user?.account.settings.agent.package_type) {
        push({
          pathname: `/select-package`,
          state
        })
      } else {
        const currentTransfer = await APIAgentTransfer.index(companyId, {
          status: 'in_progress'
        })
          .then(({ data }) => data[0])
          .catch(() => null)

        if (currentTransfer?.id) {
          push({
            pathname: `/transfers/${currentTransfer.id}`,
            state
          })
        } else {
          const newTransfer = await APIAgentTransfer.create(companyId)

          push({
            pathname: `/transfers/${newTransfer.id}`,
            state
          })
        }
      }
    }

    const onSkip = onSkip_
    const onContinue = onNext

    return (
      <div {...{ className }}>
        <SignupHeader />
        <XLarge>Transfer existing accounts</XLarge>
        <div>
          <Medium>
            Let Middesk manage all of your accounts, regardless of who set them
            up.
          </Medium>
        </div>
        <Card variant='secondary' size='medium'>
          <Medium>
            We found that you&apos;re registered in the following states:
          </Medium>
          <CollectionSection>
            {get(state, 'registrations', []).map((e: { state: string }) => (
              <Bubble key={e.state} variant='karl'>
                {e.state}
              </Bubble>
            ))}
          </CollectionSection>
          <Separator />
          <Medium>
            You can transfer your accounts to Middesk to manage your state
            compliance:
          </Medium>
          <ItemSection>
            <Bubble variant='purple'>
              <Mail />
            </Bubble>
            <div>
              <Medium bold>Payroll Tax</Medium>
              <Medium>
                Process your state mail and monitor SUI rate changes
              </Medium>
            </div>
          </ItemSection>
          <ItemSection>
            <Bubble variant='orange'>
              <Document />
            </Bubble>
            <div>
              <Medium bold>Secretary of State</Medium>
              <Medium>
                Manage your annual report filings, annual franchise tax filings,
                and service of process
              </Medium>
            </div>
          </ItemSection>
          <Separator />
          <ActionGroup>
            <Button type='secondary' {...{ onClick: onSkip }}>
              Skip
            </Button>
            <Button {...{ onClick: onContinue }}>Continue</Button>
          </ActionGroup>
        </Card>
      </div>
    )
  }
)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${SPACING.xsmall};

  ${Separator} {
    margin: 0;
  }

  ${XLarge} {
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
    margin-top: ${SPACING.xlarge};
  }

  ${SignupHeader} {
    padding-left: ${SPACING.xlarge};
    width: calc(100% - ${SPACING.xlarge});
  }

  ${Bubble} {
    padding: ${SPACING.small};
  }

  ${Card} {
    display: flex;
    flex-direction: column;
    gap: ${SPACING.medium};
    margin-top: ${SPACING.medium};
  }
`

export default TransferRegistrations
