import React, { MouseEventHandler } from 'react'
import { BackButton, Button, Loader, theme } from '@middesk/components'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import LoaderButton from './LoaderButton'
import { MOBILE_BUTTON_HEIGHT } from '../lib/constants'
import PoweredByMiddesk from './System/Icons/PoweredByMiddesk'
import { MAX_WIDTH_MOBILE } from './Body'

const { colors, spacing, typography } = theme

const FlexContainer = styled.div`
  justify-content: flex-end;
  text-align: end;
  display: flex;

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    justify-content: center;
    width: 100%;
  }
`

const BackButtonContainer = styled.div`
  flex-grow: 0;
  flex-basis: 0;
  max-width: 100%;

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }
`

const EmptyBackButtonWrapper = styled(BackButton)`
  background-image: none;
`

export const ErrorMessage = styled.div`
  color: ${colors.red};
  line-height: 35px;
  padding: 0 ${spacing.normal};
  font-size: ${typography.sizes.medium};
`

const PoweredByMiddeskDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.large};
`

const PrimaryButton = styled(LoaderButton)`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    width: 100%;

    button {
      height: ${MOBILE_BUTTON_HEIGHT};
      max-height: ${MOBILE_BUTTON_HEIGHT};
      width: 100%;
    }
  }
`

const Container = styled.div`
  margin-top: ${spacing.large};

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    background: ${colors.white};
    width: 100%;
    margin-bottom: ${spacing.medium};
  }
`

const ProgressBar = styled.progress`
  background-color: ${colors.white};
  margin-bottom: ${spacing.xsmall};
  width: 100%;
  height: 5px;

  @media (min-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }

  ::-webkit-progress-bar {
    background: ${colors.frostLight};
  }
  ::-webkit-progress-value {
    background: ${colors.greenAccent};
  }
`

const ProgressBarLabel = styled.div`
  color: ${colors.graphite};
  font-size: ${typography.sizes.medium};
  font-weight: ${typography.weights.bold};
  margin-bottom: ${spacing.xlarge};

  @media (min-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }
`

const NewRegistrationIntakeFooter = styled(
  ({
    onClick,
    isSubmitting,
    isDisabled,
    onCancel,
    backLoading,
    error,
    submitText,
    secondaryButton,
    progress,
    title,
    showPoweredByMiddesk
  }: {
    onClick: (
      e:
        | React.MouseEventHandler<HTMLButtonElement>
        | React.FormEvent<HTMLFormElement>
    ) => void
    isSubmitting: boolean
    isDisabled: boolean
    onCancel?: () => void
    backLoading?: boolean
    error?: string | null
    submitText?: string
    secondaryButton?: { text: string; onClick: MouseEventHandler }
    progress?: string
    title?: string
    showPoweredByMiddesk?: boolean
  }): JSX.Element => {
    return (
      <Container>
        <ProgressBar value={progress || 0} max={100} />
        {title && <ProgressBarLabel>{title}</ProgressBarLabel>}
        <Row between='xs'>
          {onCancel ? (
            <BackButtonContainer>
              {backLoading ? (
                <EmptyBackButtonWrapper>
                  <Loader size='small' />
                </EmptyBackButtonWrapper>
              ) : (
                <BackButton onClick={onCancel} type='cancel' />
              )}
            </BackButtonContainer>
          ) : (
            <></>
          )}
          <Col xs>
            <FlexContainer>
              {error ? <ErrorMessage>{error}</ErrorMessage> : <></>}
              <FlexContainer>
                {secondaryButton && (
                  <Button
                    style={{ marginRight: '10px' }}
                    type='secondary'
                    onClick={secondaryButton.onClick}
                  >
                    {secondaryButton.text}
                  </Button>
                )}
                <PrimaryButton
                  submitting={isSubmitting}
                  isDisabled={isDisabled}
                  submitText={submitText ? submitText : 'Continue'}
                  type={'primary'}
                  onClick={onClick}
                />
              </FlexContainer>
            </FlexContainer>
          </Col>
        </Row>
        {showPoweredByMiddesk && (
          <PoweredByMiddeskDiv>
            <PoweredByMiddesk />
          </PoweredByMiddeskDiv>
        )}
      </Container>
    )
  }
)``

export default NewRegistrationIntakeFooter
