import React, { useEffect, useState } from 'react'
import Page from '../../components/System/Layout/Page'
import Sidebar from '../../components/Sidebar'
import {
  Button,
  List,
  ListPagination,
  Modal,
  TextField,
  theme
} from '@middesk/components'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { parse } from 'qs'
import APICompany from '../../lib/api/company'
import pluralize from 'pluralize'
import DebouncedField from '../../components/Forms/DebouncedField'
import { Formik } from 'formik'
import * as yup from 'yup'
import { isEmpty, omitBy } from 'lodash'
import {
  BLOCKED_BY_GOVERNMENT,
  COMPLETED,
  DUPLICATE,
  GOVERNMENT_PROCESSING,
  INFORMATION_REQUESTED,
  MIDDESK_PROCESSING,
  UNMANAGED
} from '../../lib/constants'
import { Company } from '../../types'
import { SkeletonArray } from '../../components/Shared'
import { GapWrapper } from '../../components/Page'

const { colors } = theme

const DEFAULT_PAGE_SIZE = 30

const Centered = styled.div`
  text-align: center;
  width: 100%;
`

const Circle = styled.div`
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  width: 10px;
`

const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
    display: flex;

    &.left {
      align-items: flex-start;
      align-items: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
`

const getUrlParams = (params: any) =>
  new URLSearchParams(
    (omitBy(params, val => !val) as unknown) as URLSearchParams
  ).toString()

const validationSchema = yup.object().shape({
  legal_name: yup.string().required('Required'),
  ein: yup.string().optional().nullable()
})

type CompanySearchParams = {
  page?: string
  per_page?: string
  q?: string
}

const StatContainer = ({
  company: { tax_agency_registrations },
  statuses,
  color
}: {
  company: Company
  statuses: string[]
  color: string
}): JSX.Element | null => {
  const count = (tax_agency_registrations || []).filter(({ status: s }) =>
    statuses.includes(s)
  ).length
  if (count === 0) {
    return null
  }

  return (
    <Centered>
      <Circle color={color} />
      {count}
    </Centered>
  )
}

const CompaniesList = (): JSX.Element => {
  const { search } = useLocation()
  const history = useHistory()

  const [fetchingCompanies, setFetchingCompanies] = useState(true)
  const [companies, setCompanies] = useState<{
    total_count: number
    data: Company[]
  }>()
  const [showModal, setShowModal] = useState(false)

  const params = parse(search, { ignoreQueryPrefix: true })

  const q = params.q as string
  const page = Number(params.page) || 1
  const per_page = Number(params.per_page) || DEFAULT_PAGE_SIZE

  useEffect(() => {
    fetchCompanies()
  }, [q, page, per_page])

  const fetchCompanies = () => {
    setFetchingCompanies(true)
    APICompany.index({
      q: isEmpty(q) ? undefined : q,
      page,
      per_page,
      sort_by: 'created_at',
      sort_order: 'desc'
    })
      .then(setCompanies)
      .finally(() => setFetchingCompanies(false))
  }

  const updateParams = (newParams: CompanySearchParams) => {
    history.push(['/companies', getUrlParams(newParams)].join('?'))
  }

  const createCompany = (form: { legal_name: string; ein: string }) => {
    APICompany.create(form).then(() => {
      setShowModal(false)
      if (isEmpty(getUrlParams(params))) {
        // fetch updated companies even if the params didn't change
        fetchCompanies()
      } else {
        updateParams({})
      }
    })
  }

  const columns = [
    {
      title: 'Name',
      key: 'legal_name',
      width: 3
    },
    { title: 'EIN', key: 'ein', width: 3 },
    {
      title: <Centered>Action required</Centered>,
      key: 'action_required',
      width: 3,
      render: (company: Company) => (
        <StatContainer
          company={company}
          statuses={[INFORMATION_REQUESTED]}
          color={colors.orange}
        />
      )
    },
    {
      title: <Centered>Processing</Centered>,
      key: 'processing',
      width: 2,
      render: (company: Company) => (
        <StatContainer
          company={company}
          statuses={[
            BLOCKED_BY_GOVERNMENT,
            MIDDESK_PROCESSING,
            GOVERNMENT_PROCESSING
          ]}
          color={colors.yellow}
        />
      )
    },
    {
      title: <Centered>Completed</Centered>,
      key: 'completed',
      width: 2,
      render: (company: Company) => (
        <StatContainer
          company={company}
          statuses={[COMPLETED]}
          color={colors.green}
        />
      )
    },
    {
      title: <Centered>Duplicate/Unmanaged</Centered>,
      key: 'duplicate_unmanaged',
      width: 3,
      render: (company: Company) => (
        <StatContainer
          company={company}
          statuses={[DUPLICATE, UNMANAGED]}
          color={colors.karl}
        />
      )
    }
  ]

  return (
    <>
      <Page title='Entities'>
        <Sidebar />
        <List
          data={companies?.data || []}
          columns={columns}
          onRowClick={(_e, { id }: Company) =>
            history.push(`/companies/${id}/home`)
          }
        >
          <ListHeader>
            <div className='left'>
              <div style={{ width: '315px' }}>
                <DebouncedField
                  placeholder='Search entity name or ein'
                  value={q}
                  updateValue={newQ =>
                    updateParams({ ...params, q: newQ, page: '1' })
                  }
                />
              </div>
              <div style={{ color: colors.karl, marginLeft: '10px' }}>
                {companies?.total_count &&
                  pluralize('Entity', companies.total_count, true)}
              </div>
            </div>
            <div className='right'>
              {companies?.total_count && (
                <ListPagination
                  onPage={newPage =>
                    updateParams({ ...params, page: newPage as string })
                  }
                  page={page}
                  perPage={per_page}
                  total={companies.total_count}
                />
              )}
              <Button
                onClick={() => setShowModal(true)}
                style={{ marginLeft: '10px' }}
              >
                Add entity
              </Button>
            </div>
          </ListHeader>
        </List>
        {fetchingCompanies && <SkeletonArray />}
      </Page>
      {showModal && (
        <Formik
          initialValues={{ legal_name: '', ein: '' }}
          onSubmit={createCompany}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <Modal
              title='Add entity'
              styles={{ width: '500px', height: '350px' }}
              isOpen={true}
              confirmLabel='Add entity'
              confirm={() => handleSubmit()}
              isConfirmDisabled={!isValid || isSubmitting}
              closeLabel='Cancel'
              close={() => setShowModal(false)}
            >
              <GapWrapper>
                <TextField
                  label='Legal name'
                  name='legal_name'
                  showErrorMessage
                />
                <TextField label='EIN (optional)' name='ein' showErrorMessage />
              </GapWrapper>
            </Modal>
          )}
        </Formik>
      )}
    </>
  )
}

export default CompaniesList
