import {
  GetTaxAgencyRegistrationsParameters,
  UpdateUnmanagedTaxAgencyRegistrationsParameters,
  TaxAgencyRegistration,
  AgencyRegistration
} from '../../types'
import api from '../api'

export type GetTaxAgencyRegistrationsResponse = {
  data: Array<TaxAgencyRegistration | AgencyRegistration>
  has_more: boolean
  total_count: number
}

export const APITaxAgencyRegistration = {
  index: (
    companyId: string,
    params: GetTaxAgencyRegistrationsParameters
  ): Promise<GetTaxAgencyRegistrationsResponse> =>
    api.get(
      `/v1/agent/companies/${companyId}/tax_agency_registrations`,
      params
    ),
  update: (
    companyId: string,
    params: UpdateUnmanagedTaxAgencyRegistrationsParameters
  ): Promise<TaxAgencyRegistration> => {
    return api.patch(
      `/v1/agent/companies/${companyId}/tax_agency_registrations/${params.id}`,
      params
    )
  }
}
