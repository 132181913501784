import React, { useEffect } from 'react'
import { Attribute } from './ReviewAndFile'
import { Individual, Owner } from '../types'
import { GapWrapper } from '../components/Page'
import styled from 'styled-components'
import { Icon, theme } from '@middesk/components'
import { useFormikContext } from 'formik'
import { get, isString } from 'lodash'
import { AGENT_INDIVIDUAL } from '../lib/constants'

const { typography, colors, spacing } = theme

const Content = styled.span`
  font-size: ${typography.sizes.medium};
`

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xsmall};
  margin: ${spacing.xxsmall} 0;
`

const ErrorRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${spacing.xsmall};
`

const ErrorIcon = styled(Icon)`
  display: flex;

  svg {
    width: 18px;
    height: 18px;
  }
`

const ErrorLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${colors.red};
  font-size: ${typography.sizes.medium};
`

export const IndividualErrors = ({
  fieldName
}: {
  fieldName: string
}): JSX.Element | null => {
  const { errors } = useFormikContext()

  const fieldErrors: { [key: string]: string } = get(errors, fieldName)

  if (!fieldErrors || isString(fieldErrors)) {
    return null
  }

  return (
    <ErrorWrapper>
      {Object.keys(fieldErrors).map(key => {
        const errorMessage =
          key == 'address' ? 'Invalid address' : fieldErrors[key]

        return (
          <ErrorRow key={key}>
            <ErrorIcon name={'cross2'} color={colors.red} />
            <ErrorLabel>{errorMessage}</ErrorLabel>
          </ErrorRow>
        )
      })}
    </ErrorWrapper>
  )
}

export const OwnerCard = ({ index }: { index: number }): JSX.Element => {
  const { values, validateForm } = useFormikContext<{
    owners: Owner[]
  }>()
  const owner = values.owners[index]

  useEffect(() => {
    validateForm()
  }, [])

  return owner.object === AGENT_INDIVIDUAL ? (
    <>
      <div>
        <Content>
          <b>{owner.name}</b>
        </Content>
      </div>
      <div />
      <GapWrapper>
        <Attribute label='Job Title(s)'>
          <Content>{owner.titles?.join(', ')}</Content>
        </Attribute>
        <Attribute label='Email'>
          <Content>{owner.email}</Content>
        </Attribute>
        <Attribute label='Ownership Percentage'>
          <Content>{owner.ownership_percentage}%</Content>
        </Attribute>
        <IndividualErrors fieldName={`owners[${index}]`} />
      </GapWrapper>
    </>
  ) : (
    <>
      <div>
        <Content>
          <b>{owner.legal_name}</b>
        </Content>
      </div>
      <div />
      <GapWrapper>
        {owner.dba_name && (
          <Attribute label='DBA Name'>
            <Content>{owner.dba_name}</Content>
          </Attribute>
        )}
        <Attribute label='EIN'>
          <Content>{owner.ein}</Content>
        </Attribute>
        <Attribute label='Contact email'>
          <Content>{owner.contact_email}</Content>
        </Attribute>
        <Attribute label='Ownership Percentage'>
          <Content>{owner.ownership_percentage}%</Content>
        </Attribute>
        <IndividualErrors fieldName={`owners[${index}]`} />
      </GapWrapper>
    </>
  )
}

const IndividualCard = ({ index }: { index: number }): JSX.Element => {
  const { values, validateForm } = useFormikContext<{
    owners: Individual[]
  }>()
  const { name, titles, email, ownership_percentage } = values.owners[index]

  useEffect(() => {
    validateForm()
  }, [index])

  return (
    <>
      <div>
        <Content>
          <b>{name}</b>
        </Content>
      </div>
      <div />
      <GapWrapper>
        <Attribute label='Job Title(s)'>
          <Content>{titles?.join(', ')}</Content>
        </Attribute>
        <Attribute label='Email'>
          <Content>{email}</Content>
        </Attribute>
        <Attribute label='Ownership Percentage'>
          <Content>{ownership_percentage}%</Content>
        </Attribute>
        <IndividualErrors fieldName={`owners[${index}]`} />
      </GapWrapper>
    </>
  )
}

export default IndividualCard
