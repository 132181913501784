import React from 'react'
import styled from 'styled-components'
import BORDER_RADIUS from '../BorderRadius'
import { COLORS } from '../Colors'

export type CloseXVariant = 'grey' | 'green'

export type CloseXProps = {
  className?: string
  variant?: CloseXVariant
}

const CloseX = styled(({ className, variant = 'grey' }: CloseXProps) => {
  let stroke = COLORS.karl

  if (variant === 'grey') {
    stroke = COLORS.karl
  } else {
    stroke = COLORS.green
  }

  const strokeLinecap = 'round'
  const strokeLinejoin = 'round'
  const strokeWidth = BORDER_RADIUS.xxsmall

  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <path
        d='M13.5 4.5L4.5 13.5'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
      <path
        d='M4.5 4.5L13.5 13.5'
        {...{ stroke, strokeLinecap, strokeLinejoin, strokeWidth }}
      />
    </svg>
  )
})`
  cursor: pointer;
`

export default CloseX
