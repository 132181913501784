import React from 'react'
import { theme } from '@middesk/components'
import styled from 'styled-components'
import { IntakeSidebarContentProps } from '../types'
import CheckPoint from './Checkpoint'
import { MAX_SIDEBAR_WIDTH } from './System/Sidebar'
import { MAX_WIDTH_MOBILE } from './Body'

const { spacing, colors, typography } = theme

const IntakeSidebarContent = styled(
  ({
    className,
    title,
    content,
    groups,
    current
  }: IntakeSidebarContentProps): JSX.Element => (
    <div {...{ className }}>
      <h3>{title}</h3>
      {content}
      {groups.map(({ title, page }: { title: string; page: number }, i) => {
        return (
          <CheckPoint
            key={title}
            text={title}
            enabled={current >= page}
            isComplete={
              current - 1 >= groups[i].page - 1 + (groups[i].pageCount || 1)
            }
            isCurrent={
              page <= current &&
              ((i < groups.length - 1 && groups[i + 1].page > current) ||
                i === groups.length - 1)
            }
          />
        )
      })}
    </div>
  )
)`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    display: none;
  }

  color: ${colors.karl};
  display: flex;
  flex-direction: column;
  font-size: ${typography.sizes.medium};
  max-width: ${MAX_SIDEBAR_WIDTH};
  padding: 0px 40px;

  > h3 {
    color: ${colors.graphite};
    font-size: ${typography.sizes.display.small};
    margin-bottom: ${spacing.compact};
  }
`

export default IntakeSidebarContent
