import Cookies from 'js-cookie'
import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useHistory } from 'react-router'
import Brand from '../components/Brand'
import LandingCard from '../components/LandingCard'
import { COLORS } from '../components/System/Colors'
import LandingGlobalStyle from '../LandingGlobalStyle'
import useInterval from '../lib/useInterval'

const Logout = (): JSX.Element => {
  const { push } = useHistory()

  useInterval(() => {
    if (!Cookies.get('current_user')) {
      push('/login')
    }
  }, 500)

  return (
    <>
      <LandingGlobalStyle />
      <LandingCard>
        <Row center='xs'>
          <Col>
            <Brand color={COLORS.black} height='45px' />
            <p>Signing out...</p>
          </Col>
        </Row>
      </LandingCard>
    </>
  )
}

export default Logout
