import { createGlobalStyle } from 'styled-components'
import { COLORS } from './components/System/Colors'

const LandingGlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLORS.dawn};
    height: 100vh;

    > div#root {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        flex: none;
      }
    }
  }
`

export default LandingGlobalStyle
