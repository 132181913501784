import React from 'react'
import { ContainerProps } from '../../../types'
import { theme } from '@middesk/components'

const { colors } = theme

const RedCircledPencil = ({ className }: ContainerProps): JSX.Element => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <circle cx='15' cy='15' r='15' fill={colors.redLight} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.6202 8.24642C19.4249 8.05116 19.1083 8.05116 18.9131 8.24642L10.9812 16.1783C10.8912 16.2683 10.8193 16.3746 10.7691 16.4915L9.20707 20.1363C9.12653 20.3243 9.16852 20.5423 9.31309 20.6869C9.45766 20.8314 9.67568 20.8734 9.8636 20.7929L13.5084 19.2308C13.6254 19.1807 13.7317 19.1087 13.8216 19.0188L21.7535 11.0869C21.8473 10.9931 21.9 10.8659 21.9 10.7333C21.9 10.6007 21.8473 10.4735 21.7535 10.3798L19.6202 8.24642ZM11.6883 16.8854L19.2666 9.30708L20.6929 10.7333L13.1145 18.3117L11.5259 18.9925L11.0074 18.4741L11.6883 16.8854Z'
        fill={colors.red}
      />
    </svg>
  )
}

export default RedCircledPencil
