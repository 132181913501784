import React, { createContext, useEffect, useState } from 'react'
import API from '../lib/api'
import APISingleStateQuestions from '../lib/api/singleStateQuestions'
import {
  Question,
  LocalQuestion,
  SingleStateQuestionResponse,
  TaxTypesByIntent
} from '../types'
import {
  FOREIGN_QUALIFICATION_TYPE,
  MANAGED_BY_MIDDESK,
  PAYROLL_REGISTRATION_TYPES
} from '../lib/constants'
import { intersection } from 'lodash'

interface ApplicationProps {
  state: string
  localJurisdictionsInState: any[]
  questions: any
  fetchingQuestions: boolean
  setState: (s: string) => void
  setQuestions: (q: any) => void
  setFetchingQuestions: (e: boolean) => void
  taxTypesByIntent: TaxTypesByIntent
  setTaxTypesByIntent: (taxTypesByIntent: TaxTypesByIntent) => void
  fetchingLocalQuestions: boolean
  setLocalJurisdictionSlugs: (slugs: string[]) => void
  localQuestionsMap: Record<string, LocalQuestion[]>
  hideTopBar: boolean
  setHideTopBar: (e: boolean) => void
}

export const ApplicationContext = createContext<ApplicationProps>({
  state: '',
  localJurisdictionsInState: [],
  questions: [],
  fetchingQuestions: false,
  setState: () => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFetchingQuestions: (e: boolean) => undefined,
  setQuestions: () => undefined,
  taxTypesByIntent: {},
  setTaxTypesByIntent: () => undefined,
  fetchingLocalQuestions: false,
  setLocalJurisdictionSlugs: () => undefined,
  localQuestionsMap: {},
  hideTopBar: false,
  setHideTopBar: () => undefined
})

export const ApplicationProvider = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const [state, setState] = useState<string>('')
  const [questions, setQuestions] = useState<Question[]>([])
  const [taxTypesByIntent, setTaxTypesByIntent] = useState<TaxTypesByIntent>({})
  const [fetchingQuestions, setFetchingQuestions] = useState<boolean>(true)
  const [localJurisdictionsInState, setLocalJurisdictionsInState] = useState([])
  const [hideTopBar, setHideTopBar] = useState<boolean>(false)

  const [localJurisdictionSlugs, setLocalJurisdictionSlugs] = useState<
    string[]
  >([])
  const [fetchingLocalQuestions, setFetchingLocalQuestions] = useState<boolean>(
    false
  )
  const [localQuestionsMap, setLocalQuestionsMap] = useState({})

  useEffect(() => {
    if (!state) return

    setFetchingQuestions(true)
    APISingleStateQuestions.get({
      categories: [...PAYROLL_REGISTRATION_TYPES, FOREIGN_QUALIFICATION_TYPE],
      state: state
    })
      .then(({ data = [] }: SingleStateQuestionResponse) => {
        setQuestions(data)
      })
      .catch(e => {
        /* eslint-disable-next-line no-console */
        console.log(e)
      })
      .finally(() => {
        setFetchingQuestions(false)
      })

    API.get(`/v1/agent/jurisdictions/${state}`)
      .then(json => setLocalJurisdictionsInState(json.data))
      /* eslint-disable-next-line no-console */
      .catch(err => console.error(err))
  }, [state])

  useEffect(() => {
    if (localJurisdictionSlugs.length === 0) return
    setFetchingLocalQuestions(true)

    const params = {
      state,
      jurisdiction_slugs: localJurisdictionSlugs
    }

    API.get('/v1/agent/questions', params)
      .then(json => setLocalQuestionsMap(json.data))
      .catch(err => {
        /* eslint-disable-next-line no-console */
        console.error(err)
      })
      .finally(() => setFetchingLocalQuestions(false))
  }, [localJurisdictionSlugs])

  const managedTaxTypes = taxTypesByIntent[MANAGED_BY_MIDDESK] || []
  // if we filter out the categories through the API request, we risk
  // the questions stored in state being out of sync with the taxTypesByIntent
  // so we filter here to avoid that asynchronous race condition issue
  const filteredQuestions = questions.filter(
    ({ categories }) =>
      intersection(categories, [...managedTaxTypes, FOREIGN_QUALIFICATION_TYPE])
        .length > 0
  )

  return (
    <ApplicationContext.Provider
      value={{
        state,
        localJurisdictionsInState,
        questions: filteredQuestions,
        fetchingQuestions,
        setState,
        setQuestions,
        setFetchingQuestions,
        taxTypesByIntent,
        setTaxTypesByIntent,
        fetchingLocalQuestions,
        setLocalJurisdictionSlugs,
        localQuestionsMap,
        hideTopBar,
        setHideTopBar
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}
