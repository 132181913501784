import React from 'react'
import { Dropzone, DropzoneFile } from '@middesk/components'
import Files from './Files'
import { SignatureUploaderProps } from '../types'

const PROCESSING_DURATION = 500

const SignatureUploader = ({
  onDrop,
  onSetFiles,
  files
}: SignatureUploaderProps) => {
  return (
    <>
      <Dropzone
        {...{
          label: 'Upload signature',
          name: 'signature',
          onDrop: (
            data: DropzoneFile[],
            setProcessing: (e: boolean) => void
          ) => {
            onDrop(data)

            setTimeout(() => {
              setProcessing(false)
            }, PROCESSING_DURATION)
          },
          activeText: 'Drop signature',
          inactiveText: 'Drop signature file here',
          loadingText: 'Processing signature'
        }}
      />
      <Files
        {...{
          files: files,
          setFiles: onSetFiles
        }}
      />
    </>
  )
}

export default SignatureUploader
