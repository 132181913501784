import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ContainerProps } from '../../../types'
import Button from '../../System/Button'
import Card from '../../System/Card'
import Loader from '../../System/Loader'
import styled from 'styled-components'
import { LargeDisplay } from '../../System/Typography'
import { Icons, Link, Tab, Tabs, theme } from '@middesk/components'
import IconBubble from '../../System/IconBubble'
import Document from '../../System/Icons/Document'
import { openFAQ } from '../../../lib/helpers'
import Insights from './Insights'
import States from './States'
import {
  APICompanyStateStatuses,
  StateStatus
} from '../../../lib/api/companyStateStatuses'
import { APICompanyInsights, Insight } from '../../../lib/api/companyInsights'

const { spacing, colors, typography } = theme

const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`

const ZeroStateColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.compact};
  margin-top: 30px;
  width: 100%;

  > div:first-child {
    align-self: center;
  }
`

const StyledArrowUpRight = styled(Icons.ArrowUpRight)`
  stroke: ${colors.karl};
  height: 15px;
  width: 15px;
  vertical-align: middle;
`

const HelpCenterRow = styled.div`
  justify-content: flex-start;
  display: flex;
  margin-top: ${spacing.large};
  color: ${colors.karl};

  > a {
    color: ${colors.karl};
    font-weight: ${typography.weights.normal};
  }

  > a:hover {
    text-decoration: underline;
  }
`

const TabContainer = styled.div`
  margin-top: ${spacing.medium};
`

const TabContent = styled.div`
  margin-top: ${spacing.medium};
  margin-left: ${spacing.xsmall};
`

const REGISTRATION_STATUS_HELP_CENTER_LINK =
  'https://help.middesk.com/hc/en-us/articles/8102498969115-Registration-Process-Overview'

const AccountsCard = ({
  className,
  companyId
}: ContainerProps & { companyId: string }): JSX.Element => {
  const { push } = useHistory()
  const [loading, setLoading] = useState(true)
  const [insights, setInsights] = useState<Array<Insight>>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [stateStatuses, setStateStatuses] = useState<Array<StateStatus>>([])
  const [stateStatusesLoading, setStateStatusesLoading] = useState(true)

  useEffect(() => {
    APICompanyInsights.show(companyId).then(({ data }) => {
      setInsights(data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    APICompanyStateStatuses.show(companyId).then(({ data }) => {
      setStateStatuses(data)
      setStateStatusesLoading(false)
    })
  }, [])

  const viewAccounts = () => push(`/companies/${companyId}/accounts/overview`)

  const totalCountInsight = insights.find(
    insight => insight.key == 'total_count'
  )

  const Header = () => (
    <HeaderRow>
      <LargeDisplay>Accounts</LargeDisplay>
      <Button
        {...{
          onClick: () => viewAccounts(),
          type: 'secondary',
          disabled: !totalCountInsight?.value
        }}
      >
        View <span>Accounts</span>
      </Button>
    </HeaderRow>
  )

  const ZeroState = () => (
    <ZeroStateColumn>
      <IconBubble variant='magenta'>
        <Document />
      </IconBubble>
      <>Register a state to view your accounts</>
      <Link onClick={openFAQ}>Learn more in our Help Center</Link>
    </ZeroStateColumn>
  )

  const HelpCenterLink = () => (
    <HelpCenterRow>
      <Link href={REGISTRATION_STATUS_HELP_CENTER_LINK} target='_blank'>
        Learn more about registration statuses <StyledArrowUpRight />
      </Link>
    </HelpCenterRow>
  )

  return (
    <Card {...{ className, variant: 'primary', size: 'large' }}>
      <div style={{ width: '100%' }}>
        <Header />
        <TabContainer>
          <Tabs
            activeIndex={activeIndex}
            onTab={selectedIndex => setActiveIndex(selectedIndex)}
          >
            <Tab>States</Tab>
            <Tab>Statuses</Tab>
          </Tabs>
          <TabContent>
            <Loader {...{ loading }}>
              {totalCountInsight?.value ? (
                <>
                  {activeIndex === 0 && (
                    <States
                      companyId={companyId}
                      loading={stateStatusesLoading}
                      stateStatuses={stateStatuses}
                    />
                  )}
                  {activeIndex === 1 && (
                    <Insights companyId={companyId} insights={insights} />
                  )}
                  <HelpCenterLink />
                </>
              ) : (
                <ZeroState />
              )}
            </Loader>
          </TabContent>
        </TabContainer>
      </div>
    </Card>
  )
}

export default AccountsCard
