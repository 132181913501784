import styled from 'styled-components'
import { theme } from '@middesk/components'

const { spacing } = theme

const FilterCollection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${spacing.small};
`

export default FilterCollection
