import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'
import SPACING from '../Spacing'

const OrderSubmitted = styled(({ className }: ContainerProps) => {
  return (
    <svg
      width={SPACING.xxlarge}
      height={SPACING.xxlarge}
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{ className }}
    >
      <circle cx='30.5' cy='30' r='30' fill='#9DF2BB' />
      <path
        d='M24.2 15.6001H36.2337C36.9901 15.6001 37.7117 15.9173 38.2231 16.4746L45.9894 24.9378C46.4464 25.4359 46.7 26.0873 46.7 26.7633V45.3001C46.7 46.7912 45.4911 48.0001 44 48.0001H24.2C22.7088 48.0001 21.5 46.7912 21.5 45.3001V18.3001C21.5 16.8089 22.7088 15.6001 24.2 15.6001Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.6341 13.8003H20.6004C19.1092 13.8003 17.9004 15.0091 17.9004 16.5003V43.5003C17.9004 44.9914 19.1092 46.2003 20.6004 46.2003H40.4004C41.8915 46.2003 43.1004 44.9914 43.1004 43.5003V24.9635C43.1004 24.2875 42.8468 23.6361 42.3898 23.138L34.6235 14.6748C34.1121 14.1175 33.3905 13.8003 32.6341 13.8003ZM19.7004 16.5003C19.7004 16.0032 20.1033 15.6003 20.6004 15.6003H32.6341C32.8863 15.6003 33.1268 15.706 33.2972 15.8918L41.0635 24.355C41.2158 24.521 41.3004 24.7382 41.3004 24.9635V43.5003C41.3004 43.9973 40.8974 44.4003 40.4004 44.4003H20.6004C20.1033 44.4003 19.7004 43.9973 19.7004 43.5003V16.5003Z'
        fill='#333333'
      />
      <path
        d='M32.3 24.6003H42.2C42.697 24.6003 43.1 25.0032 43.1 25.5003C43.1 25.9973 42.697 26.4003 42.2 26.4003H31.4C30.903 26.4003 30.5 25.9973 30.5 25.5003V14.7003C30.5 14.2032 30.903 13.8003 31.4 13.8003C31.897 13.8003 32.3 14.2032 32.3 14.7003V24.6003Z'
        fill='#333333'
      />
      <path
        d='M33.8327 31.5L29.2493 36.0833L27.166 34'
        stroke='#333333'
        strokeWidth='2.22222'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
})``

export default OrderSubmitted
