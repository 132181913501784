import React, { useContext, useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../lib/api'
import startCase from 'lodash/startCase'
import { parse, stringify } from 'qs'
import pluralize from 'pluralize'
import styled from 'styled-components'
import { AuthContext } from '../contexts/AuthProvider'
import { COLORS } from '../components/System/Colors'
import { TYPOGRAPHY_WEIGHTS } from '../components/System/Typography'
import Sidebar from '../components/Sidebar'
import Button from '../components/System/Button'
import Page from '../components/System/Layout/Page'
import queryStringSerializer from '../lib/queryStringSerializer'
import { DateTime, Icons, List, ListPagination } from '@middesk/components'
import AddUserModal from '../components/AddUserModal'
import UpdateUserModal from '../components/UpdateUserModal'
import RemoveUserModal from '../components/RemoveUserModal'
import SPACING from '../components/System/Spacing'
import { DEFAULT_PER_PAGE_COUNT, DEFAULT_PAGE_NUM } from '../lib/constants'
import { ContainerProps } from '../types'
import { SkeletonArray, StyledListControls } from '../components/Shared'
import Skeleton from 'react-loading-skeleton'

const { Edit, Delete } = Icons

export interface User {
  id: string
  name: string
  email: string
  roles: Array<string>
  last_login_at: string
  settings: { receives_agent_emails: boolean }
}

const Name = styled.span`
  font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
`

const Inactive = styled.span`
  color: ${COLORS.karl};
`

const UserUpdate = styled.div`
  margin-right: ${SPACING.small};
  display: inline-block;
`

const UserRemove = styled.div`
  display: inline-block;
`

const StyledEdit = styled(Edit)`
  stroke: ${COLORS.karl};
`

const StyledDelete = styled(Delete)`
  stroke: ${COLORS.karl};
`

export const Team = styled(({ className }: ContainerProps) => {
  const { addToast } = useToasts()
  const [fetching, setFetching] = useState(true)
  const [addingUser, setAddingUser] = useState(false)
  const [userBeingRemoved, setUserBeingRemoved] = useState<User | null>(null)
  const [userBeingUpdated, setUserBeingUpdated] = useState<User | null>(null)
  const [reinvitedUserIds, setReinvitedUserIds] = useState<string[]>([])
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)
  const { user: currentUser } = useContext(AuthContext)
  const { push } = useHistory()
  const location = useLocation()
  const { search } = location

  const params = parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    fetchUsers()
  }, [location])

  const handleResendInvitation = (id: string): void => {
    api
      .post(`/users/${id}/reinvite`, { invite_target: 'agent' })
      .then(() => {
        setReinvitedUserIds([...reinvitedUserIds, id])
        addToast(`Invitation resent`, { appearance: 'success' })
      })
      .catch(() => {
        addToast(`Invitation could not be resent`, { appearance: 'error' })
      })
  }

  const handleAddUserSubmit = () => {
    fetchUsers()
    setAddingUser(false)
  }

  const handleRemoveUserSubmit = () => {
    fetchUsers()
    setUserBeingRemoved(null)
  }

  const handleUpdateUserSubmit = () => {
    fetchUsers()
    setUserBeingUpdated(null)
  }

  const fetchUsers = () => {
    const defaultParams = {
      page: DEFAULT_PAGE_NUM,
      per_page: DEFAULT_PER_PAGE_COUNT
    }

    api
      .get(`/v1/users`, { ...defaultParams, ...params })
      .then(json => {
        setUsers(json.data)
        setTotal(json.total_count)
      })
      .finally(() => setFetching(false))
  }

  const {
    per_page = DEFAULT_PER_PAGE_COUNT,
    page = DEFAULT_PAGE_NUM
  }: {
    [key: string]: any
  } = params as any

  const onChange = (nextParams: { [key: string]: any }): void => {
    push(`/team?${queryStringSerializer(params, nextParams)}`)
  }

  const onFilter = (params: { [key: string]: undefined | string }) => {
    const query = stringify(
      {
        ...parse(search, { ignoreQueryPrefix: true }),
        ...params
      },
      { skipNulls: true }
    )

    push(`/team?${query}`)
  }

  const onPage = (page: number) => onChange({ page: page })

  const onSort = (key?: string) => {
    let { sort_by, sort_order } = parse(search, {
      ignoreQueryPrefix: true
    })

    if (key === sort_by) {
      sort_order = sort_order === 'asc' ? 'desc' : 'asc'
    } else {
      sort_by = key
      sort_order = 'desc'
    }

    onFilter({ sort_by, sort_order })
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      width: 4,
      render: ({ name, last_login_at }: User) => {
        if (!last_login_at) {
          return <Inactive>-</Inactive>
        }

        return <Name>{name}</Name>
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 4,
      render: ({ email, last_login_at }: User) => {
        if (!last_login_at) {
          return <Inactive>{email}</Inactive>
        }

        return <span>{email}</span>
      }
    },
    {
      title: 'Role',
      key: 'roles',
      width: 4,
      render: ({ roles, last_login_at }: User) => {
        const role = startCase(roles[0])

        if (!last_login_at) {
          return <Inactive>{role}</Inactive>
        }

        return <span>{role}</span>
      }
    },
    {
      title: 'Last Login',
      key: 'last_login_at',
      width: 4,
      render: ({ id, last_login_at }: User) => {
        if (last_login_at) {
          return <DateTime formatter={undefined}>{last_login_at}</DateTime>
        }

        if (!reinvitedUserIds.includes(id)) {
          return (
            <Button type='secondary' onClick={() => handleResendInvitation(id)}>
              Resend Invitation
            </Button>
          )
        }

        return <Inactive>Invitation sent</Inactive>
      }
    },
    {
      title: 'Notifications',
      key: 'receives_agent_emails',
      width: 4,
      render: ({ settings, last_login_at }: User) => {
        const receivesAgentEmails = settings.receives_agent_emails
          ? 'Yes'
          : 'No'

        if (!last_login_at) {
          return <Inactive>{receivesAgentEmails}</Inactive>
        }

        return <span>{receivesAgentEmails}</span>
      }
    },
    {
      title: '',
      key: 'user_update',
      width: 2,
      render: (user: User) => {
        return (
          <>
            {user.email !== currentUser?.email && (
              <>
                <UserUpdate onClick={() => setUserBeingUpdated(user)}>
                  <StyledEdit />
                </UserUpdate>
                <UserRemove onClick={() => setUserBeingRemoved(user)}>
                  <StyledDelete />
                </UserRemove>
              </>
            )}
          </>
        )
      }
    }
  ]

  return (
    <Page
      {...{
        title: 'Team',
        subtitle: 'Invite teammates to your account.',
        className
      }}
    >
      <Sidebar />
      {addingUser ? (
        <AddUserModal
          onClose={() => setAddingUser(false)}
          onSubmit={handleAddUserSubmit}
          isOpen={addingUser}
        />
      ) : null}
      {userBeingUpdated ? (
        <UpdateUserModal
          onClose={() => setUserBeingUpdated(null)}
          user={userBeingUpdated}
          onSubmit={handleUpdateUserSubmit}
          isOpen={!!userBeingUpdated}
        />
      ) : null}
      {userBeingRemoved ? (
        <RemoveUserModal
          onClose={() => setUserBeingRemoved(null)}
          user={userBeingRemoved}
          onSubmit={handleRemoveUserSubmit}
          isOpen={!!userBeingRemoved}
        />
      ) : null}

      <List data={users} columns={columns} onSort={onSort}>
        <StyledListControls>
          <Button onClick={() => setAddingUser(true)}>Add user</Button>
          <div className='muted'>
            {fetching ? <Skeleton /> : <>{pluralize('User', total, true)}</>}
          </div>
          {total > per_page ? (
            <ListPagination
              onPage={onPage}
              page={parseInt(page)}
              perPage={per_page}
              total={total}
            />
          ) : (
            <div />
          )}
        </StyledListControls>
      </List>
      {fetching && <SkeletonArray />}
    </Page>
  )
})``
