import { CompanyAPIResource, Action } from '../../types'
import api from '../api'

export type GetActionsParameters = {
  state?: string
  sort?: string
}

export type GetActionsResponse = {
  actions: Array<Action>
  states_requiring_action: Array<string>
}

const APIAgentActions: Pick<
  CompanyAPIResource<GetActionsParameters, GetActionsResponse>,
  'index'
> = {
  index: (companyId, params): Promise<GetActionsResponse> =>
    api.get(`/v1/agent/companies/${companyId}/actions/`, params)
}

export default APIAgentActions
