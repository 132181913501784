import amplitude from 'amplitude-js'
import Cookies from 'js-cookie'
import { parse } from 'qs'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import Body from '../components/Body'
import LoaderCard from '../components/LoaderCard'
import Sidebar from '../components/Sidebar'
import Page from '../components/System/Layout/Page'
import { AuthContext } from '../contexts/AuthProvider'
import API from '../lib/api'
import Env from '../lib/env'

const Confirm = (): JSX.Element => {
  const { push } = useHistory()
  const { search } = useLocation()

  const { setFetchingUser, authenticated, fetching } = useContext(AuthContext)

  const { email, confirmation_token } = parse(search, {
    ignoreQueryPrefix: true
  })

  useEffect(() => {
    if (!authenticated) {
      API.post('/users/confirm', { email, confirmation_token })
        .then((json: any) => {
          const cookieOptions = Env.isProduction()
            ? { domain: 'middesk.com' }
            : {}
          Cookies.set('current_user', json.access_token, cookieOptions)

          amplitude.getInstance().logEvent('confirm-email', { email })

          setFetchingUser(true)
        })
        /* eslint-disable-next-line no-console */
        .catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (!fetching && authenticated) {
      push('/set-user-info')
    }
  }, [fetching])

  if (!email || !confirmation_token) {
    return <h2>Page not found</h2>
  }

  return (
    <Page title='Confirm'>
      <Sidebar />
      <Body
        title='Confirming account'
        description='Setting up your account, and redirecting you to find your business.'
      >
        <LoaderCard />
      </Body>
    </Page>
  )
}

export default Confirm
