import React from 'react'
import styled from 'styled-components'
import { ContainerProps } from '../../../types'
import { COLORS } from '../Colors'

const GreyX = styled(
  ({ className }: ContainerProps): JSX.Element => {
    return (
      <svg
        {...{ className }}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='12' cy='12' r='12' fill={COLORS.karl_l2} />
        <path
          d='M16 8L8 16'
          stroke={COLORS.karl}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 8L16 16'
          stroke={COLORS.karl}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
)``

export default GreyX
