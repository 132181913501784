import { Company, APIParams, AvailableAccountStateMapData } from '../../types'
import api from '../api'

const APICompany = {
  create: ({
    legal_name,
    ein
  }: {
    legal_name?: string
    ein?: string
  }): Promise<Company> => {
    return api.post('/v1/agent/companies', { legal_name, ein })
  },
  update: (params: APIParams): Promise<Company> => {
    return api.patch(`/v1/agent/companies/${params?.id}`, params)
  },
  show: (id: string): Promise<Company> => api.get(`/v1/agent/companies/${id}`),
  available_accounts: (id: string): Promise<AvailableAccountStateMapData> => {
    return api.get(`/v1/agent/companies/${id}/available_accounts`)
  },
  index: (params: {
    page?: number
    per_page?: number
    sort_by?: string
    sort_order?: string
    q?: string
  }): Promise<{ total_count: number; data: Company[] }> =>
    api.get('/v1/agent/companies', params)
}

export default APICompany
