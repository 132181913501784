import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import api from '../lib/api'
import GuestAccessDenied from './GuestAccessDenied'
import Body from './Body'
import SystemSidebar from './System/Sidebar'
import { COLORS } from './System/Colors'
import SPACING from './System/Spacing'
import { AuthContext } from '../contexts/AuthProvider'

const PageBackground = createGlobalStyle`
  html, body {
    background-color: ${COLORS.frost_l1};
    height: 100%;
  }
`

const Icon = styled.img`
  display: block;
  justify: center;
  height: 60px;
  width: 60px;
  margin: 1rem auto;
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`

const StyledBody = styled(Body)`
  padding-top: ${SPACING.xxlarge};
`

const StyledContent = styled.div`
  background-color: ${COLORS.white};
  padding: 40px;
`

const SOSRegistrationConfirmation = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { fetching, user } = useContext(AuthContext)
  const [confirmedApplication, setConfirmedApplication] = useState(false)

  useEffect(() => {
    if (!fetching && user) {
      api
        .post(`/v1/agent/applications/${id}/sos_registration_confirmation`, {})
        .then(() => {
          setConfirmedApplication(true)
        })
        .catch(() => {
          window.location.href = '/'
        })
    }
  }, [fetching, user])

  return (
    <>
      {!fetching && !user ? (
        <GuestAccessDenied />
      ) : (
        <>
          <PageBackground />
          <SystemSidebar
            hideMenu={true}
            fetchingApplication={!confirmedApplication}
          >
            <></>
          </SystemSidebar>
          <StyledBody
            title={confirmedApplication ? "You're all set!" : ''}
            contentsLoading={!confirmedApplication}
          >
            <StyledContent>
              <Icon src='/icons/order_submitted.svg' />
              <StyledCol>Thank you for confirming your registration!</StyledCol>
              <StyledCol>
                We&apos;ll get started on submitting your Payroll Tax
                registration.
              </StyledCol>
            </StyledContent>
          </StyledBody>
        </>
      )}
    </>
  )
}

export default SOSRegistrationConfirmation
