import styled from 'styled-components'
import { COLORS } from '../Colors'
import SPACING from '../Spacing'
import { BODY_MIN_WIDTH, MAX_WIDTH_MOBILE } from '../../Body'

export type BodyProps = {
  left: number
  background?: string
}

export const MIN_BODY_HEIGHT = '100vh'

const Body = styled.div`
  height: 100%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  > div:last-child {
    background: ${props =>
      props.background ? props.background : COLORS.frost_l1};
    min-height: ${MIN_BODY_HEIGHT};
    min-width: ${BODY_MIN_WIDTH}px;
    padding: ${SPACING.xlarge};

    &::-webkit-scrollbar {
      display: none;
    }

    ${({ left }: BodyProps) => {
      if (left) {
        return `
          margin-left: ${left}px;
          width: calc(100% - ${left}px);
        `
      }
    }}

    @media (max-width: ${MAX_WIDTH_MOBILE}) {
      padding-top: ${SPACING.small};
      padding-bottom: ${SPACING.small};
    }
  }
`

export default Body
