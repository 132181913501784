import React from 'react'
import styled, { css } from 'styled-components'
import SPACING from '../../../System/Spacing'
import { Body } from '../../../System/Typography'
import Link from '../../../System/Link'
import { DepartmentElement } from '../AccountInformation'

const Styles = css`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.small};
`

const DepartmentOfUnemploymentAssistance = styled(
  ({ className }): JSX.Element => {
    return (
      <div {...{ className }}>
        <Body>
          1. Navigate to{' '}
          <Link
            href={'https://uionline.detma.org/Employer/Core/Login.ASPX'}
            target='_blank'
          >
            https://uionline.detma.org/Employer/Core/Login.ASPX
          </Link>
        </Body>
        <Body>2. Log in using your credentials</Body>
        <Body>
          3. On the home page, select <b>Account Maintenance</b>
        </Body>
        <Body>
          4. On this page, select <b>Address Information</b>
        </Body>
        <Body>
          5. Select <b>Mailing</b>
        </Body>
        <Body>
          6. Input new address as <b>56 Broad St STE 14028, Boston, MA 02109</b>
        </Body>
        <Body>
          7. Select <b>Save</b>
        </Body>
        <Body>
          8. Select the verified address, ensuring PMB 73938 was not erased
        </Body>
        <Body>
          9. Select <b>Next</b>
        </Body>
        <Body>
          10. Under <b>Update Additional Addresses</b>, select{' '}
          <b>Benefit Charges</b> and <b>Wage and Separation</b>
        </Body>
        <Body>
          11. Select <b>Submit</b>
        </Body>
      </div>
    )
  }
)`
  ${Styles}
`

const DepartmentOfRevenue = styled(({ className }) => {
  return (
    <div {...{ className }}>
      <Body>
        1. Navigate to{' '}
        <Link href='https://mtc.dor.state.ma.us/mtc/_/' target='_blank'>
          https://mtc.dor.state.ma.us/mtc/_/
        </Link>{' '}
        and log in with your credentials at the top right corner
      </Body>
      <Body>
        2. On the main menu, select <b>More...</b>
      </Body>
      <Body>
        3. Scroll down to <b>Names & Addresses</b>
      </Body>
      <Body>
        4. Click <b>Manage Names & Addresses</b>
      </Body>
      <Body>
        5. Select the tab <b>Addresses</b>
      </Body>
      <Body>
        6. Under <b>Defaults</b> select the current mailing address
      </Body>
      <Body>
        7. Select <b>Change the Address</b>
      </Body>
      <Body>
        8. Input new address as <b>56 Broad St STE 14028, Boston, MA 02109</b>{' '}
      </Body>
      <Body>
        9. Click <b>Verify Address</b>
      </Body>
      <Body>
        10. Select the address as it matches the above, confirming PMB 73938 was
        not removed
      </Body>
      <Body>
        11. Select <b>Next</b>
      </Body>
      <Body>
        12. Review that the address is correct and select <b>Submit</b>
      </Body>
      <Body>13. Confirm submission by inputting your password</Body>
      <Body>
        14. Repeat this process by selecting <b>Change your Address</b> next to
        the Withholding Tax information on the Addresses page
      </Body>
      <br />
    </div>
  )
})`
  ${Styles}
`

const Massachusetts = (): Array<DepartmentElement> => [
  {
    name: '',
    children: [],
    renderMailingAddressCallout: true
  },
  {
    link: 'https://mtc.dor.state.ma.us/mtc/_/',
    name: 'Department of Revenue (DOR)',
    children: <DepartmentOfRevenue />
  },
  {
    link: 'https://uionline.detma.org/Employer/Core/Login.ASPX',
    name: 'Department of Unemployment Assistance (DUA)',
    children: <DepartmentOfUnemploymentAssistance />,
    renderCompleteLater: true
  }
]

export default Massachusetts
