import React, { useContext } from 'react'
import styled from 'styled-components'
import { theme } from '@middesk/components'
import RadioField from '../System/RadioField'

import { AuthContext } from '../../contexts/AuthProvider'
import { ApplicationContext } from '../../contexts/ApplicationProvider'

const { spacing, typography, colors } = theme

const StyledContainer = styled.div`
  padding: ${spacing.xlarge};
  border-radius: ${typography.sizes.medium};
  border: 1px solid ${colors.frost};
  margin-bottom: ${spacing.xlarge};
  font-size: ${typography.sizes.medium};

  .label {
    display: inline-block;
    color: ${colors.graphite};
    font-weight: ${typography.weights.bold};
  }
`

const ItemWrapper = styled.ul`
  margin-top: 0;
  margin-left: 15px;
`

const LocalRegistrationOptions = ({
  jurisdiction,
  localPriceInCents
}: {
  jurisdiction: string
  localPriceInCents: number
}): JSX.Element => {
  const { localJurisdictionsInState } = useContext(ApplicationContext)
  const {
    transactionalAccount,
    account: {
      settings: {
        agent: { skip_payment = false }
      }
    }
  } = useContext(AuthContext)
  const unbundledReferral = transactionalAccount && !skip_payment

  const localJurisdiction = (localJurisdictionsInState || []).find(
    local => local.slug == jurisdiction
  )

  if (!localJurisdiction) {
    return <></>
  }

  const priceLabel = unbundledReferral ? ` ($${localPriceInCents / 100})` : ''
  const agencyItems: string[] = localJurisdiction.agency_items || []

  return (
    <StyledContainer>
      <RadioField
        label={
          <div className='label'>
            Register for {localJurisdiction.name}
            {priceLabel}
          </div>
        }
        checked={true}
        name={`managed-jurisdictions-${jurisdiction}`}
        value={'managed'}
        style={{ marginBottom: spacing.small }}
      />
      {agencyItems.length > 0 && (
        <ItemWrapper>
          {agencyItems.map((item, i) => (
            <li key={`item-${i}`}>{item}</li>
          ))}
        </ItemWrapper>
      )}
    </StyledContainer>
  )
}

export default LocalRegistrationOptions
