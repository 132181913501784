import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { COLORS } from './System/Colors'
import { TYPOGRAPHY_SIZES, TYPOGRAPHY_WEIGHTS } from './System/Typography'

const LineItem = styled(
  ({
    name,
    children,
    className
  }: {
    className?: string
    name: string
    children: ReactNode
  }) => (
    <div {...{ className }}>
      <span>{name}</span>
      <span>{children}</span>
    </div>
  )
)`
  align-items: center;
  border-bottom: 2px solid ${COLORS.dawn};
  color: ${COLORS.graphite};
  display: flex;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  justify-content: space-between;
  padding: 10px 0;

  > span:first-child {
    font-weight: ${TYPOGRAPHY_WEIGHTS.semibold};
  }

  &:last-child {
    border-bottom: none;
  }
`

export default LineItem
