import React, { useContext, useEffect } from 'react'
import { Dropdown, Icon, theme } from '@middesk/components'
import amplitude from 'amplitude-js'
import { useHistory, useParams } from 'react-router'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

import { AuthContext } from '../../contexts/AuthProvider'
import { ADMIN_ROLES } from '../../lib/constants'
import Line from '../System/Line'
import { openFAQ } from '../../lib/helpers'
import API from '../../lib/api'
import styled from 'styled-components'
import { User } from '../../types'
import { CompanyContext } from '../CompanyProvider'

const { colors, typography } = theme

const DropdownContainer = styled.div`
  .menu {
    width: 90%;
    padding: 0;
    max-height: 50%;
    overflow: scroll;
  }

  .toggle {
    width: 90%;
    box-sizing: border-box;
    border: 1px solid ${colors.frost};
    border-radius: 4px;
    color: ${colors.graphite};
    padding: 10px 10px 10px 20px;
    margin: 10px 10px 10px 20px;
    font-weight: ${typography.weights.bold};
    font-size: ${typography.sizes.large};
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .unnamed {
    color: ${colors.silasLight1};
    font-style: italic;
  }

  .bubble {
    height: 24px;
    width: 24px;
    font-size: ${typography.sizes.small};
    font-weight: ${typography.weights.normal};
  }
`

const CompanyName = ({ name }: { name?: string }) => (
  <div className={!name ? 'unnamed' : ''}>{name || 'Unnamed company'}</div>
)

const MainMenu = ({
  user,
  openFeedback
}: {
  user?: User
  openFeedback: () => void
}): JSX.Element => {
  const { push } = useHistory()
  const { activeCompanyId } = useContext(CompanyContext)
  const userHasAdminRole = ADMIN_ROLES.some(role => user?.roles.includes(role))

  const onSignout = () => {
    amplitude.getInstance().setUserId(null)
    API.logout()

    push('/signout')
  }

  return (
    <>
      <Dropdown.Option onClick={openFeedback}>Send feedback</Dropdown.Option>
      <Dropdown.Option
        onClick={() => push(`/companies/${activeCompanyId}/edit-information`)}
      >
        Edit information
      </Dropdown.Option>
      {userHasAdminRole && (
        <Dropdown.Option onClick={() => push('/billing')}>
          Billing
        </Dropdown.Option>
      )}
      {userHasAdminRole && (
        <Dropdown.Option
          onClick={() =>
            push('/team', {
              state: { companyId: activeCompanyId }
            })
          }
        >
          Team
        </Dropdown.Option>
      )}
      {
        <Dropdown.Option
          onClick={() =>
            push('/companies', {
              state: { companyId: activeCompanyId }
            })
          }
        >
          Entities
        </Dropdown.Option>
      }
      <Dropdown.Option onClick={openFAQ}>Help Center</Dropdown.Option>
      <Line />
      <Dropdown.Option onClick={onSignout}>Sign out</Dropdown.Option>
    </>
  )
}

const DropdownMenu = ({
  openFeedback
}: {
  openFeedback: () => void
}): JSX.Element | null => {
  const { companyId } = useParams<{ companyId: string }>()
  const { activeCompany, setActiveCompanyId } = useContext(CompanyContext)

  const { user, authenticated, inGuestMode } = useContext(AuthContext)

  if (!authenticated || inGuestMode) {
    return null
  }

  useEffect(() => {
    setActiveCompanyId(companyId)
  }, [])

  return (
    <DropdownContainer>
      <Dropdown>
        <Dropdown.Toggle className='toggle'>
          {activeCompany ? (
            <div className='flex'>
              <CompanyName name={activeCompany.legal_name} />
              <Icon name='caretDown' size={16} />
            </div>
          ) : (
            <SkeletonTheme
              baseColor={colors.dawn}
              highlightColor={colors.frost}
            >
              <Skeleton />
            </SkeletonTheme>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu'>
          <MainMenu user={user} openFeedback={openFeedback} />
        </Dropdown.Menu>
      </Dropdown>
    </DropdownContainer>
  )
}

export default DropdownMenu
