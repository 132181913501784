import React from 'react'
import { TaxAgencyRegistration } from '../../types'
import {
  BLOCKED_BY_GOVERNMENT,
  GOVERNMENT_PROCESSING,
  HIRE_DATE,
  LIABILITY,
  PAYROLL_DATE,
  PORTAL_UNAVAILABLE,
  SECRETARY_OF_STATE_TYPE
} from '../../lib/constants'
import { getDowntimeNotice } from '../../lib/helpers'
import styled from 'styled-components'
import { Icon, theme } from '@middesk/components'
import moment from 'moment'
import { capitalize } from 'lodash'

const { colors, typography, spacing } = theme

const IconWrapper = styled.div`
  > svg {
    height: 12px;
    width: 12px;
  }
`

const TurnaroundTimeElement = styled.span`
  color: ${colors.graphite};
  font-size: ${typography.sizes.small};
`

const DowntimeNoticeElement = styled.div`
  color: ${colors.magenta};
  font-weight: ${typography.weights.bold};
  font-size: ${typography.sizes.small};
  margin-top: 10px;
  margin-right: 5px;
`

const TurnaroundTimeNoticeContainer = styled.div`
  padding: ${spacing.large};
  border-radius: 10px;
  border: 1px solid ${colors.frost};
  background: ${colors.frostLight};
  margin-top: ${spacing.large};
`

const TurnAroundTimeElementWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.xsmall};
`

const getBlockedByText = (
  agency: string,
  blocked_by?: string,
  blocked_until?: string
) => {
  switch (blocked_by) {
    case SECRETARY_OF_STATE_TYPE:
      return `The ${agency} requires that the Secretary of State registration is complete before registering`
    case PAYROLL_DATE:
      return `The ${agency} requires that the employee's first payroll date has passed before registering. Middesk will process your registration ${
        blocked_until
          ? `on ${moment(blocked_until).format('MMMM Do YYYY')}`
          : 'at that time'
      }`
    case HIRE_DATE:
      return `The ${agency} requires that the employee's date of hire has passed before registering. Middesk will process your registration ${
        blocked_until
          ? `on ${moment(blocked_until).format('MMMM Do YYYY')}`
          : 'at that time'
      }`
    case LIABILITY:
      return `The ${agency} requires a number of weeks worked by an employee to have passed, or reach a certain amount of wages paid before registering.
                Middesk will process your registration ${
                  blocked_until
                    ? `on ${moment(blocked_until).format('MMMM Do YYYY')}`
                    : 'at that time'
                }`
    case PORTAL_UNAVAILABLE:
      return `The ${agency} portal is unavailable at present. Middesk will monitor availablity and process your registration when the portal is back online`
    default:
      return `The ${agency} will not accept this registration ${
        blocked_until
          ? `until ${moment(blocked_until).format('MMMM Do YYYY')}`
          : 'at present'
      } due to ${capitalize(blocked_by?.replaceAll('_', ' '))}`
  }
}

const getTurnaroundTime = (tatFloor: number, tatCeiling: number) => {
  if (tatFloor === tatCeiling) {
    if (tatFloor === 0) {
      return 'Immediately'
    } else if (tatFloor === 1) {
      return `${tatFloor} business day`
    } else if (tatFloor % 7 === 0) {
      return `${tatFloor / 7} weeks`
    }

    return `${tatFloor} business days`
  } else {
    if (tatFloor % 7 === 0 && tatCeiling % 7 === 0) {
      return `${tatFloor / 7}-${tatCeiling / 7} weeks`
    }

    return `${tatFloor}-${tatCeiling} business days`
  }
}

const TurnAroundTimeBanner = ({
  taxAgencyRegistration,
  agencyData
}: {
  taxAgencyRegistration: TaxAgencyRegistration
  agencyData: any[]
}) => {
  const {
    state,
    agency,
    status,
    blocked_by,
    blocked_until
  } = taxAgencyRegistration
  const agencyInformation = agencyData?.find(
    a => a.state === state && a.agency_name === agency
  )

  if (
    !agencyInformation ||
    ![GOVERNMENT_PROCESSING, BLOCKED_BY_GOVERNMENT].includes(status)
  ) {
    return <></>
  }

  const turnaroundTimeText = getTurnaroundTime(
    agencyInformation.tat_floor,
    agencyInformation.tat_ceiling
  )

  const downtimeNotice = getDowntimeNotice(agencyInformation)

  const GovernmentProcessingBanner = () => (
    <TurnaroundTimeNoticeContainer>
      <TurnAroundTimeElementWrapper>
        <IconWrapper>
          <Icon name='clock' />
        </IconWrapper>
        <TurnaroundTimeElement>
          Average government processing time: <b>{turnaroundTimeText}</b> after
          registration submitted
        </TurnaroundTimeElement>
      </TurnAroundTimeElementWrapper>
      {downtimeNotice && (
        <DowntimeNoticeElement>{downtimeNotice}</DowntimeNoticeElement>
      )}
    </TurnaroundTimeNoticeContainer>
  )

  const BlockedByGovernmentBanner = () => (
    <TurnaroundTimeNoticeContainer>
      <TurnAroundTimeElementWrapper>
        <IconWrapper>
          <Icon name='crossCircled' />
        </IconWrapper>
        <TurnaroundTimeElement>
          {getBlockedByText(agency, blocked_by, blocked_until)}
        </TurnaroundTimeElement>
      </TurnAroundTimeElementWrapper>
    </TurnaroundTimeNoticeContainer>
  )

  return status === GOVERNMENT_PROCESSING ? (
    <GovernmentProcessingBanner />
  ) : (
    <BlockedByGovernmentBanner />
  )
}

export default TurnAroundTimeBanner
